import React from 'react'
import { useSelector } from 'react-redux'
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = () => {
  const partnerSettings = useSelector((state) => state.global.partner)

  var Global
  if (partnerSettings) {
    Global = createGlobalStyle`
      html {
        --color-newsletter-color: ${partnerSettings.main_colors.primary_color};
        --color-primary: ${partnerSettings.main_colors.primary_color};
        --color-primary-hover: ${
          partnerSettings.main_colors.hover_primary_color
        };
        --color-secondary: ${partnerSettings.main_colors.secondary_color};
        --color-secondary-hover: ${
          partnerSettings.main_colors.hover_secondary_color
        };
        --color-link: ${partnerSettings.main_colors.link_color};
        --color-link-hover: ${partnerSettings.main_colors.link_hover_color};
        --title-color: ${partnerSettings.main_colors.title_color};
        --background-color: ${partnerSettings.main_colors.background_color};
        --image-newsletter: url(${
          partnerSettings.images.secondary_image_footer
        });
        --background-image-newsletter: ${
          partnerSettings.images.secondary_image_footer
            ? 'var(--image-newsletter)'
            : partnerSettings.main_colors.secondary_color
        };
        --image-footer: url(${partnerSettings.images.primary_image_footer});
        --background-image-footer: ${
          partnerSettings.images.primary_image_footer
            ? 'var(--image-footer)'
            : partnerSettings.main_colors.primary_color
        };
        --color-button-primary-bg: ${
          partnerSettings.button_colors.primary_background_color
        };
        --color-button-primary-bg-hover: ${
          partnerSettings.button_colors.primary_hover_background_color
        };
        --color-button-primary-text: ${
          partnerSettings.button_colors.primary_text_color
        };
        --color-button-primary-text-hover: ${
          partnerSettings.button_colors.primary_hover_text_color
        };
        --color-button-secondary-bg: ${
          partnerSettings.button_colors.secondary_background_color
        };
        --color-button-secondary-bg-hover: ${
          partnerSettings.button_colors.secondary_hover_background_color
        };
        --color-button-secondary-text: ${
          partnerSettings.button_colors.secondary_text_color
        };
        --color-button-secondary-text-hover: ${
          partnerSettings.button_colors.secondary_hover_text_color
        };
        --notifications-text: ${
          partnerSettings.notifications.notifications_text_color
        };
        --notifications-bg: ${
          partnerSettings.notifications.notifications_background_color
        };
        --image-notifications: url(${
          partnerSettings.notifications.notifications_background_image
        });
        --background-image-notifications: ${
          partnerSettings.notifications.notifications_background_image
            ? 'var(--image-notifications)'
            : partnerSettings.notifications.notifications_background_color
        };



        .MuiTab-root{
          min-width: 30px;
          padding: 4px;
        }
       
      }

      .MuiTabs-scrollable {
          overflow-x: Hidden;
          padding-left: 230px;
          padding-right: 230px;

          @media only screen and (max-width: 600px) {
            /* padding-left: 230px;
            padding-right: 230px; */

        }
      }

      .MuiTabs-flexContainer {
        justify-content: center;
        width: 100%;

        @media only screen and (max-width: 769px) {
        }
      }

      .MuiTab-root{
        padding: 16px;
        align-self: center;
      }

      .MuiTabs-centered {
        justify-content: center;
        padding: 16px;
      }


    `
  } else {
    Global = createGlobalStyle`html {}`
  }

  return <Global />
}

export default GlobalStyles
