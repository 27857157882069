import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import PageLoading from "components/pageLoading";

import {
  Container,
  Row,
  Form,
  FormGroup,
  FormCheck,
  FormControl,
  Alert,
} from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import LazyLoad from "react-lazyload";

import {
  TextFieldController,
  SelectFieldController,
  AutocompleteController,
} from "components/forms";
import CryptJS from "services/cryptJS";

import { showRegisterModal, userLogin } from "store/actions";

import {
  signUpRequest,
  treatRequestErrors,
  getCitiesRequest,
} from "services/api";

import ReCAPTCHA from "react-google-recaptcha";

import { classNames } from "utils";
import styles from "./index.module.scss";
import OiLogo from "./images/Oi_Alegria_RGB.png";

import Header from "../../components/header";
import Footer from "../../components/footer";
const Crypt = new CryptJS();

const CadastroOi = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { register, handleSubmit, control, errors, getValues, setValue } =
    useForm({
      reValidateMode: "onSubmit",
    });
  const [show, setShow] = useState(false);
  const [requestErrors, setRequestErrors] = useState([]);
  const [recaptchaHasLoaded, setRecaptchaHasLoaded] = useState(false);
  const [recaptchaVerify, setRecaptchaVerify] = useState(false);
  const [errorId, setErrorId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);

  const [crypted] = useState(
    Crypt.decrypt(decodeURIComponent(props.match.params.cpf))
  );
  const [userDecoded, setUserDecoded] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    register("city", {
      validate: (value) => value.length || "Campo obrigatório.",
    });
  }, [register]);

  useEffect(() => {
    const crypted = Crypt.decrypt(decodeURIComponent(props.match.params.cpf));
    if (
      typeof crypted === "string" &&
      crypted.includes("token") &&
      crypted !== null
    ) {
      setUserDecoded(JSON.parse(crypted));
    } else {
      setMessage(
        "Os dados estão inválidos. Por favor, tente novamente ou entre em contato com o SAC."
      );
    }
  }, [props]);

  useEffect(() => {
    if (userDecoded) {
      if (userDecoded === "error") {
        history.push("/erro/contaOi");
        return null;
      }
    }
  }, [crypted, history, userDecoded]);

  const recaptchaChange = (value) => {
    setRecaptchaVerify(value);
    if (errorId === "invalid_recaptcha" && Boolean(value)) {
      setErrorId(null);
    }
  };

  const validateCPF = () => {
    var strCPF = getValues("cpf");
    strCPF = strCPF.replaceAll(".", "").replace("-", "");
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (var i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  };

  const validForm = () => {
    if (!recaptchaVerify) {
      setErrorId("invalid_recaptcha");
      return null;
    } else if (!validateCPF()) {
      setRequestErrors(["CPF inválido"]);
      return false;
    } else {
      setErrorId(null);
    }

    return true;
  };

  const getCities = (e) => {
    setValue("city", null);
    getCitiesRequest(e.target.value).then((cities) => setCities(cities));
  };

  const onSubmit = async (user) => {
    if (!validForm()) return null;

    setLoading(true);

    signUpRequest(user, recaptchaVerify, userDecoded.token)
      .then((response) => {
        if (response.success) {
          response.headers.provider = "nds_oi";

          dispatch(userLogin(response));
          dispatch(showRegisterModal(false));

          if (sessionStorage.getItem("AU_redirect")) {
            window.location.href = sessionStorage.getItem("AU_redirect");
            sessionStorage.removeItem("AU_redirect")
          } else {
            window.location.href = "/ofertas";
          }
        } else {
          setRequestErrors(
            treatRequestErrors(response.errors || response.message)
          );
          setShow(true);
        }
      })
      .catch((err) => {
        setRequestErrors(["Erro ao se conectar com o servidor"]);
        setShow(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <PageLoading>
      <Header />
      <Container className={styles.cadastroContainer}>
        <Row>
          <LazyLoad once>
            <img src={OiLogo} className={styles.logo} alt="Oi +Alegria" />
          </LazyLoad>
        </Row>
        <Row>
          {message ? (
            <p className="text-center mt-5 pt-5 mb-5 pb-5">
              <h4 className="mt-5 pt-5 mb-5 pb-5">{message}</h4>
            </p>
          ) : (
            <p className="text-center">
              Seja bem-vindo ao <strong>Oi +Alegria</strong>, nosso clube de
              vantagens. Antes de começar, realize seu cadastro. É bem rápido!
            </p>
          )}
        </Row>
        <Row>
          {userDecoded && (
            <Form
              className={styles.cadastroForm}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <div className="col-12 pt-3">
                  <TextFieldController
                    label="Nome completo"
                    name="name"
                    control={control}
                    errors={errors}
                  />
                </div>
                <div className="col-12 pt-3">
                  <TextFieldController
                    label="CPF"
                    name="cpf"
                    defaultValue={userDecoded.cpf}
                    disabled={true}
                    control={control}
                    errors={errors}
                  />
                </div>
                <div className="col-12 pt-3">
                  <TextFieldController
                    label="E-mail"
                    name="email"
                    control={control}
                    errors={errors}
                  />
                </div>

                <div className="col-12 pt-3">
                  <SelectFieldController
                    options={[
                      { value: "m", label: "Homem cisgênero" },
                      { value: "f", label: "Mulher cisgênero" },
                      { value: "mt", label: "Homem transgênero" },
                      { value: "ft", label: "Mulher transgênero" },
                      { value: "pnb", label: "Pessoa não binária" },
                      { value: "n", label: "Prefiro não dizer" },
                    ]}
                    name="gender"
                    label="Gênero"
                    control={control}
                    errors={errors}
                  />
                </div>

                <div className="col-6 pt-3">
                  <TextFieldController
                    label="Celular"
                    type="tel"
                    name="mobile_number"
                    mask="(99) 99999-9999"
                    control={control}
                    errors={errors}
                    rules={{
                      pattern: /(\(\d{2}\)\s)(\d{5}-\d{4})/,
                    }}
                  />
                </div>

                <div className="col-6 pt-3">
                  <TextFieldController
                    label="Data de nascimento"
                    name="born_date"
                    mask="99/99/9999"
                    control={control}
                    errors={errors}
                    rules={{
                      pattern:
                        /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                    }}
                  />
                </div>

                <div className="col-6 pt-3">
                  <SelectFieldController
                    options={[
                      { value: "AC", label: "Acre" },
                      { value: "AL", label: "Alagoas" },
                      { value: "AP", label: "Amapá" },
                      { value: "AM", label: "Amazonas" },
                      { value: "BA", label: "Bahia" },
                      { value: "CE", label: "Ceará" },
                      { value: "DF", label: "Distrito Federal" },
                      { value: "ES", label: "Espírito Santo" },
                      { value: "GO", label: "Goiás" },
                      { value: "MA", label: "Maranhão" },
                      { value: "MT", label: "Mato Grosso" },
                      { value: "MS", label: "Mato Grosso do Sul" },
                      { value: "MG", label: "Minas Gerais" },
                      { value: "PA", label: "Pará" },
                      { value: "PB", label: "Paraíba" },
                      { value: "PR", label: "Paraná" },
                      { value: "PE", label: "Pernambuco" },
                      { value: "PI", label: "Piauí" },
                      { value: "RR", label: "Roraima" },
                      { value: "RO", label: "Rondônia" },
                      { value: "RJ", label: "Rio de Janeiro" },
                      { value: "RN", label: "Rio Grande do Norte" },
                      { value: "RS", label: "Rio Grande do Sul" },
                      { value: "SC", label: "Santa Catarina" },
                      { value: "SP", label: "São Paulo" },
                      { value: "SE", label: "Sergipe" },
                      { value: "TO", label: "Tocantins" },
                    ]}
                    name="state"
                    label="Estado"
                    handleChange={getCities}
                    control={control}
                    errors={errors}
                  />
                </div>

                <div className="col-6 pt-3">
                  <AutocompleteController
                    label="Cidade"
                    name="city"
                    options={cities}
                    onChange={(e, options) => {
                      setCity(options.value);
                      setValue("city", options.value);
                    }}
                    control={control}
                    errors={errors}
                    value={
                      getValues("city") === null ? getValues("city") : city
                    }
                  />
                </div>
              </Row>

              <FormGroup className="text-left mt-4">
                <FormCheck
                  type="checkbox"
                  id="terms_and_conditions"
                  name="terms_and_conditions"
                  label="Li e aceito os termos desse contrato"
                  ref={register({ required: true })}
                />
                {errors.terms_and_conditions && (
                  <span className={styles.error}>Campo obrigatório</span>
                )}
                <FormCheck
                  type="checkbox"
                  id="newsletter"
                  name="newsletter"
                  label="Autorizo o envio de e-mails com promoções e ofertas exclusivas"
                  ref={register}
                />

                <FormControl
                  type="hidden"
                  defaultValue="nds_oi"
                  id="provider"
                  name="provider"
                  ref={register}
                />

                <FormControl
                  type="hidden"
                  defaultValue="123teste"
                  id="password"
                  name="password"
                  ref={register}
                />
              </FormGroup>

              <p className={classNames(styles.warning, "text-center")}>
                Ao dar aceite estou de acordo com as{" "}
                <a
                  aria-label="condições gerais de uso"
                  href={`${process.env.REACT_APP_SERVER_HOST_URL}/Regulamento_Clube_OI+Alegria_V2.pdf`}
                  alt="Condições Gerais de Uso"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  condições gerais de uso
                </a>{" "}
                e compra de produtos e serviços do site e conformidade com as{" "}
                <a
                  aria-label="condições gerais de uso"
                  href={`${process.env.REACT_APP_SERVER_HOST_URL}/Politica%20de%20Privacidade%20Clube%20+Alegria.pdf`}
                  alt="Condições Gerais de Uso"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  políticas de privacidade
                </a>{" "}
                do clube maisalegria.com
              </p>
              <FormGroup>
                {!recaptchaHasLoaded && (
                  <CircularProgress
                    color="inherit"
                    style={{ alignSelf: "center" }}
                  />
                )}
                <ReCAPTCHA
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={recaptchaChange}
                  asyncScriptOnLoad={() => setRecaptchaHasLoaded(true)}
                />
                {errorId === "invalid_recaptcha" && (
                  <div className={classNames(styles.error, "text-center")}>
                    Por favor, verifique que não é um robô
                  </div>
                )}
              </FormGroup>

              {show && (
                <Alert
                  variant="danger"
                  onClose={() => setShow(false)}
                  dismissible
                >
                  Atenção! <br />
                  {requestErrors && (
                    <ul>
                      {requestErrors.map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  )}
                </Alert>
              )}

              <div className={styles.botaoVerde}>
                <button
                  aria-label="ir para o site"
                  type="submit"
                  name="submit"
                  className={styles.btnVerde}
                  disabled={loading}
                >
                  Ir para o site
                  {loading && (
                    <CircularProgress
                      color="inherit"
                      className={styles.circularProgress}
                    />
                  )}
                </button>
              </div>
            </Form>
          )}
        </Row>
      </Container>
      <Footer />
    </PageLoading>
  );
};

export default CadastroOi;
