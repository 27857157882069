import React from "react";

import styles from "./index.module.scss";
import { classNames, formatMonetaryValue } from "utils";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

export default function () {
  const plan = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.plan
  );

  return (
    <Card className={classNames("m-auto", styles.bgNavy, styles.cardAlegria)}>
      <Card.Body>
        <h5 className="card-title text-center text-white h2">
          {formatMonetaryValue(parseFloat(plan.price_in_money))}
        </h5>
      </Card.Body>
      <p
        className={classNames(
          styles.bgOrange,
          "card-text text-center text-white p-2"
        )}
      >
        <strong>{plan.title}</strong>
      </p>
      <ul
        className={classNames(
          styles.planDescription,
          "list-group list-group-flush"
        )}
      >
        <li
          className={classNames(
            styles.bgNavy,
            "list-group-item text-white bg-navy"
          )}
        >
          {ReactHtmlParser(plan.summary)}
        </li>
      </ul>
    </Card>
  );
}
