import React from "react";
import clsx from "clsx";
import css from "../../index.module.scss";
import useIsMobile from "utils/useIsMobile";

export default function ({ program }) {
  const { isMobile } = useIsMobile();
  return (
    <>
      <p
        className={clsx(
          "font-weight-bold",
          css["color-darkgray"],
          css[`${isMobile ? "font-size-md" : "font-size-lg"}`]
        )}
        style={{ marginTop: !isMobile ? 20 : 0 }}
      >
        {program.name}
      </p>

      <div
        dangerouslySetInnerHTML={{ __html: program.description }}
        style={{ maxWidth: 600 }}
      />
    </>
  );
}
