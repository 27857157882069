import React from 'react'
import { Controller } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      margin: 0,
      padding: 0,
    },
  },
  input: {
    '& .MuiInputBase-input': {
      backgroundColor: 'white',
      borderRadius: 4,
      height: '26px',
    },
    '& fieldset': {
      borderColor: '#ECECEE',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
}))

const AutocompleteController = ({
  name,
  label,
  errors,
  onChange,
  options,
  multiple = false,
  defaultValue,
  control,
  value,
  style,
  required = true,
  getOptionSelected = (option, value) => option.value === value,
  variant = 'outlined',
  type = 'text',
}) => {
  const classes = useStyles()
  let error = Boolean(errors && errors[name])

  const BaseComponent = (props) => (
    <Autocomplete
      options={options}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      getOptionSelected={getOptionSelected}
      onChange={onChange}
      value={value}
      multiple={multiple}
      className={classes.root}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: false,
            style: {
              position: 'relative',
              top: '-20px',
              left: '-13px',
            },
          }}
          style={style}
          type={type}
          label={label}
          error={error}
          variant={variant}
          className={classes.input}
          helperText={error ? `Campo obrigatório` : ''}
        />
      )}
    />
  )

  return control ? (
    <Controller
      as={(props) => <BaseComponent {...props} />}
      name={name}
      rules={{ required: required }}
      control={control}
      defaultValue={defaultValue || ''}
    />
  ) : (
    <BaseComponent
      required={required}
      name={name}
      defaultValue={defaultValue || ''}
    />
  )
}

export default AutocompleteController
