import React from "react";
import loadable from "@loadable/component";

import "react-multi-carousel/lib/styles.css";

import styles from "./index.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useIsMobile from "utils/useIsMobile";
import { Paper } from "@material-ui/core";
import clsx from "clsx";
import { Card } from "react-bootstrap";

const StyledTitle = loadable(() => import("components/styledTitle"), {
  fallback: <></>,
});

const OfferRelated = ({ related, noResultsComponent = null }) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? (
        <>
          <div className={styles.desktopContainer}>
            <div className="mt-5">
              <StyledTitle title={"Ofertas Relacionadas"} />

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                {related.map((r) => (
                  <Paper
                    key={r.title}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      height: 230,
                      padding: 16,
                    }}
                  >
                    <div style={{ position: "relative", flex: 1 }}>
                      <img
                        src={r.image_url}
                        alt="ofertas relacionadas"
                        style={{
                          maxWidth: 100,
                          maxHeight: 45,
                          marginBottom: 16,
                          flex: 1,
                        }}
                      />
                      <Card.Title
                        className={styles.cardTitle}
                        style={{ position: "absolute", top: 50, width: "100%" }}
                      >
                        {r.title.length > 80
                          ? `${r.title.substring(0, 80)}...`
                          : r.title}
                      </Card.Title>
                    </div>
                    <button
                      aria-label=" ACESSE AQUI"
                      onClick={() => history.push(`/programa/oferta/${r.id}`)}
                      className={clsx(
                        styles["btn-produto"],
                        styles["btn-laranja"]
                      )}
                    >
                      Ver Oferta
                    </button>
                  </Paper>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 950 }}>
          <div className={styles.desktopContainer}>
            <div className="mt-5">
              <StyledTitle title={"Ofertas Relacionadas"} />

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(5, 1fr)",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                {related.map((r) => (
                  <Paper
                    key={r.title}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      height: 250,
                      padding: 16,
                    }}
                    onClick={() => history.push(`/programa/oferta/${r.id}`)}
                  >
                    <div
                      style={{
                        position: "relative",
                        flex: 1,
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={r.image_url}
                          alt="ofertas relacionadas"
                          style={{
                            maxWidth: 130,
                            maxHeight: 45,
                            marginBottom: 16,
                            flex: 1,
                            objectFit: "contain",
                          }}
                        />
                      </div>

                      <Card.Title
                        className={styles.cardTitle}
                        style={{ position: "absolute", top: 55, width: "100%" }}
                      >
                        {r.title.length > 80
                          ? `${r.title.substring(0, 80)}...`
                          : r.title}
                      </Card.Title>
                    </div>
                    <button
                      aria-label=" ACESSE AQUI"
                      onClick={() => history.push(`/programa/oferta/${r.id}`)}
                      className={clsx(
                        styles["btn-produto"],
                        styles["btn-laranja"]
                      )}
                    >
                      Ver Oferta
                    </button>
                  </Paper>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OfferRelated;
