import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormGroup from "@material-ui/core/FormGroup";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.scss";
import * as Actions from "../../store/actions";
import { getAddresses } from "services/api";
import AddressDialog from "components/address/AddressDialog";

import AddIcon from "@material-ui/icons/Add";
import AddressCard from "components/address/AddressCard";

export default function () {
  const dispatch = useDispatch();
  const billingAddress = useSelector(
    ({ orderCheckout }) => orderCheckout.billingAddress
  );

  const [initialLoading, setInitialLoading] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showNewAddressDialog, setNewAddressShowDialog] = React.useState(false);
  const [addresses, setAddresses] = React.useState(null);
  const [selectedAddress, setSelectedAddress] = React.useState(null);

  const fetchAddresses = useCallback(() => {
    setInitialLoading(true);
    getAddresses()
      .then((response) => {
        setAddresses(response);
        setSelectedAddress(billingAddress);
      })
      .finally(() => setInitialLoading(false));
  }, [billingAddress]);

  const handleClose = () => {
    setShowDialog(false);
  };

  const setNewBillingAddress = () => {
    if (selectedAddress) {
      dispatch(Actions.setBillingAddress(selectedAddress));
      setShowDialog(false);
      setNewAddressShowDialog(false);
    }
  };

  React.useEffect(() => {
    fetchAddresses();
  }, [fetchAddresses]);

  const validateAddress = (address) => {
    address.city = address.city.replace(/\s\s+/g, " ").trim();
    address.neighborhood = address.neighborhood.replace(/\s\s+/g, " ").trim();
    address.number = address.number.replace(/\s\s+/g, " ").trim();
    address.state = address.state.replace(/\s\s+/g, " ").trim();
    address.street = address.street.replace(/\s\s+/g, " ").trim();
    address.zip_code = address.zip_code.replace(/\s\s+/g, " ").trim();

    if (
      address.city === "" ||
      address.neighborhood === "" ||
      address.number === "" ||
      address.state === "" ||
      address.street === "" ||
      address.zip_code === ""
    ) {
      alert("Endereço inválido!");
    } else {
      setSelectedAddress(address);
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} className="pr-2 pt-2 mt-2">
          <div className="d-flex align-items-center">
            <h1 className={styles.sectionTitle2}>Endereço de Cobrança</h1>
            <span
              className="pl-2"
              onClick={() => setShowDialog(true)}
              style={{ cursor: "pointer" }}
            >
              (clique para alterar)
            </span>
          </div>
          {billingAddress.street}, {billingAddress.number}{" "}
          {billingAddress.complement}
          <br />
          {billingAddress.neighborhood} - {billingAddress.zip_code}
          <br />
          {billingAddress.city} - {billingAddress.state}
        </Col>
      </Row>

      <AddressDialog
        show={showNewAddressDialog}
        onClose={() => {
          setShowDialog(true);
          setNewAddressShowDialog(false);
          fetchAddresses();
        }}
      />

      <Dialog onClose={handleClose} open={showDialog}>
        <DialogContent>
          {initialLoading ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : (
            <FormGroup className={styles.formGroup}>
              <Row className="mt-3">
                <Col xs={12} className="d-flex mt-2">
                  <div
                    className={styles.addButton}
                    onClick={() => {
                      setShowDialog(false);
                      setNewAddressShowDialog(true);
                    }}
                  >
                    <AddIcon />
                    <Typography variant="h5" component="h2">
                      Novo Endereço
                    </Typography>
                  </div>
                </Col>
                {addresses &&
                  addresses.map((address) => (
                    <Col
                      xs={12}
                      className="mt-3 d-flex justify-content-center align-items-center"
                      key={address.id}
                    >
                      <Radio
                        checked={selectedAddress?.id === address.id}
                        onChange={() => validateAddress(address)}
                        value={address.id}
                        color="secondary"
                        name="address"
                      />

                      <AddressCard
                        address={address}
                        onClick={() => validateAddress(address)}
                        className={"flex-grow-1"}
                        style={{ cursor: "pointer" }}
                      />
                    </Col>
                  ))}
              </Row>
            </FormGroup>
          )}
        </DialogContent>

        <DialogActions className="justify-content-center">
          <Button
            aria-label="Selecionar"
            color="default"
            className="w-50"
            variant="contained"
            onClick={setNewBillingAddress}
          >
            Selecionar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
