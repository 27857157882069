import Switch from '@material-ui/core/Switch'
import styled from '@emotion/styled'
import check from './assets/check.png'

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 52,
  height: 32,
  padding: 1,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 24,
      marginLeft: 2,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(20px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    borderColor: 'red',
    borderWidth: 2,
       
    '& .MuiSwitch-thumb': {
      backgroundColor: '#979797',  
  
 
    },
   

    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff',
        width: 24,
        height: 24,
        marginTop: 2,
        marginLeft: 2,
      },

      transform: 'translateX(20px)',
      '& .MuiSwitch-thumb:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 1,
        top: 1,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${check})`,
        backgroundSize: '10px 10px',
      },

      color: '#fff',

      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#141414',
        border: "none",
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 16,
    marginTop: 5.5,
    marginLeft: 5.5,
    '&.Mui-checked': {
      backgroundColor: '#fff',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 16,
    opacity: 1,
    backgroundColor: '#D9D9D9',
    border: '2px solid #979797',
    boxSizing: 'border-box',
  },
}))

export default CustomSwitch
