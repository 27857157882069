import React, { useEffect } from "react";
import { createSelector } from "reselect";

import Header from "components/header";

import { Container, Row } from "react-bootstrap";
import Person from "@material-ui/icons/Person";
import Payment from "@material-ui/icons/Payment";
import Done from "@material-ui/icons/Done";
import Edit from "@material-ui/icons/Edit";
import Room from "@material-ui/icons/Room";
import Close from "@material-ui/icons/Close";

import Newsletter from "components/newsletter";
import Footer from "components/footer";
import StyledTitle from "components/styledTitle";
import CustomizedStepper from "components/customized-stepper";

import PlanInfo from "./PlanInfo";
import CouponComponent from "./Coupon";
import SignIn from "./components/SignIn";
import PersonalData from "./components/PersonalData";
import PaymentMethod from "./components/PaymentMethod";
import Confirmation from "./components/Confirmation";
import Finish from "./components/Finish";
import Address from "./components/Address";

import { useSelector, useDispatch } from "react-redux";
import withReducer from "store/withReducer";
import reducer from "./store/reducers";
import { isLoggedIn } from "store/selectors";
import * as Actions from "./store/actions";
import PageLoading from "components/pageLoading";

const iconToStep = {
  signin: <Person />,
  payment: <Payment />,
  address: <Room />,
  confirmation: <Edit />,
  finish: <Done />,
  rejected: <Close />,
};

function stepName(step, logged = false) {
  if (Array.isArray(step.description)) {
    return logged
      ? step.description[1] || step.description[0]
      : step.description[0];
  }

  return step.description;
}

function stepIsRejected(key, subscriptionResult) {
  if (key === "finish" && subscriptionResult) {
    return subscriptionResult.status === "Rejeitado";
  }

  return false;
}

const stepsFactory = createSelector(
  isLoggedIn,
  ({ subscriptionCheckout }) => subscriptionCheckout,
  (logged, subscriptionCheckout) =>
    subscriptionCheckout.steps.map((step) => {
      let isRejected = stepIsRejected(
        step.key,
        subscriptionCheckout?.subscriptionResult
      );

      return {
        ...step,
        description: stepName(step, logged),
        icon: isRejected
          ? iconToStep["rejected"]
          : iconToStep[step.key] || <Done />,
        reject: isRejected,
      };
    })
);

const SubscriptionCheckout = (props) => {
  const dispatch = useDispatch();
  const logged = useSelector(isLoggedIn);
  const currentStep = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.currentStep
  );
  const steps = useSelector(stepsFactory);

  const StepComponent = (props) => {
    if (currentStep.key === "finish") {
      return <Finish {...props} />;
    } else if (currentStep.key === "confirmation") {
      return <Confirmation {...props} />;
    } else if (currentStep.key === "payment") {
      return <PaymentMethod {...props} />;
    } else if (currentStep.key === "address") {
      return <Address {...props} />;
    } else if (currentStep.key === "signin" && logged) {
      return <PersonalData {...props} />;
    } else {
      return <SignIn {...props} />;
    }
  };

  useEffect(() => {
    let plan_id = props.match.params.id;
    if (plan_id) {
      dispatch(Actions.getPlan(plan_id));
    }
  }, [dispatch, props]);

  useEffect(() => {
    dispatch(Actions.setCurrentStep("signin"));
  }, [dispatch]);

  return (
    <PageLoading>
      <Header hideSearchBar={true} />
      <Container className="mt-5">
        <StyledTitle title={"Contratação do plano"} />

        <Row>
          <div className="col-12 mb-3">
            <CustomizedStepper steps={steps} activeStep={currentStep.index} />
          </div>
        </Row>
        <Row className="mb-5">
          <div className="col-12 col-md-4 mb-3">
            <PlanInfo />
            <div className="d-none d-md-block">
              {!["finish", "signin", "address"].includes(currentStep.key) && (
                <CouponComponent />
              )}
            </div>
          </div>

          <div className="col-12 col-md-8">
            <StepComponent />
          </div>
        </Row>

        <div className="d-block d-md-none mb-4">
          {!["finish", "signin", "address"].includes(currentStep.key) && (
            <CouponComponent />
          )}
        </div>
      </Container>
      <Newsletter />
      <Footer />
    </PageLoading>
  );
};

export default withReducer(
  "subscriptionCheckout",
  reducer
)(SubscriptionCheckout);
