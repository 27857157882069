import React from "react";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useStyles } from "./useStyle";
import check from "./images/check.png";
import celular from "../../../../assets/video/alegria.mp4";
import CtaButton from "components/ctaButton";
import { useHistory } from "react-router-dom";
import LazyLoad from "react-lazyload";

const Personalization = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box className={classes.wrapper}>
      <Grid container className={classes.container}>
        <Grid sx={{ display: "flex", flexDirection: "column" }} xs={12}>
          <Typography className={classes.title}>Personalização</Typography>
          <Typography className={classes.headline}>
            Utilize a identidade visual de sua marca
          </Typography>
        </Grid>
        <Box className={classes.videoWrapper}>
          <Grid item xs={12} md={8} className={classes.sectionContainer}>
            <LazyLoad>
              <Box className={classes.videoContainer}>
                <video
                  width={isMobile ? "345" : "764"}
                  height={isMobile ? "186" : "416"}
                  controls="controls"
                  preload="none"
                  className={classes.video}
                >
                  <source src={celular} type="video/mp4" />
                </video>
              </Box>
            </LazyLoad>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box className={classes.checkWrapper}>
              <Box className={classes.checkContainer}>
                <Box className={classes.checkBackground}>
                  <img src={check} alt="check" className={classes.check} />
                </Box>
                <Typography className={classes.checkMessage}>
                  Logo e cores de sua identidade visual
                </Typography>
              </Box>
              <Box className={classes.checkContainer}>
                <Box className={classes.checkBackground}>
                  <img src={check} alt="check" className={classes.check} />
                </Box>
                <Typography className={classes.checkMessage}>
                  Ambiente personalizável
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Box>
        <Grid xs={12} md={12}>
          <Box className={classes.personalizationFooter}>
            <Typography className={classes.subheadline}>
              Gostou da solução?
            </Typography>
            <CtaButton
              style={{ marginTop: "10px" }}
              onClick={() => history.push("/planos")}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Personalization;
