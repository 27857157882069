import React, { useState } from "react";
import { useSelector } from "react-redux";

import ClientMenu from "components/clientMenu/index";

import User from "./images/btn_user.webp";
import Avatar from "./images/avatar.png";

import { classNames } from "utils";
import { isLoggedIn } from "store/selectors";

import styles from "./index.module.scss";
import { makeStyles, Button } from "@material-ui/core";
import FallbackImage from "components/fallbackImage";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      [theme.breakpoints.down("md")]: {
        width: "121.539px",
        height: "36.26px",
        fontSize: "12px",
        lineHeight: "10px",
      },
      width: "154px",
      height: "45.945px",
      flexShrink: 0,
      borderRadius: "12.762px",
      background: "#13224C",
      color: "#fff",
      textAlign: "center",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: "13.43px",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "9.852px",
      transition: "background 0.3s ease, color 0.3s ease",
      "&:hover": {
        background: "#1E355F",
      },
    },
  };
});

const UserActions = () => {
  const classes = useStyles();
  const history = useHistory();
  // const order = JSON.parse(localStorage.getItem("AU_order"));
  const logged = useSelector(isLoggedIn);
  // const location = useLocation();
  // const [carrinhoQuantity, setCarrinhoQuantity] = useState(0);
  // const [total, setTotal] = useState(0);
  // const user = useSelector((state) => state.user.info);
  // const [cookies] = useCookies(["AU_cart_" + user?.id]);
  // const userCarrinho = order || (user && cookies["AU_cart_" + user.id]);

  // useEffect(() => {
  // setCarrinhoQuantity(
  //   userCarrinho
  //     ? userCarrinho.order.reduce(function (prev, cur) {
  //         return prev + cur.quantity;
  //       }, 0)
  //     : 0
  // );
  // setTotal(
  //   userCarrinho
  //     ? userCarrinho.order.reduce(function (prev, cur) {
  //         return prev + cur.value * cur.quantity;
  //       }, 0)
  //     : 0
  // );
  // }, [userCarrinho]);

  const [clientMenuModal, setClientMenuModal] = useState(false);

  return (
    <>
      <div className={styles.navUserWrapper}>
        <div className={classNames("user", "desktop", styles.deskUserAction)}>
          {logged ? (
            <>
              <div
                className={classNames(styles.clientMenuButton)}
                onClick={() => setClientMenuModal(!clientMenuModal)}
              >
                <FallbackImage width={24} height={24}>
                  <img src={User} alt="user" className={styles.userIcon} />
                </FallbackImage>
                <ClientMenu
                  onHeader={true}
                  open={clientMenuModal}
                  onHide={() => setClientMenuModal(false)}
                />
              </div>
            </>
          ) : (
            <div className={styles.enterContainer}>
              <Button
                id="login"
                onClick={() => {
                  history.push(`/partners`);
                }}
                className={classes.button}
                startIcon={
                  <FallbackImage width={24} height={24}>
                    <img src={Avatar} alt="user" className={styles.userIcon} />
                  </FallbackImage>
                }
              >
                Login
              </Button>

              {/* <span
                className={classNames(
                  "desk-nav-control-icons",
                  styles.desknavlink
                )}
                data-toggle="modal"
                data-target="#modalLogin"
              >
                Entrar
              </span> */}
            </div>
          )}
        </div>
        {/* <div
          className={classNames(
            "user",
            "desktop",
            styles.deskUserAction,
            styles.cart
          )}
        >
          <div className="d-lg-block">
            <LazyLoad once>
              <a
                aria-label="lnk para carrinho"
                href="/carrinho"
                className={classNames(
                  "desk-nav-control-icons",
                  styles.desknavlink
                )}
                data-target="#login"
              >
                <img src={Cart} alt="" className={styles.userIcon} />
                <span className={styles.cartQty}>
                  {carrinhoQuantity > 1
                    ? `${carrinhoQuantity} itens`
                    : `${carrinhoQuantity} item`}
                </span>
              </a>
            </LazyLoad>
          </div>
        </div> */}
        {/* {carrinhoQuantity > 0 &&
          location.pathname !== "/carrinho" &&
          location.pathname !== "/checkout" && (
            <div className={classNames(styles.cartMobile)}>
              <a aria-label="link para carrinho" href="/carrinho">
                <div className={styles.cartIcon}>
                  <LazyLoad once>
                    <img src={CartMobile} alt="" className={styles.userIcon} />
                  </LazyLoad>{" "}
                  {carrinhoQuantity > 1
                    ? `${carrinhoQuantity} itens`
                    : `${carrinhoQuantity} item`}
                </div>
                <div className={styles.cartValue}>
                  R$ {total.toFixed(2).toString().replace(".", ",")}
                </div>
              </a>
            </div>
          )} */}
      </div>
    </>
  );
};

export default UserActions;
