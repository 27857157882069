import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";

import ReCAPTCHA from "react-google-recaptcha";

const CircularProgress = loadable.lib(() =>
  import("@material-ui/core/CircularProgress")
);

const RecaptchaControl = React.forwardRef(
  ({ onChange, validate, invisible = false }, ref) => {
    const [recaptchaHasLoaded, setRecaptchaHasLoaded] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(false);
    const [errorId, setErrorId] = useState(null);

    const recaptchaChange = (value) => {
      setRecaptchaValue(value);
      if (errorId === "invalid_recaptcha" && Boolean(value)) {
        setErrorId(null);
      }

      if (typeof onChange === "function") {
        onChange(value);
      }
    };

    useEffect(() => {
      if (validate && !Boolean(recaptchaValue)) {
        setErrorId("invalid_recaptcha");
      }
    }, [validate, recaptchaValue]);

    return (
      <div className="d-flex flex-column justify-content-center">
        {!recaptchaHasLoaded && (
          <CircularProgress color="inherit" style={{ alignSelf: "center" }} />
        )}
        <ReCAPTCHA
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          ref={ref}
          size={invisible ? "invisible" : "normal"}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          onChange={recaptchaChange}
          asyncScriptOnLoad={() => setRecaptchaHasLoaded(true)}
        />
        {errorId === "invalid_recaptcha" && (
          <div
            className="text-center"
            style={{
              paddingLeft: 10,
              paddingTop: 3,
              fontSize: "0.9em",
              color: "red",
            }}
          >
            Por favor, verifique que não é um robô
          </div>
        )}
      </div>
    );
  }
);

export default RecaptchaControl;
