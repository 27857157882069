import React, { useState } from "react";
import { useSelector } from "react-redux";

import Footer from "components/footer";
import NewLayoutHeader from 'components/NewLayoutHeader';
// import Header from "components/header";
import Newsletter from "components/newsletter";

import PageLoading from "components/pageLoading";
import { getOffer } from "services/api";
import { isLoggedIn } from "store/selectors";
import OfferDetail from "./offerDetail";
import ProgramUnavailable from "./components/ProgramUnavailable";

const ProgramOffer = (props) => {
  const offerId = props.match.params.id;
  const logged = useSelector(isLoggedIn);
  const [offer, setOffer] = useState(null);
  const [related, setRelated] = useState({});

  React.useEffect(() => {
    if (offerId) {
      getOffer(logged, offerId, 100).then((res) => {
        setOffer(res.offer);
        setRelated(res.related);
      });
    }
  }, [logged, offerId]);

  return (
    <PageLoading>
      {/* <Header /> */}
      <NewLayoutHeader />
      {offer ? (
        <OfferDetail offer={offer} related={related} />
      ) : (
        <ProgramUnavailable />
      )}
      <Newsletter />
      <Footer />
    </PageLoading>
  );
};

export default ProgramOffer;
