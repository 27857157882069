import React from "react";
import {
  Grid,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Star from "./images/star.png";
import circle from "./images/360.png";
import search from "./images/search.png";
import gear from "./images/gear.png";
import dashboard from "./images/dashboard.png";
import check from "./images/check.png";
import ticket from "./images/ticket.png";
import email from "./images/email.png";
import { useStyles } from "./useStyle";

const bullets = [
  {
    text: "<strong>Entretenimento como <br/>diferencial<strong>",
    icon: ticket,
    iconHeight: 33,
    iconWidth: 36,
    iconMobileWidth: 18.6,
    iconMobileHeight: 17,
  },
  {
    text: "Criação de <br/><strong>artes e campanhas</strong>",
    icon: dashboard,
    iconHeight: 39,
    iconWidth: 30,
    iconMobileWidth: 15.3,
    iconMobileHeight: 20,
  },
  {
    text: "Entrega do produto <br/><strong>final para o cliente</strong>",
    icon: check,
    iconHeight: 32,
    iconWidth: 32,
    iconMobileWidth: 16,
    iconMobileHeight: 16,
  },
  {
    text: "<strong>Gerenciamento</strong> do <br/>ambiente e parceiros",
    icon: gear,
    iconHeight: 37,
    iconWidth: 37,
    iconMobileWidth: 19.1,
    iconMobileHeight: 18.7,
  },
  {
    text: "Disparo de <strong>e-mails</strong>",
    icon: email,
    iconHeight: 24,
    iconWidth: 43,
    iconMobileWidth: 22,
    iconMobileHeight: 12.3,
  },
  {
    text: " <strong>Captação</strong> de produtos <br/>alinhados com o cliente",
    icon: search,
    iconHeight: 31,
    iconWidth: 31,
    iconMobileWidth: 15.7,
    iconMobileHeight: 15.7,
  },
];

const HomeAdvantage = () => {
  const classes = useStyles();

  return (
    <Box container className={classes.container}>
      <Typography className={classes.title}>Solução</Typography>
      <Typography className={classes.headline}>
        Como podemos ajudar sua empresa
      </Typography>
      <Typography className={classes.subheadline}>
        <strong>O Clube +alegria, uma solução Easy Live,</strong> faz parte do
        grupo Dreamers, o maior conglomerado de entretenimento do Brasil, com
        mais de 50 anos de experiência.
      </Typography>
      <Box className={classes.sectionContainer}>
        <Grid item xs={12} md={4} className={classes.imageContainer}>
          <Box className={classes.imageBackground}>
            <img src={Star} alt="headline" className={classes.star} />
            <img src={circle} alt="headline" className={classes.circle} />
          </Box>
        </Grid>
        <Grid item xs={12} md={8} className={classes.bulletContainer}>
          {bullets.map((content) => Bullet(content))}
        </Grid>
      </Box>
    </Box>
  );
};

export const Bullet = (content) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box className={classes.bulletWrap}>
      <img
        src={content.icon}
        alt="icon"
        style={{
          marginRight: isMobile ? "12px" : "25px",
          width: isMobile ? content.iconMobileWidth : content.iconWidth,
          height: isMobile ? content.iconMobileHeight : content.iconHeight,
        }}
      />
      <Typography
        dangerouslySetInnerHTML={{ __html: content.text }}
        className={classes.bulletContent}
      />
    </Box>
  );
};

export default HomeAdvantage;
