import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, Col, Row } from "react-bootstrap";

import { classNames, scrollTo } from "utils";
import styles from "./index.module.scss";

import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";

import { updateUserData, getUserData } from "services/api";

import { SelectFieldController, TextFieldController } from "components/forms";

import * as Actions from "../../store/actions";
import { setHeaders } from "store/actions";

import moment from "moment";
import RecaptchaControl from "components/recaptchaControl";
moment().format();

const userRecaptchaRef = React.createRef();

export default function () {
  const dispatch = useDispatch();
  const { handleSubmit, control, errors, setValue } = useForm({
    reValidateMode: "onSubmit",
  });

  const [updateLoading, setUpdateLoading] = useState(false);
  const [showRepactcha, setShowRepactcha] = useState(false);
  const [tryToSubmit, setTryToSubmit] = useState(false);

  const [user, setUser] = useState(useSelector((state) => state.user.info));

  const onSubmit = (data) => {
    setTryToSubmit(true);
    let recaptchaValue = userRecaptchaRef?.current?.getValue() || "";
    if (!recaptchaValue) return false;
    scrollTo("#stepper");
    setUpdateLoading(true);
    let newValues = {
      ...data,
      born_date: moment(data.born_date, "DD/MM/YYYY").toDate(),
    };
    updateUserData(newValues, recaptchaValue)
      .then((response) => {
        dispatch(setHeaders(response.headers));
        userRecaptchaRef.current.reset();
        setUpdateLoading(false);
        dispatch(Actions.changeStep("next"));
      })
      .catch((err) => userRecaptchaRef.current.reset());
  };

  useEffect(() => {
    getUserData().then((response) => {
      if (response.success) {
        let bornDate = moment(response.data.born_date, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
        setUser({ ...response.data, born_date: bornDate });
      }
      setTimeout(() => setShowRepactcha(true), 700);
    });
  }, [setValue]);

  return (
    <div className={styles.rootContainer}>
      <Row>
        <Col className={classNames(styles.infosWrapper)}>
          <h1 className={styles.sectionTitle}>Dados pessoais</h1>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className={styles.formGroup}>
              <Row>
                <div className="col-12">
                  <TextFieldController
                    label="Nome completo"
                    name="name"
                    defaultValue={user.name}
                    control={control}
                    errors={errors}
                  />
                </div>

                <div className="col-12 pt-4">
                  <TextFieldController
                    label="E-mail"
                    name="email"
                    defaultValue={user.email}
                    control={control}
                    errors={errors}
                  />
                </div>

                <div className="col-12 pt-4">
                  <SelectFieldController
                    options={[
                      { value: "m", label: "Masculino" },
                      { value: "f", label: "Feminino" },
                      { value: "n", label: "Prefiro não dizer" },
                    ]}
                    name="gender"
                    label="Gênero"
                    defaultValue={user.gender}
                    control={control}
                    errors={errors}
                  />
                </div>

                <div className="col-12 col-sm-6 pt-4">
                  <TextFieldController
                    label="Data de nascimento"
                    name="born_date"
                    mask="99/99/9999"
                    defaultValue={user.born_date}
                    control={control}
                    errors={errors}
                    rules={{
                      pattern:
                        /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                    }}
                  />
                </div>

                <div className="col-12 col-sm-6 pt-4">
                  <TextFieldController
                    label="Telefone celular"
                    type="tel"
                    name="mobile_number"
                    defaultValue={user.mobile_number}
                    mask="(99) 99999-9999"
                    required={false}
                    control={control}
                    errors={errors}
                    rules={{
                      pattern:
                        /^(\([0-9][0-9]\) [0-9][0-9]{4}-[0-9]{4})|(\(1[2-9]\) [5-9][0-9]{3}-[0-9]{4})|(\([2-9][1-9]\) [5-9][0-9]{3}-[0-9]{4})$/,
                    }}
                  />
                </div>
              </Row>
              <Row className="pt-3">
                <div className="col-12">
                  {showRepactcha && (
                    <RecaptchaControl
                      validate={tryToSubmit}
                      ref={userRecaptchaRef}
                    />
                  )}
                </div>
              </Row>
              <Row className="pt-4">
                <div className={`col-12 mb-2 text-center`}>
                  <button
                    aria-label="avançar"
                    className={classNames("custom-btn-verde btn-form")}
                    type="submit"
                    name="submit"
                    disabled={updateLoading}
                  >
                    {updateLoading ? (
                      <CircularProgress
                        color="inherit"
                        className={styles.circularProgress}
                      />
                    ) : (
                      <div>Avançar</div>
                    )}
                  </button>
                </div>
              </Row>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
