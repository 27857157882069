import React from "react";
// import { useSelector } from "react-redux";

import Breadcumb from "components/breadcumb";
import CarouselContainer from "./components/CarouselContainer";
import TicketAndPriceSection from "./components/TicketAndPriceSection";
import OfferRelated from "components/offerRelated";
import useIsMobile from "utils/useIsMobile";
// import ProgramsRelated from "components/ProgramsRelated";
// import fallback from "./images/fallback.png";
// import OffersHighlights from "components/offersHighlight";
// import Tabs from "./components/Tabs";
// import InformationBanner from "./components/InformationBanner";

export default function OfferDetail({ offer, related }) {
  const { isMobile } = useIsMobile();

  return (
    <>
      <div className="container mb-4">
        <div className="d-none d-md-block mt-1">
          <Breadcumb currentPath={[{ route: "/ofertas", name: "Ofertas" }]} />
        </div>

        <div className="row mt-2">
          <div
            className={`col-12 col-md-4 ${!isMobile ? "" : "pl-5 pr-5"}`}
            style={{ height: "100%" }}
          >
            <CarouselContainer offer={offer} />
          </div>

          <div className="col-12 col-md-8">
            <TicketAndPriceSection offer={offer} />
          </div>
        </div>
      </div>

      {related.length > 0 && (
        <div className="container">
          <OfferRelated related={related} />
        </div>
      )}
    </>
  );
}
