export const removeDuplicatedAndSortByWeight = (
  { products = [], excludeIds = [] },
  groupRelated = false
) => {
  let ignoreIds = [...excludeIds];

  if (groupRelated) {
    var obj = {};
    for (var i = 0, len = products.length; i < len; i++)
      obj[products[i]["id"]] = products[i];

    products = [];
    for (var key in obj) products.push(obj[key]);
    return products.sort((a, b) => b.orderWeight - a.orderWeight);
  } else {
    return products
      .filter((prod) => {
        if (ignoreIds.includes(prod.id)) return false;

        ignoreIds.push(prod.id);
        return true;
      })
      .sort((a, b) => b.orderWeight - a.orderWeight);
  }
};
