import React from "react"
import { useSideWindowAction } from "context/SideWindow-context"
import { FilterIcon } from "assets/icons"

import styles from "./filterMobile.module.scss"

const FilterMobile = () => {
  const { openSideWindow } = useSideWindowAction()

  return (
    <div className={styles.container}>
      <button onClick={() => openSideWindow('MOBILE_FILTERS')}>
        <FilterIcon width='22px' height='18px'/>
        <p>Filtros</p>
      </button>
    </div>
  )
}

export { FilterMobile }
