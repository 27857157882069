import React, { useState, useEffect, useRef } from "react";
import loadable from "@loadable/component";

import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import styles from "./index.module.scss";
import { classNames } from "utils";
import { getLoginProvider } from "store/selectors";

import { Nav } from "react-bootstrap";

const PersonalData = loadable(
  () => import("pages/userpage/components/personal-data"),
  { fallback: <></> }
);
const Addresses = loadable(
  () => import("pages/userpage/components/addresses"),
  { fallback: <></> }
);
const History = loadable(() => import("pages/userpage/components/history"), {
  fallback: <></>,
});
const Subscriptions = loadable(
  () => import("pages/userpage/components/subscriptions"),
  { fallback: <></> }
);
const Orders = loadable(() => import("pages/userpage/components/orders"), {
  fallback: <></>,
});
const MyAccount = loadable(
  () => import("pages/userpage/components/my-account"),
  { fallback: <></> }
);
const GiftCard = loadable(() => import("pages/userpage/components/gift-card"), {
  fallback: <></>,
});
const DiscountCoupons = loadable(
  () => import("pages/userpage/components/discount-coupons"),
  { fallback: <></> }
);
const Masterclass = loadable(
  () => import("pages/userpage/components/masterclass"),
  { fallback: <></> }
);
const UserReferral = loadable(
  () => import("pages/userpage/components/referral"),
  { fallback: <></> }
);
const LogoutModal = loadable(() => import("../logoutModal"), {
  fallback: <></>,
});

const baseurl = "/usuario";

export const links = [
  {
    url: `${baseurl}`,
    title: "Minha conta",
    component: MyAccount,
  },
  {
    url: `${baseurl}/dados`,
    title: "Dados pessoais",
    component: PersonalData,
  },
  {
    url: `${baseurl}/enderecos`,
    title: "Meus endereços",
    component: Addresses,
  },
  {
    url: `${baseurl}/gerenciar-assinaturas`,
    title: "Gerenciar assinatura",
    component: Subscriptions,
    restrictions: {
      alternativeLinkWhenPlanIsMissing: "/planos",
    },
  },
  {
    url: [`${baseurl}/meus-pedidos`, `${baseurl}/meus-pedidos/*`],
    title: "Meus pedidos",
    component: Orders,
    restrictions: {
      hideWhenPlanIsMissing: true,
    },
  },
  // {
  //   url: `${baseurl}/meus-cartoes`,
  //   title: "Meus cartões",
  //   component: Cards,
  //   restrictions: {
  //     hideWhenPlanIsMissing: true,
  //   },
  // },
  {
    url: [`${baseurl}/vale-presente`, `${baseurl}/vale-presente/*`],
    title: "Vale-presente",
    component: GiftCard,
  },
  {
    url: [`${baseurl}/cupons-de-desconto`, `${baseurl}/cupons-de-desconto/*`],
    title: "Cupons de desconto",
    component: DiscountCoupons,
  },
  {
    url: `${baseurl}/indique`,
    title: "Indique seus amigos",
    component: UserReferral,
    restrictions: {
      hideWhenPlanIsMissing: true,
    },
  },
  {
    url: `${baseurl}/masterclass`,
    title: "Master class",
    component: Masterclass,
    restrictions: {
      hideWhenPlanIsMissing: true,
    },
  },
  {
    url: `${baseurl}/historico`,
    title: "Histórico de navegação",
    component: History,
  },
  { url: `/sair`, title: "Sair", component: LogoutModal },
];

const ClientMenu = ({ onHeader, open, onHide }) => {
  const userProvider = useSelector(getLoginProvider);
  const [show, setShow] = useState(open);
  const [firstName, setFirstName] = useState("");

  const userInfos = useSelector((state) => state.user.info);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (userInfos && userInfos.name) {
      setFirstName(userInfos.name.split(" ")[0]);
    }
  }, [userInfos]);

  useEffect(() => {
    let handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
        if (typeof onHide === "function") onHide();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, onHide]);

  useEffect(() => {
    setShow(open);
  }, [open]);

  return onHeader ? (
    <div className={styles.headerContainer}>
      {show && (
        <div className={styles.headerLinksContainer} ref={wrapperRef}>
          <div className={styles.userGreetings}>
            <span style={{ fontSize: "1.2em" }}>
              {userProvider === "nds_oi" ? "Oi" : "Olá"}, {firstName}
            </span>
            <span style={{ fontSize: "0.9em" }}>
              Cliente {userInfos.benefit_tier}
            </span>
          </div>
          <div className={styles.grayBlock}>
            <div className="d-none d-lg-block">
              <MenuLinksContainer only={["/sair", `${baseurl}`]} />
            </div>
            <div className="d-block d-lg-none">
              <MenuLinksContainer />
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <MenuLinksContainer />
  );
};

const MenuLinksContainer = ({ only = null }) => {
  let location = useLocation();
  const [currentRoute, setCurrentRoute] = useState("");
  const userProvider = useSelector(getLoginProvider);
  const userInfos = useSelector((state) => state.user.info);

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  return (
    <Nav className={classNames("flex-column", styles.sidebar)}>
      {links &&
        links.map((link, index) => {
          let linkUrl = Array.isArray(link.url) ? link.url[0] : link.url;

          if (Array.isArray(only) && !only.includes(linkUrl)) {
            return null;
          }

          if (
            userProvider !== "maratona_do_rio" &&
            link.url.includes("masterclass")
          ) {
            return null;
          }

          if (
            !["", null, "+alegria", "email"].includes(userProvider) &&
            link.url.includes("indique")
          ) {
            return null;
          }

          if (link?.restrictions) {
            if (["", null, "+alegria", "email"].includes(userProvider)) {
              let userNeverSubscribe =
                userInfos.subscription_history?.length === 0;

              if (link.restrictions.hideWhenPlanIsMissing && userNeverSubscribe)
                return null;

              if (
                link.restrictions.alternativeLinkWhenPlanIsMissing &&
                userNeverSubscribe
              ) {
                linkUrl = link.restrictions.alternativeLinkWhenPlanIsMissing;
              }
            } else {
              if (link.restrictions.hideToProvider) return null;
            }
          }

          return (
            <div className={styles.navItemSidebar} key={index}>
              <Link
                className="nav-link"
                style={
                  currentRoute === linkUrl
                    ? { color: "var(--color-link)", fontWeight: "bold" }
                    : null
                }
                to={linkUrl}
              >
                {link.title}
              </Link>
            </div>
          );
        })}
    </Nav>
  );
};

export default ClientMenu;
