import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'

import { classNames, formatMonetaryValue } from 'utils'
import styles from './index.module.scss'
import * as Actions from '../../store/actions'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function () {
  const dispatch = useDispatch()

  const paymentInfo = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.paymentInfo,
  )
  const addressInfo = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.addressInfo,
  )
  const sendingRequest = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.sendingRequest,
  )
  const usePix = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.usePix,
  )

  const useAme = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.useAme,
  )

  const usePicPay = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.usePicPay,
  )

  const plan = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.plan,
  )

  const availableAmount = useSelector(
    ({ subscriptionCheckout }) =>
      subscriptionCheckout.giftVouchers?.available_amount,
  )

  const is100Discount = parseFloat(plan.price_in_money - availableAmount) <= 0

  useEffect(() => {
    let focusElement = document.getElementById('focus-on-component-load')
    focusElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [])

  return (
    <div className={styles.rootContainer}>
      <Row>
        <Col
          className={classNames(styles.infosWrapper)}
          id="focus-on-component-load"
        >
          <h1 className={styles.sectionTitle}>Confirmar os dados</h1>

          {!is100Discount && (
            <Card className="mt-3">
              <CardContent>
                {usePix ? (
                  <p>Pagamento via PIX</p>
                ) : useAme ? (
                  <p>Pagamento via Ame Digital</p>
                ) : usePicPay ? (
                  <p>Pagamento via PicPay</p>
                ) : (
                  <>
                    <span className="font-weight-bold">Cartão selecionado</span>
                    <br />
                    <span>
                      <span className={styles.cardInfo}>
                        ({paymentInfo.brand}) **** **** ****{' '}
                        {paymentInfo.last_4}
                      </span>
                      <br />
                      <span>Vencimento: {paymentInfo.expiration_date}</span>
                    </span>
                  </>
                )}
              </CardContent>
            </Card>
          )}

          <Row className={styles.orderSummary}>
            <Col className="pr-2 pt-4">
              <h1 className={styles.sectionTitle2}>Resumo da Compra</h1>
              Valor do Pacote:{' '}
              {formatMonetaryValue(parseFloat(plan.price_in_money))}
              <br />
              {availableAmount ? (
                <>
                  Desconto: -{' '}
                  {plan.price_in_money > availableAmount
                    ? formatMonetaryValue(parseFloat(availableAmount))
                    : formatMonetaryValue(parseFloat(plan.price_in_money))}
                  <br />
                  <br />
                  <strong>
                    TOTAL:{' '}
                    {plan.price_in_money > availableAmount
                      ? formatMonetaryValue(
                          parseFloat(plan.price_in_money - availableAmount),
                        )
                      : formatMonetaryValue(0)}
                  </strong>
                </>
              ) : (
                <>
                  <br />
                  <strong>
                    TOTAL:{' '}
                    {formatMonetaryValue(parseFloat(plan.price_in_money))}
                  </strong>
                </>
              )}
            </Col>
          </Row>

          {!usePix && (
            <Row>
              <Col className="pr-2 pt-4">
                <h1 className={styles.sectionTitle2}>Endereço de Cobrança</h1>
                {addressInfo.street}, {addressInfo.number}{' '}
                {addressInfo.complement}
                <br />
                {addressInfo.neighborhood} - {addressInfo.zip_code}
                <br />
                {addressInfo.city} - {addressInfo.state}
              </Col>
            </Row>
          )}

          <Row className="mt-3">
            <Col xs={6}>
              <button
                aria-label="voltar"
                type="button"
                className={classNames(styles.backButton, 'btn-form')}
                onClick={() => {
                  dispatch(Actions.changeStep('previous'))
                  // scrollTo("#stepper");
                }}
              >
                Voltar
              </button>
            </Col>
            <Col xs={6}>
              <button
                aria-label="continuar"
                type="submit"
                className={classNames(
                  styles.confirmButton,
                  'custom-btn-verde btn-form',
                )}
                onClick={() => {
                  dispatch(Actions.subscriptionCheckout())
                  // scrollTo('#stepper')
                }}
                disabled={sendingRequest}
              >
                {sendingRequest ? (
                  <CircularProgress
                    color="inherit"
                    className={styles.circularProgress}
                  />
                ) : (
                  <div>Continuar</div>
                )}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
