import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { formmattedPrice } from 'components/cart/desktopItemCart'
import styles from './index.module.scss'
import { Box } from '@material-ui/core'

const FinishProductList = () => {
  const [carrinho, updateCarrinho] = useState([])
  const cart = useSelector(({ orderCheckout }) => orderCheckout.cart)

  const getProduct = async (id, price_id, quantity, ticket_id) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/products/${id}`)
      .then(function (resp) {
        let price = resp.data.tickets
          .map((obj) => {
            var array = obj.prices.filter((price) => {
              return price.id === price_id
            })
            return array[0]
          })
          .filter(function (el) {
            return el != null
          })

        let label = resp.data.tickets.filter(
          (ticket) => ticket.id === ticket_id,
        )

        let product = {
          id: resp.data.id,
          name: resp.data.title,
          photo: resp.data?.images[0]?.url,
          price_id,
          quantity,
          value: price[0]?.price_in_money,
          status: resp?.data?.status,
          label: label?.at(0)?.label,
          ticket_id,
        }

        return product
      })
  }

  useEffect(() => {
    if (cart) {
      let products = cart.map(async (product) => {
        return await getProduct(
          product.id,
          product.priceId,
          product.quantity,
          product.ticket_id,
        )
      })

      Promise.all(products).then((products) => {
        let filteredProducts = products.filter(
          (product) => product && product.status === 'No Ar',
        )

        updateCarrinho(filteredProducts)
      })
    }
  }, [cart])

  const groupedById = carrinho.reduce((acc, current) => {
    const existingItem = acc.find((item) => item.id === current.id)

    if (existingItem) {
      existingItem.details.push({
        id: current.id,
        price_id: current.price_id,
        quantity: current.quantity,
        value: current.value,
        label: current.label,
      })
    } else {
      acc.push({
        id: current.id,
        name: current.name,
        photo: current.photo,
        status: current.status,
        details: [
          {
            price_id: current.price_id,
            quantity: current.quantity,
            value: current.value,
            label: current.label,
          },
        ],
      })
    }

    return acc
  }, [])

  return (
    <Box sx={{ marginTop: 24 }}>
      {groupedById?.map((product, key) => (
        <div key={key} className={styles.container}>
          <img
            src={product.photo}
            alt={product.name}
            className={styles.photo}
          />
          <div className={styles.wrapper}>
            <div className={styles.productName}>{product.name}</div>
            <div>
              <div className={styles.productDetailsWrapper}>
                {product.details.map((detail, index) => (
                  <div key={index} className={styles.detail}>
                    <div className={styles.productLabel}>{detail?.label}</div>
                    <div>
                      <div className={styles.priceWrapper}>
                        <span className={styles.price}>
                          {`R$${formmattedPrice(detail?.value)} `}
                        </span>
                        <span>{`x${detail?.quantity}`}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Box>
  )
}

export default FinishProductList
