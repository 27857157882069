import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import * as Actions from '../../store/actions'

import styles from './styles.module.scss'

const currentQuantityOnCart = (ticketPriceId) =>
  createSelector(
    ({ product }) => product.checkout.order,
    ({ product }) => product.checkout.updatedAt,
    (orderCheckout) =>
      parseInt(
        orderCheckout.find((ticket) => ticket.price_id === ticketPriceId)
          ?.quantity,
      ),
  )

export default function ({ ticketPrice, setHasMessage }) {
  const dispatch = useDispatch()
  const currentQuantity = useSelector(currentQuantityOnCart(ticketPrice.id))

  const handleDecrease = () => {
    dispatch(Actions.removeFromCart(ticketPrice.id))

    if (currentQuantity) {
      setHasMessage(null)
    }
  }

  const handleIncrease = () => {
    dispatch(Actions.addToCart(ticketPrice.id, ticketPrice.ticket_id))

    // setHasMessage('Você adicionou um produto ao seu carrinho')
  }

  return (
    <div className={styles.box}>
      <div>
        <button
          onClick={() => handleDecrease()}
        >
          -
        </button>
      </div>

      <div>
        <span>{currentQuantity || 0}</span>
      </div>

      <div>
        {(currentQuantity || 0) < 10 && (
          <button
            onClick={() => handleIncrease()}
          >
            +
          </button>
        )}
      </div>
    </div>
  )
}
