import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      background: "#FCF0DD",
      display: "flex",
      flexDirection: "column",
      justifyContent: "fklex-start",
      alignItems: "center",
      maxWidth: "1200px",
      margin: "0 auto 50px auto",
      height: "841px",
      borderRadius: "26px",
      [theme.breakpoints.down("md")]: {
        borderRadius: "0px",
        flexDirection: "column",
        position: "relative",
        marginTop: "30px",
        paddingBottom: "56px",
        width: "100%",
        height: "838px",
      },
    },

    title: {
      marginTop: "74px",
      color: "#ED9915",
      textAlign: "center",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: "24px",
      fontStyle: "normal",
      fontFamily: "NeuePlak-Bold",
      fontWeight: 400,
      lineHeight: "46px",
      textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        lineHeight: "30px",
        marginTop: "65px",
      },
    },

    headline: {
      color: "#2F2F2F",
      textAlign: "center",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: "36px",
      fontStyle: "normal",
      fontFamily: "NeuePlak-Black",
      fontWeight: 400,
      lineHeight: "46px",
      [theme.breakpoints.down("md")]: {
        fontSize: "23px",
        width: "75%",
        textAlign: "center",
        lineHeight: "30px",
      },
    },
    subheadline: {
      marginTop: "10px",
      color: "#888",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "29.954px",
      letterSpacing: "0.6px",
      width: "48%",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        width: "82%",
        textAlign: "center",
        lineHeight: "18.843px",
      },
    },
    sectionContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      marginTop: "46px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    imageContainer: {
      [theme.breakpoints.down("md")]: {},
    },
    star: {
      position: "absolute",
      right: "144px",
      bottom: "0",
      top: "-350px",

      [theme.breakpoints.down("md")]: {
        height: "140px",
        left: "-167px",
        top: "0px",
      },
    },
    circle: {
      marginLeft: "24px",
      [theme.breakpoints.down("md")]: {
        width: "290px",
        margin: "0 auto",
      },
    },
    imageBackground: {
      position: "relative",
      [theme.breakpoints.down("md")]: {},
    },
    bulletContainer: {
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "72px",
      gap: "16px",

      [theme.breakpoints.down("md")]: {
        alignItems: "center",
        marginLeft: "0px",
        padding: "0 20px",
        justifyContent: "center",
        gap: "24px",
        marginTop: "24px",
        height: "100%",
      },
    },
    bulletContent: {
      fontFamily: "NeuePlak-Regular",
      color: "white",
      fontSize: "22.121px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "30.417px",
      "& strong": {
        fontFamily: "NeuePlak-Bold",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "11px",
        lineHeight: "15px",
      },
    },
    bulletWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "317px",
      height: "85px",
      background: "#ED9915",
      paddingLeft: "24px",
      borderRadius: "4px",
      [theme.breakpoints.down("md")]: {
        width: "46%",
        height: "42px",
        marginRight: "0px",
        paddingLeft: "11px",
      },
    },
  };
});
