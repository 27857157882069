import React from 'react'
import { Controller } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme, props) => ({
  root: {
    '& .MuiInputBase-input': {
      backgroundColor: 'white',
      borderRadius: 4,
    },
    '& fieldset': {
      borderColor: '#ECECEE',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
}))


const TextFieldController = ({
  id,
  name,
  label,
  placeholder,
  errors,
  defaultValue,
  disabled,
  control,
  mask,
  inputProps,
  rules = {},
  maskChar = ' ',
  required = true,
  type = 'text',
  size = 'medium',
}) => {
  const classes = useStyles()
  let error = Boolean(errors && errors[name])

  const BaseComponent = (props) => (
    <>
      {mask ? (
        <InputMask mask={mask} maskChar={maskChar} {...props}>
          {() => (
            <TextField
              InputLabelProps={{
                shrink: false,
                style: { position: 'relative', top: '-20px', left: '-13px' },
              }}
              id={id}
              style={{ width: '100%' }}
              className={classes.root}
              variant="outlined"
              type={type}
              label={label}
              size={size}
              placeholder={placeholder}
              error={error}
              InputProps={inputProps}
              helperText={error ? `Campo obrigatório` : ''}
              name={name}
              required={required}
            />
          )}
        </InputMask>
      ) : (
        <TextField
          InputLabelProps={{
            shrink: false,
            style: { position: 'relative', top: '-20px', left: '-13px' },
          }}
          key={label}
          id={id}
          style={{ width: "100%" }}
          className={classes.root}
          variant="outlined"
          type={type}
          disabled={disabled}
          label={label}
          placeholder={placeholder}
          error={error}
          size={size}
          InputProps={inputProps}
          helperText={error ? `Campo obrigatório` : ''}
          name={name}
          {...props}
        />
      )}
    </>
  )

  return control ? (
    <Controller
      as={(props) => <BaseComponent {...props} />}
      defaultValue={defaultValue || ''}
      name={name}
      required={required}
      rules={{ required: required, ...rules }}
      control={control}
    />
  ) : (
    <BaseComponent
      required={required}
      defaultValue={defaultValue || ''}
      name={name}
    />
  )
}

export default TextFieldController
