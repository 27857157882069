import React from 'react';
import {
  SearchTextAreaInput,
  SearchTextInput,
  SearchRadioInput,
  SearchCheckboxInput,
} from './Inputs';

const SearchQuestion = (props) => {
  const {
    id,
    question,
    description,
    image,
    type,
    options,
  } = props.question

  return (
    <>
      {type === 'text'
        && (
          <SearchTextInput
            nextQuestion={props.nextQuestion}
            type={type}
            id={id}
            title={question}
            description={description}
            image={image}
            setFinished={props.setFinished}
          />
        )}
      {type === 'textarea'
        && (
          <SearchTextAreaInput
            nextQuestion={props.nextQuestion}
            type={type}
            id={id}
            title={question}
            description={description}
            image={image}
            setFinished={props.setFinished}
          />
        )}
      {type === 'radio'
        && (
        <SearchRadioInput
          nextQuestion={props.nextQuestion}
          type={type}
          id={id}
          title={question}
          description={description}
          image={image}
          options={options}
          setFinished={props.setFinished}
        /> )}
      {type === 'checkbox'
        && (
          <SearchCheckboxInput
            nextQuestion={props.nextQuestion}
            type={type}
            id={id}
            title={question}
            description={description}
            image={image}
            options={options}
            setFinished={props.setFinished}
          />
        )}
    </>
  )
}

export default SearchQuestion;
