import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { classNames } from 'utils'
import { QuestionMarkIcon } from 'assets/icons'
import styles from './index.module.scss'

const CartHeader = () => {
  const history = useHistory()
  const partnerSettings = useSelector((state) => state.global.partner)

  const getCompanyLogo = () => {
    return partnerSettings?.logo || './logo.png'
  }

  return (
    <div className={styles.container} id="header">
      <div className={styles.wrapper}>
        <div className={styles.logoContainer} onClick={() => history.push('/ofertas')}>
          <img
            src={getCompanyLogo()}
            alt="logo"
            className={classNames('main-logo', styles.mainLogo)}
          />
        </div>

        <div className={styles.user}>
          <div className={styles.doubtsContainer}>
            <QuestionMarkIcon width="16" height="16" />
            <div className={styles.doubts}>Dúvidas</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartHeader
