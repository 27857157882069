import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      background: '#EFEFEF',
    },
    footerWrapper: {
      maxWidth: '1200px',
      width: '100%',
      padding: '42px 0 110px 0',
      gap: '10%',
      display: 'flex',
      alignItems: 'flex-start',
      margin: '0px auto 0 auto',
      position: 'relative',

      [theme.breakpoints.down('md')]: {
        padding: '62px 35px 35px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '',
        // height: "100%",
      },
    },

    loggedFooterWrapper: {
      maxWidth: '1200px',
      width: '100%',
      padding: '42px 0 110px 0',
      gap: '10%',
      display: 'flex',
      alignItems: 'flex-start',
      margin: '0px auto 0 auto',
      position: 'relative',

      [theme.breakpoints.down('md')]: {
        padding: '62px 35px 35px',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '',
        // height: "100%",
      },
    },
    headlineContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
      },
    },
    anchor: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },

    titleItems: {
      color: '#2F2F2F',
      textAlign: 'left',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: '24px',
      fontStyle: 'normal',
      fontFamily: 'NeuePlak-Bold',
      fontWeight: 400,
      lineHeight: '40px',
      marginBottom: '36px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '17px',
        fontSize: '18px',
        textAlign: 'left',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    footerItems: {
      marginTop: '10px',
      color: '#2F2F2F',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '40px',
      textAlign: 'left',
      '&:hover': {
        cursor: 'pointer',
        color: '#E90',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '40px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        textAlign: 'left',
        lineHeight: '21px',
      },
    },

    logo: {
      [theme.breakpoints.down('md')]: {
        width: '119px',
        height: '35px',
        marginBottom: '10px',
      },
    },
    options: {
      marginLeft: '20px',
      [theme.breakpoints.down('md')]: {
        marginTop: '33px',
        flexBasis: '0%',
      },
    },
    instagram: {
      '&:hover': {
        cursor: 'pointer',
      },
      marginRight: '16px',
      [theme.breakpoints.down('md')]: {
        width: '22px',
      },
    },
    facebook: {
      '&:hover': {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        width: '27px',
      },
    },
    easylive: {
      width: '200px',

      [theme.breakpoints.down('md')]: {
        marginLeft: '8px',
        width: '125px',
      },
    },
    easyLiveFooter: {
      color: '#636363',
      textAlign: 'right',
      fontFamily: 'NeuePlak-Bold',
      fontSize: '19.265px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      marginTop: '-50px',

      [theme.breakpoints.down('md')]: {
        color: '#2F2F2F',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '16.285px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginTop: '0px',
      },
    },
    easyliveContainer: {
      position: 'absolute',
      bottom: '36px',
      right: '0px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: '70px',
        paddingTop: '20px',
        borderTop: '1px solid #D9D9D9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        flexDirection: 'row',
      },
    },
  }
})
