import React from "react";
import LazyLoad from "react-lazyload";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { classNames } from "utils";

export default function OfferImage({ offer }) {
  return (
    <div
      id="carousel-thumb"
      data-ride="carousel"
      className={classNames(
        "carousel slide carousel-fade d-block position-relative"
      )}
      style={{ height: "200px", alignItems: "center" }}
    >
      <div className="carousel-inner" role="listbox" style={{ height: "100%" }}>
        <div
          style={{
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "100%",
          }}
        >
          <LazyLoad once>
            <img
              src={offer.image_url}
              alt={offer.description}
              style={{ maxWidth: 200, maxHeight: 200 }}
            />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
}
