import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ChevronRight } from 'assets/icons'
import styles from './styles.module.scss'

const Categories = () => {
  const [categorySelected, setCategorySelected] = useState(null)
  const categories = useSelector((state) => state.global.interestAreas)

  if(categorySelected) {
    return (
      <ul className={styles.list}>
        <li onClick={() => setCategorySelected(null)} className={styles.categoryItem}>
          <span>
            <ChevronRight />
          </span>
          <p>
            {categories.find(category => category.slug === categorySelected).name}
          </p>
        </li>
        <hr />
        {categories.find(category => category.slug === categorySelected).sub_categories.map(category => (
          <li
            key={category.slug}
            className={styles.item}
            onClick={() => window.open(`/categorias/${categorySelected}?q=${category.slug}`, '_self')}
          >
            <span>{category.name}</span>
          </li>
        ))}

      </ul>
    )
  }

  return (
    <ul className={styles.list}>
      {categories.map(category => (
        <li
          key={category.slug}
          className={styles.item}
          onClick={() => setCategorySelected(category.slug)}
        >
          <span>{category.name}</span>
          <ChevronRight />
        </li>
      ))}
      <hr />
      <li className={styles.item} onClick={() => window.open("http://maisalegriahelp.zendesk.com/", "_black")}>
        Dúvidas
      </li>
      <li className={styles.itemLogout} onClick={() => window.open('/sair', '_self')}>
        Sair
      </li>
    </ul>
  )
}

export default Categories
