import React from 'react';
import { useStyles } from './useStyle';
import { classNames } from 'utils';

const QuizQuestion = (props) => {
  const classes = useStyles();

  const {
    question,
    description,
    image,
    options,
  } = props.quiz;

  const {
    correct_option,
    correct,
    option
  } = props.answer;

  return (
    <div className={classes.container}>
      {question && (
        <h2 className={classes.title}>
          {question}
        </h2>
      )}
      {description && (
        <p className={classes.description}>
          {description}
        </p>
      )}
      {image && (
        <img
          src={image}
          alt={`imagem da questão ${question}`}
          className={classes.image}
        />
      )}
      {options && (
        <div className={classes.questionContainer}>
          {options.map(opt => (
            <button
              key={opt.id}
              disabled={props.isDisabled}
              onClick={() => props.clickQuestion(opt.id)}
              className={classNames(classes.questionBtn, {
                [classes.correct] : correct_option === opt.id,
                [classes.wrong] :  opt.id === option && correct_option !== opt.id && !correct,
              })}
            >
              {opt.option}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default QuizQuestion;
