import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  bannerFooter: {
    background: "#ED9915",
    borderRadius: "28px",
    display: "flex",
    alignItems: "center",
    margin: "0px auto -32px auto",
    width: "1210px",
    height: "233px",
    zIndex: 3,
    position: "relative",

    [theme.breakpoints.down("md")]: {
      margin: "0",
      width: "100%",
      borderRadius: "0px",
      height: "133px",
    },
  },
  bannerImage: {
    width: "366px",
    position: "absolute",
    bottom: 0,
    left: "46px",
    zIndex: "4",
    [theme.breakpoints.down("md")]: {
      width: "161px",
      left: "-20px",
    },
  },
  layer: {
    width: "364px",
    position: "absolute",
    bottom: 0,
    left: "41px",
    [theme.breakpoints.down("md")]: {
      width: "158px",
      left: "-20px",
    },
  },
  footerText: {
    color: "#fff",
    fontFamily: "NeuePlak-Black",
    fontSize: "40px",
    lineHeight: 1,
    width: "440px",

    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      width: "229px",
      padding: "0",
    },
  },
  footerSubText: {
    color: "#5B2B00",
    fontFamily: "NeuePlak-Regular",
    fontSize: "20px",
    lineHeight: "46px",
    width: "300px",

    [theme.breakpoints.down("md")]: {
      fontSize: "9px",
      padding: "0rem 0",
      lineHeight: "20px",
      width: "229px",
    },
  },
}));
