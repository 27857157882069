import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import withReducer from 'store/withReducer'

import { useHistory } from 'react-router-dom'

import { Container } from 'react-bootstrap'

import NewLayoutHeader from 'components/NewLayoutHeader'
import AllCategories from 'components/AllCategories'
import Footer from 'components/footer'

// import Footer from "components/footer";
// import Header from "components/header";
// import Newsletter from "components/newsletter";
import reducer from './store/reducers'
import * as Actions from './store/actions'
import { fetchBenefitTier, isLoggedIn } from 'store/selectors'
import { getUser } from 'store/actions'
import { debounce } from 'lodash'
import ProductDetails from './ProductDetails'
import ProductUnavailable from './components/ProductUnavailable'
import PageLoading from 'components/pageLoading'
import ProductsRelated from 'components/productsRelated'

const ProductPage = (props) => {
  const history = useHistory()
  const productId = props.match.params.id
  const dispatch = useDispatch()

  const userIsLogged = useSelector(isLoggedIn)
  const benefitTier = useSelector(fetchBenefitTier)
  const product = useSelector(({ product }) => product.product)
  const userData = JSON.parse(localStorage.getItem('AU_data'))

  const debouncedAddToBrowsingHistory = React.useMemo(
    () => debounce(() => dispatch(Actions.addToBrowsingHistory()), 850),
    [dispatch],
  )

  useEffect(() => {
    const userHeader = JSON.parse(localStorage.getItem('AU_header'))
    if (!userIsLogged && !userHeader) {
      history.push('/partners')
    }
  }, [history, userIsLogged])

  useEffect(() => {
    if (!userData?.subscription) {
      dispatch(getUser())
    }
  }, [dispatch, userData])

  useEffect(() => {
    if (productId) {
      dispatch(Actions.getProductById(productId))
    }
  }, [dispatch, productId, benefitTier])

  useEffect(() => {
    if (benefitTier && userIsLogged) {
      debouncedAddToBrowsingHistory()
    }
  }, [debouncedAddToBrowsingHistory, benefitTier, userIsLogged, product])

  useEffect(() => {
    dispatch(Actions.getCheckoutInfo())
  }, [dispatch])

  // Reload the page when the component mounts

  return (
    <PageLoading>
      <NewLayoutHeader />
      <AllCategories />
      <hr />
      <Container className="mb-4 mt-4">
        {product && product.id ? <ProductDetails /> : <ProductUnavailable />}
        <ProductsRelated relatedTo={[product]} noResultsComponent={<></>} />
      </Container>
      <Footer />
    </PageLoading>
  )
}

export default withReducer('product', reducer)(ProductPage)
