import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { classNames } from "utils";
import { getLoginProvider, isLoggedIn } from "store/selectors";

import Fade from "@material-ui/core/Fade";

import styles from "./index.module.scss";
import { Button, Menu, MenuItem, makeStyles } from "@material-ui/core";
import { ChevronDown, ChevronRight, Arrow } from "assets/icons";
import { Hidden } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const baseButtonStyle = {
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "16.955px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "17.741px",
  paddingRight: 16,
  paddingLeft: 16,
  paddingTop: 20,
  paddingBottom: 20,
  background: "#fff",
  borderRadius: "8px 8px 0px 0px",
};

const useStyles = makeStyles((theme) => ({
  button: { ...baseButtonStyle, color: theme.palette.font.title },

  activeButton: {
    ...baseButtonStyle,
    color: theme.palette.primary.main,
    background: theme.palette.primary.background,
  },
  menu: {
    marginTop: 48,
    "& .MuiPaper-root": {
      borderRadius: "0px 0px 8px 8px",
      border: `1px solid ${theme.palette.primary.background}`,
      padding: "20px  0px",
      boxShadow: "0px 8px 12px 0px rgba(136, 136, 136, 0.42)",
    },
  },
  menuItem: {
    color: theme.palette.font.dropdownItem, // Default color
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "17.835px",
    fontStyle: "normal",
    fontWeightprops: 400,
    lineHeight: "18.661px",
    transition: "color 0.3s ease",
    padding: "16px 57px",
    "&:hover": {
      color: theme.palette.primary.main,
      background: "#fff",
    },
  },
  icon: {
    color: "#2F2F2F",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "14.839px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  close: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5.935px",
    border: "0.742px solid #000",
    width: "20.774px",
    height: "23px",
    flexShrink: 0,
    background: "white",
  },
  headerDrawer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    borderBottom: "1px solid #DDD",
    height: "53px",
    padding: "0px 26px 0px 22px",
  },
  goBackButton: {
    padding: "0px",
    textTransform: "none",
    "&:hover": {
      background: "transparent",
    },
  },
  goBack: {
    color: "#2F2F2F",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18.661px",
    fontFamily: "NeuePlak-SemiBold",
    paddingLeft: "8px",
  },
  menuMobileButton: {
    width: "100%",
    padding: "0px 26px",
    textTransform: "none",
    height: "42px",
    margin: "10px 0px",

    "&:hover": {
      color: theme.palette.primary.main,
      background: "#FCF0DD",
    },
    "& .MuiButton-label": {
      justifyContent: "space-between",
      color: "#2F2F2F",
      textAlign: "center",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: "17.835px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18.661px",
    },
  },
}));

const links = [
  {
    name: "Para a empresa",
    path: null,
    hasDropDown: true,
    isAnchorTag: false,
    id: 0,
    dropDownItems: [
      {
        name: "Planos",
        path: "/planos",
      },
      {
        name: "Vantagens",
        path: "/vantagens",
      },
    ],
  },

  {
    name: "A Solução",
    path: "/solucao",
    isAnchorTag: false,
    hasDropDown: false,
    id: 1,
  },
];


const NavLinks = ({ mobileShow, onClick }) => {

 
  return (
    <div style={{ flex: 1 }}>
      <div
        className={classNames("collapse navbar-collapse", styles.mobileMenu, {
          [styles.mobileShow]: mobileShow,
        })}
        id="menu"
      >
        <ul className={classNames("navbar-nav", styles.deskmenulinks)}>
          <Hidden mdDown>
            <NavLinkDesktop />
          </Hidden>

          {/* Display only on mobile */}
          <Hidden mdUp>
            <NavLinkMobile onClick={onClick} />
          </Hidden>
          {/* <li className="nav-item">
            <a
              aria-label="link menu como participar"
              className={styles.desknavlink}
              href="/ofertas"
              data-scroll-section="#ofertas"
            >
              Ofertas
            </a>
          </li> */}

          {/* {userProvider === "" && (
            <li>
              <Button
                aria-controls="para-empresas"
                onClick={handleClick}
                aria-haspopup="true"
                style={{ textTransform: "none" }}
                className={classes.customButton}
                endIcon={
                  <ChevronDown
                    color={clicked ? "#ED9915" : "#2F2F2F"}
                    animate={clicked}
                  />
                }
              >
                Para a empresa
              </Button>
            </li>
          )}
          <li>
            <Button
              onClick={handleClick}
              style={{ textTransform: "none" }}
              className={classes.customButton}
            >
              A Solução
            </Button>
          </li>
          <li>
            <Button
              aria-controls="para-empresas"
              onClick={handleClick}
              aria-haspopup="true"
              style={{ textTransform: "none" }}
              className={classes.customButton}
              endIcon={<ChevronDown />}
            >
              Para o usuário
            </Button>
          </li>

          <li>
            <Button
              onClick={handleClick}
              style={{ textTransform: "none" }}
              className={classes.customButton}
            >
              Sobre o +alegria
            </Button>
          </li> */}
          {/* <li className="nav-item">
            <a
              aria-label="link menu duvidas"
              //id="link-menu-duvidas"
              className={classNames("nav-link", styles.desknavlink)}
              href="http://maisalegriahelp.zendesk.com/"
              data-toggle="popup"
              data-target="#duvidas"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dúvidas
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

NavLinks.propTypes = {
  mobileShow: PropTypes.bool,
  onClick: PropTypes.func,
};

NavLinks.defaultProps = {
  mobileShow: false,
  onClick: () => null,
};

export default NavLinks;

const NavLinkDesktop = (props) => {
  const isLogged = useSelector(isLoggedIn);
  const partnerSettings = useSelector((state) => state.global.partner)


  const loggedLinks = [
    {
      name: "Ofertas",
      path: "/ofertas",
      hasDropDown: false,
      isAnchorTag: false,
      id: 0,
    },
  
    {
      name: "Dúvidas",
      path: partnerSettings?.faq_url,
      hasDropDown: false,
      isAnchorTag: true,
      id: 1,
    },
  ];

  const location = useLocation();
  const history = useHistory();
  const [linkIndex, setLinkIndex] = useState(null);

  const classes = useStyles();
  const userProvider = useSelector(getLoginProvider);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, link) => {
    setLinkIndex(link.id);

    if (!link.hasDropDown) {
      if (link.isAnchorTag) {
        event.preventDefault();
        window.open(link.path, "_blank");
      } else {
        history.push(link.path);
      }
    }

    if (link.hasDropDown) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleDropDownClick = (link) => {
    if (link.isAnchorTag) {
      window.open(link.path, "_blank");
    } else {
      history.push(link.path);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLinkIndex(null);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setLinkIndex(null);
    }
  }, [location.pathname]);

  return (
    <>
      {isLogged ? (
        <>
          {loggedLinks.map((link, index) => (
            <li key={link.name}>
              <Button
                aria-controls={link.name}
                onClick={(event) => handleClick(event, link)}
                aria-haspopup={link.hasDropDown}
                style={{ textTransform: "none" }}
                className={
                  linkIndex === index ? classes.activeButton : classes.button
                }
                endIcon={
                  <>
                    {link.hasDropDown && (
                      <ChevronDown
                        color={linkIndex === index ? "#ED9915" : "#2F2F2F"}
                      />
                    )}
                  </>
                }
              >
                {link.name}
              </Button>
            </li>
          ))}
        </>
      ) : (
        <>
          {links
            .filter((link) => {
              if (userProvider !== "") {
                return link.name !== links[0].name;
              } else {
                return link;
              }
            })
            .map((link, index) => (
              <li key={link.name}>
                <Button
                  aria-controls={link.name}
                  onClick={(event) => handleClick(event, link)}
                  aria-haspopup={link.hasDropDown}
                  style={{ textTransform: "none" }}
                  className={
                    linkIndex === index ? classes.activeButton : classes.button
                  }
                  endIcon={
                    <>
                      {link.hasDropDown && (
                        <ChevronDown
                          color={linkIndex === index ? "#ED9915" : "#2F2F2F"}
                        />
                      )}
                    </>
                  }
                >
                  {link.name}
                </Button>
              </li>
            ))}
        </>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        className={classes.menu}
      >
        {links
          .filter((link) => link.hasDropDown && link.id === linkIndex)
          .map((link) => (
            <div key={link.name}>
              {link.dropDownItems.map((item) => (
                <MenuItem
                  key={item.name}
                  className={classes.menuItem}
                  onClick={() => handleDropDownClick(item)}
                >
                  {item.name}
                </MenuItem>
              ))}
            </div>
          ))}
      </Menu>
    </>
  );
};

const NavLinkMobile = ({ onClick }) => {
  const isLogged = useSelector(isLoggedIn);

  const partnerSettings = useSelector((state) => state.global.partner)


  const loggedLinks = [
    {
      name: "Ofertas",
      path: "/ofertas",
      hasDropDown: false,
      isAnchorTag: false,
      id: 0,
    },
  
    {
      name: "Dúvidas",
      path: partnerSettings?.faq_url,
      hasDropDown: false,
      isAnchorTag: true,
      id: 1,
    },
  ];

  const [linkIndex, setLinkIndex] = useState(null);
  const history = useHistory();

  const classes = useStyles({ linkIndex });
  const userProvider = useSelector(getLoginProvider);

  const handleMenuClick = (event, link) => {
    setLinkIndex(link.id);

    if (!link.hasDropDown) {
      if (link.isAnchorTag) {
        event.preventDefault();
        window.open(link.path, "_blank");
        handleClose();
      } else {
        history.push(link.path);
        handleClose();
      }
    }
  };

  const handleDropDownClick = (link) => {
    if (link.isAnchorTag) {
      window.open(link.path, "_blank");
      handleClose();
    } else {
      history.push(link.path);
      handleClose();
    }
  };

  const handleClose = () => {
    onClick();
    setLinkIndex(null);
  };

  const handleGoBack = () => {
    setLinkIndex(null);
  };

  const LoggedDropDownItems = () => {
    return (
      <>
        {loggedLinks
          .filter((link) => link.hasDropDown && link.id === linkIndex)
          .map((link, index) => (
            <div key={link.name}>
              {link.dropDownItems.map((item) => (
                <Button className={classes.menuMobileButton}>
                  {item.name}
                </Button>
              ))}
            </div>
          ))}
      </>
    );
  };

  const DropDownItems = () => {
    return (
      <>
        {links
          .filter((link) => link.hasDropDown && link.id === linkIndex)
          .map((link, index) => (
            <div key={link.name}>
              {link.dropDownItems.map((item) => (
                <Button
                  className={classes.menuMobileButton}
                  onClick={() => handleDropDownClick(item)}
                >
                  {item.name}
                </Button>
              ))}
            </div>
          ))}
      </>
    );
  };

  const LoggedMenuItems = () => {
    return (
      <>
        {loggedLinks.map((link, index) => (
          <li key={link.name}>
            <Button
              onClick={(event) => handleMenuClick(event, link)}
              className={classes.menuMobileButton}
              endIcon={
                <>
                  {link.hasDropDown && (
                    <ChevronRight
                      color={linkIndex === index ? "#ED9915" : "#2F2F2F"}
                      animate={linkIndex === index ? "true" : "true"}
                    />
                  )}
                </>
              }
            >
              {link.name}
            </Button>
          </li>
        ))}
      </>
    );
  };

  const MenuItems = () => {
    return (
      <>
        {links
          .filter((link) => {
            if (userProvider !== "") {
              return link.name !== links[0].name;
            } else {
              return link;
            }
          })
          .map((link, index) => (
            <li key={link.name}>
              <Button
                onClick={(event) => handleMenuClick(event, link)}
                className={classes.menuMobileButton}
                endIcon={
                  <>
                    {link.hasDropDown && (
                      <ChevronRight
                        color={linkIndex === index ? "#ED9915" : "#2F2F2F"}
                        animate={linkIndex === index ? "true" : "true"}
                      />
                    )}
                  </>
                }
              >
                {link.name}
              </Button>
            </li>
          ))}
      </>
    );
  };

  return (
    <>
      <div className={classes.headerDrawer}>
        <div style={{ visibility: linkIndex !== null ? "visible" : "hidden" }}>
          <>
            <Button
              onClick={handleGoBack}
              variant="text"
              className={classes.goBackButton}
            >
              <Arrow />

              <span className={classes.goBack}>Voltar</span>
            </Button>
          </>
        </div>
        <button
          type="button"
          aria-label="Close"
          className={classes.close}
          onClick={handleClose}
        >
          <span tabIndex="-1" className={classes.icon}>
            X
          </span>
        </button>
      </div>
      {linkIndex !== null ? (
        <>{isLogged ? <LoggedDropDownItems /> : <DropDownItems />}</>
      ) : (
        <>{isLogged ? <LoggedMenuItems /> : <MenuItems />}</>
      )}
    </>
  );
};
