import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PasswordFieldController, TextFieldController } from "components/forms";
import { loginRequest } from "services/api";

import { userLogin } from "store/actions";
import { Form, Row, Alert } from "react-bootstrap";

import { classNames } from "utils";
import styles from "./index.module.scss";
import { showRegisterModal } from "store/actions";
import { isLoggedIn } from "store/selectors";
import InvalidUserMessage from "./InvalidUserMessage";

export default function () {
  const dispatch = useDispatch();
  const { handleSubmit, control, errors } = useForm();
  const logged = useSelector(isLoggedIn);
  const [showAlert, setShowAlert] = useState(false);
  const [requestErrors, setRequestErrors] = useState([]);

  const onSubmit = async (user) => {
    loginRequest({ email: user.email, password: user.password })
      .then((response) => {
        if (response.success) {
          dispatch(userLogin(response));
          // dispatch(Actions.changeStep('next'));
        } else {
          setRequestErrors(response.errors);
        }
      })
      .catch((err) => {
        setRequestErrors(["Erro ao se conectar com o servidor"]);
      });
  };

  useEffect(() => {
    if (Array.isArray(requestErrors) && requestErrors.length > 0) {
      setShowAlert(true);
    }
  }, [requestErrors]);

  return (
    <div className={styles.rootContainer}>
      {logged ? (
        <InvalidUserMessage />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.modalTitle}>Faça Login</div>

          <Row>
            <div className="col-12 pt-3">
              <TextFieldController
                label="E-mail"
                type="email"
                name="email"
                control={control}
                errors={errors}
              />
            </div>

            <div className="col-12 pt-3">
              <PasswordFieldController
                name="password"
                label="Senha"
                control={control}
                errors={errors}
              />
            </div>
          </Row>
          {showAlert && (
            <Alert
              variant="danger"
              onClose={() => setShowAlert(false)}
              className="mt-3"
              dismissible
            >
              Atenção! <br />
              {requestErrors && (
                <ul>
                  {requestErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              )}
            </Alert>
          )}

          <div className="text-center mt-3">
            <button
              aria-label="entrar"
              type="submit"
              className="custom-btn-laranja btn-form text-white"
            >
              Entrar
            </button>
          </div>

          <div className="mt-3">
            <p className="text-center">OU</p>
          </div>
          <div className="text-center mt-3">
            <button
              type="button"
              className={classNames("custom-btn-verde btn-form")}
              onClick={() => dispatch(showRegisterModal(true))}
            >
              Faça seu cadastro
            </button>
          </div>
        </Form>
      )}
    </div>
  );
}
