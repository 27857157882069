import React, { useState, useEffect } from 'react';
import { useStyles } from '../useStyle';
import { classNames } from 'utils';
import { sendEachSurveysAnswer } from 'services/api';

const SearchRadioInput = (props) => {
  const classes = useStyles();
  const [option, setOption] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (option) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [option]);

  const click = () => {
    setIsDisabled(true);
    const hasSearch = JSON.parse(localStorage.getItem('hasSearch') || null);
    const { survey: { id } } = hasSearch;

    const survey = {
      id,
      question: props.id,
      answer: [option]
    }

    sendEachSurveysAnswer(survey)
      .then(({ result }) => {
        const { finished, info } = result;

        if (finished) {
          props.setFinished(info);
        }

        setOption('');
      })
      .catch(error => console.error(error));

    setOption('');
    props.nextQuestion();
  }

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        {props.title}
      </h2>
      <p className={classes.description}>
        {props.description}
      </p>
      {props?.image && (
        <img
          src={props.image}
          alt='search'
          width={100}
          className={classes.image}
        />
      )}
      {props.options.map(opt => (
        <div key={opt.id} className={classes.radioWrapper}>
          <input
            type='radio'
            id={opt.id}
            value={opt.id}
            name='unique_option'
            className={classes.radioInput}
            onChange={() => setOption(opt.id)}
          />
          <label
            htmlFor={opt.id}
            className={classes.label}>
              {opt.option}
          </label>
        </div>
      ))}
       <button
        className={classNames(classes.btn,
          {[classes.disabled]: isDisabled })}
        disabled={isDisabled}
        onClick={click}
      >
        Próxima
      </button>
    </div>

  )
}

export default SearchRadioInput;
