import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";
import { classNames } from "utils";

// import MaisAlegria from "assets/images/logo.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import PageLoading from "components/pageLoading";

import { isLoggedIn } from "store/selectors";

export default function (props) {
  const history = useHistory();
  const user = useSelector((state) => state.user.info);
  const logged = useSelector(isLoggedIn);

  const voucherToken = decodeURIComponent(props.match.params.voucherToken);
  let orderItem = localStorage.getItem("active_order_item");
  const companyLogo = useSelector((state) => state.user?.companyLogo);

  try {
    orderItem = JSON.parse(orderItem);
  } catch (error) {
    window.close();
  }
  const voucherDetails = orderItem.vouchers.find(
    (voucher) => voucher.token === voucherToken
  );
  if (!voucherDetails) {
    window.close();
  }

  useEffect(() => {
    const userHeader = JSON.parse(localStorage.getItem("AU_header"));
    if (!logged && !userHeader) {
      history.push("/partners");
    }
  }, [history, logged]);

  return (
    <PageLoading>
      {user ? (
        <div>
          <div className={styles.header}>
            <div className={styles.logoContainer}>
              <div className={styles.ImgContainer}>
                <LazyLoad once>
                  <img
                    src={companyLogo}
                    alt="Logo da empresa"
                    className={styles.imgLogo}
                  />
                </LazyLoad>
              </div>
              <div>o seu clube de felicidade</div>
            </div>
          </div>

          <div className={styles.pageTitle}>VOUCHER ELETRÔNICO</div>

          <div className={styles.voucherBody}>
            <div className={styles.orderInfo}>
              <span>
                Olá, <span className="font-weight-bolder">{user.name}</span>.
                Parabéns!
              </span>
              <span>Desfrute agora mesmo da sua troca :-)</span>
              <span className="mt-4">
                Confira abaixo os dados do seu pedido e número do voucher.
              </span>
            </div>

            <div
              className={classNames(
                "row align-items-center",
                styles.orderDetails
              )}
            >
              <div className="col-6">
                <span>Dados do Cliente</span>
                <br />
                <span>Cliente: {user.name}</span>
                <br />
                <span>CPF: {user.cpf}</span>
              </div>
              <div className={classNames("col-6 text-center", styles.details)}>
                <span className="font-weight-bolder mb-2">
                  {voucherDetails?.title
                    ? voucherDetails.title
                    : orderItem.product_name}
                </span>
                <span>Número do Pedido: {orderItem.order.id}</span>
                <span>Emissão: {orderItem.order.created_at}</span>
                <span className="font-weight-bolder mt-2 mb-1">
                  CÓDIGO DO VOUCHER
                </span>
                <span
                  className="font-weight-bolder"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {voucherToken}
                </span>
              </div>
            </div>

            <div>
              <p>Termos de uso</p>
              {ReactHtmlParser(orderItem.product_additional_info)}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <CircularProgress />
        </div>
      )}
    </PageLoading>
  );
}
