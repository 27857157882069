import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import LazyLoad from 'react-lazyload'

import { classNames } from 'utils'
import styles from './index.module.scss'
import * as Actions from '../../store/actions'
import CreditCard from './CreditCard'
import Box from '@material-ui/core/Box'
import Pix from './Pix'
import Ame from './Ame'

import PixIcon from './imgs/pix.svg'
import PixBlackIcon from './imgs/pix-black.svg'
import CreditCardIcon from './imgs/cc.svg'
import CreditCardBlackIcon from './imgs/cc-black.svg'
import AmeDigital from './imgs/ame.svg'
import AmeDigitalBlack from './imgs/ame-black.svg'
import PicPayImage from './imgs/picpay.svg'
import PicPayBlackImage from './imgs/picpay-black.svg'
import { altImage, classNameImage, srcImg } from 'utils/helper'
import PicPay from './PicPay'
import useIsMobile from 'utils/useIsMobile'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box className="pt-3">{children}</Box>}
    </div>
  )
}

function TabOption(props) {
  const { icon, index, ...other } = props

  return (
    <Box sx={{ padding: 4, marginX: 1 }}>
      <Tab
        styles={{
          height: '100%',
          paddingLeft: 4,
          paddingRight: 4,
          marginLeft: 4,
          marginRight: 4,
        }}
        id={`tab-${index}`}
        aria-controls={`tabpanel-${index}`}
        label={<span>{icon}</span>}
        {...other}
      />
    </Box>
  )
}

export default function () {
  const dispatch = useDispatch()

  const isMobile = useIsMobile()

  const partnerSettings = useSelector((state) => state.global.partner)

  const currentPaymentTab = useSelector(
    ({ orderCheckout }) => orderCheckout.currentPaymentTab,
  )
  const orderFinalValue = useSelector(
    ({ orderCheckout }) => orderCheckout.orderFinalValue,
  )
  const useGiftVoucher = useSelector(
    ({ orderCheckout }) => orderCheckout.useGiftVouchers,
  )

  const handleChange = (event, newValue) => {
    dispatch(Actions.setCurrentPaymentTab(newValue))
  }

  useEffect(() => {
    if (useGiftVoucher && orderFinalValue === 0) {
      dispatch(Actions.changeStep('next'))
    }
  }, [useGiftVoucher, orderFinalValue, dispatch])

  // useEffect(() => {r
  //   function handleResize() {
  //     setWindowDimensions(getWindowDimensions());
  //   }

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  const hasCreditCardPaymentMethod =
    partnerSettings.payment_info.payment_options.includes('credit_card') &&
    partnerSettings.payment_info.cc_payment_gateway

  const hasPixPaymentMethod =
    partnerSettings.payment_info.payment_options.includes('pix') &&
    partnerSettings.payment_info.pix_payment_gateway

  return (
    <div className={styles.rootContainer}>
      <Row className="d-flex">
        <Col className={classNames(styles.infosWrapper)}>
          <div className={styles.tab}>
            {partnerSettings.payment_info.payment_options.length === 0 ? (
              <p className={styles.noPayment}>
                Não há nenhuma forma de pagamento disponível.
              </p>
            ) : (
              <Box sx={{ width: '100%' }}>
                <Tabs
                  value={currentPaymentTab}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  scrollButtons="on"
                >
                  {partnerSettings.payment_info.payment_options
                    .filter((method) => {
                      return (
                        (method !== 'credit_card' ||
                          hasCreditCardPaymentMethod) &&
                        (method !== 'pix' || hasPixPaymentMethod)
                      )
                    })
                    .map((method, i) => (
                      <TabOption
                        index={i}
                        key={i}
                        icon={
                          <LazyLoad once>
                            <img
                              src={srcImg(
                                method,
                                CreditCardIcon,
                                PixIcon,
                                AmeDigital,
                                PicPayImage,
                                currentPaymentTab,
                                i,
                                CreditCardBlackIcon,
                                PixBlackIcon,
                                PicPayBlackImage,
                                AmeDigitalBlack,
                              )}
                              alt={altImage(method)}
                              style={classNameImage(method, isMobile)}
                            />
                          </LazyLoad>
                        }
                      />
                    ))}
                </Tabs>
              </Box>
            )}
          </div>
          {partnerSettings.payment_info.payment_options.map((method, i) => (
            <TabPanel value={currentPaymentTab} index={i} key={i}>
              {method === 'credit_card' && hasCreditCardPaymentMethod && (
                <CreditCard />
              )}
              {method === 'pix' && hasPixPaymentMethod && <Pix />}
              {method === 'ame' && <Ame />}
              {method === 'picpay' && <PicPay />}
            </TabPanel>
          ))}
        </Col>
      </Row>

      {currentPaymentTab === false && (
        <button className={styles.buttonWithoutPaymentMethod}>
          Nenhum método de pagamento selecionado
        </button>
      )}
    </div>
  )
}
