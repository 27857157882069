import React, { useState } from 'react'
import useIsMobile from 'utils/useIsMobile'

import Desktop from './image/Desktop.png'
import Mobile from './image/Mobile.png'

import styles from './styles.module.scss'

export default function BlackNovemberBanner() {
  const isMobile = useIsMobile()
  const [showBanner, setShowBanne] = useState(true)

  const handleBanner = () => {
    setShowBanne(false)
  }

  return (
    <>
      {showBanner && (
        <div className={styles.container}>
          <div onClick={() => window.open('https://oferta.maisalegria.com/novembro-black', '_self')}>
          </div>
          <img
            src={isMobile ? Mobile : Desktop}
            alt='promotio balck november'
            className={styles.banner}
          />
          <button
            className={styles.close}
            onClick={() => handleBanner()}
          >
            x
          </button>
        </div>
      )}
    </>
  )
}
