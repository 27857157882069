import React from "react";
import { classNames } from "utils";
import styles from "./index.module.scss";

const Breadcumb = ({ currentPath = [] }) => {
  return (
    <nav aria-label="breadcrumb" className={styles.container}>
      <ol className={classNames(styles.breadcrumb, "breadcrumb")}>
        {currentPath.map((path, index) => (
          <li className={classNames(styles.breadcrumbItem)} key={index}>
            {path.route}
          </li>
        ))}
      </ol>
      <p className={styles.category}>{currentPath[0].name}</p>
    </nav>
  );
};

export default Breadcumb;
