import React, { useEffect, useState } from 'react'
import { Trash } from 'assets/icons'
import styles from './styles.module.scss'

export const formmattedPrice = (value) => {
  return String(value.toFixed(2)).replace('.', ',')
}

const updatedLocalStorage = (order, items) => {
  localStorage.setItem('AU_order', JSON.stringify({ ...order, order: [...items]}))
}

const ItemsCart = ({ order, calcTotal }) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(order?.order)
    calcTotal(order?.order)
  }, [calcTotal, order])

  const removeItem = (price_id) => {
    const updatedItems = items.filter(item => item.price_id !== price_id)
    setItems(updatedItems)
    calcTotal(updatedItems)
    updatedLocalStorage(order, updatedItems)
  }

  const decreaseItem = (price_id) => {
    const updatedItems = items.map(item => item.price_id === price_id ? {...item, quantity: item.quantity - 1 } : item )
    setItems(updatedItems)
    calcTotal(updatedItems)
    updatedLocalStorage(order, updatedItems)
  }

  const increaseItem = (price_id) => {
    const updatedItems = items.map(item => item.price_id === price_id ? {...item, quantity: item.quantity + 1 } : item )
    setItems(updatedItems)
    calcTotal(updatedItems)
    updatedLocalStorage(order, updatedItems)
  }

  return (
    <div className={styles.container}>
      {items?.map(item => (
        <div key={item.price_id} className={styles.item}>
          <img
            src={item.photo}
            alt={item.name}
            className={styles.image}
          />
          <p className={styles.title}>
            {item.name}
          </p>
          <div className={styles.wrapper}>
            <div className={styles.box}>
              <div>
                <button
                  disabled={item.quantity === 1}
                  onClick={() => decreaseItem(item.price_id)}
                >
                  -
                </button>
                <div>
                  <span>{item.quantity}</span>
                </div>
                <button
                  disabled={item.quantity === 10}
                  onClick={() => increaseItem(item.price_id)}
                >
                  +
                </button>
              </div>
              <div onClick={() => removeItem(item.price_id)}>
                <Trash />
              </div>
            </div>
            {item.quantity > 1 && (
              <p>
                <span>{`${item.quantity} x ${formmattedPrice(item.value)}`}</span>
              </p>
            )}
            <p>R${formmattedPrice(item.value * item.quantity)}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ItemsCart
