import * as Actions from "store/actions/user";

function saveHeadersToLocalStorage(headers) {
  localStorage.setItem("AU_header", JSON.stringify(headers));
}

function removeHeadersFromLocalStorage() {
  localStorage.removeItem("AU_header");
  localStorage.removeItem("AU_data");
  localStorage.removeItem("AU_order");
}

const INITIAL_STATE = {
  headers: null,
  info: null,
  companyLogo: null,
  subscriptionInfo: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.SET_HEADER_INFO: {
      saveHeadersToLocalStorage(action.payload);
      return { ...state, headers: action.payload };
    }
    case Actions.SET_USER_INFO: {
      return { ...state, info: action.payload };
    }
    case Actions.USER_LOGOUT: {
      removeHeadersFromLocalStorage();
      return INITIAL_STATE;
    }
    case Actions.SET_COMPANY_LOGO: {
      return { ...state, companyLogo: action.payload };
    }
    case Actions.SET_SUBSCRIPTION_INFO: {
      return { ...state, subscriptionInfo: action.payload };
    }
    case Actions.USER_LOGIN: {
      saveHeadersToLocalStorage(action.headers);
      return {
        ...state,
        info: action.user,
        headers: action.headers,
      };
    }
    default: {
      return state;
    }
  }
};
