import axios from 'axios'
import store from 'store'

import { removeDuplicatedAndSortByWeight } from 'utils/productsRelated'

const getHeaders = () => {
  const state = store.getState()
  let headers = state.user.headers
  if (!headers) {
    return {}
  } else {
    return headers
      ? {
          'access-token': headers['access-token'],
          'token-type': headers['token-type'],
          client: headers['client'],
          uid: headers['uid'],
          expiry: headers['expiry'],
        }
      : {}
  }
}

const productFiltersToQueryParams = (params) => {
  let filters = []
  let paramNames = Object.keys(params)

  paramNames.forEach((name) => {
    if (params[name] !== null && params[name] !== undefined) {
      let value = params[name]
      if (Array.isArray(value)) {
        value.forEach((element) => filters.push(`q[${name}][]=${element}`))
      } else {
        filters.push(`q[${name}]=${value}`)
      }
    }
  })
  return filters.join('&')
}

export const signUpRequest = async (user, recaptchaToken, token = '') => {
  try {
    let referralUserId = sessionStorage.getItem('referral_user_id')

    return await axios
      .post(`${process.env.REACT_APP_API_URL}/auth`, {
        user: user,
        'g-recaptcha-response-data': recaptchaToken,
        referral_user_id: referralUserId,
        token: token,
      })
      .then((res) => {
        return { ...res.data.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const forgotPasswordRequest = async (user, recaptchaToken) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/auth/password`, {
        user: user,
        'g-recaptcha-response-data': recaptchaToken,
      })
      .then((res) => {
        return { ...res.data.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const loginRequest = async (
  { email, password, provider, cpf },
  recaptchaToken,
) => {
  var user = {}

  if (provider === 'email') {
    user = {
      email: email,
      password: password,
    }
  } else {
    user = {
      email: email,
      password: password,
      provider: provider,
      cpf: cpf,
    }
  }
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/auth/sign_in`, {
        user,
        'g-recaptcha-response-data': recaptchaToken,
      })
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    let errorResponse = {
      success: false,
      errors: [],
    }
    if (error.response.data.errors) {
      errorResponse.errors.push(...error.response.data.errors)
    } else if (error.response.data.error) {
      errorResponse.errors.push('Usuário não encontrado no clube +alegria.')
    } else {
      errorResponse.errors.push('E-mail ou senha inválidos.')
    }

    return errorResponse
  }
}

export const logoutRequest = async () => {
  try {
    return await axios
      .delete(`${process.env.REACT_APP_API_URL}/auth/sign_out`, {
        headers: getHeaders(),
      })
      .then((res) => ({ ...res.data, success: true }))
  } catch (error) {
    let data = error.response.data
    return data
  }
}

export const linkRequest = async ({ token }) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/users/link/${token}`)
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    let errorResponse = {
      success: false,
      errors: [],
    }
    if (error.response.data.error_message) {
      errorResponse.errors.push(error.response.data.error_message)
    } else {
      errorResponse.errors.push('Erro ao conectar com o servidor.')
    }

    return errorResponse
  }
}

export const updateUserData = async (params, recaptchaToken) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/users/update`,
        { user: params, 'g-recaptcha-response-data': recaptchaToken },
        { headers: getHeaders() },
      )
      .then((res) => ({ ...res.data, success: true }))
  } catch (error) {
    let data = { message: error.response.data.error_message, success: false }
    return data
  }
}

export const updateNewUserData = async (params, recaptchaToken) => {
  try {
    return await axios
      .put(`${process.env.REACT_APP_API_URL}/users/register`, {
        user: params,
        'g-recaptcha-response-data': recaptchaToken,
      })
      .then((res) => ({ ...res.data, success: true }))
  } catch (error) {
    let data = { message: error.response.data.error_message, success: false }
    return data
  }
}

export const registerUserByAccessCode = async (params, recaptchaToken) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/access_codes/register`, {
        user: params,
        'g-recaptcha-response-data': recaptchaToken,
      })
      .then((res) => ({ ...res.data, success: true }))
  } catch (error) {
    let data = { message: error.response.data.error_message, success: false }
    return data
  }
}

export const renewUserByAccessCode = async (params, recaptchaToken) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/access_codes/renew`, {
        user: params,
        'g-recaptcha-response-data': recaptchaToken,
      })
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    let data = { message: error.response.data.error_message, success: false }
    return data
  }
}

export const updateNewsletter = async (newsletter) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/users/newsletter`,
        { user: { newsletter } },
        { headers: getHeaders() },
      )
      .then((res) => ({ ...res.data, success: true }))
  } catch (error) {
    let data = { message: error.response.data.error_message, success: false }
    return data
  }
}

export const updateHistory = async (browsing_history) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/users/browsing_history`,
        { user: { browsing_history } },
        { headers: getHeaders() },
      )
      .then((res) => ({ ...res.data, success: true }))
  } catch (error) {
    let data = { message: error.response.data.error_message, success: false }
    return data
  }
}

export const getUserData = async () => {
  var headers = getHeaders()
  if (Object.keys(headers).length > 0) {
    try {
      return await axios
        .get(`${process.env.REACT_APP_API_URL}/users`, {
          headers,
        })
        .then((res) => ({ data: res.data, success: true }))
    } catch (error) {
      return error.response.data
    }
  }
}

export const getPartners = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/partners`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const resetPassword = async ({
  resetToken,
  newPassword,
  newPasswordConfirmation,
  recaptchaToken,
}) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/auth/password`,
        {
          user: {
            reset_password_token: resetToken,
            password: newPassword,
            password_confirmation: newPasswordConfirmation,
          },
          'g-recaptcha-response-data': recaptchaToken,
        },
        { headers: getHeaders() },
      )
      .then((res) => res.data)
  } catch (error) {
    return error.response.data
  }
}

export const getSubscriptionData = async () => {
  var headers = getHeaders()
  if (Object.keys(headers).length > 0) {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/subscriptions`, {
        headers,
      })
      .then((res) => res.data)
  }
}

export const getOrders = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/orders`, { headers: getHeaders() })
    .then((res) => res.data)
}

export const getHistory = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/browsing_histories`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getCoupons = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/coupons`, { headers: getHeaders() })
    .then((res) => res.data)
}

export const getSavingsSummary = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/savings_summary`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getCards = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/credit_cards`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const deleteCard = async (cardId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/credit_cards/${cardId}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getGiftVouchers = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/gift_vouchers`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getCampaignEvents = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/campaign_events`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getPartnerVideos = async (id) => {
  if (id) {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/partner_videos?partner_id=${id}`, {
        headers: getHeaders(),
      })
      .then((res) => res.data?.partner_videos)
  } else {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/partner_videos`, {
        headers: getHeaders(),
      })
      .then((res) => res.data?.partner_videos)
  }
}

export const getSubscripitionGiftVouchers = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/subscription_gift_vouchers`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const useSubscripitionGiftVoucher = async (params) => {
  return await axios
    .post(
      `${process.env.REACT_APP_API_URL}/subscription_gift_vouchers`,
      params,
      { headers: getHeaders() },
    )
    .then((res) => ({
      success: true,
      data: res.data,
    }))
    .catch((error) => ({
      success: false,
      data: error.response.data,
    }))
}

export const postCoupons = async (id) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/coupons`,
        {
          coupon_id: id,
        },
        {
          headers: getHeaders(),
        },
      )
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const postGiftVouchers = async (token) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/gift_vouchers`,
        {
          gift_voucher: { token: token },
        },
        {
          headers: getHeaders(),
        },
      )
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const postCampaignEvents = async (id) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/campaign_events`,
        {
          campaign_event: { id: id },
        },
        {
          headers: getHeaders(),
        },
      )
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const subscriptionPackageCheckout = async (params) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/subscription_package_orders`,
        params,
        { headers: getHeaders() },
      )
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const ordersValidate = async (cart) => {
  var headers

  if (
    getHeaders() &&
    Object.keys(getHeaders()).length === 0 &&
    getHeaders().constructor === Object
  ) {
    var auHeader = JSON.parse(localStorage.getItem('AU_header'))
    headers = {
      'access-token': auHeader['access-token'],
      'token-type': auHeader['token-type'],
      client: auHeader['client'],
      uid: auHeader['uid'],
      expiry: auHeader['expiry'],
    }
  } else {
    headers = getHeaders()
  }

  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/orders/validate`,
        {
          order: { items: cart },
        },
        {
          headers: headers,
        },
      )
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const performOrderCheckout = async (params) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/orders`, params, {
        headers: getHeaders(),
      })
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const performOrderSurvey = async (params) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_URL}/order_surveys`, params, {
        headers: getHeaders(),
      })
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    return error.response.data.error
  }
}

export const getProducts = async (
  page,
  isLogged,
  params = {},
  perPage = 10,
  sort_by = null,
  bannerID = null,
) => {
  let queryParams = [
    `page=${page}`,
    `per_page=${perPage}`,
    `per_page=${perPage}`,
    productFiltersToQueryParams(params),
  ]

  if (sort_by !== null) {
    let ascending = ['price'].includes(sort_by)
    queryParams.push(`sort_by[direction]=${ascending ? 1 : -1}`)
    queryParams.push(`sort_by[field]=${sort_by || 'price'}`)
  }

  if (bannerID) {
    return await axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/product_banners/${bannerID}?${queryParams.join('&')}`,
        {
          headers: isLogged ? getHeaders() : {},
        },
      )
      .then((res) => res.data)
  } else {
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/products?${queryParams.join('&')}`,
        {
          headers: isLogged ? getHeaders() : {},
        },
      )
      .then((res) => res.data)
  }
}

export const getProduct = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/products/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getHighlights = async (
  interest_area_in = {},
  page,
  perPage = 10,
) => {
  let pageParams = [`page=${page}`, `per_page=${perPage}`]
  let queryParams = productFiltersToQueryParams(interest_area_in, page, perPage)

  return await axios
    .get(
      `${
        process.env.REACT_APP_API_URL
      }/highlights?${queryParams}&${pageParams.join('&')}`,
      {
        headers: getHeaders(),
      },
    )
    .then((res) => res.data)
}

export const getMostVisited = async (isLogged = false) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/products/most_accessed`, {
      headers: isLogged ? getHeaders() : {},
    })
    .then((res) => res.data?.products)
}

export const getOurPartners = async (isLogged = false, per_page, page) => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/programmes?per_page=${per_page}&page=${page}`,
      {
        headers: isLogged ? getHeaders() : {},
      },
    )
    .then((res) => res.data)
}

export const getProgram = async (
  isLogged = false,
  programId,
  perPage,
  page,
) => {
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/programmes/${programId}?per_page=${perPage}&page=${page}`,
      {
        headers: isLogged ? getHeaders() : {},
      },
    )
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

export const getOffer = async (isLogged = false, offerId) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/programmes/offer/${offerId}`, {
      headers: isLogged ? getHeaders() : {},
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

export const getPlans = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/subscription_packages`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getPlan = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/subscription_packages/${id}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getProductsRelated = async (product) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/related_products/${product.id}`, {
      headers: getHeaders(),
    })
    .then((res) => {
      if (!res.data) return []

      const calculateTagWeight = (tags) => {
        let weight = 0

        if (Array.isArray(product.tags) && Array.isArray(tags)) {
          product.tags.forEach((tag) => {
            if (tags.map((obj) => obj.id).includes(tag.id)) weight += 2
          })
        }

        return weight
      }

      let allProducts = [
        ...res.data['tags'].map((prod) => ({
          ...prod,
          orderWeight: calculateTagWeight(prod.tags),
        })),
        ...res.data['sub_category'].map((prod) => ({
          ...prod,
          orderWeight: 1,
        })),
        ...res.data['category'].map((prod) => ({ ...prod, orderWeight: 1 })),
        ...res.data['city'].map((prod) => ({ ...prod, orderWeight: 0.5 })),
      ]

      let treatedProducts = removeDuplicatedAndSortByWeight({
        products: allProducts,
        excludeIds: [product.id],
      })
      return treatedProducts.slice(0, 10)
    })
}

export const getUserOffers = async (isLogged) => {
  if (isLogged) {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/recommended_products`, {
        headers: isLogged ? getHeaders() : {},
      })
      .then((res) => {
        if (!res.data) return []
        const calculateTagWeight = (tags) => {
          let weight = 0

          if (Array.isArray(tags) && Array.isArray(tags)) {
            tags.forEach((tag) => {
              if (tags.map((obj) => obj.id).includes(tag.id)) weight += 2
            })
          }

          return weight
        }

        var boughtProducts = []
        var visitedProducts = []

        if (res.data.bought) {
          boughtProducts = [
            ...res.data.bought['tags'].map((prod) => {
              return {
                ...prod,
                orderWeight: calculateTagWeight(prod.tags),
              }
            }),
            ...res.data.bought['sub_category'].map((prod) => {
              return {
                ...prod,
                orderWeight: 1,
              }
            }),
            ...res.data.bought['category'].map((prod) => {
              return { ...prod, orderWeight: 1 }
            }),
            ...res.data.bought['city'].map((prod) => {
              return { ...prod, orderWeight: 0.5 }
            }),
          ]
        }

        if (res.data.visited) {
          visitedProducts = [
            ...res.data.visited['tags'].map((prod) => {
              return {
                ...prod,
                orderWeight: calculateTagWeight(prod.tags),
              }
            }),
            ...res.data.visited['sub_category'].map((prod) => {
              return {
                ...prod,
                orderWeight: 1,
              }
            }),
            ...res.data.visited['category'].map((prod) => {
              return { ...prod, orderWeight: 1 }
            }),
            ...res.data.visited['city'].map((prod) => {
              return { ...prod, orderWeight: 0.5 }
            }),
          ]
        }

        const allProducts = boughtProducts.concat(visitedProducts)
        let treatedProducts = removeDuplicatedAndSortByWeight(
          {
            products: allProducts,
          },
          true,
        )

        return { products: treatedProducts }
      })
  } else {
    return { products: [] }
  }
}

export const saveOnUserHistory = async (product_id) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/browsing_histories`,
        {
          product_id,
        },
        {
          headers: getHeaders(),
        },
      )
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const createLead = async (email) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/leads`,
        {
          lead: {
            email: email,
          },
        },
        {
          headers: getHeaders(),
        },
      )
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const getCategories = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/categories`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getHomeBanners = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/home_banners`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getSettings = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/settings`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getReferrals = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/user_referrals`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getLoyaltyPrograms = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/loyalty_programs`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getLoyaltyProgramByProvider = async (provider) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/loyalty_programs/by_provider`, {
      headers: getHeaders(),
      params: { provider },
    })
    .then((res) => res.data)
}

export const getPartnerSettings = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/partners/settings`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getCities = async (interestAreaSlug = '') => {
  let queryParams = ``
  if (interestAreaSlug) {
    queryParams = `q[interest_area_in][]=${interestAreaSlug}`
  }

  return await axios
    .get(`${process.env.REACT_APP_API_URL}/products/cities?${queryParams}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const getCitiesRequest = async (UF) => {
  return await axios
    .get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${UF}/municipios`,
    )
    .then((res) => {
      return res.data
        .map((city) => city.nome)
        .sort((a, b) => a.localeCompare(b))
        .map((city) => ({ value: city, label: city }))
    })
}

export const getAllCitiesRequest = async () => {
  return await axios
    .get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios`)
    .then((res) =>
      res.data
        .map((city) => city.nome)
        .sort((a, b) => a.localeCompare(b))
        .map((city) => ({ value: city, label: city })),
    )
}

export const getCep = async (cep) => {
  return await axios
    .get(`https://viacep.com.br/ws/${cep}/json/`)
    .then((res) => res.data)
}

export const treatRequestErrors = (requestError) => {
  if (Array.isArray(requestError)) {
    return [...requestError]
  } else if (typeof requestError === 'object' && requestError.full_messages) {
    return [...requestError.full_messages]
  } else if (typeof requestError === 'string') {
    return [requestError]
  }

  return ['Não foi possivel se conectar com o servidor.']
}

export const getPagarmeToken = async (card) => {
  var data = {
    type: 'card',
    card,
  }
  return await axios
    .post(`${process.env.REACT_APP_PAGARME_URL}`, data)
    .then((res) => res.data.id)
}

export const getRecurrent = async (id, recurrent) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/subscriptions/${id}`,
        { recurrent: recurrent },
        { headers: getHeaders() },
      )
      .then((res) => ({ ...res.data, success: true }))
  } catch (error) {
    let data = { message: error.response.data.error_message, success: false }
    return data
  }
}

export const cancelSubscription = async (id) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/subscriptions/${id}/cancel`, {
      headers: getHeaders(),
    })
    .then((res) => {
      if (res.status === 200) {
        return 'Assinatura cancelada com sucesso.'
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data.error_message
      }
    })
}

export const getAddresses = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/addresses`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const addressCreate = async (addressData) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_URL}/addresses`,
        { address: addressData },
        { headers: getHeaders() },
      )
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const addressUpdate = async (addressData, addressId) => {
  try {
    return await axios
      .put(
        `${process.env.REACT_APP_API_URL}/addresses/${addressId}`,
        { address: addressData },
        { headers: getHeaders() },
      )
      .then((res) => {
        return { ...res.data, success: true }
      })
  } catch (error) {
    return error.response.data
  }
}

export const addressDelete = async (addressId) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/addresses/${addressId}`, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
}

export const historyDelete = async (id) => {
  if (!id) {
    return await axios
      .delete(`${process.env.REACT_APP_API_URL}/browsing_histories`, {
        headers: getHeaders(),
      })
      .then((res) => res.data)
  } else {
    return await axios
      .delete(`${process.env.REACT_APP_API_URL}/browsing_histories/${id}`, {
        headers: getHeaders(),
      })
      .then((res) => res.data)
  }
}

export const sendContactForEmail = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/send`, {
      ...data,
    })

    return response.data
  } catch (error) {
    console.error(error.message)
    return null
  }
}

export const createAmeDigitalOrder = async () => {
  return await axios
    .post(
      `${process.env.REACT_APP_AMEDIGITAL_URL}/ordens`,
      {
        title: 'Minha Loja.com',
        description: 'Pedido X',
        amount: 14990,
        type: 'PAYMENT',
        currency: 'BRL',
        attributes: {
          transactionChangedCallbackUrl:
            'https://webhook.site/1b636146-7899-4e71-962c-3ef753f5ddfc',
          items: [
            {
              ean: '22475411',
              sku: 'None',
              amount: 670,
              quantity: 1,
              description: 'CAFE BR MANIA CAPPUCCINO 180ML',
            },
            {
              ean: '22475400',
              sku: 'None',
              amount: 990,
              quantity: 1,
              description: 'REFRI COCA COLA LT 350ML',
            },
            {
              ean: '22475450',
              sku: 'None',
              amount: 13000,
              quantity: 1,
              description: 'Abastecimento - Gasolina Aditivada',
            },
          ],
          customPayload: {
            isFrom: 'MinhaPlataforma',
          },
          address: [
            {
              postalCode: '86047610',
              street: 'Rua Sete de Setembro',
              number: '3',
              complement: 'APTO 2',
              neighborhood: 'Centro',
              city: 'Londrina',
              state: 'PR',
              country: 'BRA',
              type: 'BILLING',
            },
            {
              postalCode: '86047610',
              street: 'Rua Sete de Setembro',
              number: '3',
              complement: 'APTO 2',
              neighborhood: 'Centro',
              city: 'Londrina',
              state: 'PR',
              country: 'BRA',
              amountValue: 0,
              type: 'DELIVERY',
            },
          ],
          paymentOnce: true,
          riskHubProvider: 'SYNC',
          origin: 'ECOMMERCE',
        },
      },

      {
        headers: {
          client_id: 'será enviado depois',
          access_token: 'teste',
        },
      },
    )
    .then((res) => res.data)
}

export const createPicPayPayment = async () => {
  return await axios
    .post(
      `${process.env.REACT_APP_PICPAY_URL}/payments`,
      {
        referenceId: '102030',
        callbackUrl: 'http://www.sualoja.com.br/callback',
        returnUrl: 'http://www.sualoja.com.br/cliente/pedido/102030',
        value: 20.51,
        expiresAt: '2022-05-01T16:00:00-03:00',
        channel: 'my-channel',
        purchaseMode: 'in-store',
        buyer: {
          firstName: 'João',
          lastName: 'Da Silva',
          document: '123.456.789-10',
          email: 'teste@picpay.com',
          phone: '+55 27 12345-6789',
        },
      },

      {
        headers: {
          'x-picpay-token': `${process.env.REACT_APP_PICPAY_TOKEN}`,
        },
      },
    )
    .then((res) => res.data)
}

export const getAllPlans = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/plans`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const prePartnersForms = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/pre_partners`,
      {
        ...data,
      },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const uploadImagePrePartners = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/pre_partners/upload_images`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const prePartnersTokenConfirmation = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/pre_partners/confirmation`,
      data,
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const resendPrePartnersTokenConfirmation = async (id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/pre_partners/resend_confirmation`,
      id,
    )
    return response.data
  } catch (error) {
    return error.resposne.data
  }
}

export const prePartnersPayment = async (order) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/subscription_partner_orders`,
      order,
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const getQuizById = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/quizzes/${id}`,
      {
        headers: getHeaders(),
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const sendEachQuizAnswer = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/quizzes`,
      {
        quiz: { ...data },
      },
      {
        headers: getHeaders(),
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getAllQuiz = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/quizzes/`,
      {
        headers: getHeaders(),
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getAllSurveys = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/surveys`,
      {
        headers: getHeaders(),
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getSurveysById = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/surveys/${id}`,
      {
        headers: getHeaders(),
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const sendEachSurveysAnswer = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/surveys`,
      {
        survey: { ...data },
      },
      {
        headers: getHeaders(),
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const programmersHighlights = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/programmes/highlights`, {
      headers: getHeaders()
    })

    return response.data

  } catch (error) {
    console.error(error)
  }
}
