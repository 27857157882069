import React from "react";

import { Button, Box, useTheme, useMediaQuery } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import { ArrowRight } from "assets/icons/ArrowRight";

const CtaButton = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Button
      className={classes.cta}
      endIcon={
        <Box className={classes.icon}>
          <ArrowRight width={isMobile ? "11px" : "15px"} color={props?.color} />
        </Box>
      }
      onClick={props.onClick}
      {...props}
    >
      Veja Planos
    </Button>
  );
};

export default CtaButton;

const useStyles = makeStyles((theme) => {
  return {
    cta: {
      marginTop: "57px",

      width: "181px",
      height: "54px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "33px",
      background: "#ED9915",
      textTransform: "none",
      color: "#FFF",
      textAlign: "center",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "37.195px",
      fontFamily: "NeuePlak-SemiBold",
      [theme.breakpoints.down("md")]: {
        marginTop: "26px",
        width: "113px",
        height: "33px",
        borderRadius: "20px",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontSize: "8.807px",
        fontWeight: 400,
        lineHeight: "23.398px",
      },
    },
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      marginLeft: "20px",
      paddingRight: "20px",
      [theme.breakpoints.down("md")]: {
        width: "10px",
        marginLeft: "14px",
        paddingRight: "14px",
      },
    },
  };
});
