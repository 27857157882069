import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'

import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useCookies } from 'react-cookie'
import Cookies from 'universal-cookie'

import { classNames } from 'utils'
import { isLoggedIn } from 'store/selectors'

import {
  getGiftVouchers,
  postGiftVouchers,
  ordersValidate,
} from 'services/api/index'
import * as Actions from 'store/actions'

import styles from './index.module.scss'

import Footer from 'components/footer'
import PageLoading from 'components/pageLoading'

import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  SnackbarContent,
} from '@material-ui/core'
import DesktopCartItem from 'components/cart/desktopItemCart'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { CustomSwitch, TextFieldController } from 'components/forms'
import { Snackbar } from '@mui/material'
import CartHeader from './components/header'
import ProductsRelated from 'components/productsRelated'
import { Container } from 'react-bootstrap'
import OutPartners from 'components/ourPartners'

const Carrinho = () => {
  const logged = useSelector(isLoggedIn)
  const user = useSelector((state) => state.user.info)
  const history = useHistory()
  const dispatch = useDispatch()
  const [carrinho, updateCarrinho] = useState([])

  const [total, updateTotal] = useState(0)
  const [subTotal, updateSubTotal] = useState(0)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [hasRelated, setHasRelated] = useState(true)
  const [validateMessage, setShowValidateMessage] = useState(null)
  const [giftCardChecked, setGiftCardChecked] = useState(true)
  const [avaiableAmount, setAvaiableAmout] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [isDisabled, setIsDisabled] = useState(true)
  const [cookies, setCookie, removeCookie] = useCookies(['AU_cart_' + user?.id])
  const [cartCookie, setCartCookie] = useState(
    null || (user && cookies['AU_cart_' + user?.id]),
  )

  useEffect(() => {
    const userHeader = JSON.parse(localStorage.getItem('AU_header'))
    if (!logged && !userHeader) {
      history.push('/partners')
    }
  }, [logged, history])

  const handleCartCookie = useCallback(
    (cart) => {
      if (cart.order.length > 0 && user) {
        setCookie(['AU_cart_' + user.id], cart, {
          path: '/',
          maxAge: 604800,
        })
      }
    },
    [setCookie, user],
  )

  useEffect(() => {
    dispatch(Actions.getUser())
  }, [dispatch])

  useEffect(() => {
    const universalCookies = new Cookies()
    if (user) {
      setCartCookie(universalCookies.get('AU_cart_' + user.id))
    }
  }, [user])

  useEffect(() => {
    const orders = JSON.parse(localStorage.getItem('AU_order')) || cartCookie

    if (orders) {
      const orderProducts = orders

      if (orderProducts.order.length) {
        let products = orderProducts.order.map(async (product) => {
          if (product.quantity > 0 && product.quantity <= 10) {
            return await getProduct(
              product.id,
              product.price_id,
              product.quantity,
              product.ticket_id,
            )
          } else {
            localStorage.removeItem('AU_order')
            if (user) {
              removeCookie(['AU_cart_' + user.id])
            }
            history.push(`/ofertas`)
          }
        })

        Promise.all(products).then((products) => {
          let filteredProducts = products.filter(
            (product) => product && product.status === 'No Ar',
          )

          updateSubTotal(
            filteredProducts.reduce(function (prev, cur) {
              if (cur && cur.quantity > 0 && cur.quantity <= 10) {
                return prev + cur.value * cur.quantity
              } else {
                return 0
              }
            }, 0)
          );
          updateCarrinho(filteredProducts);
        });
      }
      if (orderProducts.order) {
        ordersValidate(orderProducts.order).then((response) => {
          if (response.status === 'ok') {
            setShowValidateMessage(null)
            setIsDisabled(false)
          } else {
            setShowValidateMessage(response.reason)
          }
        })
      }
    }
  }, [cartCookie, history, removeCookie, user])

  useEffect(() => {
    if (logged && user) {
      getGiftVouchers().then((response) => {
        setAvaiableAmout(response.available_amount)
        if (response.available_amount === 0 && carrinho.length > 0) {
          setGiftCardChecked(false)
          localStorage.setItem(
            'AU_order',
            JSON.stringify({
              use_gift_vouchers: false,
              order: carrinho,
              total: total,
            }),
          )

          handleCartCookie({
            use_gift_vouchers: false,
            order: carrinho,
            total: total,
          })
        } else if (user) {
          localStorage.setItem(
            'AU_order',
            JSON.stringify({
              use_gift_vouchers: giftCardChecked,
              order: carrinho,
              total: total,
            }),
          )
          removeCookie(['AU_cart_' + user.id])
        }
      })
    }
  }, [
    logged,
    carrinho,
    user,
    handleCartCookie,
    removeCookie,
    total,
    giftCardChecked,
  ])

  useEffect(() => {
    const userCarrinho = JSON.parse(localStorage.getItem('AU_order'))

    if (userCarrinho) {
      setGiftCardChecked(userCarrinho.use_gift_vouchers)
    } else {
      setGiftCardChecked(giftCardChecked)
    }

    if (!giftCardChecked) {
      updateTotal(subTotal)
      setDiscount(0)
    } else {
      if (subTotal - avaiableAmount < 0) {
        updateTotal(0)
        setDiscount(subTotal)
      } else {
        updateTotal(subTotal - avaiableAmount)
        setDiscount(avaiableAmount)
      }
    }
  }, [giftCardChecked, subTotal, avaiableAmount])

  const getProduct = async (id, price_id, quantity, ticket_id) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/products/${id}`)
      .then(function (resp) {
        let price = resp.data.tickets
          .map((obj) => {
            var array = obj.prices.filter((price) => {
              return price.id === price_id
            })
            return array[0]
          })
          .filter(function (el) {
            return el != null
          })

        let label = resp.data.tickets.filter(
          (ticket) => ticket.id === ticket_id,
        )

        if (price.length > 0) {
          let product = {
            id: resp.data.id,
            name: resp.data.title,
            photo: resp.data?.images[0]?.url,
            price_id,
            quantity,
            value: price[0].price_in_money,
            status: resp.data.status,
            label: label?.at(0)?.label,
            ticket_id,
          }
          return product
        } else {
          return null
        }
      })
  }

  const updateOrderOnSession = (carrinho) => {
    if (carrinho) {
      ordersValidate(carrinho).then((response) => {
        if (response.status === 'ok') {
          setIsDisabled(false)
          setShowValidateMessage(null)
        } else {
          setShowValidateMessage(response.reason)
        }
      })
    }
    localStorage.setItem(
      'AU_order',
      JSON.stringify({
        use_gift_vouchers: giftCardChecked,
        order: carrinho,
        total: total,
      }),
    )
    handleCartCookie({ use_gift_vouchers: giftCardChecked, order: carrinho })
    if (carrinho.length === 0) {
      localStorage.removeItem('AU_order')
      if (user) {
        removeCookie(['AU_cart_' + user.id])
      }
    }
  }

  const increaseQuantity = (id, price_id) => {
    let newCarrinho = [...carrinho]

    const itemIndex = newCarrinho.findIndex(
      (item) => item.id === id && item.price_id === price_id,
    )

    if (itemIndex !== -1) {
      newCarrinho[itemIndex].quantity++

      updateCarrinho(newCarrinho)
      updateSubTotal(subTotal + newCarrinho[itemIndex].value)
      updateOrderOnSession(newCarrinho)
    }
  }

  const decreaseQuantity = (id, price_id) => {
    let newCarrinho = [...carrinho]

    const itemIndex = newCarrinho.findIndex(
      (item) => item.id === id && item.price_id === price_id,
    )

    if (itemIndex !== -1 && newCarrinho[itemIndex].quantity > 0) {
      newCarrinho[itemIndex].quantity--

      updateCarrinho(newCarrinho)
      updateSubTotal(subTotal - newCarrinho[itemIndex].value)
      updateOrderOnSession(newCarrinho)
    }
  }

  const handleRemoveItem = (price_id) => {
    const product = carrinho.filter((product) => product.price_id === price_id)
    updateCarrinho(carrinho.filter((product) => product.price_id !== price_id))
    updateSubTotal(subTotal - product[0].value * product[0].quantity)
    updateOrderOnSession(
      carrinho.filter((product) => product.price_id !== price_id),
    )
  }

  const goToCheckout = () => {
    const orders = localStorage.getItem('AU_order')

    if (orders) {
      const orderProducts = JSON.parse(localStorage.getItem('AU_order'))
      if (orderProducts && orderProducts.order) {
        ordersValidate(orderProducts.order).then((response) => {
          if (response.status === 'ok') {
            history.push(`/checkout`)
            setShowValidateMessage(null)
          } else {
            setShowValidateMessage(response.reason)
          }
        })
      }
    }
  }

  const groupedById = carrinho.reduce((acc, current) => {
    const existingItem = acc.find((item) => item.id === current.id)

    if (existingItem) {
      existingItem.details.push({
        id: current.id,
        price_id: current.price_id,
        quantity: current.quantity,
        value: current.value,
        label: current.label,
      })
    } else {
      acc.push({
        id: current.id,
        name: current.name,
        photo: current.photo,
        status: current.status,
        details: [
          {
            price_id: current.price_id,
            quantity: current.quantity,
            value: current.value,
            label: current.label,
          },
        ],
      })
    }

    return acc
  }, [])

  return (
    <PageLoading>
      <CartHeader />
      <Grid container className={styles.grid}>
        <Grid item xs={12} sm={8} p={0}>
          <Box className={styles.desktopCart}>
            {carrinho && !carrinho.length ? (
              <div>Seu carrinho está vazio</div>
            ) : (
              groupedById.map((product, index) => (
                <DesktopCartItem
                  key={index}
                  product={product}
                  decreaseQuantity={decreaseQuantity}
                  increaseQuantity={increaseQuantity}
                  removeItem={handleRemoveItem}
                />
              ))
            )}
          </Box>

          {validateMessage && (
            <div className={styles.errorValidate}>{validateMessage}</div>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box className={styles.rigthBox}>
            {carrinho && carrinho?.length > 0 && (
              <Box className={styles.boxWrapper}>
                {carrinho && carrinho?.length > 0 && (
                  <Giftcard
                    carrinho={carrinho}
                    showSnackbar={showSnackbar}
                    setShowSnackbar={setShowSnackbar}
                    handleCartCookie={handleCartCookie}
                    subTotal={subTotal}
                    setDiscount={setDiscount}
                  />
                )}
                <div className={styles.carrinhoTotalBox}>
                  <CartTotal
                    subTotal={subTotal}
                    giftCardChecked={giftCardChecked}
                    discount={discount}
                    total={total}
                    isDisabled={isDisabled}
                    goToCheckout={goToCheckout}
                  />
                </div>
                {total < 1 && total > 0 && (
                  <div className={styles.minimumTotal}>
                    Mínimo de R$1,00 para pagamento com <br /> cartão de crédito
                    ou PIX
                  </div>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      {carrinho && carrinho?.length > 0 && hasRelated && (
        <Container>
          <ProductsRelated relatedTo={carrinho} setHasRelated={setHasRelated} />
        </Container>
      )}

      <Container>
        <OutPartners />
      </Container>
      <Footer />
    </PageLoading>
  )
}

export default Carrinho

const Giftcard = ({
  carrinho,
  showSnackbar,
  setShowSnackbar,
  handleCartCookie,
  subTotal,
  setDiscount,
}) => {
  const [giftCardChecked, setGiftCardChecked] = useState(true)
  const [loadingEffect, setLoadingEffect] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [avaiableAmount, setAvaiableAmout] = useState(0)
  const [total, updateTotal] = useState(0)

  const { handleSubmit, errors, control } = useForm({
    reValidateMode: 'onSubmit',
  })

  const onSubmit = (data) => {
    setLoadingEffect(true)
    postGiftVouchers(data.token).then((response) => {
      setLoadingEffect(false)
      setShowSnackbar(true)
      if (response.error) {
        setSnackbarMessage(response.error)
      } else if (response.message) {
        setSnackbarMessage(response.message)
      } else {
        setAvaiableAmout(avaiableAmount + response.remaining_value)
        setGiftCardChecked(true)
        setSnackbarMessage('Vale-presente ativado com sucesso!')
      }
    })
  }

  useEffect(() => {
    const userCarrinho = JSON.parse(localStorage.getItem('AU_order'))

    if (userCarrinho) {
      setGiftCardChecked(userCarrinho.use_gift_vouchers)
    } else {
      setGiftCardChecked(giftCardChecked)
    }

    if (!giftCardChecked) {
      updateTotal(subTotal)
      setDiscount(0)
    } else {
      if (subTotal - avaiableAmount < 0) {
        updateTotal(0)
        setDiscount(subTotal)
      } else {
        updateTotal(subTotal - avaiableAmount)
        setDiscount(avaiableAmount)
      }
    }
  }, [giftCardChecked, subTotal, avaiableAmount, setDiscount])

  const changeGiftCardChecked = () => {
    setGiftCardChecked(!giftCardChecked)
    localStorage.setItem(
      'AU_order',
      JSON.stringify({
        use_gift_vouchers: !giftCardChecked,
        order: carrinho,
        total: total,
      }),
    )
    handleCartCookie({
      use_gift_vouchers: !giftCardChecked,
      order: carrinho,
      total: total,
    })
  }

  return (
    <div className={styles.carrinhoValeWrapper}>
      <div className={styles.valePresenteContainer}>
        <div className={styles.inputGroup}>
          {loadingEffect ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.giftDiv}>
                  <TextFieldController
                    // label="Adicionar Vale-Presente"
                    placeholder={'Adicionar vale-presente'}
                    name="token"
                    size="small"
                    control={control}
                    errors={errors}
                  />
                </div>
                <button className={styles.btnForm} type="submit">
                  Ativar
                </button>
              </form>

              <Snackbar
                open={showSnackbar}
                autoHideDuration={5000}
                onClose={() => setShowSnackbar(false)}
              >
                <SnackbarContent message={snackbarMessage} />
              </Snackbar>
              {avaiableAmount > 0 && (
                <div className={styles.giftCardCheckedRow}>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={giftCardChecked}
                        onChange={changeGiftCardChecked}
                        name="use-giftcard"
                        color="primary"
                      />
                    }
                    label={
                      <span
                        className={
                          giftCardChecked
                            ? styles.giftCardPrice
                            : styles.giftCardPriceDisabled
                        }
                      >
                        Saldo de{' '}
                        <span
                          className={
                            giftCardChecked
                              ? styles.giftCardStrongPrice
                              : styles.giftCardStrongPriceDisabled
                          }
                        >
                          R${' '}
                          {avaiableAmount
                            .toFixed(2)
                            .toString()
                            .replace('.', ',')}
                        </span>{' '}
                        para utilizar
                      </span>
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const CartTotal = ({
  subTotal,
  giftCardChecked,
  discount,
  total,
  isDisabled,
  goToCheckout,
}) => {
  return (
    <div className={styles.carrinhoTotalBox}>
      <div className={styles.carrinhoTotalContainer}>
        <>
          <div className={styles.carrinhoSubtotalContainer}>
            <p className={styles.carrinhoSubtotalTitulo}>Subtotal</p>
            <p className={styles.carrinhoSubtotalValor}>
              R$ {subTotal.toFixed(2).toString().replace('.', ',')}
            </p>
          </div>
          <div className={styles.carrinhoValePresenteContainer}>
            <p className={styles.carrinhoSubtotalTitulo}>Vale presente</p>
            {giftCardChecked ? (
              <p className={styles.carrinhoSubtotalValor}>
                - R$ {discount.toFixed(2).toString().replace('.', ',')}
              </p>
            ) : (
              <p className={styles.carrinhoSubtotalValor}>--</p>
            )}
          </div>
        </>
        <div className={styles.carrinhoTotalValueContainer}>
          <p className={styles.carrinhoTotalTitulo2}>Total</p>
          <p className={styles.carrinhoTotalValor}>
            R$ {total.toFixed(2).toString().replace('.', ',')}
          </p>
        </div>

        {total < 1 && total > 0 ? (
          <div
            className={classNames('btn btn-block', styles.btnDisabled)}
            disabled
          >
            Finalizar Pedido
          </div>
        ) : (
          <button
            aria-label="finalizar pedido"
            className={
              isDisabled
                ? classNames(styles.btnLaranja, styles.btnDisabled)
                : classNames(styles.btnLaranja, styles.btnFinalizar)
            }
            onClick={() => goToCheckout()}
            disabled={isDisabled}
          >
            Finalizar Pedido
          </button>
        )}

        <div style={{ width: '100%' }}>
          <Link to="/ofertas">
            <button className={styles.btnKeepBuying}>
              Comprar mais produtos
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
