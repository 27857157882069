import React, { useCallback, useState } from 'react'
// import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form, Col, FormGroup, Row } from 'react-bootstrap'

import AddIcon from '@material-ui/icons/Add'

import AddressCard from 'components/address/AddressCard'

import * as Actions from '../../store/actions'

import { getAddresses } from 'services/api'
import { classNames } from 'utils'
import styles from './index.module.scss'

import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'

import AddressDialog from 'components/address/AddressDialog'

export default function () {
  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' })

  // const history = useHistory();
  const dispatch = useDispatch()
  const [initialLoading, setInitialLoading] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [addresses, setAddresses] = useState(null)
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [showNotification, setShowNotification] = useState(false)

  const validateAddress = (address) => {
    address.city = address.city.replace(/\s\s+/g, ' ').trim()
    address.neighborhood = address.neighborhood.replace(/\s\s+/g, ' ').trim()
    address.number = address.number.replace(/\s\s+/g, ' ').trim()
    address.state = address.state.replace(/\s\s+/g, ' ').trim()
    address.street = address.street.replace(/\s\s+/g, ' ').trim()
    address.zip_code = address.zip_code.replace(/\s\s+/g, ' ').trim()

    if (
      address.city === '' ||
      address.neighborhood === '' ||
      address.number === '' ||
      address.state === '' ||
      address.street === '' ||
      address.zip_code === ''
    ) {
      return false
    } else {
      return true
    }
  }

  const onSubmit = async (data) => {
    if (validateAddress(selectedAddress)) {
      if (selectedAddress) {
        dispatch(Actions.setAddressInfo({ ...data, ...selectedAddress }))
        dispatch(Actions.changeStep('next'))
        setShowNotification(false)
      } else {
        setShowNotification(true)
      }
    } else {
      alert('Endereço inválido!')
    }
  }

  const handleBack = () => {
    dispatch(Actions.changeStep(''))
  }

  const fetchAddresses = useCallback(() => {
    setShowNotification(false)
    setInitialLoading(true)
    getAddresses()
      .then((response) => {
        setAddresses(response)
        if (Array.isArray(response) && response.length > 0) {
          let defaultAddress = response.find((add) => add.default)
          setSelectedAddress(defaultAddress || response[0])
        }
      })
      .finally(() => setInitialLoading(false))
  }, [])

  React.useEffect(() => {
    fetchAddresses()
  }, [fetchAddresses])

  return (
    <div className={styles.rootContainer}>
      <Row>
        <Col className={classNames(styles.infosWrapper)}>
          <h1 className={styles.sectionTitle}>Endereços</h1>

          <AddressDialog
            show={showDialog}
            onClose={() => {
              setShowDialog(false)
              fetchAddresses()
            }}
          />

          {initialLoading ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className={styles.formGroup}>
                <Row className="mt-3">
                  <Col sm={12} className="d-flex mt-2">
                    <div
                      className={styles.addButton}
                      onClick={() => setShowDialog(true)}
                    >
                      <AddIcon />
                      <Typography variant="h5" component="h2">
                        Adicionar
                      </Typography>
                    </div>
                  </Col>
                  {addresses &&
                    addresses.map((address) => (
                      <Col
                        sm={12}
                        className="mt-3 d-flex justify-content-center align-items-center"
                        key={address.id}
                      >
                        <Radio
                          checked={selectedAddress?.id === address.id}
                          onChange={() => setSelectedAddress(address)}
                          value={address.id}
                          color="secondary"
                          name="address"
                        />

                        <AddressCard
                          address={address}
                          onClick={() => setSelectedAddress(address)}
                          className={'flex-grow-1'}
                          style={{ cursor: 'pointer' }}
                        />
                      </Col>
                    ))}
                </Row>
                {showNotification && (
                  <span className={styles.error}>Campo Obrigatório</span>
                )}
              </FormGroup>

              <Row className="mt-3">
                <Col xs={6}>
                  <button
                    aria-label="voltar"
                    type="button"
                    className={classNames(styles.backButton, 'btn-form')}
                    onClick={() => {
                      //history.push("/carrinho");
                      handleBack()
                    }}
                  >
                    Voltar
                  </button>
                </Col>
                <Col xs={6}>
                  <button
                    aria-label="continuar"
                    type="submit"
                    className={'custom-btn-verde btn-form'}
                    // disabled={!selectedAddress}
                  >
                    Continuar
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  )
}
