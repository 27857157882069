import React from 'react'

import styles from './index.module.scss'
import { Box } from '@mui/material'

export default function ({
  address,
  editButtonEvent = null,
  deleteButtonEvent = null,
  setAsDefaultButtonEvent = null,
  ...props
}) {
  return (
    <Box {...props} className={styles.cardWrapper}>
      <Box>
        <Box className={styles.addressName}>
          {address.name}{' '}
          {address.default && (
            <span className="font-weight-bold">(Padrão)</span>
          )}
        </Box>
        <Box className={styles.address}>
          {address.street} {address.number} <br />
          {address.complement && `${address.complement}, `}
          {address.neighborhood} <br />
          {address.city} - {address.state}
        </Box>
      </Box>
      {/* <CardActions className={styles.actionsWrapper}>
        {typeof setAsDefaultButtonEvent === 'function' && !address.default && (
          <Button
            aria-label="Definir como padrão"
            size="small"
            className={styles.defaultBtn}
            onClick={() => setAsDefaultButtonEvent(address)}
          >
            Definir como padrão
          </Button>
        )}
        <div className={styles.btnWrapper}>
          {typeof editButtonEvent === 'function' && (
            <Button
              aria-label="editar"
              size="small"
              variant="outlined"
              onClick={() => editButtonEvent(address)}
              className={styles.editBtn}
            >
              Editar
            </Button>
          )}
          {typeof deleteButtonEvent === 'function' && (
            <Button
              aria-label="Excluir"
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => deleteButtonEvent(address)}
              className={styles.excludeBtn}
            >
              Excluir
            </Button>
          )}
        </div>
      </CardActions> */}
    </Box>
  )
}
