import React, { useEffect } from "react";
// import PageLoading from "components/pageLoading";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./custom.css";
// import { Container } from "react-bootstrap";
// import { classNames } from "utils";

// import CataventoImg from "./img/catavento.png";
// import CoracaoImg from "./img/coracao.png";
// import EstrelaImg from "./img/estrela.png";

// import MostVisited from "components/mostVisited";
import Layout from "components/layout";
import KnowMore from "./components/knowMore";
import Variaty from "./components/variaty";
import HomeAdvantage from "./components/homeAdvantage";
import Headline from "./components/headline";
import Banner from "./components/banner";
import { isLoggedIn } from "store/selectors";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// const ProductsHighlight = loadable(
//   () => import("components/productsHighlight"),
//   {
//     fallback: <></>,
//   }
// );
// const StyledTitle = loadable(() => import("components/styledTitle"), {
//   fallback: <></>,
// });

// const Newsletter = loadable(() => import("components/newsletter"), {
//   fallback: <></>,
// });

const Home = (props) => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      if (window.location.hash) {
        let elementNode = document.getElementById(
          window.location.hash.slice(1)
        );
        if (elementNode) {
          elementNode.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 1800);
  }, []);

  const isLogged = useSelector(isLoggedIn)

  useEffect(() => {
    if(isLogged) {
      history.push("/ofertas")
    }

  }, [isLogged, history])


  return (
    <Layout>
      <Headline />
      <HomeAdvantage />
      <KnowMore />
      <Variaty />
      <Banner />
      {/* <div className="home-alegria-hero mb-5">
        <Container>
          <div className="row p-0">
            <div className="col-md-8 col-sm-12">
              <p className="text-navy text-hero">
                <span className="text-white">Chegou o seu</span> novo clube de
                felicidade!
              </p>
              <p className="text-white mt-2 mb-5">
                Descontos de até 70% e promoções exclusivas.{" "}
              </p>
            </div>
          </div>
        </Container>
      </div>

      <Container>
        <ProductsHighlight title={"DESTAQUES"} />
      </Container>

      <Container className="mt-3" id="como-funciona">
        <StyledTitle title={"COMO FUNCIONA"} />
      </Container>
      <div className="como-funciona-hero mb-5">
        <Container className="mt-3 mb-1">
          <div className="row">
            <div className="col-lg-5 col-md-7 col-sm-12 offset-lg-7 offset-md-5">
              <CustomCardButton
                imgSrc={CataventoImg}
                strongText="Acesse nossas ofertas"
                descriptionText="Navegue em nosso site e descubra um mundo de entretenimento."
                appendClasses="mb-4"
              />

              <CustomCardButton
                imgSrc={CoracaoImg}
                strongText="Entre para o nosso clube"
                descriptionText="Escolha seu plano para ter acesso a descontos de até 70%."
                appendClasses="mb-4"
              />

              <CustomCardButton
                imgSrc={EstrelaImg}
                strongText="Aproveite seus benefícios"
                descriptionText="Tenha acesso a experiências e promoções exclusivas para sócios."
              />
            </div>
          </div>
        </Container>
      </div>

      <Container>
        <MostVisited />
      </Container>

      <Newsletter /> */}
    </Layout>
  );
};

export default Home;

// const CustomCardButton = ({
//   imgSrc,
//   strongText,
//   descriptionText,
//   appendClasses,
// }) => {
//   return (
//     <button
//       aria-label={descriptionText}
//       type="button"
//       className={classNames("btn btn-light w-100", appendClasses)}
//     >
//       <div className="row">
//         <div className="col-3 pl-icone">
//           <LazyLoad once>
//             <img src={imgSrc} alt="" />
//           </LazyLoad>
//         </div>
//         <div className="col-9">
//           <p className="text-left py-2">
//             <strong>{strongText}</strong>
//             <br />
//             {descriptionText}
//           </p>
//         </div>
//       </div>
//     </button>
//   );
// };
