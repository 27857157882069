import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import css from "./index.module.scss";

import Footer from "components/footer";
// import Header from "components/header";
import Newsletter from "components/newsletter";
import NewLayoutHeader from "components/NewLayoutHeader";

import PageLoading from "components/pageLoading";
import { getProgram } from "services/api";
import { isLoggedIn } from "store/selectors";
import ProgramDetail from "./programDetail";
import ProgramUnavailable from "./components/ProgramUnavailable";

const ProgramPage = (props) => {
  const programId = props.match.params.id;
  const logged = useSelector(isLoggedIn);
  const [offers, setOffers] = useState([]);
  const [program, setProgram] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const per_page = 25;

  useEffect(() => {
    const numberOfPages = Math.ceil(total / per_page);
    setTotalPages(numberOfPages);
  }, [total]);

  useEffect(() => {
    if (currentPage) {
      if (currentPage < totalPages) {
        setHasMore(true);
      } else if (currentPage === totalPages) {
        setHasMore(false);
      }
    }
  }, [currentPage, totalPages]);

  React.useEffect(() => {
    if (programId) {
      getProgram(logged, programId, per_page, currentPage).then((res) => {
        setOffers([...offers, ...res.offers]);
        setProgram(res.programme);
        setCurrentPage(res.pagination.current_page);
        setTotal(res.pagination.total);
      });
    }
    // eslint-disable-next-line
  }, [logged, programId]);

  const fetchNextPage = () => {
    const updatePAge = currentPage + 1;
    getProgram(logged, programId, per_page, updatePAge).then((res) => {
      setOffers([...offers, ...res.offers]);
      setProgram(res.programme);
      setCurrentPage(res.pagination.current_page);
      setTotal(res.pagination.total);
    });
  };

  return (
    <PageLoading>
      {/* <Header /> */}
      <NewLayoutHeader />
      {offers.length > 0 ? (
        <ProgramDetail offers={offers} program={program} />
      ) : (
        <ProgramUnavailable />
      )}
      {hasMore && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            maxWidth: 200,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 10,
            marginBottom: 32,
          }}
        >
          <button
            className={clsx(css["btn-produto"], css["btn-laranja"])}
            aria-label="Definir como padrão"
            onClick={() => fetchNextPage()}
          >
            Carregar mais ofertas
          </button>
        </div>
      )}
      <Newsletter />
      <Footer />
    </PageLoading>
  );
};

export default ProgramPage;
