import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useSideModalState, useSideWindowAction } from 'context/SideWindow-context'
import { useLocation } from "react-router-dom";

import { getProducts } from "services/api";
import { isLoggedIn } from "store/selectors";

import styles from "./index.module.scss";

import Hidden from "@material-ui/core/Hidden";

import Filters from "../filters";
import Breadcumb from "components/breadcumb";
import ProductsGrid from "components/productsGrid";

import { Container } from "react-bootstrap";

import { FilterMobile } from "../filters/mobile/filterMobile";

const ProductsList = (props) => {
  const logged = useSelector(isLoggedIn);
  const interestArea = useSelector((state) => state.global.activeinterestArea);
  const { data } = useSideModalState();
  const { clearData } = useSideWindowAction();
  const { search } = useLocation();

  const [page, setPage] = useState(1);
  const [productsData, setProductsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setFilters((oldState) => ({
      ...oldState,
      interest_area_in: [interestArea?.slug],
    }));
  }, [interestArea]);

  useEffect(() => {
    if (filters?.interest_area_in) {
      setProductsData(null);
      setLoading(true);

      if (filters.city_in && filters.city_in[0] !== "") {
        filters.city_in.unshift("");
      }

      if (props.bannerID) {
        getProducts(page, logged, filters, 12, props.bannerID)
          .then((productsApi) => {
            setProductsData(productsApi);
          })
          .finally(() => setLoading(false));
      } else {
        getProducts(page, logged, filters, 12)
          .then((productsApi) => {
            setProductsData(productsApi);
          })
          .finally(() => setLoading(false));
      }
    }
  }, [props, page, logged, filters]);

  useEffect(() => {
    if (page === 1) {
      setRefresh(false);
    }
  }, [page]);

  const handleFilterChange = useCallback((newFilterValues) => {
    let treatArrayField = (field) => {
      return Array.isArray(field) && field.length > 0 ? field : null;
    };

    setFilters((oldState) => ({
      interest_area_in: oldState["interest_area_in"],
      title_cont: oldState["title_cont"],
      category_in: treatArrayField(newFilterValues.categories),
      subcategory_in: treatArrayField(newFilterValues.subcategories),
      city_in: treatArrayField(newFilterValues.cities),
      price_lte: newFilterValues.priceRange[1],
      price_gte: newFilterValues.priceRange[0],
    }));
    setRefresh(true);
    setPage(1);
  }, []);

  useEffect(() => {
    if (data) {
      handleFilterChange(data);
      clearData();
    }
  }, [clearData, data, handleFilterChange]);

  useEffect(() => {
    const query = search?.replace('?q=', '');

    if (query) {
      let treatArrayField = (field) => {
        return Array.isArray(field) && field.length > 0 ? field : null;
      };

      setFilters((oldState) => ({
        interest_area_in: oldState["interest_area_in"],
        title_cont: oldState["title_cont"],
        category_in: treatArrayField([query]),
        subcategory_in: treatArrayField([]),
        city_in: treatArrayField([]),
        price_lte: 2000,
        price_gte: 0,
      }));
      setRefresh(true);
      setPage(1);
    }
  }, [search]);

  return (
    <Container className={styles.container}>
      <div
        className={
          !props.bannerID ? styles.listContainer : styles.listBannerContainer
        }
      >
        <>
          <div className="d-flex align-items-center justify-content-between">
            <Breadcumb
              currentPath={[
                {
                  route: `Categoria / ${interestArea?.name}`,
                  name: interestArea?.name,
                },
              ]}
            />
          </div>
        </>
        <FilterMobile />

        <div style={{ display: "flex" }}>
          {!props.bannerID && (
            <Hidden xsDown key="fixed_filter_desktop">
              <Filters onFilterChange={handleFilterChange} />
            </Hidden>
          )}

          <ProductsGrid
            loading={loading}
            productsData={productsData}
            bannerID={props.bannerID}
            interestArea={interestArea}
            currentPage={page}
            refresh={refresh}
            pageChangeCallback={(newPage) => {
              setPage(newPage);
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default ProductsList;
