import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import { formmattedPrice } from 'components/cart/desktopItemCart'
import styles from './index.module.scss'

const ProductList = () => {
  const user = useSelector((state) => state.user.info)
  const [removeCookie] = useCookies(['AU_cart_' + user?.id])
  const [carrinho, updateCarrinho] = useState([])

  const getProduct = async (id, price_id, quantity, ticket_id) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/products/${id}`)
      .then(function (resp) {
        let price = resp.data.tickets
          .map((obj) => {
            var array = obj.prices.filter((price) => {
              return price.id === price_id
            })
            return array[0]
          })
          .filter(function (el) {
            return el != null
          })

        let label = resp.data.tickets.filter(
          (ticket) => ticket.id === ticket_id,
        )

        if (price.length > 0) {
          let product = {
            id: resp.data.id,
            name: resp.data.title,
            photo: resp.data?.images[0]?.url,
            price_id,
            quantity,
            value: price[0].price_in_money,
            status: resp.data.status,
            label: label?.at(0)?.label,
            ticket_id,
          }
          return product
        } else {
          return null
        }
      })
  }

  useEffect(() => {
    const orders = JSON.parse(localStorage.getItem('AU_order'))
    if (orders) {
      const orderProducts = orders

      if (orderProducts.order.length) {
        let products = orderProducts.order.map(async (product) => {
          if (product.quantity > 0 && product.quantity <= 10) {
            return await getProduct(
              product.id,
              product.price_id,
              product.quantity,
              product.ticket_id,
            )
          } else {
            localStorage.removeItem('AU_order')
            if (user) {
              removeCookie(['AU_cart_' + user.id])
            }
          }
        })

        Promise.all(products).then((products) => {
          let filteredProducts = products.filter(
            (product) => product && product.status === 'No Ar',
          )

          updateCarrinho(filteredProducts)
        })
      }
    }
  }, [user, removeCookie])

  const groupedById = carrinho.reduce((acc, current) => {
    const existingItem = acc.find((item) => item.id === current.id)

    if (existingItem) {
      existingItem.details.push({
        id: current.id,
        price_id: current.price_id,
        quantity: current.quantity,
        value: current.value,
        label: current.label,
      })
    } else {
      acc.push({
        id: current.id,
        name: current.name,
        photo: current.photo,
        status: current.status,
        details: [
          {
            price_id: current.price_id,
            quantity: current.quantity,
            value: current.value,
            label: current.label,
          },
        ],
      })
    }

    return acc
  }, [])

  return (
    <>
      {groupedById?.map((product, key) => (
        <div key={key} className={styles.container}>
          <img
            src={product.photo}
            alt={product.name}
            className={styles.photo}
          />
          <div className={styles.wrapper}>
            <div className={styles.productName}>{product.name}</div>
            <div>
              <div className={styles.productDetailsWrapper}>
                {product.details.map((detail, index) => (
                  <div key={index} className={styles.detail}>
                    <div className={styles.productLabel}>{detail?.label}</div>
                    <div>
                      <div className={styles.priceWrapper}>
                        <span className={styles.price}>
                          {`R$${formmattedPrice(detail?.value)}`}
                        </span>
                        <span
                          className={styles.productQuantity}
                        >{`x${detail?.quantity}`}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default ProductList
