export const SET_INTEREST_AREAS = "[GLOBAL DATA] SET INTEREST AREAS";
export const SET_ACTIVE_INTEREST_AREA =
  "[GLOBAL DATA] SET ACTIVE INTEREST AREA";
export const SET_HOME_BANNERS = "[GLOBAL DATA] SET HOME BANNERS";
export const SET_GLOBAL_SETTINGS = "[GLOBAL DATA] SET GLOBAL SETTINGS";
export const SET_PARTNER_SETTINGS = "[GLOBAL DATA] SET PARTNER SETTINGS";

export function setInterestAreas(areas) {
  return (dispatch) => {
    dispatch({ type: SET_INTEREST_AREAS, payload: areas });
  };
}

export function setActiveInterestArea(slug) {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVE_INTEREST_AREA, payload: slug });
  };
}

export function setHomeBanners(areas) {
  return (dispatch) => {
    dispatch({ type: SET_HOME_BANNERS, payload: areas });
  };
}

export function setGlobalSettings(settings, logged) {
  return (dispatch) => {
    dispatch({ type: SET_GLOBAL_SETTINGS, payload: settings });
  };
}

export function setPartnerSettings(partner) {
  return (dispatch) => {
    dispatch({ type: SET_PARTNER_SETTINGS, payload: partner });
  };
}
