import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getOurPartners } from 'services/api'
import { isLoggedIn } from 'store/selectors'
import SwiperCore, { Autoplay, Scrollbar } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import styles from './index.module.scss'

SwiperCore.use([Autoplay, Scrollbar])

const OutPartners = () => {
  const logged = useSelector(isLoggedIn)
  const history = useHistory()
  const [partners, setPartners] = useState([])
  const per_page = 10

  const openFilter = () => {
    history.push(`/ofertas/parceiros`)
  }

  useEffect(() => {
    getOurPartners(logged, per_page, 1).then((response) => {
      setPartners(response.programmes)
    })
  }, [logged])

  return (
    partners.length > 0 && (
      <>
        <div className={styles.desktopContainer}>
          <div className="mt-4">
            <div className={styles.titleBar}>
              <h2 className={styles.title}>Compre com nossos parceiros</h2>
            </div>
            <Swiper
              modules={[Autoplay, Scrollbar]}
              slidesPerView={5.5}
              spaceBetween={4}
              scrollbar={{ draggable: true, hide: true }}
            >
              {partners.map((partner) => (
                <SwiperSlide key={partner.name}>
                  <div
                    className={styles.imageContainer}
                    onClick={() => history.push(`/programa/${partner.id}`)}
                  >
                    <img
                      src={partner.image}
                      alt={partner.name}
                      className={styles.image}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={styles.btnContainer}>
            <button className={styles.btn} onClick={openFilter}>
              Ver mais
            </button>
          </div>
        </div>

        <div className={styles.mobileContainer}>
          <div className="mt-8 mb-4">
            <div className={styles.titleBar}>
              <h2 className={styles.title}>Compre com nossos parceiros</h2>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '10px',
              }}
            >
              {partners.map((partner) => (
                <div key={partner.name} className={styles.container}>
                  <div
                    className={styles.imageContainer}
                    onClick={() => history.push(`/programa/${partner.id}`)}
                  >
                    <img
                      src={partner.image}
                      alt={partner.name}
                      className={styles.image}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.btnContainer}>
              <button className={styles.btn} onClick={openFilter}>
                Ver mais
              </button>
            </div>
          </div>
        </div>
      </>
    )
  )
}

export default OutPartners
