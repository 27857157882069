import React, { useEffect } from "react";

const ExternalScript = (props) => {
  useEffect(() => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = props.url;
    script.onload = function () {
      console.log(props.url + "- LOADED");
    };
    script.onerror = function () {
      console.log(props.url + "FAILED");
    };

    if (props.position === "top") {
      let s = document.getElementsByTagName("body")[0];
      document.body.parentNode.insertBefore(script, s);
    } else if (props.position === "inside") {
      document.body.appendChild(script);
    } else {
      let s = document.getElementsByTagName("body")[0];
      document.body.parentNode.insertAfter(script, s);
    }
  }, [props.position, props.url]);

  return <></>;
};

export default ExternalScript;
