import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, Col, FormGroup, Row, FormControl } from "react-bootstrap";

import CircularProgress from "@material-ui/core/CircularProgress";

import { classNames, scrollTo } from "utils";
import styles from "../index.module.scss";
import { TextFieldController } from "components/forms";
import * as Actions from "../../../store/actions";
import BillingAddress from "../BillingAddress";
import { getPagarmeToken } from "services/api/index";

import CryptJS from "services/cryptJS";

const Crypt = new CryptJS();

export default function () {
  const dispatch = useDispatch();
  const { handleSubmit, register, control, errors } = useForm({
    reValidateMode: "onSubmit",
  });

  const userInfos = useSelector((state) => state.user.info);

  const partnerSettings = useSelector((state) => state.global.partner);
  const [initialLoading, setInitialLoading] = React.useState(false);

  const billingAddress = useSelector(
    ({ orderCheckout }) => orderCheckout.billingAddress
  );

  const paymentInfo = useSelector(
    ({ orderCheckout }) => orderCheckout.paymentInfo
  );
  const [selectedCard, setSelectedCard] = useState("new");
  const [invalidBinMessage, setInvalidBinMessage] = useState(null);

  function savePaymentData(paymentData, brand, token) {
    let cardNumber = paymentData?.number?.replaceAll(" ", "");
    dispatch(
      Actions.setPaymentInfo({
        ...paymentData,
        bin: cardNumber.slice(0, 6),
        last_4: cardNumber.slice(cardNumber.length - 4),
        token: token,
        brand: brand,
      })
    );

    dispatch(Actions.changeStep("next"));
  }

  function checkBin() {
    return partnerSettings.payment_info.payment_options.includes(
      "credit_card_bin_lock"
    );
  }

  function isValidBin(bin) {
    return (
      partnerSettings.payment_info.bins &&
      partnerSettings.payment_info.bins.includes(bin)
    );
  }

  function processPaymentWithPagarMe(paymentData) {
    const date = paymentData.expiration_date.split("/");

    const card = {
      number: paymentData.number.replace(/\s/g, ""),
      holder_name: paymentData.holder_name,
      exp_month: date[0].toString(),
      exp_year: date[1].toString(),
      cvv: paymentData.cvv.replaceAll(" ", ""),
    };

    getPagarmeToken(card)
      .then((token) => {
        let pagarMeToken = Crypt.encrypt(
          JSON.stringify({
            token: token,
            cvv: paymentData.cvv.replaceAll(" ", ""),
          })
        );

        savePaymentData(paymentData, null, pagarMeToken);
        setInitialLoading(false);
      })
      .catch((err) => console.log(err));
  }

  const onSubmit = async (paymentData) => {
    paymentData = { ...paymentData, installments: 1 };
    setInitialLoading(true);
    scrollTo("#stepper");
    if (
      checkBin() &&
      !isValidBin(paymentData.number.replaceAll(" ", "").slice(0, 6))
    ) {
      setInvalidBinMessage(partnerSettings.payment_info.message);
      setInitialLoading(false);
      return;
    }

    processPaymentWithPagarMe(paymentData);
  };

  useEffect(() => {
    if (paymentInfo && Object.entries(paymentInfo).length !== 0) {
      if (paymentInfo.id) setSelectedCard(paymentInfo?.id);
      else setSelectedCard("new");
    }

    setTimeout(() => {
      let confirmButton = document.getElementById("confirmButton");
      if (confirmButton) {
        confirmButton.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 1200);
  }, [paymentInfo]);

  return (
    <>
      {!invalidBinMessage ? (
        <>
          {initialLoading ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
              <FormGroup className={styles.formGroup}>
                {selectedCard === "new" && (
                  <>
                    <Row>
                      <div className="col-12">
                        <TextFieldController
                          label="Número do Cartão"
                          name="number"
                          mask="9999 9999 9999 9999"
                          control={control}
                          errors={errors}
                          // inputProps={{ "data-iugu": "number" }}
                          defaultValue={paymentInfo?.number}
                        />
                        <FormControl
                          type="hidden"
                          defaultValue={paymentInfo?.bin}
                          id="bin"
                          name="bin"
                          ref={register}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="col-12 pt-3">
                        <TextFieldController
                          label="Nome Impresso no Cartão"
                          name="holder_name"
                          control={control}
                          errors={errors}
                          defaultValue={paymentInfo?.holder_name}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="col-6 pt-3">
                        <TextFieldController
                          label="Validade"
                          name="expiration_date"
                          mask="99/99"
                          control={control}
                          errors={errors}
                          defaultValue={paymentInfo?.expiration_date}
                        />
                      </div>
                      <div className="col-6 pt-3">
                        <TextFieldController
                          label="Código de Segurança"
                          name="cvv"
                          mask="9999"
                          control={control}
                          errors={errors}
                          defaultValue={paymentInfo?.cvv}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="col-6 pt-3">
                        <TextFieldController
                          mask="99/99/9999"
                          label="Data de Nascimento"
                          name="holder_birth_date"
                          control={control}
                          errors={errors}
                          rules={{
                            pattern:
                              /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                          }}
                          defaultValue={paymentInfo?.holder_birth_date}
                        />
                      </div>
                      <div className="col-6 pt-3">
                        <TextFieldController
                          label="Telefone"
                          type="tel"
                          name="phone"
                          mask="(99) 99999-9999"
                          required={true}
                          control={control}
                          errors={errors}
                          defaultValue={userInfos?.mobile_number}
                          rules={{
                            pattern:
                              /^(\([0-9][0-9]\) [0-9][0-9]{4}-[0-9]{4})|(\(1[2-9]\) [5-9][0-9]{3}-[0-9]{4})|(\([2-9][1-9]\) [5-9][0-9]{3}-[0-9]{4})$/,
                          }}
                        />
                      </div>
                    </Row>
                  </>
                )}

                {billingAddress && <BillingAddress />}
              </FormGroup>
              <Row className="mt-3">
                <Col xs={6}>
                  <button
                    aria-label="voltar"
                    type="button"
                    className={classNames(styles.backButton, "btn-form")}
                    onClick={() => {
                      dispatch(Actions.changeStep("previous"));
                      scrollTo("#stepper");
                    }}
                  >
                    Voltar
                  </button>
                </Col>
                <Col xs={6}>
                  <button
                    aria-label="continuar"
                    type="submit"
                    className={"custom-btn-verde btn-form"}
                  >
                    Continuar
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </>
      ) : (
        <>
          <Row className="mt-3">
            <Col xs={12}>
              <p className={styles.binMessage}>{invalidBinMessage}</p>
            </Col>
            <Col xs={12} style={{ display: "flex", flexDirection: "column" }}>
              <button
                type="button"
                aria-label="voltar"
                className={classNames(
                  styles.backButton,
                  styles.backButtonMsg,
                  "btn-form"
                )}
                onClick={() => {
                  setInvalidBinMessage(null);
                  scrollTo("#stepper");
                }}
              >
                Voltar
              </button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
