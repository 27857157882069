import React, { useState, useRef } from "react";
// import axios from "axios";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Container, Row, Form, Modal } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";

import { resetPassword } from "services/api/index";

import ReCAPTCHA from "react-google-recaptcha";

import { classNames } from "utils";

import styles from "./index.module.scss";

import Header from "../../components/header";
import Footer from "../../components/footer";

import { PasswordFieldController } from "components/forms/index";
import PageLoading from "components/pageLoading";

const ResetarSenha = (props) => {
  const { handleSubmit, control, errors } = useForm({
    reValidateMode: "onSubmit",
  });
  const [recaptchaHasLoaded, setRecaptchaHasLoaded] = useState(false);
  const [recaptchaVerify, setRecaptchaVerify] = useState(false);
  const [errorId, setErrorId] = useState(null);
  const [requestErrors, setRequestErrors] = useState([]);
  const [alertShow, setAlertShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();
  const recaptchaRef = useRef();

  const onSubmit = (data) => {
    if (formValid(data)) {
      setDisabled(true);
      resetPassword({
        resetToken: props.match.params.token,
        newPassword: data.new_password,
        newPasswordConfirmation: data.new_password_confirmation,
        recaptchaToken: recaptchaVerify,
      }).then((res) => {
        if (res.success) {
          setAlertShow(true);

          setTimeout(() => {
            setAlertShow(false);
            setDisabled(false);
            history.push("/ofertas");
            if (sessionStorage.getItem("AU_redirect")) {
              history.push(sessionStorage.getItem("AU_redirect"));
              sessionStorage.removeItem("AU_redirect")
            } else {
              history.push("/ofertas");
            }
          }, 2500);
        } else {
          setDisabled(false);
          if (Array.isArray(res.errors)) setRequestErrors([...res.errors]);
          else setRequestErrors([res.errors]);
        }
      });
    }
  };

  const formValid = (data) => {
    setErrorId(null);
    if (!recaptchaVerify) {
      setErrorId("invalid_recaptcha");
      return false;
    } else if (data.new_password !== data.new_password_confirmation) {
      setErrorId("diff_password");
      recaptchaRef.current.reset();
      return false;
    }

    return true;
  };

  const recaptchaChange = (value) => {
    setRecaptchaVerify(value);
    if (errorId === "invalid_recaptcha" && Boolean(value)) {
      setErrorId(null);
    }
  };

  return (
    <PageLoading>
      <Header hideSearchBar={true} />
      <Container className={styles.cadastroContainer}>
        <Row>
          <p className="text-center">
            Informe a sua nova senha. Após redefinir sua senha{" "}
            <strong>será necessário realizar o login</strong> na aplicação!
          </p>
        </Row>
        <Row>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-3">
              <PasswordFieldController
                name="new_password"
                label="Nova senha"
                control={control}
                errors={errors}
                rules={{ minLength: 8 }}
              />

              <div className="mt-2" style={{ opacity: 0.5 }}>
                A senha deve possuir 8 caracteres, uma letra maiúscula, uma
                letra minúscula e um número
              </div>
            </Row>
            <Row className="mt-3 mb-4">
              <PasswordFieldController
                name="new_password_confirmation"
                label="Confirma a nova senha"
                control={control}
                errors={errors}
                displayButton={false}
                rules={{ minLength: 8 }}
              />

              {errorId === "diff_password" && (
                <div className={`col-12 text-center ${styles.error}`}>
                  Valor difere do informado no campo "Nova senha" ou não atende
                  as regras determinadas acima.
                </div>
              )}
            </Row>
            <Row className="mt-3 mb-4">
              <div className="col-12 d-flex flex-column justify-content-center">
                {!recaptchaHasLoaded && (
                  <CircularProgress
                    color="inherit"
                    style={{ alignSelf: "center" }}
                  />
                )}
                <ReCAPTCHA
                  ref={recaptchaRef}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={recaptchaChange}
                  asyncScriptOnLoad={() => setRecaptchaHasLoaded(true)}
                />
                {errorId === "invalid_recaptcha" && (
                  <div className={classNames(styles.error, "text-center")}>
                    Por favor, verifique que não é um robô
                  </div>
                )}
              </div>
            </Row>
            <Row className="mt-4 mb-5">
              <div className="col-12 text-center">
                <button
                  aria-label="Salvar"
                  className="custom-btn-verde btn-form"
                  style={{ width: "50%" }}
                  type="submit"
                  name="submit"
                  disabled={disabled}
                >
                  Salvar
                </button>
              </div>

              {requestErrors.length > 0 && (
                <div className="col-12 text-center">
                  <ul>
                    {requestErrors.map((error) => (
                      <li className={styles.error}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </Row>
          </Form>
        </Row>
      </Container>
      <Footer />
      {alertShow && (
        <>
          <Modal show={alertShow} onHide={() => setAlertShow(false)} centered>
            <Modal.Body className="text-center">
              Senha alterada com sucesso. Por favor, faça o login.
            </Modal.Body>
          </Modal>
        </>
      )}
    </PageLoading>
  );
};

export default ResetarSenha;
