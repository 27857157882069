import axios from 'axios'
import { saveOnUserHistory } from 'services/api/index'
import { fetchBenefitTier } from 'store/selectors'

export const SET_PRODUCT = '[PRODUCT PAGE] SET PRODUCT'
export const SET_TICKET_PRICES = '[PRODUCT PAGE] SET TICKET PRICES'
export const SET_SAVED_ON_HISTORY = '[PRODUCT PAGE] SET SAVED ON HISTORY'
export const SET_CHECKOUT_INFO = '[PRODUCT PAGE] SET CHECKOUT PRODUCTS'
export const SET_LOADING = '[PRODUCT PAGE] SET LOADING'
export const RESET_LOADING = '[PRODUCT PAGE] RESET LOADING'

export function getProductById(id) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/${id}`)
      .then((resp) => {
        let product = {
          ...resp.data,
          summary: resp.data.summary.replace(/(?:\r\n|\r|\n)/g, '<br />'),
        }

        dispatch({ type: SET_PRODUCT, payload: product })

        dispatch(setTicketPrices(product.tickets))
      })
  }
}

export function setTicketPrices(tickets) {
  return (dispatch, getState) => {
    let benefitTier = fetchBenefitTier(getState())

    let sortedPrices = []

    tickets.forEach((ticket) => {
      let onlyPricesForCurrentTier = ticket.prices.filter(
        (price) => price.benefit_tier.id === benefitTier,
      )

      let pricesWithTicketInfoAppended = onlyPricesForCurrentTier.map(
        (elem) => ({
          ...elem,
          ticket_id: ticket.id,
          ticket_label: ticket.label,
        }),
      )

      sortedPrices = [...sortedPrices, ...pricesWithTicketInfoAppended]
    })

    sortedPrices.sort(
      (a, b) =>
        parseFloat(a.percent_discount || 0) -
        parseFloat(b.percent_discount || 0),
    )

    dispatch({ type: SET_TICKET_PRICES, payload: sortedPrices })
  }
}

export function addToBrowsingHistory() {
  return (dispatch, getState) => {
    let { product } = getState().product
    let { savedOnHistory } = getState().product
    const browsingHistory = getState().user?.info?.browsing_history

    if (product?.id && browsingHistory && !savedOnHistory) {
      dispatch({ type: SET_SAVED_ON_HISTORY, payload: true })
      saveOnUserHistory(product.id).then((response) => response.success)
    }
  }
}

export function getCheckoutInfo() {
  return (dispatch) => {
    let checkoutInfo = JSON.parse(localStorage.getItem('AU_order')) || {}

    if (!checkoutInfo.order) checkoutInfo.order = []
    if (!checkoutInfo.use_gift_vouchers) checkoutInfo.use_gift_vouchers = true
    if (!checkoutInfo.total) checkoutInfo.total = 0

    dispatch({ type: SET_CHECKOUT_INFO, payload: checkoutInfo })
  }
}

export function addToCart(ticketPriceId, ticket_id) {
  const quantity = 1
  return (dispatch, getState) => {
    let { product, checkout } = getState().product
    let ticketPrices = getState().product?.checkout?.order

    let priceIndex = ticketPrices.findIndex(
      (elem) => elem.price_id === ticketPriceId,
    )

    if (priceIndex !== -1) {
      checkout.order[priceIndex] = {
        ...checkout.order[priceIndex],
        quantity: checkout.order[priceIndex].quantity + quantity,
        ticket_id,
      }
    } else {
      checkout.order.push({
        id: product.id,
        price_id: ticketPriceId,
        quantity: quantity,
        ticket_id,
      })
    }

    if (priceIndex > -1 && checkout.order[priceIndex].quantity > 10)
      checkout.order[priceIndex].quantity = 10

    dispatch({ type: SET_CHECKOUT_INFO, payload: checkout })
  }
}

export function removeFromCart(ticketPriceId, quantity = 1) {
  return (dispatch, getState) => {
    let { checkout } = getState().product
    let ticketPrices = getState().product?.checkout?.order

    let priceIndex = ticketPrices.findIndex(
      (elem) => elem.price_id === ticketPriceId,
    )

    if (priceIndex !== -1) {
      checkout.order[priceIndex] = {
        ...checkout.order[priceIndex],
        quantity: checkout.order[priceIndex].quantity - quantity,
      }
    }

    checkout.order = checkout.order.filter((ticket) => ticket.quantity > 0)

    dispatch({ type: SET_CHECKOUT_INFO, payload: checkout })
  }
}

export function setLoading() {
  return {
    type: SET_LOADING,
  }
}

export function resetLoading() {
  return {
    type: RESET_LOADING,
  }
}
