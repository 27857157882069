import React from 'react'

import { CartIcon } from 'assets/icons'

import styles from './styles.module.scss'

const EmptyCart = () => {
  return (
    <div className={styles.container}>
      <CartIcon
        color={'#979797'}
        width={'56'}
        height={'46'}
      />
      <p>Carrinho Vazio</p>
      <span>Confira nossos descontos!</span>
    </div>
  )
}

export default EmptyCart
