import React from "react";

import LazyLoad from "react-lazyload";

import Header from "components/header";
import Footer from "components/footer";

import styles from "./index.module.scss";

import AlegriaLogo from "./images/logo.png";

const ErroApp = () => {
  return (
    <>
      <Header />
      <span className={styles.logo}>
        <LazyLoad once>
          <img src={AlegriaLogo} alt="Logo do Alegria" />
        </LazyLoad>
      </span>
      <div className={styles.errorMessage}>
        <p>Infelizmente o Clube +Alegria não está disponível para você.</p>
      </div>
      <Footer />
    </>
  );
};

export default ErroApp;
