import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";

// import Header from "components/header";
import NewLayoutHeader from "components/NewLayoutHeader";
// import AllCategories from "components/AllCategories";
import Footer from "components/footer";

import { isLoggedIn } from "store/selectors";
import { getUser } from "store/actions";

import { links } from "components/clientMenu/index";

import ClientMenu from "components/clientMenu/index";

import styles from "./index.module.scss";
import { classNames } from "utils";
import PageLoading from "components/pageLoading";

const UserPage = () => {
  const history = useHistory();
  const logged = useSelector(isLoggedIn);
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("AU_data"));

  React.useEffect(() => {
    if (!userData?.subscription) {
      dispatch(getUser());
    }
  }, [dispatch, userData]);

  useEffect(() => {
    const userHeader = JSON.parse(localStorage.getItem("AU_header"));
    if (!logged && !userHeader) {
      history.push("/partners");
    }
  }, [logged, history]);

  return (
    logged && (
      <PageLoading>
        {/* <Header /> */}
        <NewLayoutHeader />
        {/* <AllCategories /> */}

        <Container
          className={classNames(
            styles.clienteWrapper,
            window.screen.width > 1220 ? styles.largeScreenContainer : ""
          )}
        >
          <div
            className={classNames(
              styles.detailWrapper,
              styles.grayBlock,
              "d-none d-md-none d-lg-block col-3 mb-3"
            )}
          >
            <ClientMenu />
          </div>

          <Switch>
            {links &&
              links.map((link, index) => {
                return (
                  <Route
                    path={link.url}
                    exact
                    component={link.component}
                    key={index}
                  />
                );
              })}

            <Route
              path={"/usuario/**"}
              exact
              component={() => {
                history.push("/ofertas");
                return null;
              }}
            />
          </Switch>
        </Container>

        <Footer />
      </PageLoading>
    )
  );
};

export default UserPage;
