import React from 'react'
import { Col, Row, Form, FormGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styles from './index.module.scss'
import * as Actions from '../../store/actions'
import { TextFieldController } from 'components/forms'

export default function Ame() {
  const dispatch = useDispatch()
  const userInfos = useSelector((state) => state.user.info)

  const sendingRequest = useSelector(
    ({ orderCheckout }) => orderCheckout.sendingRequest,
  )

  const { handleSubmit, control, errors } = useForm({
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (paymentData) => {
    dispatch(Actions.useAme(true))
    dispatch(
      Actions.setPaymentInfo({
        ...paymentData,
      }),
    )
    dispatch(Actions.changeStep('next'))
    dispatch(Actions.performCheckout())
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
        <p className={styles.ameText}>
          Ao finalizar a compra, um QR Code será exibido e ficará disponível
          para pagamento por 24horas.
        </p>
        <p className={styles.ameText}>
          Para pagar, basta escanear o código com seu AME.
        </p>
        <Row className="mt-3" style={{ justifyContent: 'center' }}>
          {/* <Col xs={12} lg={6} className="pt-3 text-right">
            <p className="mt-4 mb-4">Por favor, p0reencha o seu telefone</p>
          </Col> */}
          <Col xs={12} lg={6} className="pt-3">
            <FormGroup className={styles.formGroup}>
              <TextFieldController
                label="Telefone"
                type="tel"
                name="phone"
                mask="(99) 99999-9999"
                required={true}
                control={control}
                errors={errors}
                defaultValue={userInfos?.mobile_number}
                rules={{
                  pattern:
                    /^(\([0-9][0-9]\) [0-9][0-9]{4}-[0-9]{4})|(\(1[2-9]\) [5-9][0-9]{3}-[0-9]{4})|(\([2-9][1-9]\) [5-9][0-9]{3}-[0-9]{4})$/,
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-3">
          {/* <Col xs={6}>
            <button
              aria-label="voltar"
              type="button"
              className={classNames(styles.backButton, 'btn-form')}
              onClick={() => dispatch(Actions.changeStep('previous'))}
            >
              Voltar
            </button>
          </Col> */}
          <Col>
            <button
              aria-label="continuar"
              type="submit"
              className={styles.finishOrderButton}
              disabled={sendingRequest}
            >
              Finalizar Pedido
            </button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
