import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import loadable from "@loadable/component";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import "./index.scss";

import GlobalStyles from "components/globalStyles";
import ExternalScript from "components/externalScript";

import * as Actions from "store/actions";
import { AuthApp, LinkLogin } from "./services";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import moment from "moment";
import { Home } from "pages";
import Solution from "pages/solution";
import ProgramPage from "pages/program";
import ProgramOffer from "pages/program-offer";
import SideWindowManager from "context/SideWindow-manager"
import { SideWindowProvider } from "context/SideWindow-context"

const LoginModal = loadable(() => import("components/loginModal"), {
  fallback: <></>,
});

const CadastroPartners = loadable(() => import("pages/cadastro-partners"), {
  fallback: <></>,
});

const Cadastro = loadable(() => import("pages/cadastro"), {
  fallback: <></>,
});

const LoginPartners = loadable(() => import("components/loginPartners"), {
  fallback: <></>,
});
const MaratonaRioMasterclassModal = loadable(
  () => import("components/maratonaRioMasterclassModal"),
  {
    fallback: <></>,
  }
);
const NotificationsModal = loadable(
  () => import("components/notificationsModal"),
  {
    fallback: <></>,
  }
);
const RegisterModal = loadable(() => import("components/registerModal"), {
  fallback: <></>,
});
const ForgotPasswordModal = loadable(
  () => import("components/forgotPasswordModal"),
  {
    fallback: <></>,
  }
);
const LogoutModal = loadable(() => import("components/logoutModal"), {
  fallback: <></>,
});
const VideoModal = loadable(() => import("components/videoModal"), {
  fallback: <></>,
});

const SubscriptionCheckout = loadable(
  () => import("./pages/subscriptionCheckout"),
  {
    fallback: <></>,
  }
);
const Ofertas = loadable(() => import("./pages/ofertas"), {
  fallback: <></>,
});

const ErroApp = loadable(() => import("./pages/error/app"), {
  fallback: <></>,
});
const ErroLogged = loadable(() => import("./pages/error/logged"), {
  fallback: <></>,
});

const CadastroMaratonaRio = loadable(
  () => import("./pages/cadastro-maratonadorio"),
  {
    fallback: <></>,
  }
);
const CadastroVascoAlegria = loadable(
  () => import("./pages/cadastro-vascoalegria"),
  {
    fallback: <></>,
  }
);

const UserPage = loadable(() => import("./pages/userpage"), {
  fallback: <></>,
});
const Carrinho = loadable(() => import("./pages/carrinho"), {
  fallback: <></>,
});
const Checkout = loadable(() => import("./pages/orderCheckout"), {
  fallback: <></>,
});
const ProductPage = loadable(() => import("./pages/product"), {
  fallback: <></>,
});
const ResetarSenha = loadable(() => import("./pages/resetar-senha"), {
  fallback: <></>,
});
const Voucher = loadable(() => import("./pages/voucher"), {
  fallback: <></>,
});
const Categories = loadable(() => import("./pages/categories"), {
  fallback: <></>,
});
const Manutencao = loadable(() => import("./pages/homepage/manutencao"), {
  fallback: <></>,
});

const VascoHomePage = loadable(() => import("./pages/homepage/vasco"), {
  fallback: <></>,
});

const Partners = loadable(() => import("./pages/partners"), {
  fallback: <></>,
});

const VantagensPage = loadable(() => import("./pages/vantagens"), {
  fallback: <></>,
});

const PlanosPage = loadable(() => import("./pages/planos"), {
  fallback: <></>,
});

const FamilhaoHomePage = loadable(() => import("./pages/homepage/familhao"), {
  fallback: <></>,
});

const PrePartners = loadable(() => import("./pages/pre_partners"), {
  fallback: <></>,
});
const ClubeRhHomePage = loadable(() => import("./pages/homepage/clubeRh"), {
  fallback: <></>,
});

// const PrePlanos = loadable(() => import("./pages/pre_planos"), {
//   fallback: <></>,
// });

moment().format();

function redirectError() {
  if (window.location.pathname !== "/manutencao") {
    window.location.href = "/manutencao";
  }
}

export default function App() {
  const dispatch = useDispatch();
  const globalSettings = useSelector((state) => state.global.settings);
  const [gateways, setGateways] = useState([]);

  // Add a response interceptor
  axios.interceptors.response.use(
    undefined,
    function axiosRetryInterceptor(error) {
      var err;
      switch (error.response?.status) {
        case 500:
          err = new Error("500 Internal Server Error on EasyLive API");
          console.log(
            "500 API Error: " +
              error.response.config.url +
              " (" +
              error.response.data.error +
              ")"
          );
          window.newrelic.noticeError(err, {
            url: error.response.config.url,
            status: error.response.status,
            error: error.response.data.error,
            method: error.response.config.method,
            message: error.message,
            data: error.response.config.data,
          });
          if (process.env.NODE_ENV === "production") {
            redirectError();
          }
          break;
        default:
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    dispatch(Actions.loadApplication());
  }, [dispatch]);

  useEffect(() => {
    setGateways([
      globalSettings?.cc_payment_gateway,
      globalSettings?.pix_payment_gateway,
      globalSettings?.cc_subscription_payment_gateway,
      globalSettings?.pix_subscription_payment_gateway,
    ]);
  }, [globalSettings]);

  return (
    <SideWindowProvider>
      {gateways.includes("pagseguro") && (
        <ExternalScript
          url={process.env.REACT_APP_PAGSEGURO_SCRIPT_URL}
          position="inside"
        />
      )}

      {gateways.includes("mercadopago") && (
        <ExternalScript
          url={process.env.REACT_APP_MERCADOPAGO_SCRIPT_URL}
          position="inside"
        />
      )}

      <GlobalStyles />
      <ToastContainer theme="colored" />
      <SideWindowManager />
      <Router>
        <LoginModal />
        <LoginPartners />
        <VideoModal />
        <MaratonaRioMasterclassModal />
        <NotificationsModal />
        <RegisterModal />
        <ForgotPasswordModal />
        <Switch>
          <Route path={"/"} component={Home} exact />
          <Route path="/partners" exact component={Partners} />
          <Route path="/pre-partners/:id" exact component={PrePartners} />
          <Route path="/vasco" exact component={VascoHomePage} />
          <Route path="/familhao" exact component={FamilhaoHomePage} />
          <Route path="/rhrio" exact component={ClubeRhHomePage} />
          <Route
            path="/contratacao/:id"
            exact
            component={SubscriptionCheckout}
          />
          <Route
            path={["/ofertas", "/busca/:busca", "/ofertas/:filtro"]}
            exact
            component={Ofertas}
          />
          <Route
            path={[
              "/categorias/:interest_area",
              "/categorias/:interest_area/:banner_id",
            ]}
            exact
            component={Categories}
          />
          <Route path="/carrinho" exact component={Carrinho} />
          <Route path="/checkout" exact component={Checkout} />
          <Route path="/auth/alegria/:token" exact component={AuthApp} />
          {/* <Route path="/auth/:token" exact component={AuthOi} /> */}
          <Route path={["/usuario", "/usuario/*"]} component={UserPage} />
          <Route path={"/voucher/:voucherToken"} component={Voucher} />
          {/* <Route path="/cadastro/oi/:cpf" exact component={CadastroOi} /> */}
          <Route
            path="/cadastro/maratonadorio/:cpf"
            exact
            component={CadastroMaratonaRio}
          />
          <Route
            path="/cadastro/vasco/:cpf"
            exact
            component={CadastroVascoAlegria}
          />
          <Route
            path="/cadastro/partner/:cpf"
            exact
            component={CadastroPartners}
          />
          <Route path="/cadastro/:cpf" exact component={Cadastro} />
          {/* <Route
            path="/login/oi"
            exact
            component={() => {
              window.location.href = process.env.REACT_APP_NDS_OI_URL;
              return null;
            }}
          /> */}
          <Route path="/vantagens" exact component={VantagensPage} />
          <Route path="/resetar_senha/:token" exact component={ResetarSenha} />
          <Route path="/planos" exact component={PlanosPage} />
          <Route path="/solucao" exact component={Solution} />
          {/* <Route path="/erro/contaOi" exact component={ErroContaOi} />
          <Route path="/resetar_senha/:token" exact component={ResetarSenha} />
          <Route path="/erro/contaOi" exact component={ErroContaOi} />
          <Route path="/erro/bloqueio" exact component={ErroBlockOi} />
          <Route path="/erro/loggedOi" exact component={ErroLoggedOi} /> */}
          <Route path="/erro/app" exact component={ErroApp} />
          <Route path="/erro/logged" exact component={ErroLogged} />
          <Route path="/produto/:id" exact component={ProductPage} />
          <Route path="/programa/:id" exact component={ProgramPage} />
          <Route path="/programa/oferta/:id" exact component={ProgramOffer} />
          <Route path="/sair/:redirect_to?" exact component={LogoutModal} />
          <Route path="/link/:token" exact component={LinkLogin} />
          <Route path="/manutencao" exact component={Manutencao} />
          <Route path="**" exact component={Home} />
        </Switch>
      </Router>
    </SideWindowProvider>
  );
}
