import React from 'react';
import { useStyles } from './useStyle';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonQuiz = () => {
  const classes = useStyles();
  return (
    <div className={classes.skeletonContainer}>
      <div className={classes.skeletonTitle}>
        <Skeleton count={4} />
      </div>
      <div className={classes.skeletonQuestions}>
        <Skeleton  className={classes.skeletonBtn}/>
      </div>
    </div>
  )
}

export default SkeletonQuiz;
