import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  body: {
    padding: '2em 1em',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignContent: 'center',
    margin: '8px auto',
    height: '100%',
    width: '100%',
  },
  title: {
    fontFamily: 'NeuePlak-Bold',
    color: '#575756',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '18px',
    marginBottom: '1em',
  },
  description: {
    textAlign: 'center',
    color: '#6b7280',
    wordBreak: 'break-word'
  },
  image: {
    width: '100px',
    margin: '8px auto',
  },
  btn: {
    border: 'none',
    borderRadius: '28px',
    background: 'var(--color-button-primary-bg)',
    color: 'var(--color-button-primary-text)',
    fontWeight: 'bold',
    margin: '8px auto',
    padding: '18x 16px',
    height: '42px',
    width: '40%',
  },
  questionContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '2px'
  },
  questionBtn: {
    border: 'none',
    borderRadius: '8px',
    margin: '4px auto',
    padding: '8px 12px',
    textAlign: 'start',
    width: '60%',
    color: '#979797'
  },
  correct: {
    background: '#58aa43',
    color: 'white'
  },
  wrong: {
    background: '#dd3900',
    color: 'white',
  },
  gift: {
    textAlign: 'center',
    wordBreak: 'break-word',

    '& span': {
      fontWeight: 'bold'
    }
  },
  closeModal: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
    marginTop: '1em',
    fontFamily: 'NeuePlak-Bold',
    color: '#575756',
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignContent: 'center',
    height: '100%',
    width: '100%',
    minHeight: '200px'
  },
  skeletonTitle: {
    margin: 'auto',
    width: '40%'
  },
  skeletonQuestions: {
    margin: '8px auto',
    width: '40%'
  },
  skeletonBtn: {
    border: 'none',
    borderRadius: '28px',
    fontWeight: 'bold',
    margin: '8px auto',
    padding: '18x 16px',
    height: '42px',
    width: '100%',
  }
}));
