import { combineReducers } from "redux";
import user from "./user";
import behavior from "./behavior";
import global from "./globalData";
import registerCompanies from './pre_partners';

const createReducer = (asyncReducers) =>
  combineReducers({
    user: user,
    behavior: behavior,
    global: global,
    registerCompanies: registerCompanies,
    ...asyncReducers,
  });

export default createReducer;
