import { createSelector } from "reselect";

import moment from "moment";
moment().format();

const user = (state) => state.user;

export const isLoggedIn = createSelector([user], (user) => {
  if (Boolean(user.headers) && Boolean(user.info)) return true;

  return false;
});

export const getLoginProvider = createSelector([user], (user) => {
  if (user && user.headers && user.headers.provider)
    return user.headers.provider;

  return "";
});

export const fetchBenefitTier = createSelector(
  ({ user }) => user.info?.benefit_tier_id,
  ({ global }) => global.settings?.benefit_tier?.id,
  (userBenefitTier, globalBenefitTier) => userBenefitTier || globalBenefitTier
);
