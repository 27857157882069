import React from 'react'
// import { useSelector } from "react-redux";

import Breadcumb from 'components/breadcumb'
import CarouselContainer from './components/CarouselContainer'
import TicketAndPriceSection from './components/TicketAndPriceSection'
import ProgramsRelated from './components/programsRelated'
import useIsMobile from 'utils/useIsMobile'

export default function ProgramDetail({ offers, program }) {
  const { isMobile } = useIsMobile()
  return (
    <>
      <div className="container">
        <div className="d-none d-md-block mt-1">
          <Breadcumb
            currentPath={[
              { route: '/parceiros', name: 'Compre com nossos parceiros' },
            ]}
          />
        </div>

        <div className="row mt-2">
          <div
            className={`col-12 col-md-4 ${!isMobile ? '' : 'pl-5 pr-5'}`}
            style={{ height: '100%' }}
          >
            <CarouselContainer program={program} />
          </div>

          <div className="col-12 col-md-8">
            <TicketAndPriceSection program={program} />
          </div>
        </div>
      </div>

      <div>
        <ProgramsRelated
          offers={offers}
          program={program}
          // noResultsComponent={<OffersHighlights />}
        />
      </div>
    </>
  )
}
