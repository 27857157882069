import React from 'react';
import { useStyles } from './useStyle';

const SearchResult = (props) => {
  const classes = useStyles();

  const {
    result,
    description,
    image,
    finish_button,
   } = props.info

   const finishBtn = () => {
     localStorage.removeItem('hasSearch');
     props.close();

     const hasQuiz = JSON.parse(localStorage.getItem('hasQuiz') || null);

     if (hasQuiz) {
       props.setModalType('quiz');
     }
   }

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        {result}
      </h2>
      <p className={classes.description}>
        {description}
      </p>
      {image && (
        <img
          src={image}
          alt='survey'
          className={classes.image}
        />
      )}
      <button
        className={classes.btn}
        onClick={finishBtn}
      >
        {finish_button}
      </button>
    </div>
  )
}

export default SearchResult;
