import React, { useState, useEffect } from 'react';
import { useStyles } from '../useStyle';
import { classNames } from 'utils';
import { sendEachSurveysAnswer } from 'services/api';

const SearchTextAreaInput = (props) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (text) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [text]);

  const click = () => {
    setIsDisabled(true);
    const hasSearch = JSON.parse(localStorage.getItem('hasSearch') || null);
    const { survey: { id } } = hasSearch;

    const survey = {
      id,
      question: props.id,
      answer: [text]
    }

    sendEachSurveysAnswer(survey)
      .then(({ result }) => {
        const { finished, info } = result;

        if (finished) {
          props.setFinished(info);
        }

        setText('');
      })
      .catch(error => console.error(error));

    setText('');
    props.nextQuestion();
  }

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        {props.title}
      </h2>
      <p className={classes.description}>
        {props.description}
      </p>
      {props?.image && (
        <img
          src={props.image}
          alt='search'
          width={100}
          className={classes.image}
        />
      )}
      <textarea
        type={props.type}
        value={text}
        onChange={({ target }) => setText(target.value)}
        className={classes.input}
        placeholder='Digite aqui'
      />
       <button
        className={classNames(classes.btn,
          {[classes.disabled]: isDisabled })}
        disabled={isDisabled}
        onClick={click}
      >
        Próxima
      </button>
    </div>
  )
}

export default SearchTextAreaInput;
