import React from "react";
import { Col, Row, Form, FormGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "utils";
import styles from "./index.module.scss";
import * as Actions from "../../store/actions";
import { TextFieldController } from "components/forms";

export default function () {
  const dispatch = useDispatch();
  const userInfos = useSelector((state) => state.user.info);

  const { handleSubmit, control, errors } = useForm({
    reValidateMode: "onSubmit",
  });

  const onSubmit = async (paymentData) => {
    dispatch(Actions.usePix(true));
    dispatch(
      Actions.setPaymentInfo({
        ...paymentData,
      })
    );
    dispatch(Actions.changeStep("next"));
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
        <p className="text-center mt-4 mb-4">
          O QR Code para efetuar o pagamento será mostrado após confirmar o
          pedido e ficará disponível para pagamento por 24 horas. Após esse
          período o pedido será cancelado!
        </p>

        <Row className="mt-3" style={{ justifyContent: "center" }}>
          {/* <Col xs={12} lg={6} className="pt-3 text-right">
            <p className="mt-4 mb-4">Por favor, p0reencha o seu telefone</p>
          </Col> */}
          <Col xs={12} lg={6} className="pt-3">
            <FormGroup className={styles.formGroup}>
              <TextFieldController
                label="Telefone"
                type="tel"
                name="phone"
                mask="(99) 99999-9999"
                required={true}
                control={control}
                errors={errors}
                defaultValue={userInfos?.mobile_number}
                rules={{
                  pattern:
                    /^(\([0-9][0-9]\) [0-9][0-9]{4}-[0-9]{4})|(\(1[2-9]\) [5-9][0-9]{3}-[0-9]{4})|(\([2-9][1-9]\) [5-9][0-9]{3}-[0-9]{4})$/,
                }}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={6}>
            <button
              aria-label="voltar"
              type="button"
              className={classNames(styles.backButton, "btn-form")}
              onClick={() => dispatch(Actions.changeStep("previous"))}
            >
              Voltar
            </button>
          </Col>
          <Col xs={6}>
            <button
              aria-label="continuar"
              type="submit"
              className={"custom-btn-verde btn-form"}
            >
              Continuar
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
