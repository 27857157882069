import React, { useState, useEffect } from 'react';

import ModalQuiz from './modalQuiz';
import ModalSearch from './modalSearch';

const QuizAndSearch = () => {
  const [modalType, setModalType] = useState('');
  const hasQuiz = JSON.parse(localStorage.getItem('hasQuiz') || null);
  const hasSearch = JSON.parse(localStorage.getItem('hasSearch') || null);

  useEffect(() => {
    if (hasSearch) {
      setModalType('search');
      return;
    }

    if (!hasSearch && hasQuiz) {
      setModalType('quiz');
      return;
    }
  }, [hasQuiz, hasSearch]);

  return (
    <>
      {modalType === 'search' && <ModalSearch setModalType={setModalType}/> }

      {modalType === 'quiz' && <ModalQuiz /> }
    </>
  )
}

export default QuizAndSearch;
