import React from "react";

import LazyLoad from "react-lazyload";

import Header from "components/header";
import Footer from "components/footer";

import styles from "./index.module.scss";

import OiLogo from "./images/Oi_Alegria_RGB.png";
import NDSLogout from "components/NDSLogout";

const ErroLoggedOi = () => {
  return (
    <>
      <Header />
      <span className={styles.logo}>
        <LazyLoad once>
          <img src={OiLogo} alt="Logo da Oi" />
        </LazyLoad>
      </span>
      <div className={styles.errorMessage}>
        <p>
          Oi, verificamos que você está acessando o Clube Oi +Alegria de outro
          dispositivo. Para acessar por aqui, você precisa primeiro ir no
          desktop ou mobile, onde sua sessão está aberta, e encerrá-la clicando
          em sair.
        </p>
        <p>
          Caso já tenha encerrado a sessão aguarde alguns momentos e tente
          novamente
        </p>
      </div>
      <NDSLogout />
      <Footer />
    </>
  );
};

export default ErroLoggedOi;
