import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import loadable from "@loadable/component";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { getHighlights } from "services/api";
import { isLoggedIn } from "store/selectors";

// import styles from "./index.module.scss";
import { getProductPrice } from "utils";

import { classNames } from "utils";
import styles from "./index.module.scss";

const CardBox = loadable(() => import("components/cardBox"), {
  fallback: <></>,
});

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 5, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 830 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  large_mobile: {
    breakpoint: { max: 830, min: 580 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  medium_mobile: {
    breakpoint: { max: 580, min: 400 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const OffersHighlights = () => {
  const logged = useSelector(isLoggedIn);
  const history = useHistory();
  const globalSettings = useSelector((state) => state.global.settings);
  const benefitTier = useSelector(
    (state) =>
      state.user.info?.benefit_tier_id || globalSettings?.benefit_tier?.id
  );
  const [productList, setProductList] = useState([]);

  const openFilter = () => {
    history.push(`/ofertas/destaques`);
  };

  useEffect(() => {
    getHighlights({}, 1, 10).then((response) => {
      setProductList(response.highlights);
    });
  }, [logged]);

  return (
    productList.length > 0 && (
      <>
        <div className={styles.desktopContainer}>
          <div className="mt-4">
            <div className={styles.titleBar}>
              <h2 className={styles.title}>
                Destaques
              </h2>
              {/* <StyledTitle title={"DESTAQUES"} /> */}
              {/* <span onClick={openFilter} className={styles.filterLink}>
                Ver todas
              </span> */}
            </div>

            <Carousel responsive={responsive} className={styles.carousel}>
              {productList.map((item, key) => {
                let productPrice = getProductPrice(
                  item.product,
                  benefitTier,
                  !logged
                );
                return logged ? (
                  productPrice && (
                    <div
                      key={key}
                      className={classNames("mb-3", styles.mobile)}
                    >
                      <CardBox
                        {...{ key }}
                        image={item.product?.images[0]?.url}
                        title={item.product.title}
                        product={item.product}
                        discountPercent={productPrice.percent_discount}
                        discountPrice={productPrice.price_in_money}
                        fullprice={productPrice.price_in_money_before_sale}
                      />
                    </div>
                  )
                ) : (
                  <div key={key} className={classNames("mb-3", styles.mobile)}>
                    <CardBox
                      {...{ key }}
                      image={item.product?.images[0]?.url}
                      title={item.product.title}
                      discountPercent={productPrice?.percent_discount}
                      product={item.product}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
        <div className={styles.mobileContainer}>
          <div className="mt-4">
            <div className={styles.titleBar}>
              <h2 className={styles.title}>
                Destaques
              </h2>
            </div>
            {productList.map((item, key) => {
              if (key < 5) {
                let productPrice = getProductPrice(
                  item.product,
                  benefitTier,
                  !logged
                );
                return logged ? (
                  productPrice && (
                    <div
                      key={key}
                      className={classNames("mb-3", styles.mobile)}
                    >
                      <CardBox
                        {...{ key }}
                        image={item.product?.images[0]?.url}
                        title={item.product.title}
                        product={item.product}
                        discountPercent={productPrice.percent_discount}
                        discountPrice={productPrice.price_in_money}
                        fullprice={productPrice.price_in_money_before_sale}
                      />
                    </div>
                  )
                ) : (
                  <div key={key} className={classNames("mb-3", styles.mobile)}>
                    <CardBox
                      {...{ key }}
                      image={item.product?.images[0]?.url}
                      title={item.product.title}
                      discountPercent={productPrice?.percent_discount}
                      product={item.product}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })}
            {/* <div className={styles.getAllLink} onClick={openFilter}>
              <span>Ver todos os destaques</span>
              <span>&gt;</span>
            </div> */}
            <div className={styles.btnContainer}>
              <button
                className={styles.btn}
                onClick={openFilter}
              >
                Ver mais
              </button>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default OffersHighlights;
