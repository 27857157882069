import React, { useState, useEffect, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  AutocompleteController,
  SelectFieldController,
  TextFieldController,
} from 'components/forms/index'

import styles from './index.module.scss'
import loadable from '@loadable/component'
import { makeStyles } from '@material-ui/core/styles'

import {
  getCep,
  getCitiesRequest,
  addressCreate,
  addressUpdate,
} from 'services/api/index'

import { Modal, Row, Form, Container, Col } from 'react-bootstrap'

import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'
import { Box } from '@material-ui/core'

const CircularProgress = loadable.lib(
  () => import('@material-ui/core/CircularProgress'),
)
const HighlightOff = loadable.lib(
  () => import('@material-ui/icons/HighlightOff'),
)

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '& .MuiInputBase-input': {
      backgroundColor: 'white',
      borderRadius: 4,
      height: '8px',
    },
    '& fieldset': {
      borderColor: '#ECECEE',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
}))

export default function ({ show, onClose, addressData = null }) {
  const { register, handleSubmit, getValues, setValue, control, errors } =
    useForm({
      reValidateMode: 'onSubmit',
    })

  const classes = useStyles()

  const [invalidCEP, setInvalidCEP] = useState(false)
  const [loadingEffect, setLoadingEffect] = useState(false)
  const [cities, setCities] = useState([])
  const [city, setCity] = useState(null)
  const [showFields, setShowFields] = useState(false)

  function create(form) {
    addressCreate(form).then((response) => {
      setLoadingEffect(false)
      onClose()
    })
  }

  function edit(form, addressId) {
    addressUpdate(form, addressId).then((response) => {
      setLoadingEffect(false)
      onClose()
    })
  }

  function validateAddress(address) {
    address.city = address.city.replace(/\s\s+/g, ' ').trim()
    address.neighborhood = address.neighborhood.replace(/\s\s+/g, ' ').trim()
    address.number = address.number.replace(/\s\s+/g, ' ').trim()
    address.state = address.state.replace(/\s\s+/g, ' ').trim()
    address.street = address.street.replace(/\s\s+/g, ' ').trim()
    address.zip_code = address.zip_code.replace(/\s\s+/g, ' ').trim()

    if (
      address.city === '' ||
      address.neighborhood === '' ||
      address.number === '' ||
      address.state === '' ||
      address.street === '' ||
      address.zip_code === ''
    ) {
      return false
    } else {
      return true
    }
  }

  const onSubmit = (data) => {
    if (validateAddress(data)) {
      setLoadingEffect(true)
      if (addressData?.id) {
        edit(data, addressData?.id)
      } else {
        create(data)
      }
    } else {
      alert('Endereço inválido!')
    }
  }

  const searchForCEP = (ev) => {
    let cep = ev.target?.value?.replace(/\D/g, '')

    if (!cep || cep.length < 8) {
      setShowFields(false)
      setValue('street', '')
      setValue('neighborhood', '')
      setValue('state', '')
      setValue('city', '')
      setCity('')
      setValue('zip_code', cep)
      return
    }

    setInvalidCEP(false)
    getCep(cep).then((cepInfo) => {
      if (cepInfo?.erro) {
        setInvalidCEP(true)
        setShowFields(false)
      } else {
        setShowFields(true)
        setValue('street', cepInfo.logradouro)
        setValue('neighborhood', cepInfo.bairro)
        setValue('state', cepInfo.uf)
        setValue('city', cepInfo.localidade)
        setCity(cepInfo.localidade)
        setValue('zip_code', cep)
      }
    })
  }

  const getCities = useCallback(
    (value) => {
      getCitiesRequest(value).then((cities) => {
        setCities(cities)
        setCity(addressData?.city)
      })
    },
    [addressData],
  )

  useEffect(() => {
    register('city', {
      validate: (value) => value.length || 'Campo obrigatório.',
    })
  }, [register])

  useEffect(() => {
    if (addressData) {
      getCep(addressData.zip_code).then((cepInfo) => {
        if (cepInfo?.erro) {
          setInvalidCEP(true)
          setShowFields(false)
        } else {
          setShowFields(true)
          setValue('street', cepInfo.logradouro)
          setValue('neighborhood', cepInfo.bairro)
          setValue('state', cepInfo.uf)
          setValue('city', cepInfo.localidade)
          setCity(cepInfo.localidade)
          setValue('zip_code', addressData.zip_code)
        }
      })
      getCities(addressData.state)
    }
  }, [getCities, addressData, setValue])

  return (
    <Modal show={show} onHide={onClose} centered>
      <Box className={styles.modalBody}>
        <div className={styles.modalTitle}>
          {addressData?.id ? 'Editar Endereço' : 'Novo Endereço'}
          <div className={styles.modalCloseButton} onClick={onClose}>
            <HighlightOff />
          </div>
        </div>
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {!showFields && (
              <>
                <Row>
                  <Col xs={12} className="pr-1 pl-1">
                    <TextFieldController
                      label="Nome completo"
                      name="name"
                      control={control}
                      errors={errors}
                      defaultValue={addressData?.name}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={12} className="pr-1 pl-1">
                    <Controller
                      as={(props) => (
                        <InputMask
                          {...props}
                          mask="99999-999"
                          onChange={searchForCEP}
                        >
                          {() => (
                            <TextField
                              InputLabelProps={{
                                shrink: false,
                                style: {
                                  position: 'relative',
                                  top: '-20px',
                                  left: '-13px',
                                },
                              }}
                              className={classes.root}
                              variant="outlined"
                              label="CEP"
                              error={errors.zip_code || invalidCEP}
                              helperText={
                                (errors.zip_code || invalidCEP) &&
                                'CEP inválido'
                              }
                            />
                          )}
                        </InputMask>
                      )}
                      defaultValue={addressData?.zip_code}
                      name="zip_code"
                      rules={{ required: true }}
                      control={control}
                    />
                  </Col>
                </Row>
              </>
            )}

            {showFields && (
              <>
                <Row>
                  <Col xs={12} className="pr-1 pl-1">
                    <TextFieldController
                      label="Nome completo"
                      name="name"
                      defaultValue={addressData?.name || getValues('name')}
                      control={control}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <Row>
                  <div className="col-12 col-md-12 pr-1 pl-1 pt-3">
                    <TextFieldController
                      label="Rua"
                      name="street"
                      control={control}
                      errors={errors}
                      defaultValue={addressData?.street}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="col-6 col-md-6 pr-1 pl-1 pt-3">
                    <TextFieldController
                      label="Número"
                      name="number"
                      control={control}
                      errors={errors}
                      defaultValue={addressData?.number}
                    />
                  </div>
                  <div className="col-6 col-md-6 pr-1 pl-1 pt-3">
                    <TextFieldController
                      label="Complemento"
                      name="complement"
                      control={control}
                      errors={errors}
                      required={false}
                      defaultValue={addressData?.complement}
                    />
                  </div>
                  <div className="col-12 col-md-6 pr-1 pl-1 pt-3">
                    <TextFieldController
                      label="Bairro"
                      name="neighborhood"
                      control={control}
                      errors={errors}
                      defaultValue={addressData?.street}
                    />
                  </div>
                  <div className="col-12 col-md-6 pr-1 pl-1 pt-3">
                    <AutocompleteController
                      label="Cidade"
                      name="city"
                      options={cities}
                      onChange={(e, options) => {
                        setCity(options.value)
                        setValue('city', options.value)
                      }}
                      control={control}
                      errors={errors}
                      value={
                        getValues('city') === null ? getValues('city') : city
                      }
                    />
                  </div>
                </Row>
                <Row>
                  <div className="col-12 col-md-6 pr-1 pl-1 pt-3">
                    <SelectFieldController
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          position: 'relative',
                          top: '-20px',
                          left: '-13px',
                        },
                      }}
                      options={[
                        { value: 'AC', label: 'Acre' },
                        { value: 'AL', label: 'Alagoas' },
                        { value: 'AP', label: 'Amapá' },
                        { value: 'AM', label: 'Amazonas' },
                        { value: 'BA', label: 'Bahia' },
                        { value: 'CE', label: 'Ceará' },
                        { value: 'DF', label: 'Distrito Federal' },
                        { value: 'ES', label: 'Espírito Santo' },
                        { value: 'GO', label: 'Goiás' },
                        { value: 'MA', label: 'Maranhão' },
                        { value: 'MT', label: 'Mato Grosso' },
                        { value: 'MS', label: 'Mato Grosso do Sul' },
                        { value: 'MG', label: 'Minas Gerais' },
                        { value: 'PA', label: 'Pará' },
                        { value: 'PB', label: 'Paraíba' },
                        { value: 'PR', label: 'Paraná' },
                        { value: 'PE', label: 'Pernambuco' },
                        { value: 'PI', label: 'Piauí' },
                        { value: 'RR', label: 'Roraima' },
                        { value: 'RO', label: 'Rondônia' },
                        { value: 'RJ', label: 'Rio de Janeiro' },
                        { value: 'RN', label: 'Rio Grande do Norte' },
                        { value: 'RS', label: 'Rio Grande do Sul' },
                        { value: 'SC', label: 'Santa Catarina' },
                        { value: 'SP', label: 'São Paulo' },
                        { value: 'SE', label: 'Sergipe' },
                        { value: 'TO', label: 'Tocantins' },
                      ]}
                      name="state"
                      label="Estado"
                      handleChange={(ev) => getCities(ev.target.value)}
                      control={control}
                      errors={errors}
                      defaultValue={addressData?.state}
                    />
                  </div>
                  <div
                    className="col-12 col-md-6 pr-1 pl-1"
                    style={{ marginTop: '24px' }}
                  >
                    <Controller
                      as={(props) => (
                        <InputMask
                          {...props}
                          mask="99999-999"
                          onChange={searchForCEP}
                        >
                          {() => (
                            <TextField
                              InputLabelProps={{
                                shrink: false,
                                style: {
                                  position: 'relative',
                                  top: '-20px',
                                  left: '-13px',
                                },
                              }}
                              className={classes.root}
                              variant="outlined"
                              label="CEP"
                              error={errors.zip_code || invalidCEP}
                              helperText={
                                (errors.zip_code || invalidCEP) &&
                                'CEP inválido'
                              }
                            />
                          )}
                        </InputMask>
                      )}
                      defaultValue={
                        addressData?.zip_code || getValues('zip_code')
                      }
                      name="zip_code"
                      rules={{ required: true }}
                      control={control}
                    />
                  </div>
                </Row>
                <Row className="mt-3">
                  <Box width={'100%'}>
                    <button
                      className={styles.submitButton}
                      aria-label="Salvar"
                      type="submit"
                      name="submit"
                      disabled={loadingEffect}
                    >
                      {loadingEffect ? (
                        <CircularProgress
                          color="inherit"
                          className={styles.circularProgress}
                        />
                      ) : (
                        <div>Salvar</div>
                      )}
                    </button>
                  </Box>
                </Row>
              </>
            )}
          </Form>
        </Container>
      </Box>
    </Modal>
  )
}
