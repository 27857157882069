import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { getProductsRelated } from "services/api";
import { isLoggedIn } from "store/selectors";

import { getProductPrice } from "utils";

import { removeDuplicatedAndSortByWeight } from "utils/productsRelated";
import { classNames } from "utils";
import styles from "./index.module.scss";

const CardBox = loadable(() => import("components/cardBox"), {
  fallback: <></>,
});

const StyledTitle = loadable(() => import("components/styledTitle"), {
  fallback: <></>,
});

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 5, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 830 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  large_mobile: {
    breakpoint: { max: 830, min: 400 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  medium_mobile: {
    breakpoint: { max: 580, min: 400 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const ProductsRelated = ({ relatedTo = [], noResultsComponent = null }) => {
  const logged = useSelector(isLoggedIn);
  const globalSettings = useSelector((state) => state.global.settings);
  const benefitTier = useSelector(
    (state) =>
      state.user.info?.benefit_tier_id || globalSettings?.benefit_tier?.id
  );
  const [productList, setProductList] = useState([]);

  const fetchRelateds = useCallback(async () => {
    setProductList([]);
    let allProducts = [];
    let excludeIds = [];

    for (let i = 0; i < relatedTo.length; i++) {
      if (relatedTo[i]) {
        excludeIds.push(relatedTo[i].id);
        allProducts = Array.prototype.concat(
          allProducts,
          await getProductsRelated(relatedTo[i])
        );
      }
    }

    let treatedProducts = removeDuplicatedAndSortByWeight({
      products: allProducts,
      excludeIds: excludeIds,
    });

    setProductList(treatedProducts.slice(0, 10));
  }, [relatedTo]);

  useEffect(() => {
    fetchRelateds();
    return () => {
      setProductList([]);
    };
  }, [fetchRelateds]);

  if (productList.length === 0 && noResultsComponent !== null) {
    return noResultsComponent;
  }

  return (
    <>
      <div className={styles.desktopContainer}>
        <div className="mt-5">
          <StyledTitle title={"Produtos relacionados"} />

          <Carousel responsive={responsive}>
            {productList.map((product, key) => {
              let productPrice = getProductPrice(product, benefitTier, !logged);
              return logged ? (
                productPrice && (
                  <div key={key} className={classNames("mb-3", styles.mobile)}>
                    <CardBox
                      {...{ key }}
                      image={product?.images[0]?.url}
                      title={product.title}
                      product={product}
                      discountPrice={productPrice.price_in_money}
                      fullprice={productPrice.price_in_money_before_sale}
                    />
                  </div>
                )
              ) : (
                <div key={key} className={classNames("mb-3", styles.mobile)}>
                  <CardBox
                    {...{ key }}
                    image={product?.images[0]?.url}
                    title={product.title}
                    discountPercent={productPrice?.percent_discount}
                    product={product}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      <div className={styles.mobileContainer}>
        <div className="mt-5">
          <StyledTitle title={"Produtos relacionados"} />

          {productList.map((product, key) => {
            let productPrice = getProductPrice(product, benefitTier, !logged);
            return logged ? (
              productPrice && (
                <div key={key} className={classNames("mb-3", styles.mobile)}>
                  <CardBox
                    {...{ key }}
                    image={product?.images[0]?.url}
                    title={product.title}
                    product={product}
                    discountPrice={productPrice.price_in_money}
                    fullprice={productPrice.price_in_money_before_sale}
                  />
                </div>
              )
            ) : (
              <div key={key} className={classNames("mb-3", styles.mobile)}>
                <CardBox
                  {...{ key }}
                  image={product?.images[0]?.url}
                  title={product.title}
                  discountPercent={productPrice?.percent_discount}
                  product={product}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ProductsRelated;
