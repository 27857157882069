/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import loadable from "@loadable/component";

import { Container, CardDeck } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import { isLoggedIn } from "store/selectors";

import { classNames, getProductPrice } from "utils";
import styles from "./index.module.scss";

const CardBox = loadable(() => import("components/cardBox"), {
  fallback: <></>,
});

const CircularProgress = loadable.lib(() =>
  import("@material-ui/core/CircularProgress")
);

const ProductsGrid = ({
  productsData,
  currentPage,
  pageChangeCallback,
  notFoundComponent,
  bannerID,
  interestArea,
  loading,
  refresh,
  hasPagination = true,
}) => {
  const logged = useSelector(isLoggedIn);
  const globalSettings = useSelector((state) => state.global.settings);
  const benefitTier = useSelector(
    (state) =>
      state.user.info?.benefit_tier_id || globalSettings?.benefit_tier?.id
  );

  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(currentPage);

  const [pageCount, setPageCount] = useState(20);
  const [lastPage, setLastPage] = useState(2);
  const [empty, setEmpty] = useState(1);
  const [pageRange, setPageRange] = useState(5);

  useEffect(() => {
    if (productsData) {
      if (hasPagination) {
        setLastPage(productsData.pagination.total);
        setEmpty(productsData.pagination.total);
      }
      setProductList([...productsData.products]);
    } else {
      setLastPage(0);
      setEmpty(1);
      setProductList([]);
    }
  }, [productsData, hasPagination]);

  useEffect(() => {
    if (refresh) {
      setPage(1);
    }
  }, [refresh]);

  useEffect(() => {
    if (page && typeof pageChangeCallback === "function") {
      pageChangeCallback(page);
    }
  }, [page, pageChangeCallback]);

  useEffect(() => {
    if (interestArea) {
      setPageCount(12);
    }
  }, [interestArea]);

  useEffect(() => {
    if (isMobile) {
      setPageRange(2);
    }
  }, []);

  const handlePageClick = (data) => {
    const selected = data.selected;
    setPage(selected + 1);
  };

  return (
    <div>
      {loading ? (
        <Container className={styles.containerLoading}>
          <CircularProgress style={{ width: 15, height: 15 }} />
        </Container>
      ) : (
        <Container
          className={classNames(
            interestArea ? styles.catContainer : bannerID && "catContainer",
            styles.container
          )}
        >
          {empty > 0 ? (
            <>
              <CardDeck className={styles.cardWrapper}>
                {productList.map((product, key) => {
                  let productPrice = getProductPrice(
                    product,
                    benefitTier,
                    !logged
                  );
                  return (
                    <div key={key} className={styles.cardContainer}>
                      {logged ? (
                        productPrice && (
                          <CardBox
                            {...{ key }}
                            image={product?.images[0]?.url}
                            title={product.title}
                            product={product}
                            discountPrice={productPrice.price_in_money}
                            discountPercent={productPrice.percent_discount}
                            fullprice={productPrice.price_in_money_before_sale}
                          />
                        )
                      ) : (
                        <CardBox
                          {...{ key }}
                          image={product?.images[0]?.url}
                          title={product.title}
                          discountPercent={productPrice?.percent_discount}
                          product={product}
                        />
                      )}
                    </div>
                  );
                })}
              </CardDeck>
              {!bannerID && hasPagination ? (
                <div className={styles.btn}>
                  <button
                    onClick={() => handlePageClick()}
                    style={hasPagination ? { display: 'none' } : { display: 'block' }}
                  >
                    Ver mais
                  </button>
                </div>
                // <ReactPaginate
                //   previousLabel={"‹"}
                //   nextLabel={"›"}
                //   forcePage={currentPage - 1}
                //   breakLabel={"..."}
                //   previousLinkClassName={"page-link"}
                //   nextLinkClassName={"page-link"}
                //   breakClassName={classNames("break-me", "page-link")}
                //   pageCount={Math.ceil(lastPage / pageCount)}
                //   marginPagesDisplayed={1}
                //   pageRangeDisplayed={pageRange}
                //   pageClassName={styles.pageItem}
                //   pageLinkClassName={"page-link"}
                //   onPageChange={handlePageClick}
                //   containerClassName={classNames(
                //     styles.pagination,
                //     "pagination"
                //   )}
                //   subContainerClassName={"pages pagination"}
                //   activeClassName={classNames(styles.active)}
                // />
              ) : (
                <p>&nbsp;</p>
              )}
            </>
          ) : (
            <Container>
              {notFoundComponent ? (
                notFoundComponent
              ) : (
                <h3 className={styles.returnNada}>
                  Não há produtos que coincidam com a sua busca.
                </h3>
              )}
            </Container>
          )}
        </Container>
      )}
    </div>
  );
};

export default ProductsGrid;
