import { makeStyles } from "@material-ui/core";

import tablet from "./images/tablet.png";
import alegria from "./images/alegria.png";
import alegriaActive from "./images/alegriaActive.png";
import peopleActive from "./images/peopleActive.png";

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      padding: "81px 0 100px 0",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "1200px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        padding: "32px 0 0px 0",
      },
    },
    headlineContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {},
    },
    titleContainer: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {},
    },

    title: {
      color: "#ED9915",
      textAlign: "center",
      fontFamily: "NeuePlak-Bold",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "46px",
      textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        color: "#ED9915",
        fontSize: "15px",
        fontStyle: "normal",
        lineHeight: "30.195px",
      },
    },
    headline: {
      color: "#2F2F2F",
      textAlign: "center",
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "46px",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "NeuePlak-Bold",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        fontSize: "23px",
        fontStyle: "normal",
        lineHeight: "30.195px",
        width: "55%",
      },
    },
    subheadline: {
      marginTop: "10px",
      color: "#888",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "29.954px",
      letterSpacing: "0.6px",
      width: "40%",
      textAlign: "center",
      marginBottom: "24px",
      [theme.breakpoints.down("md")]: {
        color: "#888",
        fontSize: "14px",
        fontStyle: "normal",
        lineHeight: "20px",
        width: "78%",
      },
    },

    slide: {
      background: `url(${tablet})`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "906px",
      height: "624px",
      marginBottom: "30px",
      padding: "29px",
      paddingTop: "57px",
      [theme.breakpoints.down("md")]: {
        width: "335px",
        height: "232px",
        backgroundRepeat: "round",
      },
    },

    urlContainer: (props) => ({
      width: "375.793px",
      height: "23.987px",
      backgroundColor: "#E2E4E9",
      borderRadius: "23.26px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "0 15px",
    }),
    url: (isActive) => ({
      color: isActive ? "#16005C" : "#2F2F2F",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "NeuePlak-Regular",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    }),
    intro: (isActive) => ({
      color: isActive ? "#353535" : "#888",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "NeuePlak-Regular",
      fontSize: "17.135px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "32.128px",
      textAlign: "left",
      marginTop: isActive ? "-20px" : "8px",
      width: "70%",
    }),
    button: (isActive) => ({
      textTransform: "none",
      borderRadius: "25.887px",
      background: isActive ? "#13224C" : "#E2E4E9",
      display: "flex",
      width: "184.199px",
      height: "43px",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
      color: "#FFF", // Replace with your color variable or default value
      textAlign: "center",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "DM Sans",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "18px",
      marginTop: "12px",
    }),
    logoContainer: (isActive) => ({
      borderRadius: "10.709px",
      border: isActive ? "none" : "5.355px solid #E2E4E9",
      width: "285.936px",
      height: isActive ? "100px" : "66.397px",
      display: "flex",
      alignItems: isActive ? "flex-start" : "center",
      justifyContent: "center",
      flexDirection: isActive ? "column-reverse" : "row",
    }),
    logoText: (isActive) => ({
      color: "#CFCFCF",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "NeuePlak-Bold",
      fontSize: "17.135px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "32.128px",
      textAlign: "left",
      textTransform: "uppercase",
    }),
    logo: (isActive) => ({
      background: isActive ? `url(${alegriaActive} )` : `url(${alegria} )`,
      width: isActive ? "153px" : "112px",
      height: isActive ? "44px" : "33px",
    }),
    peoplecontainer: (isActive) => ({
      borderRadius: "23.404px",
      background: isActive ? "rgba(22, 0, 92, 0.40)" : "#D6D6D6",
      width: "380px",
      height: "233px",
    }),
    peopleImage: (isActive) => ({
      width: "365px",
      height: "301px",
      backgroundRepeat: "no-repeat",
      background: `url(${peopleActive})`,
      marginTop: "0px",
      marginLeft: "10px",
      filter: isActive ? "grayscale(0)" : "grayscale(100%)",
    }),

    screenShot: (isActive) => ({
      width: "810px",
      filter: isActive ? "grayscale(0)" : "grayscale(100%)",
      [theme.breakpoints.down("md")]: {
        width: "305px",
        marginTop: "-39px",
      },
    }),
    ofertas: (isActive) => ({
      width: "786px",
      [theme.breakpoints.down("md")]: {
        width: "300px",
        marginTop: "-39px",
      },
      filter: isActive ? "grayscale(0)" : "grayscale(100%)",
    }),
  };
});
