import React from 'react'
import OrderCard from '../OrderCard'
import OrderCardStep from '../OrderCard/OrderCardStep'
import OrderCardHeader from '../OrderCard/OrderCardHeader'
import OrderCardTitle from '../OrderCard/OrderCardTitle'
import OrderCardContent from '../OrderCard/OrderCardContent'
import 'react-phone-input-2/lib/high-res.css'
import { Form, FormGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import * as Actions from '../../store/actions'
import { TextFieldController } from 'components/forms'
import { Box } from '@mui/material'

const Phone = () => {
  const { control, errors, handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  })

  const dispatch = useDispatch()
  const userInfos = useSelector((state) => state.user.info)

  const onSubmit = async (paymentData) => {
    dispatch(
      Actions.setPaymentInfo({
        ...paymentData,
      }),
    )
  }

  return (
    <OrderCard>
      <OrderCardHeader>
        <OrderCardStep>3</OrderCardStep>
        <OrderCardTitle>Telefone</OrderCardTitle>
      </OrderCardHeader>
      <OrderCardContent>
        <Box style={{ width: '100%' }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <TextFieldController
                style={{ width: '100%' }}
                disabled
                type="tel"
                name="phone"
                mask="(99) 99999-9999"
                control={control}
                errors={errors}
                defaultValue={userInfos?.mobile_number}
                autoFocus
                rules={{
                  pattern:
                    /^(\([0-9][0-9]\) [0-9][0-9]{4}-[0-9]{4})|(\(1[2-9]\) [5-9][0-9]{3}-[0-9]{4})|(\([2-9][1-9]\) [5-9][0-9]{3}-[0-9]{4})$/,
                }}
              />
            </FormGroup>
            {/* <button
            aria-label="continuar"
            type="submit"
            className={styles.button}
          >
            Adicionar telefone
          </button> */}
          </Form>
        </Box>
      </OrderCardContent>
    </OrderCard>
  )
}

export default Phone
