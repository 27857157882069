import React, { useState } from "react";
import { Controller } from "react-hook-form";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  formControl: {
    "& .MuiInputBase-input": {
      backgroundColor: "white",
    },
    width: "100%",
  },
}));

const PasswordFieldController = ({
  name,
  label,
  defaultValue,
  errors,
  control,
  rules = {},
  required = true,
  displayButton = true,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const error = Boolean(errors && errors[name]);

  const BaseComponent = (props) => (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      error={error}
    >
      <InputLabel id={`select-${name}`}>{label}</InputLabel>

      <OutlinedInput
        label={label}
        name={name}
        type={showPassword ? "text" : "password"}
        endAdornment={
          displayButton && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
        {...props}
      />

      {error && (
        <FormHelperText>
          {errors[name].type === "minLength"
            ? "Senha muito curta"
            : "Campo obrigatório"}
        </FormHelperText>
      )}
    </FormControl>
  );

  return control ? (
    <Controller
      as={(props) => <BaseComponent {...props} />}
      defaultValue={defaultValue || ""}
      name={name}
      rules={{ required: required, ...rules }}
      control={control}
    />
  ) : (
    <BaseComponent
      required={required}
      defaultValue={defaultValue || ""}
      name={name}
    />
  );
};

export default PasswordFieldController;
