import React from "react";
import styles from "./index.module.scss";
import { classNames } from "utils";

const StyledTitle = ({ title, containerClass, id }) => {
  return (
    <div
      className={classNames("mb-4", containerClass, styles.sectionTitleWrapper)}
      id={id}
    >
      <p className={styles.sectionTitle}>{title}</p>
    </div>
  );
};

export default StyledTitle;
