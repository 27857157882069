import React from 'react';

export default function(){  
  const ndsLogoutIframe = '<iframe src="https://hsso.oi.net.br/nidp/saml2/app/logout" width="1" height="1"></iframe>';
  
  return (
    <>
      <iframe 
        title='kill nds session' 
        src="https://hsso.oi.net.br/nidp/saml2/app/logout" 
        width="1" 
        height="1"
      />  
      <div dangerouslySetInnerHTML={ {__html: ndsLogoutIframe}} />
    </>
  )    
}