import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { classNames } from "utils";
import { useMediaQuery } from "@material-ui/core";

import StepConnector from "@material-ui/core/StepConnector";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

const ColorlibHorizontalConnector = withStyles({
  alternativeLabel: { top: 22 },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,var(--color-button-secondary-bg-hover) 0%,var(--color-button-secondary-bg) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,var(--color-button-secondary-bg-hover) 0%,var(--color-button-secondary-bg) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const ColorlibVerticalConnector = withStyles({
  line: {
    marginLeft: 12,
    marginTop: 7,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  verticalRoot: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, var(--color-button-secondary-bg) 0%, var(--color-button-secondary-bg-hover) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  rejected: {
    backgroundImage: "linear-gradient( 136deg, #a01f1f 0%, #e02a2a 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, var(--color-button-secondary-bg) 0%, var(--color-button-secondary-bg-hover) 100%)",
  },
});

const HorizontalStepper = ({ steps, activeStep }) => {
  const classes = useColorlibStepIconStyles();
  const onMobile = useMediaQuery("(max-width: 550px)");

  function getClassForStep(step) {
    if (step.reject) return classes.rejected;
    else if (step.complete) return classes.completed;

    return "";
  }

  return (
    <Stepper
      id="stepper"
      alternativeLabel
      style={{ padding: onMobile ? 0 : null }}
      activeStep={activeStep}
      connector={<ColorlibHorizontalConnector />}
    >
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel
            StepIconComponent={() => (
              <div className={classNames(classes.root, getClassForStep(step))}>
                {step.icon}
              </div>
            )}
          >
            {step.description}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const VerticalStepper = ({ steps, activeStep }) => {
  const classes = useColorlibStepIconStyles();

  function getClassForStep(step) {
    if (step.reject) return classes.rejected;
    else if (step.complete) return classes.completed;

    return "";
  }

  return (
    <Stepper
      id="stepper"
      activeStep={activeStep}
      connector={<ColorlibVerticalConnector />}
      orientation="vertical"
    >
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel
            StepIconComponent={() => (
              <div
                className={classNames(
                  classes.verticalRoot,
                  getClassForStep(step)
                )}
              >
                {step.icon}
              </div>
            )}
          >
            {step.description}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default function CustomizedStepper({
  steps,
  activeStep,
  orientation = "horizontal",
}) {
  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      {orientation === "vertical" ? (
        <VerticalStepper steps={steps} activeStep={activeStep} />
      ) : (
        <HorizontalStepper steps={steps} activeStep={activeStep} />
      )}
    </div>
  );
}
