import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ChevronDown } from 'assets/icons'
import ReactHtmlParser from 'react-html-parser'

import styles from './Tabs.module.scss'

const Tabs = () => {
  const product = useSelector(({ product }) => product.product);
  const [active, setActive] = useState(null)

  const handleCollapse = (value) => {
    if (active === value) {
      setActive(null)
    } else {
      setActive(value)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title} onClick={() => handleCollapse('summary')}>
          <p>Sobre o produto</p>
          <ChevronDown color="#000"/>
        </div>
        {active === 'summary' && (
          <div className={styles.content}>
            {ReactHtmlParser(product?.summary)}
          </div>
        )}
      </div>
      {product?.tabs.map((item, i) => (
        <div key={i} className={styles.wrapper}>
          <div className={styles.title} onClick={() => handleCollapse(item.name)}>
            <p>{item.name}</p>
            <ChevronDown color="#000"/>
          </div>
          {active === item.name && (
            <div className={styles.content}>
              {ReactHtmlParser(item.content)}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export { Tabs }
