import React, { useState } from "react";
import { useForm } from "react-hook-form";
import loadable from "@loadable/component";

import { createLead } from "services/api/index";

import { classNames } from "utils";
import styles from "./index.module.scss";

const CircularProgress = loadable.lib(() =>
  import("@material-ui/core/CircularProgress")
);

const Newsletter = () => {
  const { handleSubmit, register, errors, reset } = useForm({
    reValidateMode: "onSubmit",
  });
  const [loading, setLoading] = useState(false);
  const [requestResponse, setRequestResponse] = useState(null);

  const onSubmit = (data) => {
    setLoading(true);

    createLead(data.email)
      .then((response) => {
        if (response.success) {
          reset();
          setRequestResponse("Você se cadastrou com sucesso! :D");
        } else {
          setRequestResponse(response.error);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={classNames(styles.newsletterWrapper)}>
      <div className={classNames("container", styles.newsletterContainer)}>
        <p className={styles.newsletterText}>Fique por dentro das Novidades</p>
        <form
          className={classNames(
            "form-inline my-2 my-lg-0",
            styles.formNewsletter
          )}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <input
              type="email"
              name="email"
              placeholder="Insira seu e-mail aqui"
              ref={register({ required: true })}
              className={classNames(
                "form-control mr-sm-2",
                styles.formInput,
                Boolean(errors && errors["email"]) ? styles.inputError : ""
              )}
            />
          </div>

          <button
            aria-label="cadastrar"
            className={classNames(
              "btn btn-outline-success my-2 my-sm-0",
              styles.btnNewsletter
            )}
            type="submit"
            disabled={loading}
          >
            Cadastrar
            {loading && <CircularProgress style={{ width: 15, height: 15 }} />}
          </button>
        </form>
        <div>{requestResponse}</div>
      </div>
    </div>
  );
};

export default Newsletter;
