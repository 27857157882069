import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: theme.spacing(2),
      paddingBottom: "110px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "1200px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        marginTop: "30px",
        paddingBottom: "56px",
      },
    },
    headlineContainer: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
      },
    },

    imageContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: "auto",
        marginTop: "30px",
      },
    },
    maisAlegria: {
      color: "#ED9915",
      fontSize: "48px",
      fontWeight: 900,
      lineHeight: "53.798px",
      letterSpacing: "2px",
      width: "85%",
      fontFamily: "NeuePlak-ExtraBlack",
      textAlign: "left",
    },
    headline: {
      color: "#2F2F2F",
      fontSize: "40px",
      fontWeight: 900,
      lineHeight: "53.798px",
      letterSpacing: "2px",
      width: "94%",
      fontFamily: "NeuePlak-ExtraBlack",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "30px",
        width: "60%",
        textAlign: "center",
        lineHeight: "33.843px",
        letterSpacing: "1.258px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    subheadline: {
      marginTop: "10px",
      color: "#888",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "29.954px",
      letterSpacing: "0.6px",
      width: "90%",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        width: "40%",
        textAlign: "center",
        lineHeight: "18.843px",
        letterSpacing: "0.42px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        width: "83%",
        textAlign: "center",
        lineHeight: "19px",
      },
    },
    cta: {
      marginTop: "57px",
      width: "181px",
      height: "54px",
      borderRadius: "33px",
      background: "#ED9915",
      textTransform: "none",
      color: "#FFF",
      textAlign: "center",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "37.195px",
      fontFamily: "NeuePlak-SemiBold",
      [theme.breakpoints.down("md")]: {
        marginTop: "26px",
        width: "113px",
        height: "33px",
        borderRadius: "20px",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontSize: "8.807px",
        fontWeight: 400,
        lineHeight: "23.398px",
      },
    },

    imageBackground: {
      [theme.breakpoints.down("md")]: {
        margin: "auto",
      },
    },
    image: {
      width: "584px",
      [theme.breakpoints.down("md")]: {
        width: "338px",

        marginRight: "12px",
        marginBottom: "0px",
      },
    },
  };
});
