import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TagManager from 'react-gtm-module'
// import LazyLoad from "react-lazyload";

import Logo from "./images/logo.png";

import { classNames } from "utils";

import { getLoginProvider } from "store/selectors";

import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";

import { Box, Button, makeStyles } from "@material-ui/core";
import FallbackImage from "components/fallbackImage";
import NavLinks from "./components/mainNavLinks";
import UserActions from "./components/userActions";
import SearchBar from "./components/searchBar";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      maxWidth: "1250px",
      margin: "32px auto",
      [theme.breakpoints.down("md")]: {
        margin: "20px 0px",
      },
    },
    containerWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "1250px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        margin: "20px 0px",
      },
    },
    divider: {
      [theme.breakpoints.down("md")]: {
        borderBottom: `1px solid #DDD`,
        margin: "8px  26px 0px 26px",
      },
    },
  };
});

const Header = (props) => {
  const classes = useStyles();

  const history = useHistory();
  const userProvider = useSelector(getLoginProvider);
  const [menuMobile, setMenuMobile] = useState(false);
  const partnerSettings = useSelector((state) => state.global.partner);

  const handleOpenMenuMobile = () => {
    setMenuMobile(!menuMobile);
  };

  const getCompanyLogo = () => {
    return partnerSettings?.logo || Logo;
  };

  const handleDesktopClick = () => {
    if (userProvider === "" || userProvider === "email") {
      history.push("/");
    } else {
      history.push("/ofertas");
    }
  };

  const handleMobileClick = () => {
    if (userProvider === "nds_oi") {
      history.push("/ofertas");
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    if (partnerSettings && partnerSettings.google_tag_manager_id) {
      const tagManagerArgs = {
        gtmId: partnerSettings.google_tag_manager_id
      }
      TagManager.initialize(tagManagerArgs)
    } else {
      const tagManagerArgs = {
        gtmId: "GTM-KQ36KMB"
      }
      TagManager.initialize(tagManagerArgs)
    }
  }, [partnerSettings]);

  return (
    <div className={classes.container}>
      <div className="d-none d-md-block d-lg-block ng-scope">
        <div
          style={{ backgroundColor: props?.headerBg }}
          className={classNames("header", styles.header)}
        >
          <nav
            className={classNames("navbar", "navbar-expand-lg", "navbar-light")}
          >
            <div className={classes.containerWrapper}>
              <button
                id="btn-menu"
                aria-label="menu button"
                className={classNames(
                  "navbar-toggler",
                  "hamburger",
                  "hamburger--elastic",
                  styles.hamburguer
                )}
                type="button"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <Button
                arial-label="logo"
                className="navbar-brand"
                onClick={handleDesktopClick}
              >
                <FallbackImage width={145} height={45}>
                  <img
                    alt="logo"
                    className={classNames("main-logo", styles.mainLogo)}
                    src={getCompanyLogo()}
                  />
                </FallbackImage>
              </Button>
              <NavLinks />
              <UserActions />
            </div>
          </nav>
          {!props?.hideSearchBar && (
            <SearchBar
              searchCallback={(term) => {
                history.push(`/busca/${term}`);
              }}
            />
          )}
        </div>
      </div>
      <div className="d-lg-none d-md-none d-sm-block ng-scope">
        <div
          style={{ backgroundColor: props?.headerBg }}
          className={classNames("header", styles.header)}
        >
          <nav
            className={classNames("navbar", "navbar-expand-lg", "navbar-light")}
          >
            <div className="container">
              <button
                aria-label="icone button"
                id="btn-menu-header"
                className={classNames(
                  "navbar-toggler",
                  "hamburger",
                  "hamburger--elastic",
                  styles.hamburguer
                )}
                type="button"
                data-toggle="collapse"
                data-target="#menu"
                onClick={handleOpenMenuMobile}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <Button
                aria-label="Logo"
                className={classNames("navbar-brand", styles.logoBox)}
                onClick={handleMobileClick}
              >
                <FallbackImage width={145} height={45}>
                  <img
                    alt="logo"
                    className={classNames("main-logo", styles.mainLogo)}
                    src={getCompanyLogo()}
                  />
                </FallbackImage>
              </Button>
              <NavLinks
                mobileShow={menuMobile}
                onClick={handleOpenMenuMobile}
              />
              <UserActions />
            </div>
          </nav>
          {!props?.hideSearchBar && (
            <SearchBar
              searchCallback={(term) => {
                history.push(`/busca/${term}`);
              }}
            />
          )}
        </div>
      </div>
      <div className={classes.divider} />
    </div>
  );
};

export default Header;

export const HeaderSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rect" width="100%" height={200} />
      <Skeleton variant="rect" width="100%" height={200} />
    </Box>
  );
};
