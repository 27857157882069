import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form, Col, FormGroup, Row, FormControl } from 'react-bootstrap'

import CircularProgress from '@material-ui/core/CircularProgress'

import { classNames } from 'utils'

import styles from '../index.module.scss'
// import { TextFieldController } from "components/forms";
import * as Actions from '../../../store/actions'

export default function () {
  const dispatch = useDispatch()
  const { register } = useForm()

  const partnerSettings = useSelector((state) => state.global.partner)
  const [initialLoading, setInitialLoading] = React.useState(false)
  const [amount, setAmount] = React.useState(null)

  const subscriptionCheckout = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout,
  )

  const [selectedCard, setSelectedCard] = useState('new')

  useEffect(() => {
    if (
      subscriptionCheckout.paymentInfo &&
      Object.entries(subscriptionCheckout.paymentInfo).length !== 0
    ) {
      if (subscriptionCheckout.paymentInfo.id)
        setSelectedCard(subscriptionCheckout.paymentInfo?.id)
      else setSelectedCard('new')
    }

    setTimeout(() => {
      let confirmButton = document.getElementById('confirmButton')
      if (confirmButton) {
        confirmButton.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 1200)
  }, [subscriptionCheckout.paymentInfo])

  useEffect(() => {
    if (subscriptionCheckout.giftVouchers?.available_amount) {
      if (
        subscriptionCheckout.plan.price_in_money >
        subscriptionCheckout.giftVouchers?.available_amount
      ) {
        setAmount(
          parseFloat(
            subscriptionCheckout.plan.price_in_money -
              subscriptionCheckout.giftVouchers?.available_amount,
          ),
        )
      } else {
        setAmount(0)
      }
    } else {
      setAmount(parseFloat(subscriptionCheckout.plan.price_in_money))
    }
  }, [
    subscriptionCheckout.giftVouchers.available_amount,
    subscriptionCheckout.plan.price_in_money,
  ])

  useEffect(() => {
    const mp = new window.MercadoPago(
      process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY,
    )

    function savePaymentData(paymentData) {
      dispatch(Actions.setPaymentInfo(paymentData))

      dispatch(Actions.changeStep('next'))
    }

    function processPaymentWithMercadoPago(paymentData) {
      savePaymentData(paymentData)
    }

    const validateCPF = (strCPF) => {
      strCPF = strCPF.replaceAll('.', '').replace('-', '')
      var Soma
      var Resto
      Soma = 0
      if (strCPF === '00000000000') return false

      for (var i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      Resto = (Soma * 10) % 11

      if (Resto === 10 || Resto === 11) Resto = 0
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      Resto = (Soma * 10) % 11

      if (Resto === 10 || Resto === 11) Resto = 0
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false
      return true
    }

    function initializeForm() {
      const cardForm = mp.cardForm({
        amount: amount.toString(),
        iframe: true,
        form: {
          id: 'form-subscription-checkout',
          cardNumber: {
            id: 'form-subscription-checkout__cardNumber',
            placeholder: 'Número do cartão*',
          },
          expirationDate: {
            id: 'form-subscription-checkout__expirationDate',
            placeholder: 'MM/YY*',
          },
          securityCode: {
            id: 'form-subscription-checkout__securityCode',
            placeholder: 'Código de segurança*',
          },
          cardholderName: {
            id: 'form-subscription-checkout__cardholderName',
            placeholder: 'Nome impresso no cartão*',
          },
          issuer: {
            id: 'form-subscription-checkout__issuer',
            placeholder: 'Banco emissor',
          },
          installments: {
            id: 'form-subscription-checkout__installments',
            placeholder: 'Parcelas',
          },
          identificationType: {
            id: 'form-subscription-checkout__identificationType',
            placeholder: 'Tipo de documento',
          },
          identificationNumber: {
            id: 'form-subscription-checkout__identificationNumber',
            placeholder: 'CPF*',
          },
          cardholderEmail: {
            id: 'form-subscription-checkout__cardholderEmail',
            placeholder: 'E-mail*',
          },
        },
        callbacks: {
          onFormMounted: (error) => {
            if (error)
              return console.warn('Form Mounted handling error: ', error)
            console.log('Form mounted')
          },
          onFormUnmounted: (error) => {
            if (error)
              return console.warn('Form Unmounted handling error: ', error)
            console.log('Form unmounted')
          },
          onCardTokenReceived: (error, token) => {
            if (error) return console.warn('Token handling error: ', error)

            console.log('Token available')
          },
          onSubmit: (event) => {
            event.preventDefault()

            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              identificationNumber,
              identificationType,
            } = cardForm.getCardFormData()

            var paymentData = {
              payment_method_id,
              issuer_id,
              email,
              amount,
              token,
              installments: 1,
              identificationNumber,
              identificationType,
            }

            if (validateCPF(identificationNumber)) {
              document.getElementById(
                'form-subscription-checkout__identificationNumber_invalid',
              ).style.display = 'none'
              setInitialLoading(true)
              // scrollTo("#stepper");
              processPaymentWithMercadoPago(paymentData)
            } else {
              document.getElementById(
                'form-subscription-checkout__identificationNumber_invalid',
              ).style.display = 'block'
            }
          },
          onFetching: (resource) => {
            console.log('Fetching resource: ', resource)
          },
          onError: (error, event) => {
            console.log(event, error)

            error.forEach((e) => {
              if (e.message.includes('cardNumber')) {
                document.getElementById(
                  'form-subscription-checkout__cardNumber_error',
                ).style.display = 'block'
              }

              if (e.message.includes('securityCode')) {
                document.getElementById(
                  'form-subscription-checkout__securityCode_error',
                ).style.display = 'block'
              }

              if (e.message.includes('expirationMonth')) {
                document.getElementById(
                  'form-subscription-checkout__expirationDate_error',
                ).style.display = 'block'
              }

              if (e.message.includes('expirationYear')) {
                document.getElementById(
                  'form-subscription-checkout__expirationDate_error',
                ).style.display = 'block'
              }

              if (e.message.includes('identificationNumber')) {
                document.getElementById(
                  'form-subscription-checkout__identificationNumber_error',
                ).style.display = 'block'
              }

              if (e.message.includes('cardholderEmail')) {
                document.getElementById(
                  'form-subscription-checkout__cardholderEmail_error',
                ).style.display = 'block'
              }
            })
          },
          onValidityChange: (error, field) => {
            console.log(field)
            if (error) {
              return error.forEach((e) => console.log(`${field}: ${e.message}`))
            }

            console.log(`${field} is valid`)

            if (field === 'cardNumber') {
              document.getElementById(
                'form-subscription-checkout__cardNumber_error',
              ).style.display = 'none'
            }

            if (field === 'securityCode') {
              document.getElementById(
                'form-subscription-checkout__securityCode_error',
              ).style.display = 'none'
            }

            if (field === 'expirationDate') {
              document.getElementById(
                'form-subscription-checkout__expirationDate_error',
              ).style.display = 'none'
            }

            if (field === 'identificationNumber') {
              document.getElementById(
                'form-subscription-checkout__identificationNumber_error',
              ).style.display = 'none'
            }

            if (field === 'cardholderEmail') {
              document.getElementById(
                'form-subscription-checkout__cardholderEmail_error',
              ).style.display = 'none'
            }
          },
          onReady: () => {
            console.log('CardForm ready')
          },
        },
      })
    }
    if (amount) {
      initializeForm()
    }
  }, [
    amount,
    dispatch,
    partnerSettings.payment_info.bins,
    partnerSettings.payment_info.message,
    partnerSettings.payment_info.payment_options,
  ])

  return (
    <>
      {initialLoading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : (
        <Form id="form-subscription-checkout" className="mt-2">
          <FormGroup className={styles.formGroup}>
            {selectedCard === 'new' && (
              <>
                <Row>
                  <div className="col-12">
                    <div
                      id="form-subscription-checkout__cardNumber"
                      className={styles.mercadopagoInput}
                    ></div>
                    <div
                      id="form-subscription-checkout__cardNumber_error"
                      className={styles.mercadopagoInputErrorText}
                    ></div>
                    <FormControl
                      type="hidden"
                      defaultValue={subscriptionCheckout.paymentInfo?.bin}
                      id="bin"
                      name="bin"
                      ref={register}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="col-12 pt-3">
                    <input
                      type="text"
                      id="form-subscription-checkout__cardholderName"
                      className={styles.mercadopagoInput}
                      required
                    />
                    {/* <TextFieldController
                      id="form-subscription-checkout__cardholderName"
                      label="Nome Impresso no Cartão"
                      name="holder_name"
                    /> */}
                    <div
                      id="form-subscription-checkout__cardholderName_error"
                      className={styles.mercadopagoInputErrorText}
                    ></div>
                  </div>
                </Row>
                <Row>
                  <div className="col-6 pt-3">
                    <div
                      id="form-subscription-checkout__expirationDate"
                      className={styles.mercadopagoInput}
                    ></div>
                    <div
                      id="form-subscription-checkout__expirationDate_error"
                      className={styles.mercadopagoInputErrorText}
                    ></div>
                  </div>
                  <div className="col-6 pt-3">
                    <div
                      id="form-subscription-checkout__securityCode"
                      className={styles.mercadopagoInput}
                    ></div>
                    <div
                      id="form-subscription-checkout__securityCode_error"
                      className={styles.mercadopagoInputErrorText}
                    ></div>
                  </div>
                </Row>
                <Row>
                  <div className="col-6 pt-3">
                    <select
                      id="form-subscription-checkout__issuer"
                      style={{ display: 'none' }}
                    ></select>
                    <select
                      id="form-subscription-checkout__installments"
                      style={{ display: 'none' }}
                    ></select>
                    <select
                      id="form-subscription-checkout__identificationType"
                      style={{ display: 'none' }}
                    ></select>
                    <input
                      type="email"
                      className={styles.mercadopagoInput}
                      id="form-subscription-checkout__cardholderEmail"
                      required
                    />
                    {/* <TextFieldController
                      type="email"
                      id="form-subscription-checkout__cardholderEmail"
                      label="E-mail"
                      name="email"
                    /> */}
                    <div
                      id="form-subscription-checkout__cardholderEmail_error"
                      className={styles.mercadopagoInputErrorText}
                    ></div>
                  </div>
                  <div className="col-6 pt-3">
                    <input
                      type="number"
                      className={styles.mercadopagoInput}
                      id="form-subscription-checkout__identificationNumber"
                      required
                    />
                    {/* <TextFieldController
                      id="form-subscription-checkout__identificationNumber"
                      label="CPF"
                      name="cpf"
                      mask="99999999999"
                      rules={{ pattern: /^\d{11}$/ }}
                    /> */}
                    <div
                      id="form-subscription-checkout__identificationNumber_error"
                      className={styles.mercadopagoInputErrorText}
                    ></div>
                    <div
                      id="form-subscription-checkout__identificationNumber_invalid"
                      className={styles.mercadopagoInputInvalidText}
                    ></div>
                  </div>
                </Row>
              </>
            )}
          </FormGroup>

          <Row className="mt-3">
            <Col xs={6}>
              <button
                aria-label="voltar"
                type="button"
                className={classNames(styles.backButton, 'btn-form')}
                onClick={() => {
                  dispatch(Actions.changeStep('previous'))
                  // scrollTo('#stepper')
                }}
              >
                Voltar
              </button>
            </Col>
            <Col xs={6}>
              <button
                id="form-submit"
                aria-label="continuar"
                type="submit"
                className={'custom-btn-verde btn-form'}
              >
                Continuar
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  )
}
