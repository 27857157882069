export const SET_NEXT_STEP = "[REGISTER_COMPANIES] SET NEXT STEP"
export const SET_PREV_STEP = "[REGISTER_COMPANIES] SET PREV STEP"
export const INFO_FORMS = "[REGISTER_COMPANIES] INFO FORMS"
export const SHOW_ADDRESS_FORMS = "[REGISTER_COMPANIES] SHOW ADDRESS FORMS"
export const PAYMENT_METHODS = "[REGISTER_COMPANIES] PAYMENT METHODS"
export const CLEAR_USER_INFO = "[REGISTER_COMPANIES] CLEAR_USER_INFO"
export const HAS_CONFIRM_DATAS = "[REGISTER_COMPANIES] HAS_CONFIRM_DATAS"
export const SET_ID = "[REGISTER_COMPANIES] SET_ID"
export const CLEAR_PAYMENT = "[REGISTER_COMPANIES] CLEAR_PAYMENT"

export function nextStep(stepIndex) {
  return (dispatch) => {
    dispatch({
      type: SET_NEXT_STEP,
      payload: stepIndex,
    })
  }
}

export function prevStep(stepIndex) {
  return (dispatch) => {
    dispatch({
      type: SET_PREV_STEP,
      payload: stepIndex,
    })
  }
}

export function userInfoForms(dataForms) {
  return (dispatch) => dispatch({
    type: INFO_FORMS,
    payload: dataForms,
  })
}

export function setShowAdressForm(show) {
  return (dispatch) => dispatch({
    type: SHOW_ADDRESS_FORMS,
    payload: show,
  })
}

export function paymentInfo(data) {
  return (dispatch) => dispatch({
    type: PAYMENT_METHODS,
    payload: data,
  })
}

export function clearUserInfoForms() {
  return (dispatch) => dispatch({
    type: CLEAR_USER_INFO,
  })
}

export function hasConfirmDatas(bool) {
  return (dispatch) => dispatch({
    type: HAS_CONFIRM_DATAS,
    payload: bool,
  })
}

export function setId(id) {
  return (dispatch) => dispatch({
    type: SET_ID,
    payload: id
  })
}

export function clearPayment() {
  return (dispatch) => dispatch({
    type: CLEAR_PAYMENT
  })
}
