import React from "react";

export const QuestionMarkIcon = ({
  color = "black",
  width="39",
  height="39"
}) => (
  <svg width={width} height={height} viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.6265 0C21.3708 0.106322 22.1151 0.172774 22.846 0.305677C27.3381 1.10309 31.0993 3.22954 34.1162 6.64515C36.8673 9.75508 38.4754 13.4099 38.8741 17.5432C39.3659 22.6467 38.0634 27.2717 34.9269 31.3518C32.0296 35.1263 28.2419 37.5584 23.577 38.5419C17.7558 39.7513 12.4131 38.5684 7.6685 34.9668C3.85419 32.0828 1.44864 28.2419 0.43858 23.5637C0.252516 22.7397 0.186064 21.8891 0.0664515 21.0518C0.0531612 20.9189 0.0132903 20.786 0 20.6531C0 19.8956 0 19.1247 0 18.3672C0.0265806 18.0881 0.0664515 17.8223 0.093032 17.5432C0.558192 13.3435 2.16632 9.64876 4.98386 6.51225C7.98747 3.17638 11.7088 1.10309 16.1344 0.318967C16.8654 0.186064 17.6096 0.119613 18.3539 0.0132902C19.1114 -7.62457e-08 19.869 0 20.6265 0ZM37.0268 19.5102C37.0666 9.84811 29.1855 1.96696 19.4969 1.95367C9.84811 1.94038 2.02013 9.74179 1.94038 19.457C1.87393 29.0925 9.74179 36.9869 19.457 37.0401C29.1057 37.0932 37.0002 29.2254 37.0268 19.5102Z" fill={color}/>
    <path d="M26.8198 14.6061C26.8331 15.8022 26.2616 17.0249 25.225 17.9951C24.3079 18.8457 23.2979 19.6032 22.2878 20.3475C21.6499 20.8259 21.1714 21.3974 20.9322 22.1682C20.9056 22.2613 20.8525 22.3543 20.8126 22.4473C20.374 23.6302 19.4836 23.8694 18.3937 23.6435C17.4634 23.4441 16.9318 22.4872 17.0248 21.4373C17.1445 20.1481 17.902 19.2577 18.8323 18.4735C19.5234 17.8888 20.2677 17.3306 20.9588 16.7458C21.5834 16.2142 22.115 15.5895 22.2346 14.739C22.4207 13.2372 21.6366 12.1473 20.1481 11.8948C19.0317 11.7088 17.9419 11.7752 17.1179 12.679C16.7989 13.0245 16.5996 13.4764 16.3603 13.9017C16.2009 14.1808 16.0812 14.4732 15.9218 14.739C15.3237 15.749 14.0744 16.0813 13.0511 15.5098C12.3334 15.1111 12.0277 14.2871 12.2271 13.2903C12.546 11.6955 13.5295 10.5791 14.8187 9.68864C17.9286 7.57549 22.5536 8.26658 24.813 10.0608C26.0888 11.0708 26.8065 12.5593 26.8198 14.6061Z" fill={color}/>
    <path d="M21.4904 28.1488C21.4904 29.5044 20.374 30.6208 19.0184 30.6075C17.6761 30.5942 16.5863 29.4912 16.5863 28.1488C16.5863 26.7932 17.716 25.6635 19.0583 25.6901C20.4006 25.7167 21.4904 26.8065 21.4904 28.1488Z" fill={color}/>
  </svg>
)
