import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import Avaliacao from "./images/avaliacao.png";
import Analise from "./images/analise.png";
import Lealdade from "./images/lealdade-do-consumidor.png";
import { useStyles } from "./useStyle";
import FallbackImage from "components/fallbackImage";

const knowMoreData = [
  {
    image: Lealdade,
    imageDescription: "Lealdade",
    title: "Fidelização de base de clientes",
    subtitle:
      "com aumento comprovado, o que possibilita a criação de campanhas personalizadas.",
  },
  {
    image: Analise,
    imageDescription: "Analise",
    title: "Captação e nutrição eficaz de leads",
    subtitle:
      "com curadoria de produtos e serviços sob medida para a melhoria do relacionamento e experiência com o cliente.",
  },

  {
    image: Avaliacao,
    imageDescription: "avaliação",
    title: "Análise e entendimento da base de cliente",
    subtitle:
      "o que possibilita a criação de campanhas personalizadas, além de estratégias de incentivo e retenção.",
  },
];

const KnowMore = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Typography className={classes.title}>saiba mais</Typography>
      <Typography className={classes.headline}>
        Soluções que impulsionam seu negócio
      </Typography>
      <Typography className={classes.subheadline}>
        O Clube +alegria foi desenvolvido para empresas que desejam gerar valor
        para clientes, associados e colaboradores.
      </Typography>
      <Grid item md={12} className={classes.cardContainer}>
        {knowMoreData.map((item, index) => (
          <KnowMoreItem
            key={index}
            image={item.image}
            imageDescription={item.imageDescription}
            title={item.title}
            subtitle={item.subtitle}
          />
        ))}
      </Grid>
    </Grid>
  );
};
export default KnowMore;

const KnowMoreItem = ({ image, imageDescription, title, subtitle }) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box className={classes.icon}>
        <FallbackImage width={78} height={78} type="rounded">
          <img src={image} alt={imageDescription} />
        </FallbackImage>
      </Box>
      <Typography className={classes.cardTitle}>{title}</Typography>
      <Typography className={classes.cardSubtitle}>{subtitle}</Typography>
    </Box>
  );
};
