import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import SwiperCore, { Autoplay, Scrollbar } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import useIsMobile from 'utils/useIsMobile'

import styles from './index.module.scss'

SwiperCore.use([Autoplay, Scrollbar])

const CategoriesHightlight = () => {
  const isMobile = useIsMobile()
  const categories = useSelector((state) => state.global.interestAreas)

  const [hightlight, setHighlight] = useState([])

  useEffect(() => {
    const isHightlight = []

    categories.forEach((category) => {
      category.sub_categories.forEach((sub_category) => {
        if (sub_category.highlight) {
          isHightlight.push(sub_category)
        }
      })
    })

    setHighlight(isHightlight)
  }, [categories])

  const findPrincipalCategory = (slug) => {
    const category = categories.find((category) =>
      category.sub_categories.find(
        (sub_category) => sub_category.slug === slug,
      ),
    )
    return `/categorias/${category.slug}`
  }

  return (
    <div className={styles.container}>
      <Swiper
        modules={[Autoplay, Scrollbar]}
        slidesPerView={isMobile ? 3.4 : 5}
        spaceBetween={4}
        scrollbar={{ draggable: true, hide: true }}
      >
        {hightlight.map((item) => (
          <SwiperSlide key={item.slug}>
            <div className={styles.wrapper}>
              <div
                className={styles.circle}
                onClick={() =>
                  window.open(findPrincipalCategory(item.slug), '_self')
                }
              >
                <img
                  src={item.sub_category_image_url}
                  alt={item.slug}
                  className={styles.image}
                  width='800px'
                />
              </div>
              <p className={styles.name}>{item.name}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default CategoriesHightlight
