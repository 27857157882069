import React from 'react'

const FilterIcon = (
  { color="#000", width='40px', height='40px', ...props }
) => (
  <svg width={width} height={height} viewBox="0 0 153 114" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100.395 19.668C84.7745 19.668 69.1536 19.668 53.5327 19.668C52.3596 19.668 51.8039 19.9762 51.3099 21.086C48.5933 27.0663 43.9008 30.3338 37.2326 30.4571C30.9966 30.5805 25.5015 26.8813 23.0318 21.0243C22.5996 20.0379 22.1056 19.668 21.056 19.7296C15.6844 19.7913 10.3745 19.7913 5.0029 19.7296C1.36008 19.668 -0.862653 16.7087 0.372201 13.626C1.11311 11.7765 2.59494 10.79 4.94116 10.79C10.3128 10.79 15.6227 10.7284 20.9943 10.79C22.1056 10.79 22.5996 10.4201 23.0935 9.37202C25.8102 3.33009 30.5026 0.000856775 37.1091 0.000856775C43.7156 -0.0607956 48.4698 3.20678 51.2482 9.18706C51.8039 10.4201 52.3596 10.79 53.7179 10.79C84.7745 10.7284 115.769 10.79 146.826 10.79C147.505 10.79 148.246 10.79 148.925 10.8517C151.086 11.0366 152.815 12.7629 152.938 14.9207C153.124 17.0786 151.704 19.0515 149.543 19.5447C148.74 19.7296 147.876 19.7296 147.011 19.7296C131.39 19.668 115.893 19.668 100.395 19.668ZM37.2326 8.8788C33.7133 8.8788 30.8731 11.5915 30.8731 15.1057C30.8114 18.6815 33.6515 21.5175 37.2326 21.5175C40.7519 21.5175 43.5304 18.7432 43.5304 15.229C43.5304 11.7148 40.7519 8.8788 37.2326 8.8788Z" fill={color}/>
    <path d="M100.704 103.084C85.0213 103.084 69.3387 103.084 53.656 103.084C52.4829 103.084 51.8655 103.33 51.3098 104.563C48.5931 110.544 43.9007 113.811 37.2325 113.935C30.9965 114.058 25.4396 110.359 23.0317 104.502C22.5995 103.392 22.0438 103.084 20.9324 103.145C15.4373 103.207 9.88046 103.207 4.38535 103.145C2.10087 103.145 0.372077 101.542 0.0633632 99.3846C-0.24535 97.4117 0.866018 95.3155 2.78004 94.6373C3.64444 94.3291 4.57058 94.2674 5.43498 94.2674C10.4979 94.2058 15.6225 94.2058 20.6854 94.2674C21.9203 94.2674 22.5377 93.9591 23.0317 92.7877C25.7483 86.7458 30.5025 83.5399 37.109 83.5399C43.6537 83.5399 48.4079 86.8075 51.1246 92.7261C51.6803 93.9592 52.3594 94.2674 53.5943 94.2674C84.6509 94.2674 115.646 94.2674 146.702 94.2674C147.567 94.2674 148.431 94.2674 149.234 94.4524C151.457 94.8839 152.938 96.9185 152.753 99.138C152.568 101.296 150.901 103.022 148.74 103.207C148.122 103.269 147.443 103.269 146.826 103.269C131.514 103.084 116.078 103.084 100.704 103.084ZM37.109 104.933C40.6283 104.933 43.4685 102.221 43.5302 98.7064C43.592 95.1305 40.7518 92.2945 37.1707 92.2945C33.6514 92.2945 30.873 95.1305 30.873 98.5831C30.8112 102.097 33.6514 104.933 37.109 104.933Z" fill={color}/>
    <path d="M90.1459 61.345C87.491 68.0651 82.7368 72.0725 75.4511 72.1342C68.1038 72.1958 63.2878 68.1884 60.5711 61.345C59.9537 61.345 59.3363 61.345 58.6571 61.345C41.0605 61.345 23.4638 61.345 5.86711 61.345C5.24968 61.345 4.694 61.345 4.07657 61.2834C1.73035 61.0367 -0.0601931 59.0639 0.00154962 56.7211C0.0632923 54.4399 1.79209 52.5904 4.13831 52.3438C4.694 52.2821 5.31142 52.2821 5.92885 52.2821C23.5255 52.2821 41.1222 52.2821 58.7189 52.2821C59.3363 52.2821 60.0155 52.2821 60.6329 52.2821C63.3496 45.5003 68.1655 41.4929 75.5129 41.4929C82.7985 41.5546 87.5527 45.5003 90.2076 52.2821C90.8251 52.2821 91.5042 52.2821 92.1217 52.2821C110.459 52.2821 128.797 52.2821 147.196 52.2821C147.814 52.2821 148.369 52.2821 148.987 52.3438C151.271 52.5904 153 54.5016 153 56.7827C153 59.0639 151.271 60.9751 148.987 61.2217C148.431 61.2834 147.814 61.2834 147.196 61.2834C128.859 61.2834 110.521 61.2834 92.1217 61.2834C91.3808 61.345 90.7633 61.345 90.1459 61.345ZM69.0299 56.9677C69.0299 60.4202 71.9318 63.2562 75.3894 63.2562C78.847 63.2562 81.6872 60.4202 81.6872 56.906C81.6872 53.3302 78.7852 50.4325 75.2659 50.5558C71.8083 50.6175 69.0299 53.4535 69.0299 56.9677Z" fill={color}/>
  </svg>
)

export { FilterIcon }
