import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";

const FallbackImage = ({
  alt,
  width,
  height,
  type = "rectangular",
  children,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div>
      {!imageLoaded && (
        <Skeleton
          variant={type}
          width={width}
          height={height}
          sx={{ borderRadius: 2 }}
        />
      )}
      {React.cloneElement(children, {
        style: {
          display: imageLoaded ? "inherit" : "none",
        },
        alt,
        onLoad: handleImageLoad,
      })}
    </div>
  );
};

export default FallbackImage;
