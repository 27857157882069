import React from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import Cookies from 'universal-cookie'
import LazyLoad from 'react-lazyload'

import { classNames, scrollTo } from 'utils'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'

import MuiAlert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Rating from '@material-ui/lab/Rating'

import { performOrderSurvey } from 'services/api'
import { CircularProgress, Divider } from '@material-ui/core'
import Total from '../ReviewProducts/Total'
import FinishProductList from '../FinishProductList'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function () {
  const user = useSelector((state) => state.user.info)

  const usePix = useSelector(({ orderCheckout }) => orderCheckout.usePix)

  const useAme = useSelector(({ orderCheckout }) => orderCheckout.useAme)

  const usePicPay = useSelector(({ orderCheckout }) => orderCheckout.usePicPay)

  const processResult = useSelector(
    ({ orderCheckout }) => orderCheckout.processResult,
  )

  const cookies = new Cookies()

  React.useEffect(() => {
    scrollTo('#header')
    localStorage.removeItem('AU_order')
  }, [])

  if (user) {
    cookies.remove('AU_cart_' + user.id)
  }

  function RenderPaymentMethod() {
    if (usePix) {
      return (
        <>
          <WaitingForPaymentStatus data={processResult} />
          <FinishProductList />
          <Divider />
          <Total />
          <Survey orderID={processResult.order_id} />
        </>
      )
    }

    if (useAme) {
      return (
        <>
          <WaitingForPaymentStatusAmeDigital data={processResult} />
          <FinishProductList />
          <Divider />
          <Total />
          <Survey orderID={processResult.order_id} />
        </>
      )
    }

    if (usePicPay) {
      return (
        <>
          <WaitingForPaymentStatusPicPay data={processResult} />
          <FinishProductList />
          <Divider />
          <Total />
          <Survey orderID={processResult.order_id} />
        </>
      )
    }

    return (
      <>
        <ApprovedStatus data={processResult} />
        <FinishProductList />
        <Divider />
        <Total />
        <Survey orderID={processResult.order_id} />
      </>
    )
  }

  return (
    <div className={styles.rootContainer}>
      <Container lg={12} className={classNames(styles.finalizacaoPage)}>
        <div>
          {processResult === null && (
            <div className="text-center">
              <CircularProgress />
            </div>
          )}
          <div className={styles.pedidoContainer}>
            {processResult && (
              <>
                {processResult.status === 'Rejeitado' || processResult.error ? (
                  <RejectedStatus data={processResult} />
                ) : (
                  <>
                    {RenderPaymentMethod()}
                    {/* {usePix ? (
                      <>
                        <WaitingForPaymentStatus data={processResult} />

                        <Survey orderID={processResult.order_id} />
                      </>
                    ) : useAme ? (
                      <>
                        <WaitingForPaymentStatusAmeDigital
                          data={processResult}
                        />
                        <Survey orderID={processResult.order_id} />
                      </>
                    ) : (
                      <>
                        <ApprovedStatus data={processResult} />
                        <Survey orderID={processResult.order_id} />
                      </>
                    )} */}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {/* <Table borderless className={classNames(styles.tableCheckout)}>
          <tbody>
            {cart.map((product, key) => (
              <tr className={styles.carrinhoBorderProducts} key={key}>
                <td>
                  <small>{product.quantity}x</small> {product.name}
                </td>
                <td className={styles.checkoutPrice}>
                  {formatMonetaryValue(product.value * product.quantity)}
                </td>
              </tr>
            ))}
            {useGiftVouchers && (
              <tr>
                <td>Vale-Presente</td>
                <td className={styles.checkoutPrice}>
                  {formatMonetaryValue(orderSubtotal - orderFinalValue)}
                </td>
              </tr>
            )}

            <tr>
              <td>
                <strong>TOTAL</strong>
              </td>
              <td className={styles.checkoutPrice}>
                {paymentInfo?.installments > 1
                  ? formatMonetaryValue(
                      paymentInfo?.installments_value *
                        paymentInfo?.installments,
                    )
                  : formatMonetaryValue(orderFinalValue)}
                {paymentInfo?.installments > 1 && (
                  <small>
                    {' '}
                    ({paymentInfo?.installments}x de
                    {formatMonetaryValue(paymentInfo?.installments_value)})
                  </small>
                )}
              </td>
            </tr>
          </tbody>
        </Table> */}
      </Container>
    </div>
  )
}

const RejectedStatus = ({ data }) => {
  // if (data.error) {
  //   return (
  //     <div>
  //       <p className="mb-3 text-center h5">
  //         <strong>ATENÇÃO! SEU PEDIDO FOI REJEITADO</strong>
  //       </p>
  //       <p className="mb-3 text-center txt-orange">NÚMERO DO PEDIDO</p>
  //       <h2 className="confirmation-number text-center mb-3">{data.id}</h2>
  //       <p className="text-center">
  //         <strong>Motivo: {data.error}</strong>
  //       </p>
  //     </div>
  //   );
  // } else {
  return (
    <div>
      <p className="mb-3 text-center h5">
        <strong>ATENÇÃO! Não foi possível realizar o seu pedido</strong>
      </p>
      <p className="mb-3 text-center txt-orange">NÚMERO DO PEDIDO</p>
      <h2 className="confirmation-number text-center mb-3">{data.id}</h2>
      <p className="text-center">
        <strong>Por favor tente novamente!</strong>
      </p>
    </div>
  )
  // }
}

const ApprovedStatus = ({ data }) => (
  <div className={styles.ccWrapper}>
    <p className={styles.ccTitle}>
      Parabéns seu pedido foi recebido com sucesso!
    </p>
    {/* <p className={classNames('mb-3', 'text-center', styles.txtOrange)}>
      NÚMERO DO PEDIDO
    </p>
    <h2
      className={classNames(styles.confirmationNumber, 'text-center', 'mb-3')}
    >
      {data.id}
    </h2>
    <p className="text-center">
      <strong>
        Agora é com a gente! Você pode acompanhar o status da sua solicitação
        através da área{' '}
        <Link className={styles.txtOrange} to="/usuario/meus-pedidos">
          Meus Pedidos
        </Link>
        , ou se preferir, através do seu e-mail.
      </strong>
    </p> */}
  </div>
)

const WaitingForPaymentStatus = ({ data }) => {
  const isApp = useSelector((state) => state.behavior.isApp)
  const [copyEffect, setCopyEffect] = React.useState(false)

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setCopyEffect(false)
  }

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)

    setCopyEffect(true)
  }

  return (
    <div className={styles.pixWrapper}>
      <p className={styles.pixTitle}>
        Parabéns seu pedido foi recebido com sucesso!
      </p>
      <p className={styles.orderNumber}>NÚMERO DO PEDIDO</p>
      <h2 className={styles.orderNumber}>{data.id}</h2>

      <div className="d-flex flex-column align-items-center">
        <p className={styles.text}>
          Para realizar o pagamento via PIX, escaneie o código no App do seu
          banco para pagar.
        </p>
        <LazyLoad once>
          <img
            src={data.qrcode_url}
            className={styles.qrCodeImg}
            alt="QR Code PIX"
          />
        </LazyLoad>

        {isApp ? (
          <span>{data.qrcode_text_url}</span>
        ) : (
          <Button
            aria-label="copiar código"
            variant="outlined"
            className={styles.btnCopy}
            sx={{
              marginBottom: '16px',
            }}
            onClick={() => copyToClipboard(data.qrcode_text_url)}
          >
            Copiar código
          </Button>
        )}

        <p className={styles.text}>
          Após o pagamento você pode acompanhar o status da sua solicitação
          através da área{' '}
          <Link className={styles.txtOrange} to="/usuario/meus-pedidos">
            Meus Pedidos
          </Link>
          , ou se preferir, através do seu e-mail.
        </p>
        <p className={styles.text}>
          IMPORTANTE: O QR Code ficará disponível para pagamento por 24 horas.
          Após esse período o pedido será cancelado!
        </p>
      </div>

      <Snackbar
        open={copyEffect}
        autoHideDuration={2000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="success">
          Código copiado para área de transferência.
        </Alert>
      </Snackbar>
    </div>
  )
}

const Survey = (props) => {
  const [shoppingExperience, setShoppingExperience] = React.useState(null)
  const [willRecommend, setWillRecommend] = React.useState(null)
  const [improvements, setImprovements] = React.useState([])
  const [finishSurvey, setFinishSurvey] = React.useState(null)
  const [finishMessage, setFinishMessage] = React.useState(
    'Obrigado, sua respostas nos ajudam a melhorar e garantir mais alegria para todos!',
  )

  const handleImproveChoices = (event) => {
    if (improvements.includes(event.target.value)) {
      var newArray = improvements.filter((value) => {
        return value !== event.target.value
      })
      setImprovements(newArray)
    } else {
      setImprovements([...improvements, event.target.value])
    }
  }

  const completeSurvey = () => {
    if (props.orderID) {
      performOrderSurvey({
        order_survey: {
          shopping_experience: shoppingExperience,
          improvements: improvements,
          will_recommend: willRecommend,
          order_id: props.orderID,
        },
      }).then((res) => {
        if (res.success) {
          // scrollTo('.header')
          setFinishSurvey(true)
        } else {
          setFinishSurvey(true)
          setFinishMessage(
            'Infelizmente ocorreu um problema ao enviar suas respostas.',
          )
        }
      })
    } else {
      setFinishSurvey(true)
      setFinishMessage(
        'Infelizmente ocorreu um problema ao enviar suas respostas.',
      )
    }
  }

  React.useEffect(() => {
    if (shoppingExperience === 5) {
      setImprovements([])
    }
  }, [shoppingExperience])

  return (
    <div className={styles.surveyBox}>
      {finishSurvey ? (
        <div className={styles.surveyInnerBox}>
          <p>
            <strong>{finishMessage}</strong>
          </p>
        </div>
      ) : (
        <>
          <div className={styles.surveyInnerBox}>
            <p className={styles.surveyTitle}>
              Como foi sua experiência de compra?
            </p>
            <Rating
              name="shopping-experience"
              value={shoppingExperience}
              onChange={(event, newValue) => {
                setShoppingExperience(newValue)
              }}
              className="mt-3 mb-3"
            />
          </div>
          {shoppingExperience && (
            <>
              {shoppingExperience < 5 && (
                <div className={styles.surveyInnerBox}>
                  <p>
                    <strong>O que podemos melhorar?</strong>
                  </p>
                  <FormControl component="fieldset" className="mt-3 mb-3">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={improvements.includes('best_discounts')}
                            onChange={handleImproveChoices}
                            name="firstOption"
                            value="best_discounts"
                          />
                        }
                        label="Melhores descontos e promoções"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={improvements.includes('customer_service')}
                            onChange={handleImproveChoices}
                            name="secondOption"
                            value="customer_service"
                          />
                        }
                        label="Atendimento e serviço ao cliente"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={improvements.includes('site_navigation')}
                            onChange={handleImproveChoices}
                            name="thirdOption"
                            value="site_navigation"
                          />
                        }
                        label="Navegação do site"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={improvements.includes('payments_type')}
                            onChange={handleImproveChoices}
                            name="fourthOption"
                            value="payments_type"
                          />
                        }
                        label="Formas de pagamento"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              )}
              <div className={styles.surveyInnerBox}>
                <p>
                  <strong>
                    Em uma escala de 0 a 10, qual a chance de você indicar o
                    +Alegria para um amigo?
                  </strong>
                </p>
                <Rating
                  name="will-recommend"
                  value={willRecommend}
                  max={10}
                  onChange={(event, newValue) => {
                    setWillRecommend(newValue)
                  }}
                  className="mt-3 mb-3"
                />
              </div>
              {willRecommend && (
                <div className={styles.surveyInnerBox}>
                  <Button
                    aria-label="enviar"
                    variant="outlined"
                    onClick={() => {
                      completeSurvey()
                    }}
                  >
                    Enviar
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

const WaitingForPaymentStatusAmeDigital = ({ data }) => {
  const [copyEffect, setCopyEffect] = React.useState(false)

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setCopyEffect(false)
  }

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)

    setCopyEffect(true)
  }
  return (
    <div className={styles.ameWrapper}>
      <p className={styles.ameTitle}>
        Parabéns seu pedido foi recebido com sucesso!
      </p>
      <p className={styles.orderNumber}>NÚMERO DO PEDIDO</p>
      <h2 className={styles.orderNumber}>{data.id}</h2>

      <div className="d-flex flex-column align-items-center">
        <b className={styles.text}>Pague com Ame</b>
        <p className={styles.text}>
          Abra o AME em seu telefone e escaneie o código abaixo.
        </p>
        <LazyLoad once>
          <img
            className={styles.qrcode}
            src={data.qrcode_url}
            alt="QR Code Ame Digital"
          />
        </LazyLoad>

        <Button
          aria-label="copiar código"
          variant="outlined"
          className={styles.btnCopy}
          sx={{ marginBottom: '16px' }}
          onClick={() => copyToClipboard(data.qrcode_text_url)}
        >
          Copiar código
        </Button>
      </div>

      <div className={styles.container}>
        {/* <div className={styles.paymentValues}>
          <strong>Valor da compra:</strong>
          <strong>
            <span>{formatMonetaryValue(data.paid_value)}</span>
          </strong>
        </div> */}

        <LazyLoad once>
          <img
            className={styles.qrcode}
            src={data.qrcode_url}
            alt="QR Code Ame Digital"
          />
        </LazyLoad>
      </div>
      <p className={styles.text}>
        Após o pagamento você pode acompanhar o status da sua solicitação
        através da área{' '}
        <Link className={styles.txtOrange} to="/usuario/meus-pedidos">
          Meus Pedidos
        </Link>
        , ou se preferir, através do seu e-mail.
      </p>
      <p className={styles.text}>
        Se tiver algum problema com a leitura do QR code, atualize o aplicativo.
      </p>
      <p className={styles.text}>
        IMPORTANTE: O QR Code ficará disponível para pagamento por 24 horas.
        Após esse período o pedido será cancelado!
      </p>
      <Snackbar
        open={copyEffect}
        autoHideDuration={2000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="success">
          Código copiado para área de transferência.
        </Alert>
      </Snackbar>
    </div>
  )
}

const WaitingForPaymentStatusPicPay = ({ data }) => {
  const isApp = useSelector((state) => state.behavior.isApp)
  const [copyEffect, setCopyEffect] = React.useState(false)

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setCopyEffect(false)
  }

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)

    setCopyEffect(true)
  }

  return (
    <div className={styles.picPayWrapper}>
      {/* <div class={styles.logoPicPay}>
        <svg xmlns="http://www.w3.org/2000/svg" id="svg" viewBox="0 0 129 44">
          <path
            class="logo"
            d="M28 34.3h5.8V17.4H28V34.3zM35.8 5.9h-3.9v3.9h3.9V5.9zM13.9 7.8H8.3v4.9h5.2c3.3 0 5.2 1.6 5.2 4.6 0 3-1.9 4.7-5.2 4.7H8.3v-9.2H2.5v21.5h5.8v-7.4h5.5c6.7 0 10.6-3.6 10.6-9.8C24.4 11.3 20.6 7.8 13.9 7.8zM39.7 2H28v11.7h11.7V2zM37.8 11.7H30V3.9h7.8V11.7zM71.8 7.8h-5.3v4.9h5c3.3 0 5.2 1.6 5.2 4.6 0 3-1.9 4.7-5.2 4.7h-5v-9.2h-5.8v21.5h5.8v-7.4h5.3c6.7 0 10.6-3.6 10.6-9.8C82.4 11.3 78.5 7.8 71.8 7.8zM120.5 14l-5 12.6 -5-12.6h-6l8 20.3 -3.1 7.7h6.1l11-28H120.5zM94.5 13.9c-3.5 0-6.2 0.8-9.2 2.3l1.8 4c2.1-1.2 4.2-1.8 6.1-1.8 2.8 0 4.2 1.2 4.2 3.4v0.4h-5.6c-5 0-7.7 2.3-7.7 6.1 0 3.7 2.6 6.3 7 6.3 2.8 0 4.8-1 6.4-2.7v2.2h5.7l0-13.2C103 16.6 99.9 13.9 94.5 13.9zM97.9 27.5c-0.6 1.7-2.3 3.1-4.7 3.1 -2 0-3.2-1-3.2-2.6 0-1.6 1.1-2.3 3.3-2.3h4.6V27.5zM48.6 29.9c-2.8 0-4.8-2.2-4.8-5.5 0-3.2 2-5.4 4.8-5.4 2 0 3.5 0.8 4.6 2.2l3.9-2.8c-1.8-2.7-4.9-4.3-8.8-4.3C42.2 14 38 18.2 38 24.4c0 6.2 4.2 10.3 10.3 10.3 4.2 0 7.3-1.7 9-4.5l-4-2.7C52.3 29.1 50.7 29.9 48.6 29.9z"
          ></path>
        </svg>
      </div> */}
      <p className={styles.picPaytitle}>
        Parabéns seu pedido foi recebido com sucesso!
      </p>
      <p className={styles.orderNumber}>NÚMERO DO PEDIDO</p>
      <h2 className={styles.orderNumber}>{data.id}</h2>

      <div className="d-flex flex-column align-items-center">
        <b className={styles.text}>Pague com PicPay</b>
        <p cclassName={styles.text}>
          Abra o PicPay em seu telefone e escaneie o código abaixo
        </p>
        <LazyLoad once>
          <img
            src={data.qrcode_url}
            className={styles.qrCodeImg}
            alt="QR Code PicPay"
          />
        </LazyLoad>

        {isApp ? (
          <span>{data.qrcode_text_url}</span>
        ) : (
          <Button
            aria-label="copiar código"
            variant="outlined"
            className={styles.btnCopy}
            sx={{ marginBottom: '16px' }}
            onClick={() => copyToClipboard(data.qrcode_text_url)}
          >
            Copiar código
          </Button>
        )}

        {/* <div className={styles.messageQrcode}>
          <p className={styles.text}>
            Se tiver algum problema com a leitura do QR code, atualize o
            aplicativo.{' '}
          </p>
          <p>
            <a
              aria-label="link para suporte com picpay"
              target="_blank"
              rel="noopener noreferrer"
              href="https://meajuda.picpay.com/hc/pt-br/articles/360045117912-Quero-fazer-a-adi%C3%A7%C3%A3o-mas-a-op%C3%A7%C3%A3o-n%C3%A3o-aparece-para-mim-E-agora-"
            >
              Saiba como atualizar o aplicativo
            </a>
          </p>
        </div> */}

        <p className={styles.text}>
          Após o pagamento você pode acompanhar o status da sua solicitação
          através da área{' '}
          <Link className={styles.txtOrange} to="/usuario/meus-pedidos">
            Meus Pedidos
          </Link>
          , ou se preferir, através do seu e-mail.
        </p>
        <p className={styles.text}>
          Se tiver algum problema com a leitura do QR code, atualize o
          aplicativo.
        </p>
        <p className={styles.text}>
          <strong>
            IMPORTANTE: O QR Code ficará disponível para pagamento por 24 horas.
            Após esse período o pedido será cancelado!
          </strong>
        </p>
      </div>

      <Snackbar
        open={copyEffect}
        autoHideDuration={2000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="success">
          Código copiado para área de transferência.
        </Alert>
      </Snackbar>
    </div>
  )
}
