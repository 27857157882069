import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: "110px 0 100px 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "1200px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        padding: "31px 0 66px 0",

        paddingBottom: "56px",
      },
    },
    headlineContainer: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
        flexDirection: "row",
      },
    },

    titleBig: {
      color: "#ED9915",
      fontSize: "64px",
      fontStyle: "normal",
      fontFamily: "NeuePlak-Black",
      fontWeight: 400,
      lineHeight: "46px",
      letterSpacing: "0.72px",
      textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        color: "#ED9915",
        textAlign: "center",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "32.212px",
        letterSpacing: "0.3px",
        textTransform: "uppercase",
        marginRight: "2px",
      },
    },
    titleSmall: {
      color: "#ED9915",
      fontSize: "36px",
      fontStyle: "normal",
      fontFamily: "NeuePlak-Black",
      fontWeight: 400,
      lineHeight: "46px",
      letterSpacing: "0.72px",
      textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        color: "#ED9915",
        textAlign: "center",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "32.212px",
        letterSpacing: "0.3px",
        textTransform: "uppercase",
      },
    },
    headline: {
      color: "#2F2F2F",
      fontSize: "36px",
      fontWeight: 700,
      lineHeight: "46px",
      letterSpacing: "2px",
      width: "78%",
      fontFamily: "NeuePlak-Bold",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        color: "#2F2F2F",
        textAlign: "center",
        fontSize: "23px",
        fontStyle: "normal",
        lineHeight: "30px",
        letterSpacing: "0px",

        fontFeatureSettings: "'clig' off, 'liga' off",
      },
    },
    subheadline: {
      marginTop: "10px",
      color: "#888",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "29.954px",
      letterSpacing: "0.6px",
      width: "69%",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        width: "75%",
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "19px",
        fontFeatureSettings: "'clig' off, 'liga' off",
      },
    },

    slide: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#f2f2f2",
      borderRadius: "22px",
      width: "618px",
      height: "574px",
      marginBottom: "30px",
      padding: "50px 0px",
      [theme.breakpoints.down("md")]: {
        justifyContent: "flex-start",

        width: "88%",
        height: "656px",
        margin: "30px 24px",
        padding: "24px 0px",
      },
    },
    centerSpaceBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cinemark: {
      width: "150px",
    },
    liga: {
      width: "117px",
      marginLeft: "100px",
      [theme.breakpoints.down("md")]: {
        marginTop: "16px",
        marginLeft: "0px",
      },
    },
    decathlon: {
      width: "158px",
      [theme.breakpoints.down("md")]: {
        marginTop: "4px",
      },
    },
    outback: {
      width: "132px",
      [theme.breakpoints.down("md")]: {
        marginTop: "32px",
      },
    },
    kinoplex: {
      width: "142px",
      [theme.breakpoints.down("md")]: {
        marginTop: "24px",
      },
    },
    ifood: {
      width: "95px",
      marginLeft: "114px",
      [theme.breakpoints.down("md")]: {
        margin: "61px 0 0 0 ",
      },
    },
    netShoes: {
      width: "144px",
      [theme.breakpoints.down("md")]: {
        marginTop: "60px",
      },
    },
    betoCarrero: {
      [theme.breakpoints.down("md")]: {
        marginTop: "10px",
      },
    },
    monica: {
      width: "97px",
      marginLeft: "129px",
      [theme.breakpoints.down("md")]: {
        margin: "0",
        marginTop: "28px",
      },
    },
    opiniao: {
      width: "97px",
      marginLeft: "105px",
      [theme.breakpoints.down("md")]: {
        margin: "35px 0px 0px 0px",
      },
    },
    estacoes: {
      width: "130px",
      margin: "0 0 0 105px",
      [theme.breakpoints.down("md")]: {
        margin: "0 0",
        marginTop: "24px",
      },
    },
    yup: {
      width: "147px",
      marginLeft: "104px",
      [theme.breakpoints.down("md")]: {
        margin: "0 0",
      },
    },
    uber: {
      width: "131px",
      marginLeft: "20px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "0px",
        marginTop: "63px",
      },
    },
    carnaRio: {
      width: "91px",
      margin: "0 0 0 90px",
      [theme.breakpoints.down("md")]: {
        margin: "0 0",
        marginTop: "65px",
      },
    },
    rir: {
      width: "213px",
      marginLeft: "-44px",
      [theme.breakpoints.down("md")]: {
        margin: "0 0",
        marginTop: "65px",
      },
    },
    giuliana: {
      marginLeft: "100px",
      width: "139px",
      [theme.breakpoints.down("md")]: {
        margin: "0 0",
        marginTop: "65px",
      },
    },
  };
});
