import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getQuizById, sendEachQuizAnswer } from 'services/api';

import { showNotificationsModal } from 'store/actions';

import StarQuiz from './StartQuiz';
import SkeletonQuiz from './Skeleton';
import QuizQuestion from './QuizQuestion';
import QuizResult from './QuizResult';

import { useStyles } from './useStyle';

const ModalQuiz = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  const [question, setQuestion] = useState({
    startQuiz: false,
    current: 0,
    limit: 0,
    questions: []
  });

  const [hasFinished, setHasFinished] = useState(false);

  const [answerResult, setAnswerResult] = useState({
    correct: '',
    correct_option: '',
    option: ''
  });

  const [finishResult, setFinishResult] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const hasQuiz = JSON.parse(localStorage.getItem('hasQuiz') || null);
    dispatch(showNotificationsModal(false));

    if (hasQuiz) {
      setOpenModal(prev => !prev);
    }

    if (hasQuiz?.quiz?.id) {
      const { quiz: { id }} = hasQuiz;

      getQuizById(id).then(({ quiz }) => {
        const { questions } = quiz;

        setQuestion(prev => ({
          ...prev,
          limit: questions.length - 1,
          questions: [...questions]
        }))
      })
    }
  }, [dispatch]);

  const clickStartQuiz = () => {
    setQuestion(prev => ({
      ...prev,
      startQuiz: !prev.startQuiz,
    }))
  }

  const closeModal = () => {
    setOpenModal(prev => !prev);
    dispatch(showNotificationsModal(true));
  }

  const clickHandleQuestion = optId => {
    setIsDisabled(prev => !prev);
    const hasQuiz = JSON.parse(localStorage.getItem('hasQuiz') || null);
    const { quiz: { id } } = hasQuiz;
    const { current, limit } = question;

    const currentAnswer = {
      id,
      question: question.questions[question.current].id,
      option: optId
    };

    sendEachQuizAnswer(currentAnswer)
      .then(({ result }) => {
        const { finished, correct, correct_option, option, info, gift_voucher } = result;

        setAnswerResult({ option, correct, correct_option });

        if (finished) {
          setFinishResult({ info, gift_voucher });

          setTimeout(() => {
            setHasFinished(prev => !prev);
          }, 3000);

          if (!info) {
            closeModal();
          }
        }

        setTimeout(() => {
          setIsLoading(true);
        }, 1000);

      })
      .catch(error => {
        setAnswerResult({
          correct: false,
          correct_option: '',
          option: optId
        })
      });

    if (current < limit) {

      setTimeout(() => {
        setIsLoading(false);

        setQuestion(prev => ({ ...prev, current: current + 1 }));

        setAnswerResult({
          correct: '',
          correct_option: '',
          option: ''
        });
        setIsDisabled(prev => !prev);
      }, 3000);
    }
  }

  return (
    <Modal
      show={openModal}
      dialogClassName="modal-100w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closeModal}
    >
      <Modal.Body className={classes.body}>
        {!question.startQuiz
          && <StarQuiz clickStart={clickStartQuiz} close={closeModal} />}

        {question.startQuiz
          && !question.questions.length
          && <SkeletonQuiz />
        }

        {question.startQuiz
          && question.questions.length
          && !hasFinished
          && (!isLoading
            ? (<QuizQuestion
                quiz={question.questions[question.current]}
                clickQuestion={clickHandleQuestion}
                answer={answerResult}
                isDisabled={isDisabled}
              />)
            : <SkeletonQuiz />
          )}

          {hasFinished
            && <QuizResult result={finishResult} btnFinish={closeModal} />
          }
      </Modal.Body>
    </Modal>
  )
}

export default ModalQuiz;
