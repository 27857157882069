import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'

import ProductsGrid from 'components/productsGrid'

import {
  getProducts,
  getUserOffers,
  getHighlights,
  getOurPartners,
} from 'services/api'
import { isLoggedIn } from 'store/selectors'

import { classNames, scrollTo } from 'utils'
import styles from './index.module.scss'
import StyledTitle from 'components/styledTitle'
import useIsMobile from 'utils/useIsMobile'
// import { FormControl, Select } from "@material-ui/core";

const OfertasHome = ({ busca, filtro }) => {
  const history = useHistory()
  const logged = useSelector(isLoggedIn)
  const [productData, setProductData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [hasPagination, setHasPagination] = useState(true)
  const [filter, setFilter] = useState({
    title: null,
    sortBy: null,
  })

  useEffect(() => {
    switch (filtro) {
      case 'recentes': {
        setFilter({ title: 'OFERTAS RECENTES', sortBy: 'created_at' })
        break
      }
      case 'destaques': {
        setFilter({ title: 'DESTAQUES', sortBy: 'highlight' })
        break
      }
      case 'para-voce': {
        setHasPagination(false)
        setFilter({ title: 'PARA VOCÊ', sortBy: 'user_offers' })
        break
      }
      case 'visitados': {
        setFilter({ title: 'MAIS VISTADAS', sortBy: 'most_visited' })
        break
      }
      case 'parceiros': {
        setFilter({
          title: 'COMPRE COM NOSSOS PARCEIROS',
          sortBy: 'most_visited',
        })
        break
      }
      case 'desconto': {
        setFilter({ title: 'MAIORES DESCONTOS', sortBy: 'biggest_discount' })
        break
      }
      default: {
        setFilter({ title: 'OFERTAS RECENTES', sortBy: 'created_at' })
        break
      }
    }

    if (busca) {
      setFilter({ title: 'RESULTADO DA BUSCA', sortBy: 'created_at' })
    }
  }, [busca, filtro])

  useEffect(() => {
    setProductData(null)
    setLoading(true)
    if (filter.sortBy) {
      switch (filter.sortBy) {
        case 'highlight': {
          getHighlights({}, page, 20)
            .then((productsApi) => {
              let products = productsApi.highlights.map((item) => {
                return item.product
              })

              setProductData({
                pagination: productsApi.pagination,
                products,
              })
            })
            .finally(() => setLoading(false))
          break
        }
        case 'user_offers': {
          getUserOffers(logged)
            .then((productsApi) => {
              setProductData({
                pagination: {
                  total: productsApi.products.length,
                  current_page: 1,
                },
                products: productsApi.products,
              })
            })
            .finally(() => setLoading(false))
          break
        }
        default: {
          getProducts(page, logged, { title_cont: busca }, 20, filter.sortBy)
            .then((productsApi) => {
              setProductData(productsApi)
            })
            .finally(() => setLoading(false))
          break
        }
      }
    }

    scrollTo('.header')
  }, [logged, busca, page, filter])

  // const changeProductSorting = useCallback((event) => {
  //   let type = event.target.value;
  //   setSortBy(type);
  //   setPage(1);
  // }, []);

  const backPage = () => {
    history.push(`/ofertas`)
  }

  return filtro === 'parceiros' ? (
    <PartnerFilter backPage={backPage} />
  ) : (
    <>
      <Container
        className={classNames('filter-container', styles.filterContainer)}
        id="section"
      >
        <StyledTitle title={filter.title} />
        {/* <span onClick={backPage} className={styles.filterLink}>
          &lt;&lt; Voltar
        </span> */}
        {/* <div className={styles.filterWrapper}>
          <FormControl variant="outlined">
            <Select
              native
              onChange={changeProductSorting}
              className={styles.sortSelect}
              inputProps={{
                className: styles.sortSelectNativeElement,
              }}
            >
              <option value={"biggest_discount"}>Maior desconto</option>
              <option value={"most_visited"}>Mais acessados</option>
              <option value={"created_at"}>Mais recentes</option>
              <option value={"price"}>Menor preço</option>
            </Select>
          </FormControl>
        </div> */}
      </Container>

      <ProductsGrid
        loading={loading}
        productsData={productData}
        currentPage={page}
        pageChangeCallback={(newPage) => setPage(newPage)}
        notFoundComponent={
          <>
            <h3 className={styles.returnNada}>
              Não há produtos que coincidam com a sua busca por{' '}
              <strong style={{ overflowWrap: 'break-word' }}>{busca}</strong>
            </h3>
            <ul className={styles.listEmpty}>
              <li>Revise a ortografia da palavra</li>
              <li>Utilize palavras mais genéricas ou menos palavras</li>
            </ul>
          </>
        }
        hasPagination={hasPagination}
      />
    </>
  )
}

OfertasHome.propTypes = {
  busca: PropTypes.string,
}

OfertasHome.defaultProps = {
  busca: '',
}

export default OfertasHome

const PartnerFilter = ({ backPage }) => {
  const logged = useSelector(isLoggedIn)
  const isMobile = useIsMobile()
  const [partners, setPartners] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null)
  const [total, setTotal] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const history = useHistory()
  const per_page = 100

  useEffect(() => {
    const numberOfPages = Math.ceil(total / per_page)
    setTotalPages(numberOfPages)
  }, [total])

  useEffect(() => {
    if (currentPage) {
      if (currentPage < totalPages) {
        setHasMore(true)
      } else if (currentPage === totalPages) {
        setHasMore(false)
      }
    }
  }, [currentPage, totalPages])

  useEffect(() => {
    getOurPartners(logged, per_page, currentPage).then((response) => {
      setPartners(response.programmes)
      setCurrentPage(response.pagination.current_page)
      setTotal(response.pagination.total)
    })
    // eslint-disable-next-line
  }, [logged])

  const fetchNextPage = () => {
    const updatePAge = currentPage + 1
    getOurPartners(logged, per_page, updatePAge).then((response) => {
      setPartners([...partners, ...response.programmes])
      setCurrentPage(response.pagination.current_page)
      setTotal(response.pagination.total)
    })
  }

  return (
    <>
      <Container
        className={classNames('filter-container', styles.filterContainer)}
        id="section"
      >
        <StyledTitle title={'COMPRE COM NOSSOS PARCEIROS'} />
        <span onClick={backPage} className={styles.filterLink}>
          &lt;&lt; Voltar
        </span>
      </Container>

      {isMobile ? (
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          {partners.map((partner) => (
            <div
              key={partner.name}
              style={{ display: 'flex', justifyContent: 'center' }}
              onClick={() => history.push(`/programa/${partner.id}`)}
            >
              <img
                src={partner.image}
                alt={partner.name}
                style={{ maxWidth: 100, cursor: 'pointer' }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            maxWidth: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '100px',
            minHeight: '500px',
          }}
        >
          {partners.map((partner) => (
            <div
              key={partner.name}
              style={{ display: 'flex', justifyContent: 'center' }}
              onClick={() => history.push(`/programa/${partner.id}`)}
            >
              <img
                src={partner.image}
                alt={partner.name}
                style={{ maxWidth: 140, cursor: 'pointer' }}
              />
            </div>
          ))}
        </div>
      )}

      {hasMore && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: 240,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 10,
            marginBottom: 32,
          }}
        >
          <button
            className={clsx(styles['btn-produto'], styles['btn-laranja'])}
            aria-label="Definir como padrão"
            onClick={() => fetchNextPage()}
          >
            Carregar mais parceiros
          </button>
        </div>
      )}
    </>
  )
}
