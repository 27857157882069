import React from 'react'
import { useSideModalState } from './SideWindow-context'
import SideWindow from "./SideWindow"

import MobileMenu from 'components/NewLayoutHeader/components/mobileMenu'
import SideWindowCart  from 'components/cart/sideWindowCart'
import { FilterSideBar } from 'pages/categories/categories-v2/components/filters/mobile/filterSideBar'

const SideWindowManager = () => {
  const { view } = useSideModalState()

  if(view === undefined) return null

  return (
    <SideWindow view={view}>
      {view === 'MOBILE_MENU' && <MobileMenu />}
      {view === 'CART_WINDOW' && <SideWindowCart />}
      {view === 'MOBILE_FILTERS' && <FilterSideBar />}
    </SideWindow>
  )
}

export default SideWindowManager
