import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from "./index.module.scss";
import { Link } from 'react-router-dom';

export default function(){
  const returnToPath = encodeURIComponent(window.location.pathname.slice(1));
  return ( 
    <Row>
      <Col className={styles.infosWrapper}>
        <h1 className={styles.sectionTitle}>Ooops! =(</h1>
        <p className='mt-4 text-center' style={{lineHeight: '28px'}}>
          O usuário que você está logado não está associado ao Clube +Alegria.<br/>
          <Link to={`/sair/${returnToPath}`} className='logoutButton'>Faça logout</Link>{' '}
          e acesse novamente a plataforma com um usuário do Clube +Alegria.
        </p>
      </Col>
    </Row>
  )
}