import React from "react";
import loadable from "@loadable/component";

import "react-multi-carousel/lib/styles.css";

import styles from "./index.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useIsMobile from "utils/useIsMobile";
import clsx from "clsx";
import { Paper } from "@material-ui/core";
import { Card } from "react-bootstrap";

const StyledTitle = loadable(() => import("components/styledTitle"), {
  fallback: <></>,
});

const ProgramsRelated = ({
  offers = [],
  program,
  noResultsComponent = null,
}) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  return (
    <div style={{ marginLeft: "auto", marginRight: "auto" }}>
      {isMobile ? (
        <div style={{ padding: "16px" }}>
          <div className={styles.desktopContainer}>
            <div className="mt-5">
              <StyledTitle title={"Promoções"} />

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                {offers.map((offer) => (
                  <Paper
                    key={offer.title}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      height: 200,
                      padding: 16,
                    }}
                  >
                    <Card.Title className={styles.cardTitle}>
                      {offer.title.length > 20
                        ? offer.title.substring(0, 20)
                        : offer.title}
                    </Card.Title>
                    <button
                      aria-label=" ACESSE AQUI"
                      onClick={() =>
                        history.push(`/programa/oferta/${offer.id}`)
                      }
                      className={clsx(
                        styles["btn-produto"],
                        styles["btn-laranja"]
                      )}
                    >
                      ACESSE AQUI
                    </button>
                  </Paper>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 950 }}>
          <div className={styles.desktopContainer}>
            <div className="mt-5">
              <StyledTitle title={"Promoções"} />

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(5, 1fr)",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                {offers.map((offer) => (
                  <Paper
                    key={offer.title}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      height: 200,
                      padding: 16,
                    }}
                  >
                    <Card.Title className={styles.cardTitle}>
                      {offer.title.length > 80
                        ? `${offer.title.substring(0, 80)}...`
                        : offer.title}
                    </Card.Title>
                    <button
                      aria-label=" ACESSE AQUI"
                      onClick={() =>
                        history.push(`/programa/oferta/${offer.id}`)
                      }
                      className={clsx(
                        styles["btn-produto"],
                        styles["btn-laranja"]
                      )}
                    >
                      Ver Oferta
                    </button>
                  </Paper>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgramsRelated;
