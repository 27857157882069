import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      background: "#F8F8F8",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      [theme.breakpoints.down("md")]: {
        paddingTop: "7px",
      },
    },
    container: {
      padding: theme.spacing(2),
      paddingBottom: "110px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "1200px",

      [theme.breakpoints.down("md")]: {
        paddingBottom: "56px",
      },
    },
    sectionContainer: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
      },
    },

    checkBackground: {
      width: "57px",
      height: "55px",
      borderRadius: "9px",
      background: "#FCF0DC",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        width: "33.5px",
        height: "32.2px",
      },
    },
    title: {
      marginTop: "100px",
      color: "#ED9915",
      textAlign: "center",
      fontFamily: "NeuePlak-Bold",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "46px",
      textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        marginTop: "58px",
        color: "#ED9915",
        fontSize: "15px",
        fontStyle: "normal",
        lineHeight: "30.195px",
      },
    },
    headline: {
      color: "#2F2F2F",
      fontSize: "36px",
      fontWeight: 900,
      lineHeight: "46px",
      width: "100%",
      fontFamily: "NeuePlak-Black",
      textAlign: "center",
      marginBottom: "62px",
      [theme.breakpoints.down("md")]: {
        fontSize: "23px",
        width: "94%",
        textAlign: "center",
        lineHeight: "30px",
        letterSpacing: "1.258px",
      },
    },
    subheadline: {
      marginTop: "72px",
      marginBottom: "16px",
      color: "#888",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "30px",
      width: "90%",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        width: "40%",
        textAlign: "center",
        lineHeight: "18.843px",
        letterSpacing: "0.42px",
        marginBottom: "8px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        width: "83%",
        textAlign: "center",
        lineHeight: "19px",
      },
    },

    check: {
      [theme.breakpoints.down("md")]: {
        width: "20px",
        height: "20px",
      },
    },
    checkMessage: {
      marginTop: "32px",
      fontFamily: "NeuePlak-Black",
      color: "#4F4F4F",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: "28.007px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "36.231px",
      transform: "/* Add your transformation here if needed */",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "14px",
      },
    },
    dashboardText: {
      color: "#FFF",
      width: "130px",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "NeuePlak-Bold",
      fontSize: "15.218px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "19.445px",
      marginTop: "32px",
      "&:nth-child(1)": {
        marginBottom: "20px",
      },

      [theme.breakpoints.down("md")]: {
        width: "75px",
        fontSize: "9px",
        lineHeight: "11.5px",
      },
    },
    videoContainer: {
      background: "#333",
      borderRadius: "22.246px",
      overflow: "hidden",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)",
    },
    video: {
      width: "100%",
      borderRadius: "22.246px",
    },
    checkContainer: {
      marginLeft: "126px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "0px",
      },
    },
    checkWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
      height: "416px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "row",
        height: "150px",
        marginBottom: "20px",
        padding: "16px",
      },
    },
    videoWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
      },
    },
    personalizationFooter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  };
});
