import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LazyLoad from "react-lazyload";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import styles from "./index.module.scss";
import { classNames } from "utils";
import { Container } from "react-bootstrap";

const HomeBanner = () => {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 650 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const homeBanners = useSelector((state) => state.global?.homeBanners);

  const openLink = (banner) => {
    if (banner.destination_url === "") return;

    if (banner.external_window) {
      window.open(banner.destination_url);
    } else {
      window.location.href = banner.destination_url;
    }
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {Array.isArray(homeBanners) && homeBanners.length > 0 && (
        <Container className={styles.customResponsiveContainer}>
          <Carousel
            responsive={responsive}
            sliderClass={classNames("m-auto", styles.reactCarouselList)}
            infinite={true}
            autoPlay={homeBanners.length > 1 ? true : false}
            autoPlaySpeed={5000}
            showDots={false}
            arrows={false}
          >
            {homeBanners.map((banner, key) => {
              return (
                <div
                  key={key}
                  className={styles.carouselItem}
                  onClick={() => openLink(banner)}
                >
                  <LazyLoad once>
                    {windowDimensions !== undefined &&
                    windowDimensions.width < 500 ? (
                      <img
                        src={banner.image_url}
                        className={styles.image}
                        alt={banner.title}
                        style={{
                          cursor:
                            banner.destination_url === ""
                              ? "initial"
                              : "pointer",
                        }}
                      />
                    ) : (
                      <img
                        src={banner.image_url}
                        className={styles.image}
                        alt={banner.title}
                        style={{
                          cursor:
                            banner.destination_url === ""
                              ? "initial"
                              : "pointer",
                        }}
                      />
                    )}
                  </LazyLoad>
                </div>
              );
            })}
          </Carousel>
        </Container>
      )}
    </>
  );
};

export default HomeBanner
