import { SvgIcon } from "@mui/material";
import React from "react";

export const ChevronRight = ({color="#2f2f2f", rotate="0deg", props}) => {
  return (
    <SvgIcon
      style={{
        transform: `rotate(${rotate})`,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
      >
        <path
          d="M7.5332 16.0186L13.2825 10.2694L7.5332 4.52014"
          stroke={color}
          strokeWidth="1.37982"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
