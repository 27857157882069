import React, { useState, useEffect } from 'react';
import { useStyles } from '../useStyle';
import { classNames } from 'utils';
import { sendEachSurveysAnswer } from 'services/api';

const SearchCheckboxInput = (props) => {
  const classes = useStyles();
  const [selects, setSelects] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (selects.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selects]);


  const handleSelect = (selectID) => {
    if (selects.length > 0) {
      const alreadySelectID = selects?.find(select => select === selectID);

      if (alreadySelectID) {
        const updatedSelectID = selects.filter(select => select !== selectID);
        setSelects(updatedSelectID);

      } else {
        setSelects((prev) => ([...prev, selectID]));
      }
    } else {
      setSelects((prev) => ([...prev, selectID]));
    }
  }

  const click = () => {
    setIsDisabled(true);
    const hasSearch = JSON.parse(localStorage.getItem('hasSearch') || null);
    const { survey: { id } } = hasSearch;

    const survey = {
      id,
      question: props.id,
      answer: selects
    }

    sendEachSurveysAnswer(survey)
      .then(({ result }) => {
        const { finished, info } = result;

        if (finished) {
          props.setFinished(info);
        }

        setSelects([]);
      })
      .catch(error => console.error(error));

    setSelects([]);
    props.nextQuestion();
  }

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        {props.title}
      </h2>
      <p className={classes.description}>
        {props.description}
      </p>
      {props?.image && (
        <img
          src={props.image}
          alt='search'
          width={100}
          className={classes.image}
        />
      )}
      {props.options.map(opt => (
        <div key={opt.id} className={classes.selectWrapper}>
          <input
            type='checkbox'
            id={opt.id}
            value={opt.id}
            className={classes.radioInput}
            onChange={() => handleSelect(opt.id)}
          />
          <label
            htmlFor={opt.id}
            className={classes.label}>
              {opt.option}
          </label>
        </div>
      ))}
      <button
        className={classNames(classes.btn,
          {[classes.disabled]: isDisabled })}
        disabled={isDisabled}
        onClick={click}
      >
        Próxima
      </button>
    </div>

  )
}

export default SearchCheckboxInput;
