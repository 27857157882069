import * as Actions from "store/actions/behavior";

const saveActiveOrderItemOnLocalStorage = (orderItem) => {
  localStorage.setItem("active_order_item", JSON.stringify(orderItem));
};

const INITIAL_STATE = {
  showLoginModal: { action: false, provider: "email", data: {} },
  showForgotPasswordModal: { action: false, provider: "email", data: {} },
  showRegisterModal: false,
  registrationTempData: null,
  showMaratonaMasterclassModal: false,
  showNotificationsModal: false,
  showVideoModal: { action: false, provider: "email" },
  isApp: false,
  activeOrderItem: null,
  activeOrder: null,
  requestErrors: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.SHOW_LOGIN_MODAL: {
      return {
        ...state,
        showLoginModal: {
          action: action.payload.show,
          provider: action.payload.provider,
          data: action.payload.data,
        },
      };
    }
    case Actions.SHOW_FORGOT_PASSWORD_MODAL: {
      return {
        ...state,
        showForgotPasswordModal: {
          action: action.payload.action,
          provider: action.payload.provider,
          data: action.payload.data,
        },
      };
    }
    case Actions.SHOW_REGISTER_MODAL: {
      return { ...state, showRegisterModal: action.payload };
    }
    case Actions.SHOW_MARATONA_MASTERCLASS_MODAL: {
      return { ...state, showMaratonaMasterclassModal: action.payload };
    }
    case Actions.SHOW_NOTIFICATIONS_MODAL: {
      return { ...state, showNotificationsModal: action.payload };
    }
    case Actions.SHOW_VIDEO_MODAL: {
      return {
        ...state,
        showVideoModal: {
          action: action.payload.show,
          provider: action.payload.provider,
        },
      };
    }
    case Actions.IS_APP: {
      return { ...state, isApp: action.payload };
    }
    case Actions.SET_REQUEST_ERRORS: {
      return { ...state, requestErrors: action.payload };
    }
    case Actions.SET_REGISTRATION_TEMP_DATA: {
      return { ...state, registrationTempData: action.payload };
    }
    case Actions.SET_ACTIVE_ORDER_ITEM: {
      saveActiveOrderItemOnLocalStorage(action.payload);
      return { ...state, activeOrderItem: action.payload };
    }
    case Actions.SET_ACTIVE_ORDER: {
      return { ...state, activeOrder: action.payload };
    }
    default: {
      return state;
    }
  }
};
