import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import StartSearch from './StartSearch';
import SearchQuestion from './SearchQuestions';
import SearchResult from './SearchResult';

import { showNotificationsModal } from 'store/actions';
import { getSurveysById } from 'services/api';

import { useStyles } from './useStyle';

const ModalSearch = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  const [question, setQuestion] = useState({
    startQuiz: false,
    current: 0,
    limit: 0,
    questions: []
  });

  const [finished, setFinished] = useState(null);

  useEffect(() => {
    const hasSearch = JSON.parse(localStorage.getItem('hasSearch') || null);
    dispatch(showNotificationsModal(false));

    if(hasSearch) {
      setOpenModal(prev => !prev);
    }

    if (hasSearch?.survey?.id) {
      const { survey : { id } } = hasSearch;

      getSurveysById(id).then(({ survey }) => {
        const { questions } = survey;

        setQuestion(prev => ({
          ...prev,
          limit: questions.length - 1,
          questions: [...questions]
        }))
      })
    }
  }, [dispatch]);

  const clickStartQuiz = () => {
    setQuestion(prev => ({
      ...prev,
      startQuiz: !prev.startQuiz,
    }))
  }

  const closeModal = () => {
    setOpenModal(prev => !prev);
    dispatch(showNotificationsModal(true));
  }

  const nextQuestion = () => {
    const { current, limit } = question;

    if (current === limit) return;

    setQuestion(prev => ({ ...prev, current: prev.current + 1 }));
  }

  return (
    <Modal
      show={openModal}
      dialogClassName="modal-100w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closeModal}
    >
      <Modal.Body className={classes.body}>
        {!question.startQuiz
          && <StartSearch clickStart={clickStartQuiz} close={closeModal} />
        }
        {question.startQuiz
          && question.questions.length
          && !finished
          && (
            <SearchQuestion
              question={question.questions[question.current]}
              nextQuestion={nextQuestion}
              setFinished={setFinished}
            />
          )}
          {finished
            && <SearchResult info={finished} close={closeModal} setModalType={props.setModalType}/>
          }
      </Modal.Body>
    </Modal>
  )
}

export default ModalSearch;
