import { SvgIcon } from "@mui/material";
import React from "react";

export const Arrow = ({width="12px", rotate="none", fill="#ED9915", ...props}) => (
  <SvgIcon
    {...props}
    style={{
      transition: "transform 0.5s ease-in-out",
      transform: props.animate ? "rotate(180deg)" : rotate,
      width,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M10.3345 6.72358C10.7341 6.72358 11.0581 6.39962 11.0581 6C11.0581 5.60038 10.7341 5.27642 10.3345 5.27642L10.3345 6.72358ZM0.488349 5.48835C0.205772 5.77093 0.205772 6.22907 0.488349 6.51165L5.0932 11.1165C5.37578 11.3991 5.83392 11.3991 6.1165 11.1165C6.39908 10.8339 6.39908 10.3758 6.1165 10.0932L2.0233 6L6.1165 1.9068C6.39908 1.62422 6.39908 1.16608 6.1165 0.883499C5.83392 0.600923 5.37578 0.600923 5.0932 0.883499L0.488349 5.48835ZM10.3345 5.27642L0.999999 5.27642L0.999999 6.72358L10.3345 6.72358L10.3345 5.27642Z"
        fill={fill}
      />
    </svg>
  </SvgIcon>
);
