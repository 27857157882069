import  Tickets  from "../assets/imagens/tickes2.svg";
import Run from "../assets/imagens/run2.svg";
import Location from "../assets/imagens/icon-location2.svg";
import Uber from "../assets/imagens/icon-uber2.svg";
import Ifood from "../assets/imagens/icon-ifood2.svg";
import Retro from "../assets/imagens/retro.svg";

import IconTickets from "../assets/imagens/icon-tickes3.svg";
import IconCalendar from "../assets/imagens/icon-calendar3.svg";
import IconPinLocation from "../assets/imagens/icon-pin-location3.svg";

import ArcoplexIcon from "../assets/imagens/icon-arcoplex2.svg";
import CenterIcon from "../assets/imagens/icon-center2.svg";
import CineIcon from "../assets/imagens/icon-cine2.svg";
import CineArtIcon from "../assets/imagens/icon-cineart2.svg";
import CinemaxIcon from "../assets/imagens/icon-cinemax3.svg";
import CineplexIcon from "../assets/imagens/icon-cineplex3.svg";
import CinepolisIcon from "../assets/imagens/icon-cinepolis2.svg";
import CinesysIcon from "../assets/imagens/icon-cinesys2.svg";
import CircuitoIcon from "../assets/imagens/icon-circuito2.svg";
import GncIcon from "../assets/imagens/icon-gnc2.svg";
import ItauIcon from "../assets/imagens/icon-itau3.svg";
import KinoIcon from "../assets/imagens/icon-kino2.svg";
import MoviecoIcon from "../assets/imagens/icon-movieco2.svg";
import PlayIcon from "../assets/imagens/icon-play2.svg";
import TopazioIcon from "../assets/imagens/icon-topazio2.svg";
import UciIcon from "../assets/imagens/icon-uci.svg";
import GrupoCineIcon from "../assets/imagens/icon-grupo-cine.svg";

import LogoOi from "../assets/imagens/icon-oi2.svg";
import Check from "../assets/imagens/icon-circle-check2.svg";
import Walet from "../assets/imagens/icon-walet2.svg";

export const discoutList = [
  {
    slug: 'tickes',
    component: Tickets,
    name: 'Shows',
  },
  {
    slug: 'run',
    component: Run,
    name: 'Corridas',
  },
  {
    slug: 'location',
    component: Location,
    name: 'Parques e Passeios',
  },
  {
    slug: 'uber',
    component: Uber,
    name: 'Uber',
  },
  {
    slug: 'ifood',
    component: Ifood,
    name: 'iFood',
  },
  {
    slug: 'liga-retro',
    component: Retro,
    name: 'Liga Retrô',
  },
]


export const outherDiscontList = [
  {
    slug: 'quantos-quiser',
    component: IconTickets,
    name: 'QUANTOS QUISER'
  },
  {
    slug: 'quando-quiser',
    component: IconCalendar,
    name: 'QUANDO QUISER'
  },
  {
    slug: 'onde-quiser',
    component: IconPinLocation,
    name: 'ONDE QUISER'
  },
]

export const moviesIconsList = [
  {
    slug: 'icon-kino',
    path: KinoIcon,
  },
  {
    slug: 'icon-center',
    path: CenterIcon,
  },
  {
    slug: 'icon-cinesys',
    path: CinesysIcon,
  },
  {
    slug: 'icon-itau',
    path: ItauIcon,
  },
  {
    slug: 'icon-arcoplex',
    path: ArcoplexIcon,
  },
  {
    slug: 'icon-grupo-cine',
    path: GrupoCineIcon,
  },
  {
    slug: 'icon-play',
    path: PlayIcon,
  },
  {
    slug: 'icon-cine',
    path: CineIcon,
  },
  {
    slug: 'icon-cinepolis',
    path: CinepolisIcon,
  },
  {
    slug: 'icon-cineplex',
    path: CineplexIcon,
  },
  {
    slug: 'icon-cineart',
    path: CineArtIcon,
  },
  {
    slug: 'icon-cinemax',
    path: CinemaxIcon,
  },
  {
    slug: 'icon-uci',
    path: UciIcon,
  },
  {
    slug: 'icon-topazio',
    path: TopazioIcon,
  },
  {
    slug: 'icon-gnc',
    path: GncIcon,
  },
  {
    slug: 'icon-movieco',
    path: MoviecoIcon,
  },
  {
    slug: 'icon-circuito',
    path: CircuitoIcon,
  },
]

export const worksList = [
  {
    slug: 'logo-oi',
    icon: LogoOi,
    title: 'O que?',
    text: `<p>Todos os clientes <span><b><span>OI</span> FIBRA</b></span> ativos na operadora,
    exceto planos empresariais, são elegíveis.</p>`
  },
  {
    slug: 'check-icon',
    icon: Check,
    title: 'Como?',
    text: `<p>Acesse com <span>login/senha Minha Oi.</span>
    No primeiro acesso é preciso <span>completar um cadastro no programa e aceitar o termo de adesão.</span>
    Depois é só aproveitar parcerias imperdíveis e exclusivas para ofertas em cinemas, entretenimento, experiências,
    serviços, produtos e muito mais!</p>`
  },
  {
    slug: 'walet-icon',
    icon: Walet,
    title: 'Formas de pagamento.',
    text: `<p>Finalize suas compras para aproveitar as ofertas com <span>Pix, Ame ou Picpay.</span> O voucher ficará
    disponível em <span>"Meus Pedidos".</span></p>`
  }
]
