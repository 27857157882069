import React from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { useStyles } from "./useStyle";

import CtaButton from "components/ctaButton";

import bannerImage from "./assets/group.png";
import layer from "./assets/layer.png";
import { Box } from "@mui/material";

const Banner = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Container className={classes.bannerFooter}>
        <img src={bannerImage} alt="banner" className={classes.bannerImage} />
        <img src={layer} alt="banner" className={classes.layer} />
        <Box
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
          }}
        >
          <Box style={{ marginLeft: isMobile ? "8rem" : "28rem" }}>
            <Typography className={classes.footerText}>
              SIMPLES E MODERNO!
            </Typography>
            <Typography
              className={classes.footerSubText}
              dangerouslySetInnerHTML={{
                __html: "Faça a diferença para seu cliente!",
              }}
            />
          </Box>
          <Container
            style={{
              display: "flex",
              justifyContent: isMobile ? "flex-start" : "center",
            }}
          >
            <CtaButton
              onClick={() => history.push("/planos")}
              color="#ED9915"
              style={{
                background: "#fff",
                color: "#ED9915",
                marginTop: isMobile ? "0.5rem" : 0,
                marginLeft: isMobile ? "7rem" : 0,
              }}
            />
          </Container>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;
