import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactLoading from "react-loading";

import {
  getUser,
  userLogin,
  userLogout,
  setPartnerSettings,
  setGlobalSettings,
  setHeaders,
} from "store/actions";

import {
  linkRequest,
  getPartnerSettings,
  getSettings,
} from "services/api/index";

const LinkLogin = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = decodeURIComponent(props.match.params.token);

  const containerStyle = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  };

  linkRequest({
    token,
  })
    .then((response) => {
      if (response.success) {
        dispatch(userLogout());
        dispatch(userLogin(response));
        dispatch(setHeaders(response.headers));
        setTimeout(() => {
          getSettings().then((result) => {
            dispatch(setGlobalSettings(result));
          });
          getPartnerSettings().then((result) => {
            dispatch(setPartnerSettings(result));
          });
          dispatch(getUser());
          history.push("/ofertas");
        }, 2000);
      } else {
        if (response.errors) {
          console.log(response.errors);
        } else {
          console.log([response.error]);
        }
      }
    })
    .catch((err) => {
      console.log(["Erro ao se conectar com o servidor"]);
    });

  return (
    <div style={containerStyle}>
        <ReactLoading type={ "bars"} color={ "#A9A9A9"} height={40} width={40} />
      </div>);
};

export default LinkLogin;
