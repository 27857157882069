import React, {
  createContext,
  useReducer,
  useContext
} from 'react'


const initialState = {
  view: undefined,
  isOpen: false,
  data: null,
}

const reducer = (state, action) => {
  switch(action.type) {
    case 'OPEN':
      return {
        ...state,
        view: action.view,
        data: action.payload,
        isOpen: true,
      }
    case 'CLOSE':
      return {
        ...state,
        isOpen: false,
        view: undefined,
      }
    case 'SET_DATA':
      return {
        ...state,
        data: action.payload
      }
    case 'CLEAR_DATA':
      return {
        ...state,
        data: null
      }
    default: {
      break
    }
  }
}

const StateContext = createContext(initialState)

const ActionContext = createContext(undefined)

export const SideWindowProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StateContext.Provider value={state}>
      <ActionContext.Provider value={dispatch}>
        {children}
      </ActionContext.Provider>
    </StateContext.Provider>
  )
}

export function useSideModalState() {
  const context = useContext(StateContext)
  if (context === undefined) {
    throw new Error('useSideModalState must be used with a Provider')
  }
  return context
}

export function useSideWindowAction() {
  const dispatch = useContext(ActionContext)

  if (dispatch === undefined) {
    throw new Error('useSideWindow must be used with a Provider')
  }

  return {
    openSideWindow(view, payload) {
      dispatch({ type: 'OPEN', view, payload })
    },

    closeSideWindow() {
      dispatch({ type: 'CLOSE' })
    },

    setData(payload) {
      dispatch({ type: 'SET_DATA', payload })
    },

    clearData() {
      dispatch({ type: 'CLEAR_DATA' })
    }
  }
}
