// import axios from "axios";
import {
  getPlan as getPlanRequest,
  getCards as getCardsRequest,
  getSubscripitionGiftVouchers as getSubscripitionGiftVouchersRequest,
  subscriptionPackageCheckout,
} from "services/api";

export const GET_PLAN = "[SUBSCRIPTION CHECKOUT] GET PLAN";
export const GET_CARDS = "[SUBSCRIPTION CHECKOUT] GET CARDS";
export const GET_GIFT_VOUCHERS = "[SUBSCRIPTION CHECKOUT] GET GIFT VOUCHERS";
export const SET_STEPS = "[SUBSCRIPTION CHECKOUT] SET STEPS";
export const SET_COUPON = "[SUBSCRIPTION CHECKOUT] SET COUPON";
export const SET_USE_GIFT_VOUCHERS =
  "[SUBSCRIPTION CHECKOUT] SET USE GIFT VOUCHERS";
export const SET_CURRENT_STEP = "[SUBSCRIPTION CHECKOUT] SET CURRENT STEP";
export const SET_PAYMENT_INFO = "[SUBSCRIPTION CHECKOUT] SET PAYMENT INFO";
export const SET_ADDRESS_INFO = "[SUBSCRIPTION CHECKOUT] SET ADDRESS INFO";
export const SET_SUBSCRIPTION_RESULT =
  "[SUBSCRIPTION CHECKOUT] SET SUBSCRIPTION RESULT";
export const SET_SENDING_REQUEST =
  "[SUBSCRIPTION CHECKOUT] SET SENDING REQUEST";
export const SET_USE_PIX = "[ORDER CHECKOUT] SET USE PIX";
export const SET_USE_AME = "[ORDER CHECKOUT] SET USE AME";
export const SET_USE_PIC_PAY = "[ORDER CHECKOUT] SET USE PIC PAY";
export const SET_LOADING = "[SUBSCRIPTION CHECKOUT] SET LOADING";
export const RESET_LOADING = "[SUBSCRIPTION CHECKOUT] RESET LOADING";

export function getPlan(planId) {
  return (dispatch) => {
    dispatch(setLoading());
    const request = getPlanRequest(planId);
    request
      .then((response) => {
        dispatch({
          type: GET_PLAN,
          payload: response,
        });
      })
      .finally(dispatch(resetLoading()));
  };
}

export function getCards() {
  return (dispatch) => {
    dispatch(setLoading());
    const request = getCardsRequest();
    request
      .then((response) => {
        dispatch({
          type: GET_CARDS,
          payload: response,
        });
      })
      .finally(dispatch(resetLoading()));
  };
}

export function usePix(data) {
  return { type: SET_USE_PIX, payload: data };
}

export function useAme(data) {
  return { type: SET_USE_AME, payload: data };
}

export function usePicPay(data) {
  return { type: SET_USE_PIC_PAY, payload: data };
}

export function getSubscriptionGiftVouchers() {
  return (dispatch, getState) => {
    let { plan } = getState().subscriptionCheckout;

    dispatch(setLoading());
    const request = getSubscripitionGiftVouchersRequest();
    request
      .then((response) => {
        let vouchers = response.subscription_gift_vouchers.filter(
          (giftVoucher) => plan?.id === giftVoucher?.subscription_package_id
        );
        let availableAmount = vouchers.reduce(
          (subtotal, current) => (subtotal += current.value),
          0
        );

        dispatch({
          type: GET_GIFT_VOUCHERS,
          payload: {
            available_amount: availableAmount,
            subscription_gift_vouchers: vouchers,
          },
        });
      })
      .finally(dispatch(resetLoading()));
  };
}

export function setCurrentStep(newStep) {
  return (dispatch, getState) => {
    let { subscriptionCheckout } = getState();

    let newStepIndex = subscriptionCheckout.steps.findIndex(
      (step) => step.key === newStep
    );
    let updatedSteps = subscriptionCheckout.steps.map((step, index) => ({
      ...step,
      complete: index <= newStepIndex,
    }));

    dispatch({
      type: SET_CURRENT_STEP,
      payload: { key: newStep, index: newStepIndex },
    });
    dispatch({ type: SET_STEPS, payload: updatedSteps });
  };
}

export function changeStep(kind = "next") {
  return (dispatch, getState) => {
    let { subscriptionCheckout } = getState();
    let activeIndex = subscriptionCheckout.currentStep?.index || 0;
    let lastAvailableIndex = subscriptionCheckout.steps.length - 1;
    let goToIndex = activeIndex + (kind === "next" ? 1 : -1);

    if (goToIndex < 0) goToIndex = 0;
    else if (lastAvailableIndex < goToIndex) goToIndex = lastAvailableIndex;

    dispatch(setCurrentStep(subscriptionCheckout.steps[goToIndex].key));
  };
}

export function setPaymentInfo(data, type = "") {
  return (dispatch, getState) => {
    if (type === "append") {
      let { subscriptionCheckout } = getState();
      let paymentInfo = { ...subscriptionCheckout.paymentInfo, ...data };

      dispatch({ type: SET_PAYMENT_INFO, payload: paymentInfo });
    } else {
      dispatch({ type: SET_PAYMENT_INFO, payload: data });
    }
  };
}

export function setSubscriptionResult(data) {
  return { type: SET_SUBSCRIPTION_RESULT, payload: data };
}

export function setUseGiftVouchers(data) {
  return { type: SET_USE_GIFT_VOUCHERS, payload: data };
}

export function subscriptionCheckout() {
  const sessionID = localStorage.getItem("AU_session");

  return (dispatch, getState) => {
    let { subscriptionCheckout } = getState();
    let order = {
      subscription_package_id: subscriptionCheckout.plan.id,
      address: subscriptionCheckout.addressInfo,
      payment_type: subscriptionCheckout.usePix
        ? "pix"
        : subscriptionCheckout.useAme
        ? "ame"
        : subscriptionCheckout.usePicPay
        ? "picpay"
        : "credit_card",
      credit_card: subscriptionCheckout.usePix
        ? undefined
        : {
            ...subscriptionCheckout.paymentInfo,
            number: null,
            cvv: null,
          },
      use_gift_vouchers: subscriptionCheckout.useGiftVouchers,
    };
    let paymentData = {
      //pix: subscriptionCheckout.usePix,
      //ame: subscriptionCheckout.useAme,
      session_id: sessionID,
      phone: subscriptionCheckout.paymentInfo?.phone,
    };

    dispatch(setLoading());
    dispatch(sendingRequest(true));
    subscriptionPackageCheckout({ order: order, payment_data: paymentData })
      .then((data) => {
        dispatch(setSubscriptionResult(data));
        dispatch(changeStep("next"));
      })
      .finally(() => {
        dispatch(resetLoading());
        dispatch(sendingRequest(false));
      });
  };
}

export function setAddressInfo(data) {
  return (dispatch, getState) => {
    dispatch({ type: SET_ADDRESS_INFO, payload: data });
  };
}

export function setCoupon(data) {
  return { type: SET_COUPON, payload: data };
}

export function sendingRequest(data) {
  return {
    type: SET_SENDING_REQUEST,
    payload: data,
  };
}

export function setLoading() {
  return {
    type: SET_LOADING,
  };
}

export function resetLoading() {
  return {
    type: RESET_LOADING,
  };
}
