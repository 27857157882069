import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useSideWindowAction } from "context/SideWindow-context"
import { ChevronRight } from "assets/icons"
import { isLoggedIn } from "store/selectors";

import { classNames, formatMonetaryValue } from "utils";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slider from "@material-ui/core/Slider";

import { getAllCitiesRequest } from "services/api/index";

import { AutocompleteController } from "components/forms";

import styles from "./filterSideBar.module.scss"

const FilterSideBar = () => {
  const { closeSideWindow, setData } = useSideWindowAction()
  const interestArea = useSelector((state) => state.global.activeinterestArea)

  const logged = useSelector(isLoggedIn);
  const [categories, setCategories] = useState([]);
  const [fixedCategories, setFixedCategories] = useState([]);

  const [cities, setCities] = useState([]);

  const [filters, setFilters] = useState({
    priceRange: [0, 2000],
    subcategories: [],
    categories: [],
    cities: [],
  });
  const [selectedCities, setSelectedCities] = useState([]);

  const handleCheckboxChange = (event, id) => {
    event.persist();

    let currentValues = filters[event.target.name] || [];
    if (event.target.checked) {
      currentValues.push(id);
    } else {
      const index = currentValues.indexOf(id);
      if (index > -1) currentValues.splice(index, 1);
    }

    setFilters((state) => ({
      ...state,
      [`${event.target.name}`]: currentValues,
    }));
  };

  const setPriceRange = (range) => {
    setFilters((state) => ({
      ...state,
      priceRange: range,
    }));
  };

  const applyFilters = () => {
    setData(filters);
    closeSideWindow();
  };

  useEffect(() => {
    let list = interestArea?.sub_categories;
    if (Array.isArray(list)) {
      let checkboxesList = [];

      list.forEach((category) => {
        checkboxesList.push({
          data: category,
          name: "categories",
          sublevel: false,
        });

        category.sub_categories.forEach((subcategory) => {
          checkboxesList.push({
            data: subcategory,
            name: "subcategories",
            sublevel: false,
          });
        });
      });

      setCategories(checkboxesList);
    }

    getAllCitiesRequest().then((response) => {
      var citiesArray = response.map((city) => city.value);
      if (Array.isArray(citiesArray)) {
        setCities(citiesArray);
      } else setCities([]);
    });
  }, [interestArea]);

  useEffect(() => {
    let breakpoint = 6;
    setFixedCategories(categories.slice(0, breakpoint));
  }, [categories]);

  if (selectedCities && selectedCities[0] === "") {
    selectedCities.shift();
    setSelectedCities(selectedCities);
  }

  const FormControlCategoryCheckbox = ({ item, name, sublevel = false }) => (
    <FormControlLabel
      key={item.slug}
      className={classNames(styles.checkboxes, "mb-2", sublevel ? "pl-3" : "")}
      control={
        <Checkbox
          checked={filters[name].includes(item.slug)}
          onChange={(event) => handleCheckboxChange(event, item.slug)}
          style={{ padding: 2, marginLeft: "8px" }}
          name={name}
          color="primary"
        />
      }
      label={<span className={styles.checkboxLabel}>{item.name}</span>}
    />
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.arrow}>
          <ChevronRight
            color="#575756"
            rotate="180deg"
          />
          <p>Filtros</p>
        </div>
        <div className={styles.close}>
          <button onClick={() => closeSideWindow()}>
            X
          </button>
        </div>
      </div>
      <div className={styles.body}>
        <div>
          <p className={styles.category}>
            {interestArea?.name}
          </p>
        </div>
        <div className="pr-3 mb-2">
          <div className={styles.line}>
            <div className={styles.filterName}>Subcategoria</div>
            <div className={styles.subcategories}>
              {fixedCategories.map((item) => (
                <FormControlCategoryCheckbox
                  item={item.data}
                  name={item.name}
                  key={JSON.stringify(item.data)}
                />
              ))}
            </div>
          </div>
          {logged && (
            <div className={styles.line}>
              <div className={styles.filterName}>Preço</div>
              {filters.priceRange.length > 0 && (
                <>
                  <span className={styles.priceRange}>
                    {formatMonetaryValue(filters.priceRange[0])}
                  </span>
                  {" - "}
                  <span className={styles.priceRange}>
                    {formatMonetaryValue(filters.priceRange[1])}
                  </span>
                </>
              )}
              <Slider
                value={filters.priceRange}
                onChange={(event, newValue) => setPriceRange(newValue)}
                max={2000}
                step={50}
                style={{ width: "90%", margin: 0, padding: 0 }}
                valueLabelDisplay="auto"
              />
            </div>
          )}
          <div className="mt-4">
            <div className={styles.filterName}>Cidades</div>
            <AutocompleteController
              label={selectedCities.length > 0 ? " " : "Escolha a Cidade"}
              name="city"
              multiple={true}
              options={cities}
              variant="filled"
              style={{ width: "100%" }}
              getOptionSelected={(option, value) => option === value}
              onChange={(e, options) => {
                setSelectedCities(options);
                setFilters((state) => ({
                  ...state,
                  [`cities`]: options,
                }));
              }}
              value={selectedCities}
            />
          </div>
          <div className={classNames("mt-4", styles.applyContainer)}>
            <button
              aria-label="aplicar filtros"
              type="button"
              onClick={() => {
                applyFilters();
              }}
              className={styles.applyButton}
            >
              Aplicar filtros
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { FilterSideBar }
