import React from 'react';
import { useStyles } from './useStyle';

const StartSearch = (props) => {
  const classes = useStyles();
  const hasSearch = JSON.parse(localStorage.getItem('hasSearch') || {});

  const handleCloseModal = () => {
    props.close();
  }

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        {hasSearch?.survey?.title}
      </h2>
      <p className={classes.description}>
        {hasSearch?.survey?.description}
      </p>
      {hasSearch?.survey?.image && (
        <img
          src={hasSearch.survey.image}
          alt='survey'
          className={classes.image}
        />
      )}
      <button
        className={classes.btn}
        onClick={props.clickStart}
      >
        {hasSearch?.survey?.start_button}
      </button>
      <p
        className={classes.closeModal}
        onClick={handleCloseModal}
      >
        Responder depois
      </p>
    </div>
  )
}

export default StartSearch;
