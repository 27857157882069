import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  body: {
    padding: '2em 1em',
  },
  btnClose: {
    margin: '8px',
    borderRadius: '50%',
    width:'26px',
    height: '26px',
    border: '1px solid #a6a8a9',
    textAlign: 'center',
    color: '#a6a8a9',
    cursor: 'pointer',
  },
  closeModal: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
    marginTop: '1em',
    fontFamily: 'NeuePlak-Bold',
    color: '#575756',
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'space-between',
    alignContent: 'center',
    margin: '8px auto',
    height: '100%',
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '18px',
    marginBottom: '1em',
    fontFamily: 'NeuePlak-Bold',
    color: '#575756',
  },
  description: {
    textAlign: 'center',
    color: '#6b7280',
    wordBreak: 'break-word'
  },
  image: {
    width: '100px',
    margin: '8px auto',
  },
  btn: {
    border: 'none',
    borderRadius: '28px',
    background: 'var(--color-button-primary-bg)',
    color: 'var(--color-button-primary-text)',
    fontWeight: 'bold',
    margin: '8px auto',
    marginTop: '2em',
    padding: '18x 16px',
    height: '42px',
    width: '40%',
  },
  disabled: {
    border: 'none',
    borderRadius: '28px',
    background: '#eee',
    color: 'var(--color-button-primary-text)',
    fontWeight: 'bold',
    margin: '8px auto',
    marginTop: '2em',
    padding: '18x 16px',
    height: '42px',
    width: '40%',
  },
  input: {
    margin: '8px auto',
    padding: '1em',
    width: '80%'
  },
  radioWrapper: {
    border: '1px solid var(--color-button-primary-text)',
    borderRadius: '8px',
    padding: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '4px auto',
    width: '80%',
  },
  radioInput: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 12px',
    wordBreak: 'break-word',
    color: '#575756',
  },
  selectWrapper: {
    border: '1px solid var(--color-button-primary-text)',
    borderRadius: '8px',
    padding: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '4px auto',
    width: '80%',
  }
}));
