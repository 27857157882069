const getProductPrice = (product, benefitTier, ignoreBenefitTier = false) => {
  try {
    let priceToShow = null;
    let analyzePrice = (currentPrice) => {
      if (
        typeof currentPrice === "object" &&
        (currentPrice.benefit_tier.id === benefitTier || ignoreBenefitTier)
      ) {
        if (priceToShow === null) priceToShow = currentPrice;
        else if (priceToShow.percent_discount < currentPrice.percent_discount) {
          priceToShow = currentPrice;
        }
      }
    };

    if (Array.isArray(product.tickets)) {
      product.tickets.forEach((ticket) => {
        if (Array.isArray(ticket.prices)) {
          ticket.prices.forEach(analyzePrice);
        }
      });
    }

    return priceToShow;
  } catch (error) {
    console.log("[getProductPrice] error: ", error);
    return null;
  }
};

export default getProductPrice;
