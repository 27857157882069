import React from 'react'
import OrderCard from '../OrderCard'
import OrderCardStep from '../OrderCard/OrderCardStep'
import OrderCardHeader from '../OrderCard/OrderCardHeader'
import OrderCardTitle from '../OrderCard/OrderCardTitle'
import OrderCardContent from '../OrderCard/OrderCardContent'
import { useForm } from 'react-hook-form'
import { Stack } from '@mui/material'
import { TextFieldController } from 'components/forms'
import { useSelector } from 'react-redux'

const PersonalData = () => {
  const { control, errors } = useForm({
    reValidateMode: 'onSubmit',
  })

  const userInfos = useSelector((state) => state.user.info)

  return (
    <OrderCard>
      <OrderCardHeader>
        <OrderCardStep>1</OrderCardStep>
        <OrderCardTitle>Dados pessoais</OrderCardTitle>
      </OrderCardHeader>
      <OrderCardContent>
        <Stack spacing={4} width={'100%'}>
          <TextFieldController
            label="Nome completo"
            name="name"
            defaultValue={userInfos?.name}
            disabled
            control={control}
            errors={errors}
          />
          <TextFieldController
            defaultValue={userInfos?.cpf}
            disabled
            label="CPF"
            name="cpf"
            control={control}
            errors={errors}
          />
        </Stack>
      </OrderCardContent>
    </OrderCard>
  )
}

export default PersonalData
