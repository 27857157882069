const crypto = require("crypto"),
  algorithm = "aes-256-cbc",
  key = process.env.REACT_APP_CRYPT_KEY, // 32 Characters
  iv = "0000000000000000"; // 16 Characters

class CryptJS {
  encrypt(text) {
    var cipher = crypto.createCipheriv(algorithm, key, iv);
    var crypted = cipher.update(text, "utf-8", "base64");
    try {
      crypted += cipher.final("base64");
    } catch (err) {
      return "error";
    }
    return crypted;
  }

  decrypt(text) {
    var cipher = crypto.createDecipheriv(algorithm, key, iv);
    var crypted = cipher.update(text, "base64", "utf-8");

    try {
      crypted += cipher.final("utf-8");
    } catch (err) {
      return "error";
    }

    return crypted;
  }
}

export default CryptJS;
