import React from "react"

export const MenuIcon = ({
  color = "black ",
  width="26",
  height="24"
}) => (
  <svg width={width} height={height} viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="1.54118" x2="31" y2="1.54118" stroke={color} strokeWidth="2.91765"/>
    <line y1="13.2118" x2="31" y2="13.2118" stroke={color} strokeWidth="2.91765"/>
    <line y1="24.8824" x2="31" y2="24.8824" stroke={color} strokeWidth="2.91765"/>
  </svg>
)
