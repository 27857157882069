import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  setHeaders,
  showNotificationsModal,
  getUser,
  isApp,
} from "store/actions";

import CryptJS from "../cryptJS";

const AuthApp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const encoded = decodeURIComponent(props.match.params.token);
  const Crypt = new CryptJS();

  const getUserHeader = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return str;
    }
    return JSON.parse(str);
  };

  let userHeader = getUserHeader(Crypt.decrypt(encoded));

  if (userHeader === "error") {
    history.push("/erro/app");
    return null;
  }

  if (!userHeader.logged) {
    history.push("/erro/logged");
    return null;
  }

  dispatch(setHeaders(userHeader));
  dispatch(showNotificationsModal(true));
  dispatch(isApp(true));
  dispatch(getUser());

  if (sessionStorage.getItem("AU_redirect")) {
    history.push(sessionStorage.getItem("AU_redirect"));
    sessionStorage.removeItem("AU_redirect")
  } else {
    history.push("/ofertas");
  }

  return null;
};

export default AuthApp;
