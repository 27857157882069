import React from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { useStyles } from "./useStyle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import "swiper/components/pagination/pagination.min.css";

import SwiperCore, { Autoplay, Pagination } from "swiper/core";

import CarnaRio from "./images/carnaRio.png";
import Estacoes from "./images/estacoes.png";
import Kinoplex from "./images/Kinoplex.png";
import Rir from "./images/rockInRio.png";
import Uber from "./images/uber.png";
import Yup from "./images/yup.png";
import Giuliana from "./images/giuliana.png";
import Cinemark from "./images/cinemark.png";
import Decathlon from "./images/decathlon.png";
import Ifood from "./images/ifood.png";
import Liga from "./images/liga.png";
import BetoCarrero from "./images/betoCarrero.png";
import Monica from "./images/monica.png";
import NetShoes from "./images/netshoes.png";
import Opiniao from "./images/opiniao.png";
import Outback from "./images/outback.png";

SwiperCore.use([Autoplay, Pagination]);

const Variaty = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={6} className={classes.headlineContainer}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.titleBig}>variedade</Typography>
          <Typography className={classes.titleSmall}>de produtos</Typography>
        </Box>
        <Typography className={classes.headline}>
          As melhores marcas estarão no seu clube
        </Typography>
        <Typography className={classes.subheadline}>
          Um espaço de engajamento, incentivo e descontos em um amplo catálogo
          com mais de 2.000 produtos.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} className={classes.imageContainer}>
        <VariatyCarrossel />
      </Grid>
    </Grid>
  );
};

export default Variaty;

const VariatyCarrossel = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (!isMobile) {
    return (
      <Swiper
        style={{
          "--swiper-pagination-color": "#FFBA08",
          "--swiper-pagination-bullet-inactive-color": "#999999",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          "--swiper-pagination-bullet-size": "16px",
          "--swiper-pagination-bullet-horizontal-gap": "6px",
        }}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <Box className={classes.slide}>
            <Box className={classes.centerSpaceBetween}>
              <img src={Cinemark} alt="Cinemark" className={classes.cinemark} />
              <img src={Liga} alt="Liga" className={classes.liga} />
            </Box>
            <Box className={classes.centerSpaceBetween}>
              <img
                src={Decathlon}
                alt="Decathlon"
                className={classes.decathlon}
              />
              <img src={Opiniao} alt="Opiniao" className={classes.opiniao} />
            </Box>
            <Box className={classes.centerSpaceBetween}>
              <img src={Outback} alt="Outback" className={classes.outback} />
              <img src={Ifood} alt="Ifood" className={classes.ifood} />
            </Box>
            <Box className={classes.centerSpaceBetween}>
              <img
                src={BetoCarrero}
                alt="BetoCarrero"
                className={classes.betoCarrero}
              />
              <img src={Monica} alt="Monica" className={classes.monica} />
            </Box>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box className={classes.slide}>
            <Box className={classes.centerSpaceBetween}>
              <img src={Kinoplex} alt="Kinoplex" className={classes.kinoplex} />
              <img src={Estacoes} alt="Estacoes" className={classes.estacoes} />
            </Box>
            <Box className={classes.centerSpaceBetween}>
              <img src={Uber} alt="Uber" className={classes.uber} />
              <img src={Yup} alt="yup" className={classes.yup} />
            </Box>
            <Box className={classes.centerSpaceBetween}>
              <img src={Rir} alt="RockInRio" className={classes.rir} />
              <img src={CarnaRio} alt="CarnaRio" className={classes.carnaRio} />
            </Box>
            <Box className={classes.centerSpaceBetween}>
              <img src={NetShoes} alt="NetShoes" className={classes.netshoes} />
              <img src={Giuliana} alt="Giuliana" className={classes.giuliana} />
            </Box>
          </Box>
        </SwiperSlide>
      </Swiper>
    );
  }

  return (
    <Swiper
      style={{
        "--swiper-pagination-color": "#FFBA08",
        "--swiper-pagination-bullet-inactive-color": "#999999",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "16px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
      }}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      className="mySwiper"
    >
      <SwiperSlide>
        <Box className={classes.slide}>
          <img src={Cinemark} alt="Cinemark" className={classes.cinemark} />
          <img src={Liga} alt="Liga" className={classes.liga} />
          <img src={Decathlon} alt="Decathlon" className={classes.decathlon} />
          <img src={Outback} alt="Outback" className={classes.outback} />
          <img src={Ifood} alt="Ifood" className={classes.ifood} />
          <img src={NetShoes} alt="NetShoes" className={classes.netshoes} />
        </Box>
      </SwiperSlide>
      <SwiperSlide>
        <Box className={classes.slide}>
          <img
            src={BetoCarrero}
            alt="BetoCarrero"
            className={classes.betoCarrero}
          />
          <img src={Monica} alt="Monica" className={classes.monica} />
          <img src={Opiniao} alt="Opiniao" className={classes.opiniao} />
          <img src={Kinoplex} alt="Kinoplex" className={classes.kinoplex} />
          <img src={Estacoes} alt="Estacoes" className={classes.estacoes} />
          <img src={Yup} alt="yup" className={classes.yup} />
        </Box>
      </SwiperSlide>

      <SwiperSlide>
        <Box className={classes.slide}>
          <img src={Uber} alt="Uber" className={classes.uber} />
          <img src={Rir} alt="RockInRio" className={classes.rir} />

          <img src={Giuliana} alt="Giuliana" className={classes.giuliana} />
          <img src={CarnaRio} alt="CarnaRio" className={classes.carnaRio} />
        </Box>
      </SwiperSlide>
    </Swiper>
  );
};
