import React, { useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";

import { useStyles } from "./useStyle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import "swiper/components/pagination/pagination.min.css";

import SwiperCore, { Autoplay, Pagination } from "swiper/core";

import screenShot from "./images/screenshot.png";
import ofertas from "./images/ofertas.png";

SwiperCore.use([Autoplay, Pagination]);

const Functionality = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.headlineContainer}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>Funcionalidades</Typography>
        </Box>
        <Typography className={classes.headline}>
          Dinâmica do seu clube de descontos
        </Typography>
        <Typography className={classes.subheadline}>
          Não entendeu como a plataforma vai funcionar? Entenda um pouco como
          seu site vai se comportar.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.imageContainer}>
        <FunctionalityCarousel />
      </Grid>
    </Grid>
  );
};

export default Functionality;

const FunctionalityCarousel = () => {
  const [isActive, setIsActive] = useState(false);
  const classes = useStyles(isActive);

  return (
    <Swiper
      style={{
        "--swiper-pagination-color": "#FFBA08",
        "--swiper-pagination-bullet-inactive-color": "#999999",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "16px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
      }}
      centeredSlides={true}
      pagination={{
        clickable: true,
      }}
      className="mySwiper"
    >
      <SwiperSlide
        onMouseEnter={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
      >
        <Box className={classes.slide}>
          <img
            src={screenShot}
            alt="screenshot"
            className={classes.screenShot}
          />
        </Box>
      </SwiperSlide>

      <SwiperSlide
        onMouseEnter={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
      >
        <Box className={classes.slide}>
          <img src={ofertas} alt="screenshot" className={classes.ofertas} />
        </Box>
      </SwiperSlide>
    </Swiper>
  );
};
