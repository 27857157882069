import React from 'react'
import { Trash } from 'assets/icons'
import styles from './styles.module.scss'
import useIsMobile from 'utils/useIsMobile'

export const formmattedPrice = (value) => {
  return String(value?.toFixed(2))?.replace('.', ',')
}

const DesktopCartItem = ({
  product,
  decreaseQuantity,
  increaseQuantity,
  removeItem,
}) => {
  const total = product?.details.reduce((acc, current) => {
    return acc + current?.quantity * current?.value
  }, 0)
  const isMobile = useIsMobile()

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.imageContainer}>
          <img
            src={product?.photo}
            alt={product?.name ?? 'product name'}
            className={styles.image}
          />
          <div className={styles.contentWrapper}>
            <p className={styles.productName}>{product?.name}</p>

            <div className={styles.productDetailsWrapper}>
              {product.details.map((detail, index) => (
                <div className={styles.wrapper} key={index}>
                  <div className={styles.label}>
                    {isMobile
                      ? detail?.label?.length > 40
                        ? detail?.label?.substring(0, 40) + '...'
                        : detail?.label
                      : detail?.label}
                  </div>
                  <div className={styles.buttonContainer}>
                    <div className={styles.buttonWrapper}>
                      <button
                        className={styles.button}
                        disabled={detail?.quantity === 1}
                        onClick={() =>
                          decreaseQuantity(product?.id, detail?.price_id)
                        }
                      >
                        -
                      </button>
                      <div>
                        <span className={styles.quantity}>
                          {detail?.quantity}
                        </span>
                      </div>
                      <button
                        className={styles.button}
                        disabled={detail?.quantity === 10}
                        onClick={() =>
                          increaseQuantity(product?.id, detail?.price_id)
                        }
                      >
                        +
                      </button>
                      <div
                        onClick={() => removeItem(detail?.price_id)}
                        className={styles.trashWrapper}
                      >
                        <Trash
                          width={isMobile ? '16' : '20'}
                          height={isMobile ? '20' : '24'}
                        />
                      </div>
                    </div>
                    <div className={styles.unitPriceWrapper}>
                      <div>
                        <div className={styles.unitPrice}>
                          {detail?.quantity > 1 && (
                            <>
                              {`${detail?.quantity} x ${formmattedPrice(detail?.value)}`}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className={styles.finalPrice}>
                R${formmattedPrice(total)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesktopCartItem
