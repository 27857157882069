import React from "react";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useStyles } from "./useStyle";
import dashboard from "./images/dashboard.png";
import FallbackImage from "components/fallbackImage";
import painel from "./images/painel.png";

const SolutionDashboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box className={classes.wrapper}>
      <Grid container className={classes.container}>
        <Grid item xs={12} md={6} className={classes.headlineContainer}>
          <Typography className={classes.title}>DASHBOARD</Typography>
          <Typography className={classes.headline}>
            Número de usuários, pedidos realizados e faturamento
          </Typography>
          <Typography className={classes.subheadline}>
            Além dessas informações, acompanhe a quantidade de registros,
            segmentação e usuários ativos por estado.
          </Typography>
        </Grid>
        <FallbackImage
          width={isMobile ? 272 : 488}
          height={isMobile ? 272 : 482}
        >
          <Grid item xs={12} md={12} className={classes.imageContainer}>
            <img src={dashboard} alt="dashboard" className={classes.image} />
            <Box className={classes.dashboardDataContainer}>
              <img src={painel} alt="painel" className={classes.painel} />
              <Typography className={classes.dashboardTitle}>
                DASHBOARD
              </Typography>
              <Typography className={classes.dashboardText}>
                Faça gestão de seus dados
              </Typography>
            </Box>
          </Grid>
        </FallbackImage>
      </Grid>
    </Box>
  );
};

export default SolutionDashboard;
