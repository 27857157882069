import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: "NeuePlak-Regular",
  },
  palette: {
    font: {
      title: "#2F2F2F",
      subTitle: "#888",
      dropdownItem: "#4F4F4F",
    },

    primary: {
      main: "#ED9915",
      background: "#FCF0DC",
    },
    secondary: {
      main: "#13224C",
    },
  },
});

export default theme;
