import React from 'react'
import { useSelector } from 'react-redux'
import { isLoggedIn } from 'store/selectors'
import { useSideWindowAction } from 'context/SideWindow-context'
import { LoginIcon, QuestionMarkIcon } from 'assets/icons'

import Categories from '../categories'

import styles from './styles.module.scss'

const LogIn = () => {
  const { closeSideWindow } = useSideWindowAction()
  const partnerSettings = useSelector((state) => state.global.partner)

  const handleRedirect = () => {
    window.open('/partners', '_self')
    closeSideWindow()
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.box}>
          <div className={styles.textWrapper}>
            <p>Acesse ofertas imperdíveis!</p>
          </div>
          <div className={styles.btnWrapper}>
            <button onClick={() => closeSideWindow()}>X</button>
          </div>
        </div>
        <div className={styles.boxLogin}>
          <button onClick={() => handleRedirect()}>
            <LoginIcon
              color={'#fff'}
            />
            <span>Login</span>
          </button>
        </div>
      </div>
      <div className={styles.boxList}>
        <ul>
          <li onClick={() => window.open('/ofertas', '_self')}>
            Ofertas
          </li>
          <li onClick={() => window.open("http://maisalegriahelp.zendesk.com/", "_black")}>
            <QuestionMarkIcon
              color={partnerSettings?.main_colors?.primary_color}
              width="18"
              height="18"
            />
            <p>Dúvidas</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

const Logged = () => {
  const { closeSideWindow } = useSideWindowAction()
  const userInfos = useSelector((state) => state.user.info)
  const partnerSettings = useSelector((state) => state.global.partner)

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.box}>
          <div>
            <div className={styles.login}>
              <LoginIcon
                width='18'
                height='20'
                color={partnerSettings?.main_colors?.primary_color}
              />
              <p>Olá, <span>{userInfos?.name?.split(" ")[0]}</span></p>
            </div>
            <div className={styles.myAccountBox} onClick={() => window.open('/usuario', '_self')}>
              <span>Minha conta ></span>
            </div>
          </div>
        <div className={styles.btnWrapper}>
          <button onClick={() => closeSideWindow()}>X</button>
        </div>
        </div>
      </div>
      <Categories />
    </div>
  )
}

const MobileMenu = () => {
  const logged = useSelector(isLoggedIn)
  return (
    <>
      {logged ? <Logged />: <LogIn />}
    </>
  )
}

export default MobileMenu
