import * as Actions from "../actions";

const initialState = {
  plan: {},
  paymentInfo: {},
  addressInfo: {},
  cards: [],
  giftVouchers: null,
  useGiftVouchers: true,
  sendingRequest: false,
  subscriptionResult: null,
  usePix: false,
  useAme: false,
  usePicPay: false,
  steps: [
    {
      complete: true,
      description: ["Login / Cadastro", "Dados pessoais"],
      key: "signin",
    },
    { complete: false, description: "Endereço", key: "address" },
    { complete: false, description: "Pagamento", key: "payment" },
    { complete: false, description: "Confirmar dados", key: "confirmation" },
    { complete: false, description: "Finalizado", key: "finish" },
  ],
  currentStep: { key: "signin", index: 0 },
  coupon: null,
  loading: false,
};

const signContractReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PLAN: {
      return {
        ...state,
        plan: action.payload,
      };
    }
    case Actions.GET_CARDS: {
      return {
        ...state,
        cards: action.payload,
      };
    }
    case Actions.GET_GIFT_VOUCHERS: {
      return {
        ...state,
        giftVouchers: action.payload,
      };
    }
    case Actions.SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.payload,
      };
    }
    case Actions.SET_PAYMENT_INFO: {
      return {
        ...state,
        paymentInfo: action.payload,
      };
    }
    case Actions.SET_USE_PIX: {
      return {
        ...state,
        usePix: action.payload,
      };
    }
    case Actions.SET_USE_AME: {
      return {
        ...state,
        useAme: action.payload,
      };
    }
    case Actions.SET_USE_PIC_PAY: {
      return {
        ...state,
        usePicPay: action.payload,
      };
    }
    case Actions.SET_ADDRESS_INFO: {
      return {
        ...state,
        addressInfo: action.payload,
      };
    }
    case Actions.SET_USE_GIFT_VOUCHERS: {
      return {
        ...state,
        useGiftVouchers: action.payload,
      };
    }
    case Actions.SET_SUBSCRIPTION_RESULT: {
      return {
        ...state,
        subscriptionResult: action.payload,
      };
    }
    case Actions.SET_STEPS: {
      return {
        ...state,
        steps: action.payload,
      };
    }
    case Actions.SET_COUPON: {
      return {
        ...state,
        coupon: action.payload,
      };
    }
    case Actions.SET_SENDING_REQUEST: {
      return {
        ...state,
        sendingRequest: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default signContractReducer;
