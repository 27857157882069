import React from "react";
import { useSelector } from "react-redux";

import PagSeguro from "./gateways/PagSeguro";
import MercadoPago from "./gateways/MercadoPago";
import PagarMe from "./gateways/PagarMe";
import Default from "./gateways/Default";

export default function () {
  const partnerSettings = useSelector((state) => state.global.partner);

  switch (partnerSettings?.payment_info?.cc_subscription_payment_gateway) {
    case "pagseguro":
      return <PagSeguro />;
    case "pagarme":
      return <PagarMe />;
    case "mercadopago":
      return <MercadoPago />;
    default:
      return <Default />;
  }
}
