import React from 'react';
import { useSelector } from 'react-redux';
import OffersHighlights from 'components/offersHighlight';
import ProductsRelated from 'components/productsRelated';

export default function(){
  const product = useSelector(({ product }) => product.product);

  return (
    <div className='container'>
      <div className='text-center' style={{ margin: "100px 0px" }}>
        Esse produto não está mais disponível.
      </div>

      <ProductsRelated
        relatedTo={[product]}
        noResultsComponent={<OffersHighlights />}
      />
    </div>
  )
}
