import React from "react";
import LazyLoad from "react-lazyload";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { classNames } from "utils";

export default function OfferImage({ program }) {
  return (
    <div
      id="carousel-thumb"
      data-ride="carousel"
      className={classNames(
        "carousel slide carousel-fade d-block position-relative",
        "d-flex justify-content-center align-items-center"
      )}
      style={{ height: "200px" }}
    >
      <div className="carousel-inner" role="listbox">
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "100%",
          }}
        >
          <LazyLoad once>
            <img
              src={program.image}
              alt={program.title}
              style={{ maxWidth: 250, maxHeight: 160 }}
            />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
}
