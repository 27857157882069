export const tabOptionText = (method) => {
  let paymentMethod = ''

  switch (method) {
    case 'credit_card':
      paymentMethod = 'Cartão de Crédito'
      break

    case 'ame':
      paymentMethod = 'Ame'
      break

    case 'picpay':
      paymentMethod = 'PicPay'
      break

    default:
      //paymentMethod = "Ame Digital";
      paymentMethod = 'Pix'

      break
  }

  return paymentMethod
}

export const srcImg = (
  method,
  creditCardImage,
  PixImage,
  AmeDigitalImage,
  PicPayImage,
  currentPaymentTab,
  index,
  CreditCardBlackIcon,
  PixBlackIcon,
  PicPayBlackImage,
  AmeDigitalBlack,
) => {
  let image

  const currentTab = `${method}-${currentPaymentTab === index}`

  switch (currentTab) {
    case 'credit_card-false':
      image = CreditCardBlackIcon
      break

    case 'credit_card-true':
      image = creditCardImage
      break

    case 'pix-false':
      image = PixBlackIcon
      break

    case 'pix-true':
      image = PixImage
      break

    case 'ame-false':
      image = AmeDigitalBlack
      break
    case 'ame-true':
      image = AmeDigitalImage
      break

    case 'picpay-false':
      image = PicPayBlackImage
      break
    case 'picpay-true':
      image = PicPayImage
      break

    default:
      image = creditCardImage
      break
  }

  return image
}

export const altImage = (method) => {
  let image

  switch (method) {
    case 'credit_card':
      image = 'Logo cartão de crédito'
      break

    case 'ame':
      image = 'Logo Ame Digital'
      break

    case 'picpay':
      image = 'Logo Pic Pay image'
      break

    default:
      image = 'Logo pix'
      break
  }

  return image
}

export const classNameImage = (method, isMobile) => {
  switch (method) {
    case 'credit_card':
      return {
        height: isMobile ? '28px' : '34px',
        width: isMobile ? '68px' : '105px',
      }

    case 'ame':
      return {
        height: isMobile ? '11px' : '18px',
        width: isMobile ? '43px' : '66px',
      }

    case 'picpay':
      return {
        height: isMobile ? '13px' : '21px',
        width: isMobile ? '42px' : '65px',
      }

    default:
      return {
        height: isMobile ? '14px' : '22px',
        width: isMobile ? '40px' : '62px',
      }
  }
}
