import React, { useRef, useEffect } from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useSideModalState } from './SideWindow-context'
import { classNames } from 'utils'
import styles from './styles.module.scss'

const SideWindow = ({ children, view }) => {
  const { isOpen } = useSideModalState()
  const ref = useRef()

  useEffect(() => {
    if(ref?.current) {
      const target = document.querySelector('#drawer')

      if(isOpen) {
        disableBodyScroll(target)
      } else {
        enableBodyScroll(target)
      }
    }
    return () => clearAllBodyScrollLocks()
  }, [isOpen])

  return (
    <>
      <div className={styles.container}>
        {isOpen && (
          <aside
            ref={ref}
            id='drawer'
            className={classNames(styles.aside, view === 'CART_WINDOW' && styles.cart)}
          >
            { children }
          </aside>
        )}
      </div>
    </>
  )

}

export default SideWindow
