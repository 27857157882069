import React from "react"

export const CartIcon = ({color = "black", width="42", height="40"}) => (
<svg width={width} height={height} viewBox="0 0 52 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M42.5357 16.275C42.321 16.275 42.1332 16.275 41.9588 16.275C35.2784 16.275 28.5979 16.275 21.9174 16.275C20.8576 16.275 20.2003 15.7653 20.0393 14.8531C19.8918 13.9543 20.5759 13.0689 21.4881 12.9884C21.582 12.975 21.6893 12.975 21.7832 12.975C29.3759 12.975 36.9686 12.975 44.5613 12.975C45.5137 12.975 46.1844 13.4982 46.3052 14.3836C46.3454 14.6519 46.3052 14.947 46.2381 15.2287C45.1649 19.5616 44.0918 23.8811 42.9918 28.2006C42.3613 30.7092 40.4698 32.1714 37.8808 32.1714C32.6893 32.1714 27.4979 32.1714 22.3064 32.1714C19.7174 32.1714 17.8796 30.7628 17.182 28.2677C15.4649 22.1104 13.7613 15.9531 12.0576 9.79577C11.7625 8.7226 11.2527 8.33357 10.1259 8.32016C8.97226 8.32016 7.8186 8.33357 6.66494 8.30674C5.5247 8.27991 4.78689 7.31406 5.05518 6.2543C5.20274 5.63723 5.73933 5.0604 6.3564 5.0604C7.97957 5.03357 9.61616 4.91284 11.2125 5.11406C13.3052 5.38235 14.6466 6.72381 15.2101 8.76284C16.954 14.9872 18.671 21.2116 20.4015 27.436C20.6832 28.4287 21.2332 28.8445 22.2527 28.8445C27.4845 28.8445 32.7162 28.8445 37.9479 28.8445C38.9405 28.8445 39.5308 28.3884 39.7723 27.4494C40.671 23.8543 41.5698 20.2592 42.4686 16.6641C42.482 16.5567 42.4954 16.4494 42.5357 16.275Z" fill={color}/>
  <path d="M35.882 34.9214C37.3308 34.908 38.5515 36.1019 38.5649 37.5641C38.5784 39.0263 37.371 40.2604 35.8954 40.2604C34.4332 40.2604 33.2393 39.0665 33.2393 37.6043C33.2393 36.1421 34.4332 34.9348 35.882 34.9214Z" fill={color}/>
  <path d="M26.9881 37.6043C26.9881 39.0665 25.7942 40.2604 24.332 40.2604C22.8698 40.2604 21.6893 39.0799 21.6759 37.6043C21.6759 36.1287 22.8698 34.9214 24.3454 34.9348C25.8076 34.9348 26.9881 36.1287 26.9881 37.6043Z" fill={color}/>
</svg>
)

