import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'react-router-dom'
import { useStyles } from './useStyle'

import styles from './index.module.scss'

import { Grid, Box } from '@material-ui/core'

import Logo from './images/logo.png'
import Facebook from './images/facebook.png'
import Instagram from './images/instagram.png'
import Easylive from './images/LogoEasy.png'
import { useSelector } from 'react-redux'
import { isLoggedIn } from 'store/selectors'

const Footer = () => {
  const classes = useStyles()

  const partnerSettings = useSelector((state) => state.global.partner)

  const isLogged = useSelector(isLoggedIn)

  return (
    <Box className={classes.container}>
      <Box
        className={
          isLogged ? classes.loggedFooterWrapper : classes.footerWrapper
        }
      >
        <Box xs={12} md={2}>
          <img src={Logo} alt="Logo" className={classes.logo} />
        </Box>

        {!isLogged && (
          <Grid item xs={12} md={2} className={classes.options}>
            <div className={classes.titleItems}>Para a Empresa</div>
            <Link to="/planos" className={classes.anchor}>
              <div className={classes.footerItems}>Planos</div>
            </Link>
            <Link to="/vantagens" className={classes.anchor}>
              <div className={classes.footerItems}>Vantagens</div>
            </Link>
          </Grid>
        )}

        {!isLogged && (
          <Grid item xs={12} md={2} className={classes.options}>
            <div className={classes.titleItems}>Sobre</div>
            <Link to="/solucao" className={classes.anchor}>
              <div className={classes.footerItems}>A solução</div>
            </Link>
          </Grid>
        )}

        {!isLogged && (
          <Grid item xs={12} md={2} className={classes.options}>
            <div className={classes.titleItems}>Social</div>

            <a
              aria-label="instagram mais alegria"
              href="https://www.instagram.com/clubemaisalegria/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.anchor}
            >
              <img
                src={Instagram}
                alt="Instagram"
                className={classes.instagram}
              />
            </a>

            <a
              aria-label="facebook mais alegria"
              href="https://www.facebook.com/clubemaisalegria"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Facebook} alt="Facebook" className={classes.facebook} />
            </a>
          </Grid>
        )}
        <Box className={classes.easyliveContainer}>
          {/* <div className={classes.easyLiveFooter}>Uma solução</div> */}
          <img src={Easylive} alt="Easylive" className={classes.easylive} />
        </Box>
      </Box>
      {partnerSettings &&
        partnerSettings.documents &&
        partnerSettings.documents.privacy_politic && (
          <CookieConsent
            location="bottom"
            buttonText="Concordo"
            cookieName="AU_consent"
            containerClasses={styles.cookieConsent}
            contentClasses={styles.contentConsent}
            buttonWrapperClasses={styles.btnWrapperConsent}
            buttonClasses={styles.btnConsent}
            expires={150}
          >
            Nós usamos cookies e outras tecnologias semelhantes para melhorar a
            sua experiência em nossos serviços e recomendar conteúdo de seu
            interesse. Ao utilizar nossos serviços, você concorda com tal
            monitoramento. Veja a nossa{' '}
            <a
              aria-label="Documentos de política de privacidade"
              href={partnerSettings.documents.privacy_politic}
              target="_blank"
              rel="noopener noreferrer"
            >
              política de privacidade
            </a>
            .
          </CookieConsent>
        )}
    </Box>
  )
}

export default Footer
