// import { createRoot } from "react-dom/client";
import React from "react";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import store from "./store";

import ReactDOM from "react-dom";

import App from "./App";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "utils/theme";

// const root = createRoot(document.getElementById("root"));

// root.render((
//   <Provider store={store}>
//     <ThemeProvider theme={theme}>
//       <CookiesProvider>
//         <App />
//       </CookiesProvider>
//     </ThemeProvider>
//   </Provider>
// ));

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
