import React from "react";
import styles from "./index.module.scss";

const ErroApi = () => {
  return (
    <div className={styles.page}>
      <div className={styles.errorMessage}>
        <p>Olá =]</p>
        <p>
          Estamos realizando alguns ajustes no nosso site. Em breve retornaremos
          com nossas ofertas.
        </p>
      </div>
    </div>
  );
};

export default ErroApi;
