import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LazyLoad from 'react-lazyload'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'

import SwiperCore, { Navigation, Thumbs } from 'swiper/core'

import styles from './styles.module.scss'

SwiperCore.use([Navigation, Thumbs]);

const ThumbsCarousel = () => {
  const product = useSelector(({ product }) => product.product)
  const [thumb, setThumb] = useState()

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Swiper
          modules={[ Navigation, Thumbs ]}
          thumbs={{ swiper: thumb }}
        >
          {product?.images?.length > 0 && product.images.map((product, i) => (
            <SwiperSlide
              key={i}
            >
              <LazyLoad once>
                <img src={product.url} alt={product.title} className={styles.image} />
              </LazyLoad>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.wrapper}>
        <Swiper
          onSwiper={setThumb}
          spaceBetween={14}
          slidesPerView={4}
          freeMode
          watchSlidesVisibility
          watchSlidesProgress
          observer
          observeParents
          centeredSlides={product?.images?.length === 1 ? true : false}
          className={styles.tumbsImagens}
        >
          {product?.images?.length > 0 && product.images.map((product, i) => (
            <SwiperSlide
              key={i}
            >
              <LazyLoad once>
                <img src={product.url} alt={product.title} className={styles.thumbs} />
              </LazyLoad>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.mobileContainer}>
        {product?.images.length > 0 && (
          <img
            src={product.images[0].url}
            alt={product.title}
            className={styles.image}
          />
        )}
      </div>
    </div>
  )
}

export { ThumbsCarousel }
