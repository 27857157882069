import * as Actions from '../actions'

const initialState = {
  product: null,
  ticketPrices: [],
  checkout: {
    use_gift_vouchers: true,
    order: [],
  },
  savedOnHistory: false,
  loading: false,
}

const productReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_PRODUCT: {
      return {
        ...state,
        product: action.payload,
      }
    }
    case Actions.SET_TICKET_PRICES: {
      return {
        ...state,
        ticketPrices: action.payload,
      }
    }
    case Actions.SET_CHECKOUT_INFO: {
      return {
        ...state,
        checkout: {
          ...action.payload,
          updatedAt: Date.now(),
        },
      }
    }
    case Actions.SET_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case Actions.RESET_LOADING: {
      return {
        ...state,
        loading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default productReducer
