import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      background: "#FCF0DD",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "-270px",
      paddingTop: "270px",
      [theme.breakpoints.down("md")]: {
        marginTop: "76px",
        paddingTop: "7px",
      },
    },
    container: {
      padding: theme.spacing(2),
      paddingBottom: "110px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "1200px",

      [theme.breakpoints.down("md")]: {
        marginTop: "30px",
        paddingBottom: "56px",
      },
    },
    headlineContainer: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
      },
    },

    imageContainer: {
      width: "500px",
      height: "496px",
      background: "#fff",
      borderRadius: "22.246px",
      boxShadow: "0px 4px 6px 0px #E3C99C",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        width: "293px",
        height: "291px",
        marginTop: "45px",
      },
    },
    title: {
      color: "#ED9915",
      textAlign: "center",
      fontFamily: "NeuePlak-Bold",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "46px",
      textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        color: "#ED9915",
        fontSize: "15px",
        fontStyle: "normal",
        lineHeight: "30.195px",
      },
    },
    headline: {
      color: "#2F2F2F",
      fontSize: "36px",
      fontWeight: 900,
      lineHeight: "46px",
      width: "85%",
      fontFamily: "NeuePlak-Black",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "23px",
        width: "94%",
        textAlign: "center",
        lineHeight: "30px",
        letterSpacing: "1.258px",
      },
    },
    subheadline: {
      marginTop: "10px",
      color: "#888",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "30px",
      width: "90%",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        width: "40%",
        textAlign: "center",
        lineHeight: "18.843px",
        letterSpacing: "0.42px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        width: "83%",
        textAlign: "center",
        lineHeight: "19px",
      },
    },

    image: {
      position: "absolute",
      right: "-76px",

      [theme.breakpoints.down("md")]: {
        width: "300px",
        height: "216px",
        marginRight: "78px",
        marginBottom: "0px",
      },
    },
    dashboardDataContainer: {
      position: "absolute",
      left: "137px",
      top: "91px",
      width: "242px",
      height: "366px",
      paddingLeft: "24px",
      flexShrink: 0,
      borderRadius: "29px",
      background: "#13224C",
      boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.15)",
      zIndex: 2,
      [theme.breakpoints.down("md")]: {
        width: "142px",
        height: "215px",
        left: "88px",
        top: "49px",
        paddingLeft: "14px",
      },
    },
    painel: {
      marginTop: "86px",
      [theme.breakpoints.down("md")]: {
        marginTop: "50px",
        width: "34px",
        height: "34px",
      },
    },
    dashboardTitle: {
      color: "#ED9915",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "NeuePlak-ExtraBlack",
      fontSize: "27.055px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24.73px",
      letterSpacing: "0.541px",
      textTransform: "uppercase",
      marginTop: "46px",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "14px",
      },
    },
    dashboardText: {
      color: "#FFF",
      width: "130px",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "NeuePlak-Bold",
      fontSize: "15.218px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "19.445px",
      [theme.breakpoints.down("md")]: {
        width: "75px",
        fontSize: "9px",
        lineHeight: "11.5px",
      },
    },
  };
});
