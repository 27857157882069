import moment from "moment";
import {
  logoutRequest,
  getSubscriptionData,
  getUserData,
  getCategories,
  getHomeBanners,
  // getLoyaltyPrograms,
  getSettings,
  getPartnerSettings,
} from "services/api/index";
import * as GeneralActions from "store/actions";

export const GET_USER = "[GLOBAL USER] GET USER";
export const SET_HEADER_INFO = "[GLOBAL USER] SET HEADER INFO";
export const SET_USER_INFO = "[GLOBAL USER] SET USER INFO";
export const USER_LOGOUT = "[GLOBAL USER] USER LOGOUT";
export const USER_LOGIN = "[GLOBAL USER] USER LOGIN";
export const SET_COMPANY_LOGO = "[GLOBAL BEHAVIOR] SET COMPANY LOGO";
export const SET_SUBSCRIPTION_INFO = "[GLOBAL BEHAVIOR] SET SUBSCRIPTION INFO";

export function setUser(user) {
  return { type: SET_USER_INFO, payload: user };
}

export function setHeaders(headers) {
  return (dispatch) => {
    dispatch({ type: SET_HEADER_INFO, payload: headers });
  };
}

export function userLogin(data) {
  return (dispatch) => {
    dispatch({ type: USER_LOGIN, user: data.user, headers: data.headers });
    getSubscriptionData().then((result) => {
      if (result?.logo) {
        dispatch(setCompanyLogo(result?.logo));
      }
    });
  };
}

export function getUser() {
  return (dispatch) => {
    const headers = JSON.parse(localStorage.getItem("AU_header"));
    dispatch(setStateFromLocalStorage());
    getUserData().then((response) => {
      if (response && response.success) {
        let bornDate = moment(response.data.born_date, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );

        let benefitTier = response.data.benefit_tier || "+Alegria";

        dispatch(
          setUser({
            ...response.data,
            born_date: bornDate,
            benefit_tier: benefitTier,
          })
        );

        localStorage.setItem(
          "AU_data",
          JSON.stringify({
            id: response.data.id,
            provider: response.data.provider,
            subscription: response.data.subscription_status.active,
          })
        );
      } else if (response && response.errors) {
        localStorage.removeItem("AU_header");
        localStorage.removeItem("AU_data");
        localStorage.removeItem("AU_order");
        if (headers.provider) {
          window.location = "/" + headers.provider;
        } else {
          window.location = "/";
        }
      }
    });
  };
}

export function userLogout() {
  return (dispatch) => {
    dispatch({ type: USER_LOGOUT });
    dispatch(setCompanyLogo(null));
  };
}

export function setStateFromLocalStorage() {
  return (dispatch) => {
    if (localStorage.getItem("AU_header") !== "undefined") {
      const headers = JSON.parse(localStorage.getItem("AU_header"));

      if (headers && headers.expiry < moment().unix()) {
        logoutRequest();

        localStorage.removeItem("AU_header");
        localStorage.removeItem("AU_data");
        sessionStorage.removeItem("AU_redirect");
        localStorage.removeItem("AU_order");
        if (headers.provider) {
          window.location = "/" + headers.provider;
        } else {
          window.location = "/";
        }
      } else {
        dispatch({ type: SET_HEADER_INFO, payload: headers });
      }
    } else {
      logoutRequest();

      localStorage.removeItem("AU_header");
      localStorage.removeItem("AU_data");
      localStorage.removeItem("AU_order");
      sessionStorage.removeItem("AU_redirect");
    }
  };
}

export function loadApplication() {
  return (dispatch) => {
    dispatch(GeneralActions.getUser());

    // getLoyaltyPrograms()
    //   .then((result) => {
    //     dispatch(GeneralActions.setLoyaltyPrograms(result));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    getUserData().then((response) => {
      getSettings()
        .then((result) => {
          let logged = false;
          if (response) {
            logged = response.success;
          }
          dispatch(GeneralActions.setGlobalSettings(result, logged));
        })
        .catch((err) => {
          console.log(err);
        });
    });

    getPartnerSettings()
      .then((result) => {
        dispatch(GeneralActions.setPartnerSettings(result));
      })
      .catch((err) => {
        console.log(err);
      });

    getCategories()
      .then((result) => {
        dispatch(GeneralActions.setInterestAreas(result));
      })
      .catch((err) => {
        console.log(err);
      });

    getHomeBanners()
      .then((result) => {
        dispatch(GeneralActions.setHomeBanners(result));
      })
      .catch((err) => {
        console.log(err);
      });

    getSubscriptionData()
      .then((result) => {
        dispatch(GeneralActions.setSubscriptionInfo(result));
        dispatch(GeneralActions.setCompanyLogo(result?.logo));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function setSubscriptionInfo(info) {
  return (dispatch) => {
    dispatch({ type: SET_SUBSCRIPTION_INFO, payload: info });
  };
}

export function setCompanyLogo(logo) {
  return (dispatch) => {
    dispatch({ type: SET_COMPANY_LOGO, payload: logo });
  };
}
