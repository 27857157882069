import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import LazyLoad from "react-lazyload";

import { isLoggedIn } from "store/selectors";

import CouponBanner from "./images/coupon-promo-bg.png";
import styles from "./index.module.scss";

const FooterBanner = () => {
  const history = useHistory();
  const logged = useSelector(isLoggedIn);

  const openPage = () => {
    if (logged) {
      history.push(`/usuario/cupons-de-desconto`);
    } else {
      history.push(`/partners`)
    }
  };

  return (
    <div className={styles.wrapper}>
      <LazyLoad once>
        <img
          src={CouponBanner}
          className={styles.banner}
          alt="Cupons de Desconto"
          onClick={() => openPage()}
        />
      </LazyLoad>
    </div>
  );
};

export default FooterBanner;
