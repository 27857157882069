import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";

export default function PageLoading({ children }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1300);
  }, []);

  const containerStyle = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  };

  if (loading) {
    return (
      <div style={containerStyle}>
        <ReactLoading type={ "bars"} color={ "#A9A9A9"} height={40} width={40} />
      </div>
    );
  }

  return <div>{children}</div>;
}

