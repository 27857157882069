import { useEffect } from "react";
import Functionality from "./components/functionality";
import Layout from "components/layout";
import SolutionDashboard from "./components/solutionDashboard";
import React from "react";
import Personalization from "./components/personalization";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isLoggedIn } from "store/selectors";
import { useSelector } from "react-redux";
const Solution = () => {

  const history = useHistory();

  const isLogged = useSelector(isLoggedIn)

  useEffect(() => {
    if(isLogged) {
      history.push("/ofertas")
    }

  }, [isLogged, history])

  useEffect(() => {window.scrollTo(0, 0)}, []);

  return (
    <Layout>
      <Functionality />
      <SolutionDashboard />
      <Personalization />
    </Layout>
  );
};

export default Solution;
