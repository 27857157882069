export const SHOW_LOGIN_MODAL = "[GLOBAL BEHAVIOR] SHOW LOGIN MODAL";
export const SHOW_REGISTER_MODAL = "[GLOBAL BEHAVIOR] SHOW REGISTER MODAL";
export const SHOW_FORGOT_PASSWORD_MODAL =
  "[GLOBAL BEHAVIOR] SHOW FORGOT PASSWORD MODAL";
export const SHOW_MARATONA_MASTERCLASS_MODAL =
  "[GLOBAL BEHAVIOR] SHOW MARATONA MASTERCLASS MODAL";
export const SHOW_NOTIFICATIONS_MODAL =
  "[GLOBAL BEHAVIOR] SHOW NOTIFICATIONS MODAL";
export const SET_REGISTRATION_TEMP_DATA =
  "[GLOBAL BEHAVIOR] SET REGISTRATION TEMP DATA";
export const SET_REQUEST_ERRORS = "[GLOBAL BEHAVIOR] SET REQUEST ERRORS";
export const SET_ACTIVE_ORDER_ITEM = "[GLOBAL BEHAVIOR] SET ACTIVE ORDER ITEM";
export const SET_ACTIVE_ORDER = "[GLOBAL BEHAVIOR] SET ACTIVE ORDER";
export const SHOW_VIDEO_MODAL = "[GLOBAL BEHAVIOR] SHOW VIDEO MODAL";
export const IS_APP = "[GLOBAL BEHAVIOR] IS_APP";

export function showLoginModal(show, provider = "email", data = {}) {
  return (dispatch) => {
    dispatch({
      type: SHOW_LOGIN_MODAL,
      payload: { show: show, provider: provider, data: data },
    });
  };
}
export function showForgotPasswordModal(action, provider = "email", data) {
  return (dispatch) => {
    dispatch({
      type: SHOW_FORGOT_PASSWORD_MODAL,
      payload: { action: action, provider: provider, data: data },
    });
  };
}

export function showRegisterModal(show) {
  return (dispatch) => {
    dispatch({ type: SHOW_REGISTER_MODAL, payload: show });
  };
}

export function setRegistrationTempData(data) {
  return (dispatch) => {
    dispatch({ type: SET_REGISTRATION_TEMP_DATA, payload: data });
  };
}

export function showMaratonaMasterclassModal(show) {
  return (dispatch) => {
    dispatch({ type: SHOW_MARATONA_MASTERCLASS_MODAL, payload: show });
  };
}

export function showNotificationsModal(show) {
  return (dispatch) => {
    dispatch({ type: SHOW_NOTIFICATIONS_MODAL, payload: show });
  };
}
export function showVideoModal(show, provider = "email") {
  return (dispatch) => {
    dispatch({
      type: SHOW_VIDEO_MODAL,
      payload: { show: show, provider: provider },
    });
  };
}

export function isApp(value) {
  return (dispatch) => {
    dispatch({ type: IS_APP, payload: value });
  };
}

export function setRequestErrors(errors) {
  return (dispatch) => {
    dispatch({ type: SET_REQUEST_ERRORS, payload: errors });
  };
}

export function setActiveOrderItem(orderItem) {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVE_ORDER_ITEM, payload: orderItem });
  };
}

export function setActiveOrder(order) {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVE_ORDER, payload: order });
  };
}
