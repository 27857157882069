import {
  getCards as getCardsRequest,
  getGiftVouchers as getGiftVouchersRequest,
  getProduct,
  performOrderCheckout,
} from 'services/api'

export const REFRESH_CART_ITEMS = '[ORDER CHECKOUT] REFRESH CART ITEMS'
export const GET_CARDS = '[ORDER CHECKOUT] GET CARDS'
export const GET_GIFT_VOUCHERS = '[ORDER CHECKOUT] GET GIFT VOUCHERS'
export const SET_CART = '[ORDER CHECKOUT] SET CART'
export const SET_ORDER_SUBTOTAL = '[ORDER CHECKOUT] SET ORDER SUBTOTAL'
export const SET_ORDER_FINAL_VALUE = '[ORDER CHECKOUT] SET ORDER FINAL VALUE'
export const SET_STEPS = '[ORDER CHECKOUT] SET STEPS'
export const SET_COUPON = '[ORDER CHECKOUT] SET COUPON'
export const SET_USE_GIFT_VOUCHERS = '[ORDER CHECKOUT] SET USE GIFT VOUCHERS'
export const SET_USE_PIX = '[ORDER CHECKOUT] SET USE PIX'
export const SET_USE_AME = '[ORDER CHECKOUT] SET USE AME'
export const SET_USE_PIC_PAY = '[ORDER CHECKOUT] SET USE PIC PAY'
export const SET_CURRENT_STEP = '[ORDER CHECKOUT] SET CURRENT STEP'
export const SET_PAYMENT_INFO = '[ORDER CHECKOUT] SET PAYMENT INFO'
export const SET_CURRENT_PAYMENT_TAB =
  '[ORDER CHECKOUT] SET CURRENT PAYMENT TAB'
export const SET_ADDRESS_INFO = '[ORDER CHECKOUT] SET ADDRESS INFO'
export const SET_BILLING_ADDRESS = '[ORDER CHECKOUT] SET BILLING ADDRESS'
export const SET_PROCESS_RESULT = '[ORDER CHECKOUT] SET ORDER RESULT'
export const SET_SENDING_REQUEST = '[ORDER CHECKOUT] SET SENDING REQUEST'
export const SET_LOADING = '[ORDER CHECKOUT] SET LOADING'
export const RESET_LOADING = '[ORDER CHECKOUT] RESET LOADING'

const handleProductRequest = async (id, priceId, quantity) => {
  return getProduct(id).then((response) => {
    let refreshedPrice = null
    let productTickets = response.tickets

    productTickets.forEach((ticket) => {
      let ticketPrice = ticket.prices.find((price) => price.id === priceId)
      if (ticketPrice) {
        refreshedPrice = ticketPrice
      }
    })

    return {
      id: response.id,
      name: response.title,
      photo: response?.images[0]?.url,
      priceId,
      quantity,
      value: refreshedPrice?.price_in_money,
    }
  })
}

export function refreshCartItems() {
  return (dispatch) => {
    dispatch(setLoading())
    const orderProducts = JSON.parse(localStorage.getItem('AU_order'))
    let oldCart = orderProducts.order

    let refreshedProductList = Promise.all(
      oldCart.map(async (product) => {
        if (product.quantity > 0 && product.quantity <= 10) {
          return await handleProductRequest(
            product.id,
            product.price_id,
            product.quantity,
          )
        } else {
          localStorage.removeItem('AU_order')
        }
      }),
    )

    refreshedProductList.then((refreshedItems) => {
      dispatch({ type: SET_CART, payload: refreshedItems })
      dispatch(updateOrderValues())
    })
  }
}

export function getGiftVouchers() {
  return (dispatch) => {
    dispatch(setLoading())
    const request = getGiftVouchersRequest()
    request
      .then((response) => {
        dispatch({ type: GET_GIFT_VOUCHERS, payload: response })
        dispatch(updateOrderValues())
      })
      .finally(dispatch(resetLoading()))
  }
}

export function updateOrderValues() {
  return (dispatch, getState) => {
    let { cart, giftVouchers, useGiftVouchers } = getState().orderCheckout

    let subtotal = cart.reduce((accumulatedValue, currentProd) => {
      if (
        currentProd &&
        currentProd.quantity > 0 &&
        currentProd.quantity <= 10
      ) {
        return accumulatedValue + currentProd.value * currentProd.quantity
      } else {
        return 0
      }
    }, 0)

    let finalValue = subtotal

    if (useGiftVouchers) {
      let hadAvailableVoucher =
        giftVouchers && giftVouchers.available_amount > 0 ? true : false

      if (hadAvailableVoucher) {
        finalValue = subtotal - giftVouchers.available_amount

        if (finalValue < 0) finalValue = 0
      }

      dispatch({ type: SET_USE_GIFT_VOUCHERS, payload: hadAvailableVoucher })
    }

    dispatch({ type: SET_ORDER_FINAL_VALUE, payload: finalValue })
    dispatch({ type: SET_ORDER_SUBTOTAL, payload: subtotal })
  }
}

export function getCards() {
  return (dispatch) => {
    dispatch(setLoading())
    const request = getCardsRequest()
    request
      .then((response) => {
        dispatch({ type: GET_CARDS, payload: response })
      })
      .finally(dispatch(resetLoading()))
  }
}

export function setCurrentStep(newStep) {
  return (dispatch, getState) => {
    let { orderCheckout } = getState()

    let newStepIndex = orderCheckout.steps.findIndex(
      (step) => step.key === newStep,
    )
    let updatedSteps = orderCheckout.steps.map((step, index) => ({
      ...step,
      complete: index <= newStepIndex,
    }))

    dispatch({
      type: SET_CURRENT_STEP,
      payload: { key: newStep, index: newStepIndex },
    })
    dispatch({ type: SET_STEPS, payload: updatedSteps })
  }
}

export function changeStep(kind = 'next') {
  return (dispatch, getState) => {
    let { orderCheckout } = getState()
    let activeIndex = orderCheckout.currentStep?.index || 0
    let lastAvailableIndex = orderCheckout.steps.length - 1
    let goToIndex = activeIndex + (kind === 'next' ? 1 : -1)

    if (goToIndex < 0) goToIndex = 0
    else if (lastAvailableIndex < goToIndex) goToIndex = lastAvailableIndex

    dispatch(setCurrentStep(orderCheckout.steps[goToIndex].key))
  }
}

export function setPaymentInfo(data, type = '') {
  return (dispatch, getState) => {
    if (type === 'append') {
      let { orderCheckout } = getState()
      let paymentInfo = { ...orderCheckout.paymentInfo, ...data }
      dispatch({ type: SET_PAYMENT_INFO, payload: paymentInfo })
    } else {
      dispatch({ type: SET_PAYMENT_INFO, payload: data })
    }
  }
}

export function setProcessResult(data) {
  return { type: SET_PROCESS_RESULT, payload: data }
}

export function setUseGiftVouchers(data) {
  return { type: SET_USE_GIFT_VOUCHERS, payload: data }
}

export function usePix(data) {
  return { type: SET_USE_PIX, payload: data }
}

export function useAme(data) {
  return { type: SET_USE_AME, payload: data }
}

export function usePicPay(data) {
  return { type: SET_USE_PIC_PAY, payload: data }
}

export function performCheckout() {
  return (dispatch, getState) => {
    const userHeader = JSON.parse(localStorage.getItem('AU_header'))
    const sessionID = localStorage.getItem('AU_session')

    let { orderCheckout } = getState()

    let order = {
      address: orderCheckout.addressInfo,
      payment_type: orderCheckout.usePix
        ? 'pix'
        : orderCheckout.useAme
          ? 'ame'
          : orderCheckout.usePicPay
            ? 'picpay'
            : 'credit_card',
      credit_card: orderCheckout.usePix
        ? undefined
        : {
            ...orderCheckout.paymentInfo,
            number: null,
            cvv: null,
          },
      use_gift_vouchers: orderCheckout.useGiftVouchers,
      installments: orderCheckout.paymentInfo?.installments || 1,
      installments_value:
        orderCheckout.paymentInfo?.installments_value ||
        orderCheckout.orderFinalValue,
      subtotal: orderCheckout.orderSubtotal,
      total: orderCheckout.orderFinalValue,
      items: orderCheckout.cart.map((item) => ({
        price_id: item.priceId,
        quantity: item.quantity,
      })),
    }
    let paymentData = {
      pix: orderCheckout.usePix,
      session_id: sessionID,
      phone: orderCheckout.paymentInfo?.phone,
    }

    dispatch(setLoading())
    dispatch(sendingRequest(true))
    performOrderCheckout({
      order: order,
      payment_data: paymentData,
      authenticity_token: userHeader['X-CSRF-Token'],
    })
      .then((data) => {
        dispatch(setProcessResult(data))
        dispatch(changeStep('next'))

        // getAllQuiz().then(data => {
        //   const { quizzes } = data;

        //     if (quizzes.length) {
        //       const hasQuiz = quizzes.find(quiz => quiz.area === 'checkout');
        //       if (hasQuiz) {
        //         localStorage.setItem('hasQuiz', JSON.stringify({
        //           area: 'checkout',
        //           quiz: hasQuiz,
        //           alreadyAnswer: false
        //         }))
        //       }
        //     }
        // })
      })
      .finally(() => {
        dispatch(resetLoading())
        dispatch(sendingRequest(false))
      })
  }
}

export function setAddressInfo(data) {
  return (dispatch) => {
    dispatch({ type: SET_ADDRESS_INFO, payload: data })
  }
}

export function setBillingAddress(data) {
  return (dispatch) => {
    dispatch({ type: SET_BILLING_ADDRESS, payload: data })
  }
}

export function setCoupon(data) {
  return { type: SET_COUPON, payload: data }
}

export function sendingRequest(data) {
  return {
    type: SET_SENDING_REQUEST,
    payload: data,
  }
}

export function setCurrentPaymentTab(data) {
  return {
    type: SET_CURRENT_PAYMENT_TAB,
    payload: data,
  }
}

export function setLoading() {
  return {
    type: SET_LOADING,
  }
}

export function resetLoading() {
  return {
    type: RESET_LOADING,
  }
}
