import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import LazyLoad from "react-lazyload";

import { classNames, formatMonetaryValue } from "utils";
import styles from "./index.module.scss";
import { getUser, setCompanyLogo, setSubscriptionInfo } from "store/actions";
import { Link } from "react-router-dom";
import { getSubscriptionData } from "services/api";

import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function () {
  const dispatch = useDispatch();

  const subscriptionResult = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.subscriptionResult
  );

  const usePix = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.usePix
  );

  const useAme = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.useAme
  );

  const usePicPay = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.usePicPay
  );

  const plan = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.plan
  );

  const availableAmount = useSelector(
    ({ subscriptionCheckout }) =>
      subscriptionCheckout.giftVouchers?.available_amount
  );

  const is100Discount = parseFloat(plan.price_in_money - availableAmount) <= 0;

  React.useEffect(() => {
    dispatch(getUser());

    getSubscriptionData().then((result) => {
      dispatch(setSubscriptionInfo(result));
      if (result?.logo) {
        dispatch(setCompanyLogo(result?.logo));
      }
    });
  }, [dispatch]);

  return (
    <div className={styles.rootContainer}>
      <Row>
        <div
          className={classNames(
            styles.pedidoCardWrapper,
            "border",
            "border-secondary",
            "my-4",
            "p-3",
            "w-100"
          )}
        >
          <div className={styles.pedidoContainer}>
            {subscriptionResult && (
              <>
                {subscriptionResult.status === "Rejeitado" ? (
                  <RejectedStatus />
                ) : (
                  <>
                    {usePix ? (
                      is100Discount ? (
                        <ApprovedStatus data={subscriptionResult} />
                      ) : (
                        <WaitingForPaymentStatus data={subscriptionResult} />
                      )
                    ) : useAme ? (
                      is100Discount ? (
                        <ApprovedStatus data={subscriptionResult} />
                      ) : (
                        <WaitingForPaymentStatusAmeDigital
                          data={subscriptionResult}
                        />
                      )
                    ) : usePicPay ? (
                      is100Discount ? (
                        <ApprovedStatus data={subscriptionResult} />
                      ) : (
                        <WaitingForPicPayPaymentStatus
                          data={subscriptionResult}
                        />
                      )
                    ) : (
                      <ApprovedStatus data={subscriptionResult} />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Row>
    </div>
  );
}

const RejectedStatus = () => (
  <div>
    <p className="mb-3 text-center h5">
      <strong>ATENÇÃO! SUA ASSINATURA FOI REJEITADA</strong>
    </p>
    <p className="text-center">
      <strong>
        Houve um erro ao processar seu pedido. Tente novamente em alguns
        instantes.
      </strong>
    </p>
  </div>
);

const ApprovedStatus = ({ data }) => (
  <div>
    <p className="mb-3 text-center h5">
      <strong>SUA ASSINATURA FOI CONCLUÍDA</strong>
    </p>
    <p className="text-center">
      Estamos muito felizes em ter você com a gente. Sua assinatura está
      começando agora! Veja aqui as suas <Link to={"/ofertas"}>ofertas</Link>.
    </p>
  </div>
);

const WaitingForPaymentStatus = ({ data }) => {
  const isApp = useSelector((state) => state.behavior.isApp);
  const [copyEffect, setCopyEffect] = React.useState(false);

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopyEffect(false);
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);

    setCopyEffect(true);
  };

  return (
    <div className="text-center">
      <p className="mb-3 text-center h5">
        <strong>PARABÉNS SEU PEDIDO FOI RECEBIDO COM SUCESSO!</strong>
      </p>

      <div className="d-flex flex-column align-items-center">
        <p className="text-center">
          <strong>
            Para realizar o pagamento via PIX, escaneie o código no App do seu
            banco para pagar.
          </strong>
        </p>
        <LazyLoad once>
          <img
            src={data.qrcode_url}
            className={styles.qrCodeImg}
            alt="QR Code PIX"
          />
        </LazyLoad>

        {isApp ? (
          <span>{data.qrcode_text_url}</span>
        ) : (
          <Button
            aria-label="copiar código"
            variant="outlined"
            onClick={() => copyToClipboard(data.qrcode_text_url)}
          >
            Copiar código
          </Button>
        )}
        <p className="text-center mt-2">
          <strong>
            Após o pagamento você pode acompanhar o status da sua assinatura
            através da área{" "}
            <Link
              className={styles.txtOrange}
              to="/usuario/gerenciar-assinaturas"
            >
              Gerenciar Assinatura
            </Link>
            .
          </strong>
        </p>
        <p className="text-center mt-2">
          <strong>
            IMPORTANTE: O QR Code ficará disponível para pagamento por 24 horas.
            Após esse período o pedido será cancelado!
          </strong>
        </p>
      </div>

      <Snackbar
        open={copyEffect}
        autoHideDuration={2000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="success">
          Código copiado para área de transferência.
        </Alert>
      </Snackbar>
    </div>
  );
};

const WaitingForPicPayPaymentStatus = ({ data }) => {
  const isApp = useSelector((state) => state.behavior.isApp);
  const [copyEffect, setCopyEffect] = React.useState(false);

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopyEffect(false);
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);

    setCopyEffect(true);
  };

  return (
    <div className="text-center">
      <div class={styles.logoPicPay}>
        <svg xmlns="http://www.w3.org/2000/svg" id="svg" viewBox="0 0 129 44">
          <path
            class="logo"
            d="M28 34.3h5.8V17.4H28V34.3zM35.8 5.9h-3.9v3.9h3.9V5.9zM13.9 7.8H8.3v4.9h5.2c3.3 0 5.2 1.6 5.2 4.6 0 3-1.9 4.7-5.2 4.7H8.3v-9.2H2.5v21.5h5.8v-7.4h5.5c6.7 0 10.6-3.6 10.6-9.8C24.4 11.3 20.6 7.8 13.9 7.8zM39.7 2H28v11.7h11.7V2zM37.8 11.7H30V3.9h7.8V11.7zM71.8 7.8h-5.3v4.9h5c3.3 0 5.2 1.6 5.2 4.6 0 3-1.9 4.7-5.2 4.7h-5v-9.2h-5.8v21.5h5.8v-7.4h5.3c6.7 0 10.6-3.6 10.6-9.8C82.4 11.3 78.5 7.8 71.8 7.8zM120.5 14l-5 12.6 -5-12.6h-6l8 20.3 -3.1 7.7h6.1l11-28H120.5zM94.5 13.9c-3.5 0-6.2 0.8-9.2 2.3l1.8 4c2.1-1.2 4.2-1.8 6.1-1.8 2.8 0 4.2 1.2 4.2 3.4v0.4h-5.6c-5 0-7.7 2.3-7.7 6.1 0 3.7 2.6 6.3 7 6.3 2.8 0 4.8-1 6.4-2.7v2.2h5.7l0-13.2C103 16.6 99.9 13.9 94.5 13.9zM97.9 27.5c-0.6 1.7-2.3 3.1-4.7 3.1 -2 0-3.2-1-3.2-2.6 0-1.6 1.1-2.3 3.3-2.3h4.6V27.5zM48.6 29.9c-2.8 0-4.8-2.2-4.8-5.5 0-3.2 2-5.4 4.8-5.4 2 0 3.5 0.8 4.6 2.2l3.9-2.8c-1.8-2.7-4.9-4.3-8.8-4.3C42.2 14 38 18.2 38 24.4c0 6.2 4.2 10.3 10.3 10.3 4.2 0 7.3-1.7 9-4.5l-4-2.7C52.3 29.1 50.7 29.9 48.6 29.9z"
          ></path>
        </svg>
      </div>
      <p className="mb-3 text-center h5">
        <strong>PARABÉNS SEU PEDIDO FOI RECEBIDO COM SUCESSO!</strong>
      </p>

      <div className="d-flex flex-column align-items-center">
        <b className={styles.titlePicPay}>Pague com PicPay</b>
        <p className="text-center">
          <strong>
            Abra o PicPay em seu telefone e escaneie o código abaixo
          </strong>
        </p>
        <LazyLoad once>
          <img
            src={data.qrcode_url}
            className={styles.qrCodeImg}
            alt="QR Code PIX"
          />
        </LazyLoad>

        {isApp ? (
          <span>{data.qrcode_text_url}</span>
        ) : (
          <Button
            aria-label="copiar código"
            variant="outlined"
            onClick={() => copyToClipboard(data.qrcode_text_url)}
          >
            Copiar código
          </Button>
        )}

        <div className={styles.messageQrcode}>
          <p>
            <strong>
              Se tiver algum problema com a leitura do QR code, atualize o
              aplicativo.{" "}
            </strong>
          </p>
          <p>
            <a
              aria-label="link para suporte com picpay"
              target="_blank"
              rel="noopener noreferrer"
              href="https://meajuda.picpay.com/hc/pt-br/articles/360045117912-Quero-fazer-a-adi%C3%A7%C3%A3o-mas-a-op%C3%A7%C3%A3o-n%C3%A3o-aparece-para-mim-E-agora-"
            >
              Saiba como atualizar o aplicativo
            </a>
          </p>
        </div>
        <p className="text-center mt-2">
          <strong>
            Após o pagamento você pode acompanhar o status da sua assinatura
            através da área{" "}
            <Link
              className={styles.txtOrange}
              to="/usuario/gerenciar-assinaturas"
            >
              Gerenciar Assinatura
            </Link>
            .
          </strong>
        </p>
        <p className="text-center mt-2">
          <strong>
            Se tiver algum problema com a leitura do QR code, atualize o
            aplicativo.
          </strong>
        </p>
        <p className="text-center mt-2">
          <strong>
            IMPORTANTE: O QR Code ficará disponível para pagamento por 24 horas.
            Após esse período o pedido será cancelado!
          </strong>
        </p>
      </div>

      <Snackbar
        open={copyEffect}
        autoHideDuration={2000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="success">
          Código copiado para área de transferência.
        </Alert>
      </Snackbar>
    </div>
  );
};

const WaitingForPaymentStatusAmeDigital = ({ data }) => {
  return (
    <div class={styles.ameQrcodeWrapper}>
      <LazyLoad once>
        <img
          src="https://api.amedigital.com/vtex-checkout/images/logo-ame.svg"
          class={styles.logo}
          alt="Ame digital"
        />
      </LazyLoad>

      <div class={styles.container}>
        <div class={styles.paymentValues}>
          <strong>Valor da compra: </strong>
          <strong>
            <span>{formatMonetaryValue(data.paid_value)}</span>
          </strong>
        </div>

        <LazyLoad once>
          <img
            className={styles.qrcode}
            src={data.qrcode_url}
            alt="QR Code Ame Digital"
          />
        </LazyLoad>

        <div class={styles.paymentInfo}>
          <strong>Pagar com a AME é mais fácil e rápido!</strong>

          <ul>
            <li>
              <p>
                1. Abra o aplicativo Ame Digital. Se você ainda não tem, baixe
                no
                <LazyLoad once>
                  <img
                    src="https://api.amedigital.com/vtex-checkout/images/google-logo.svg"
                    alt="Google Play"
                  />
                </LazyLoad>
                ou na
                <LazyLoad once>
                  <img
                    src="https://api.amedigital.com/vtex-checkout/images/apple-logo.svg"
                    alt="App Store"
                  />
                </LazyLoad>
              </p>
            </li>
            <li>
              <p>
                2. Clique em <strong>pagar</strong>
                <LazyLoad once>
                  <img
                    src="https://api.amedigital.com/vtex-checkout/images/ame-small-logo.svg"
                    alt="Ame Logo"
                  />
                </LazyLoad>
                e aponte para o QR Code.
              </p>
            </li>
            <li>
              3. Pronto, agora é só finalizar o pagamento com{" "}
              <strong>Ame</strong>.
            </li>
            <li>
              4. Após o pagamento você pode acompanhar o status da sua
              assinatura através da área{" "}
              <Link
                className={styles.txtOrange}
                to="/usuario/gerenciar-assinaturas"
              >
                Gerenciar Assinatura
              </Link>
              .
            </li>

            <li>
              5. IMPORTANTE: O QR Code ficará disponível para pagamento por 24
              horas. Após esse período o pedido será cancelado!
            </li>
          </ul>

          <a
            aria-label="link para amedigital"
            href="https://www.amedigital.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.anchor}
          >
            Quer saber mais sobre a Ame? Clique aqui.
          </a>
        </div>
      </div>
    </div>
  );
};
