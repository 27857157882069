import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";

const SectionNewsAndDiscount = () => {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.section} onClick={() => history.push("/ofertas/recentes")}>
        <h2 className={styles.title}>
          Novidades
        </h2>
        <p className={styles.subTitle}>
          Ver ofertas
        </p>
        <div className={styles.details}/>
      </div>
      <div className={styles.section} onClick={() => history.push("/ofertas/desconto")}>
        <h2 className={styles.title}>
          Descontos
        </h2>
        <p className={styles.subTitle}>
          Ver ofertas
        </p>
        <div className={styles.details}/>
      </div>
    </div>
  )
}

export default SectionNewsAndDiscount;
