import * as Actions from "store/actions/globalData";

const INITIAL_STATE = {
  interestAreas: [],
  homeBanners: [],
  settings: null,
  partnerSettings: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.SET_INTEREST_AREAS: {
      return { ...state, interestAreas: action.payload };
    }
    case Actions.SET_ACTIVE_INTEREST_AREA: {
      let active = state.interestAreas.find(
        (area) => area.slug === action.payload
      );
      return { ...state, activeinterestArea: active };
    }
    case Actions.SET_HOME_BANNERS: {
      return { ...state, homeBanners: action.payload };
    }
    case Actions.SET_GLOBAL_SETTINGS: {
      return { ...state, settings: action.payload };
    }
    case Actions.SET_PARTNER_SETTINGS: {
      return { ...state, partner: action.payload };
    }
    default: {
      return state;
    }
  }
};
