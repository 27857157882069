import React from "react";

export const LoginIcon = ({
  color = "black",
  width = "24",
  height = "28"
}) => (
  <svg width={width} height={height} viewBox="0 0 35 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.7991 10.2322C26.8128 15.5058 22.5665 19.752 17.2518 19.8068C12.0056 19.8479 7.70453 15.5606 7.67713 10.2733C7.64974 4.97226 11.9371 0.684891 17.2381 0.684891C22.5254 0.684891 26.7717 4.93117 26.7991 10.2322Z" fill={color}/>
    <path d="M6.80466 40.3151C6.35264 40.2192 5.90061 40.1508 5.44859 40.0275C2.61317 39.233 0.832474 37.0551 0.709195 34.0827C0.585916 31.0144 0.91466 27.9872 2.07896 25.1244C2.83233 23.2752 3.92815 21.6589 5.76364 20.6864C6.88685 20.0837 8.10594 19.796 9.37982 19.8234C9.77705 19.8371 10.2017 20.0289 10.5715 20.2343C11.1605 20.5494 11.7084 20.9466 12.27 21.3028C15.5849 23.3574 18.9134 23.3574 22.2282 21.2891C22.694 21.0014 23.1597 20.7275 23.598 20.3987C24.3925 19.8097 25.2691 19.7275 26.2143 19.8645C28.7894 20.248 30.6112 21.6589 31.8303 23.9053C32.9261 25.9189 33.4192 28.0968 33.6384 30.3432C33.748 31.4938 33.8028 32.6581 33.7891 33.8224C33.748 37.2879 31.3646 39.8631 27.9265 40.2466C27.8443 40.2603 27.7621 40.2877 27.6799 40.3151C20.7078 40.3151 13.7494 40.3151 6.80466 40.3151Z" fill={color}/>
  </svg>
)
