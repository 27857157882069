import React from 'react';
import { useStyles } from './useStyle';

const StarQuiz = (props) => {
  const classes = useStyles();
  const hasQuiz = JSON.parse(localStorage.getItem('hasQuiz') || {});

  const handleCloseModal = () => {
    props.close();
  }

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>{hasQuiz?.quiz?.title}</h2>
      <p className={classes.description}>{hasQuiz?.quiz?.description}</p>
      {hasQuiz?.quiz?.image && (
        <img src={hasQuiz.quiz.image} alt='quiz' className={classes.image} />
      )}
      <button
        className={classes.btn}
        onClick={props.clickStart}
      >
        {hasQuiz?.quiz?.start_button}
      </button>
      <p className={classes.closeModal} onClick={handleCloseModal}>Responder depois</p>
    </div>
  )
}

export default StarQuiz;
