import React, { useState } from "react";
import LazyLoad from "react-lazyload";

import { classNames } from "utils";
import styles from "./index.module.scss";

import SearchIcon from "./images/search-icon.png";

const SearchBar = ({
  searchCallback,
  searchCategory,
  skipValidation = false,
  searchCategoryOption = false,
  category,
}) => {
  const [term, setTerm] = useState(null);
  const [checked, setChecked] = useState(true);

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      if (isValid()) {
        searchCallback(term);
      }
    }
  };

  const handleTerm = (e) => {
    setTerm(e.target.value);
  };

  const handleClickSearch = () => {
    if (isValid()) {
      searchCallback(term);
    }
  };

  const handleCheckSearch = (e) => {
    if (e.target.checked) {
      searchCategory(true);
      setChecked(true);
    } else {
      searchCategory(false);
      setChecked(false);
    }
  };

  const isValid = () => {
    if (Boolean(term) || skipValidation) {
      return true;
    }
    return false;
  };

  return (
    <div className={classNames("container", styles.searchwrapper)}>
      <div className={styles.searchbox}>
        <div className="input-group">
          <input
            type="text"
            className={classNames("form-control", styles.searchinput)}
            placeholder="Digite aqui o que você procura..."
            onKeyDown={handleEnter}
            onChange={handleTerm}
          />
          {searchCategoryOption && (
            <div className={styles.checkboxinput}>
              <input
                type="checkbox"
                onChange={handleCheckSearch}
                checked={checked}
              />{" "}
              <label>Somente em {category}</label>
            </div>
          )}
          <div className={styles.inputgroupappend}>
            <button
              aria-label="buscas"
              onClick={handleClickSearch}
              className={classNames("btn", styles.btAddon2)}
              type="button"
              id="button-addon2"
            >
              <LazyLoad once>
                <img
                  alt="Botao Busca"
                  src={SearchIcon}
                  className="search-icon"
                />
              </LazyLoad>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
