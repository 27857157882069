import React, { useState, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SwiperCore, { Autoplay, Scrollbar } from 'swiper/core'
import  { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { programmersHighlights } from 'services/api'

import SectionBanner from './images/section-banner.png'
import SectionBannerMobile from './images/section-banner-mobile.png'

import useIsMobile from 'utils/useIsMobile'

import styles from './index.module.scss'

SwiperCore.use([Autoplay, Scrollbar]);

const CopyVoucher = ({ voucher }) => {
  const [copied, setCopied] = useState(null)

  return (
    <>
      <CopyToClipboard
        text={voucher}
        onCopy={() => {
          setCopied(voucher);
          setTimeout(() => setCopied(null), 3000);
        }}
        style={{ cursor: 'pointer', background: '#FD721B' }}
      >
        <p>
          Cupom de desconto: <span> {voucher}</span>
        </p>
      </CopyToClipboard>
      {copied === voucher
        && <span className={styles.copied}>Cupom Copiado!</span>}
    </>
  )
}

export default function ProgrammersHighLights() {
  const isMobile = useIsMobile()
  const [products, setProducts] = useState([])

  useEffect(() => {
    programmersHighlights()
      .then(resp => setProducts(resp.offers))
  }, [])

  const handleClick = (url) => {
    window.open(url, '_blank')
  }

  return (
    <>
      {products.length && (
        <div className={styles.container}>
          <section>
            <img
              src={isMobile ? SectionBannerMobile : SectionBanner}
              alt='banner section programmers'
            />
          </section>
          <Swiper
            modules={[Autoplay, Scrollbar]}
            slidesPerView={5}
            spaceBetween={4}
            scrollbar={{ draggable: true, hide: true }}
            className={styles.products}
            style={isMobile ? { display: 'none' } : {}}
          >
            {products.map(product => (
              <SwiperSlide key={product.id} className={styles.item}>
                <div className={styles.wrapper}>
                  <div className={styles.highlight}>
                    {product.type === 'voucher'
                      ? <CopyVoucher voucher={product.voucher} />
                      : <p>Válido até <span>{product.end_date.slice(0, 5)}</span></p>
                    }
                  </div>
                  <img
                    src={product.image_url}
                    alt={product.title}
                  />
                </div>
                <div className={styles.wrapper}>
                  <p className={styles.title}>
                    {product.title}
                  </p>
                  <button onClick={() => handleClick(product.offer_url)}>
                    Acessar desconto
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            modules={[Autoplay, Scrollbar]}
            slidesPerView={1}
            spaceBetween={1}
            scrollbar={{ draggable: true, hide: true }}
           className={styles.products} style={!isMobile ? { display: 'none'} : {}}
          >
            {products.map(product => (
              <SwiperSlide key={product.id} className={styles.item}>
                <div className={styles.wrapper}>
                  {/* <div className={styles.highlight}>
                    {product.type === 'voucher'
                      ? <p style={{ background: '#FD721B' }}>Cupom de desconto: <span> {product.voucher}</span></p>
                      : <p>Válido até <span>{product.end_date.slice(0, 5)}</span></p>
                    }
                  </div> */}
                  <img
                    src={product.image_url}
                    alt={product.title}
                  />
                </div>
                <div className={styles.wrapper}>
                  <div className={styles.highlight}>
                    {product.type === 'voucher'
                      ? <p style={{ background: '#FD721B' }}>Cupom de desconto: <span> {product.voucher}</span></p>
                      : <p>Válido até <span>{product.end_date.slice(0, 5)}</span></p>
                    }
                  </div>
                  <p className={styles.title}>
                    {product.title}
                  </p>
                  <button onClick={() => handleClick(product.offer_url)}>
                    Acessar desconto
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  )
}
