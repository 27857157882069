import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form, Col, FormGroup, Row, FormControl } from 'react-bootstrap'

import CircularProgress from '@material-ui/core/CircularProgress'

import { classNames } from 'utils'
import styles from '../index.module.scss'
import { TextFieldController } from 'components/forms'
import * as Actions from '../../../store/actions'

export default function () {
  const dispatch = useDispatch()
  const { register } = useForm()
  const cardFormRef = useRef(null)
  const formCheckoutRef = useRef(null)

  const userCarrinho = JSON.parse(localStorage.getItem('AU_order'))

  const [initialLoading, setInitialLoading] = React.useState(false)

  const [isMounted, setIsMounted] = useState(false)

  const partnerSettings = useSelector((state) => state.global.partner)

  const paymentInfo = useSelector(
    ({ orderCheckout }) => orderCheckout.paymentInfo,
  )
  const [selectedCard, setSelectedCard] = useState('new')
  const [invalidBinMessage, setInvalidBinMessage] = useState(null)
  const [disableButton, setDisableButton] = useState(false)

  useEffect(() => {
    if (paymentInfo && Object.entries(paymentInfo).length !== 0) {
      if (paymentInfo.id) setSelectedCard(paymentInfo?.id)
      else setSelectedCard('new')
    }

    setTimeout(() => {
      let confirmButton = document.getElementById('confirmButton')
      if (confirmButton) {
        confirmButton.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 1200)
  }, [paymentInfo])

  let mp

  async function createFormMercadoPago() {
    try {
      mp = new window.MercadoPago(process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY)

      var installmentsValues = []

      function savePaymentData(paymentData) {
        dispatch(Actions.setPaymentInfo(paymentData))
        dispatch(Actions.changeStep('next'))
      }

      function processPaymentWithMercadoPago(paymentData) {
        savePaymentData(paymentData)
      }

      function removeInstallmentsOptions(value) {
        var selectobject = document.getElementById(
          'form-checkout__installments',
        )
        if (selectobject && selectobject.length > 0) {
          for (var i = 0; i < selectobject.length; i++) {
            if (parseInt(selectobject.options[i].value) > value) {
              selectobject.remove(i)
              i--
            }
          }
        }
      }

      cardFormRef.current = mp.cardForm({
        amount: userCarrinho.total.toString(),
        iframe: true,
        form: {
          id: 'form-checkout',
          cardNumber: {
            id: 'form-checkout__cardNumber',
            placeholder: 'Número do cartão',
          },
          expirationDate: {
            id: 'form-checkout__expirationDate',
            placeholder: 'MM/AA',
          },
          securityCode: {
            id: 'form-checkout__securityCode',
            placeholder: 'Código de segurança',
          },
          cardholderName: {
            id: 'form-checkout__cardholderName',
            placeholder: 'Titular do cartão',
          },
          issuer: {
            id: 'form-checkout__issuer',
            placeholder: 'Banco emissor',
          },
          installments: {
            id: 'form-checkout__installments',
            placeholder: 'Parcelas',
          },
          identificationType: {
            id: 'form-checkout__identificationType',
            placeholder: 'Tipo de documento',
          },
          identificationNumber: {
            id: 'form-checkout__identificationNumber',
            placeholder: 'Número do documento',
          },
          cardholderEmail: {
            id: 'form-checkout__cardholderEmail',
            placeholder: 'E-mail',
          },
        },
        callbacks: {
          onFormMounted: (error, cardForm, cardFormInstance) => {
            console.log('Form mounted', error, cardForm, cardFormInstance)
            setIsMounted(true)
            if (error)
              return console.warn('Form Mounted handling error: ', error)
            console.log('Form mounted')
          },
          onFormUnmounted: (error, cardForm, cardFormInstance) => {
            console.log('onFormUnmounted', error, cardForm, cardFormInstance)
            // setIsMounted(false);
            if (error)
              return console.warn('Form Unmounted handling error: ', error)
            console.log('Form unmounted')
          },
          onCardTokenReceived: (error, token) => {
            if (error) return console.warn('Token handling error: ', error)

            console.log('Token available')
          },
          onInstallmentsReceived: (error, installments) => {
            if (error)
              return console.warn('installments handling error: ', error)

            if (installments?.payer_costs) {
              installmentsValues = installments.payer_costs
            }

            if (
              partnerSettings?.payment_info?.cc_has_installments &&
              partnerSettings?.payment_info?.cc_installments_quantity >= 1
            ) {
              removeInstallmentsOptions(
                partnerSettings?.payment_info?.cc_installments_quantity,
              )
            }
          },
          onSubmit: (event) => {
            console.log(event)
            setDisableButton(true)
            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = cardFormRef.current.getCardFormData()

            var installments_value = amount

            var paymentData = {
              payment_method_id,
              issuer_id,
              email,
              amount,
              token,
              installments,
              installments_value,
              identificationNumber,
              identificationType,
            }

            if (
              partnerSettings?.payment_info?.cc_has_installments &&
              amount > partnerSettings?.payment_info?.cc_installments_value_min
            ) {
              paymentData.installments_value = installmentsValues?.filter(
                (item) => item.installments === installments,
              )[0].installment_amount
            } else {
              paymentData.installments = 1
            }

            setInitialLoading(true)

            processPaymentWithMercadoPago(paymentData)
            dispatch(Actions.performCheckout())
            event.preventDefault()
            cardFormRef.current.unmount()
            setDisableButton(false)
          },
          onFetching: (resource, event, data) => {
            console.log('Fetching resource: ', resource, event, data)
            console.log('Fetching resource: ', resource)
          },

          onError: (error, event, data) => {
            console.log('mmm', event, error, data)

            error.forEach((e) => {
              console.log({ message: e.message })
              if (e.message.includes('cardNumber')) {
                document.getElementById(
                  'form-checkout__cardNumber_error',
                ).style.display = 'block'
              }

              if (e.message.includes('securityCode')) {
                document.getElementById(
                  'form-checkout__securityCode_error',
                ).style.display = 'block'
              }

              if (e.message.includes('expirationMonth')) {
                document.getElementById(
                  'form-checkout__expirationDate_error',
                ).style.display = 'block'
              }

              if (e.message.includes('expirationYear')) {
                document.getElementById(
                  'form-checkout__expirationDate_error',
                ).style.display = 'block'
              }

              if (e.message.includes('identificationNumber')) {
                document.getElementById(
                  'form-checkout__identificationNumber_error',
                ).style.display = 'block'
              }

              if (e.message.includes('cardholderEmail')) {
                document.getElementById(
                  'form-checkout__cardholderEmail_error',
                ).style.display = 'block'
              }
            })
          },
          onValidityChange: (error, field) => {
            if (error) {
              return error.forEach((e) => console.log(`${field}: ${e.message}`))
            }

            console.log(`${field} is valid`)

            if (field === 'cardNumber') {
              document.getElementById(
                'form-checkout__cardNumber_error',
              ).style.display = 'none'
            }

            if (field === 'securityCode') {
              document.getElementById(
                'form-checkout__securityCode_error',
              ).style.display = 'none'
            }

            if (field === 'expirationDate') {
              document.getElementById(
                'form-checkout__expirationDate_error',
              ).style.display = 'none'
            }

            if (field === 'identificationNumber') {
              document.getElementById(
                'form-checkout__identificationNumber_error',
              ).style.display = 'none'
            }

            if (field === 'cardholderEmail') {
              document.getElementById(
                'form-checkout__cardholderEmail_error',
              ).style.display = 'none'
            }
          },
          onReady: () => {
            console.log('CardForm ready')
          },
        },
      })
    } catch (error) {
      console.error('ouihasdifuhasdiuh', error)
    }
  }

  useEffect(() => {
    createFormMercadoPago()

    return () => {
      if (cardFormRef.current) {
        cardFormRef.current.unmount()
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {!invalidBinMessage ? (
        <>
          {initialLoading && isMounted ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : (
            <Form id="form-checkout" className="mt-2" ref={formCheckoutRef}>
              <FormGroup className={styles.formGroup}>
                {selectedCard === 'new' && (
                  <>
                    <Row>
                      <div className="col-12 ">
                        <div
                          id="form-checkout__cardNumber"
                          className={styles.mercadopagoInput}
                        ></div>
                        <div
                          id="form-checkout__cardNumber_error"
                          className={styles.mercadopagoInputErrorText}
                        ></div>
                        <FormControl
                          type="hidden"
                          defaultValue={paymentInfo?.bin}
                          id="bin"
                          name="bin"
                          ref={register}
                        />
                      </div>
                    </Row>

                    <Row>
                      <div className="col-12 pt-3">
                        <input
                          id="form-checkout__cardholderName"
                          className={styles.mercadopagoInput}
                        />

                        <div
                          id="form-checkout__cardholderName_error"
                          className={styles.mercadopagoInputErrorText}
                        ></div>
                      </div>
                    </Row>

                    <Row>
                      <div className="col-6 pt-3">
                        <div
                          id="form-checkout__expirationDate"
                          className={styles.mercadopagoInput}
                        ></div>
                        <div
                          id="form-checkout__expirationDate_error"
                          className={styles.mercadopagoInputErrorText}
                        ></div>
                      </div>
                      <div className="col-6 pt-3">
                        <div
                          id="form-checkout__securityCode"
                          className={styles.mercadopagoInput}
                        ></div>
                        <div
                          id="form-checkout__securityCode_error"
                          className={styles.mercadopagoInputErrorText}
                        ></div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-6 pt-3">
                        <select
                          id="form-checkout__issuer"
                          style={{ display: 'none' }}
                        ></select>
                        <select
                          id="form-checkout__identificationType"
                          style={{ display: 'none' }}
                        ></select>
                        <TextFieldController
                          type="email"
                          id="form-checkout__cardholderEmail"
                          label="E-mail"
                          name="email"
                        />
                        <div
                          id="form-checkout__cardholderEmail_error"
                          className={styles.mercadopagoInputErrorText}
                        ></div>
                      </div>
                      <div className="col-6 pt-3">
                        <TextFieldController
                          id="form-checkout__identificationNumber"
                          label="CPF"
                          name="cpf"
                          mask="99999999999"
                          rules={{ pattern: /^\d{11}$/ }}
                        />
                        <div
                          id="form-checkout__identificationNumber_error"
                          className={styles.mercadopagoInputErrorText}
                        ></div>
                      </div>
                    </Row>
                    <Row
                      style={
                        partnerSettings?.payment_info?.cc_has_installments &&
                        userCarrinho.total >
                          partnerSettings?.payment_info
                            ?.cc_installments_value_min
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <div className="col-6 pt-3">
                        <select
                          style={{ display: 'none' }}
                          id="form-checkout__installments"
                          className={styles.mercadopagoInput}
                        ></select>
                      </div>
                    </Row>
                  </>
                )}

                {/* {billingAddress && <BillingAddress />} */}
              </FormGroup>
              <Row className="mt-3">
                {/* <Col xs={6}>
                  <button
                    aria-label="voltar"
                    type="button"
                    className={classNames(styles.backButton, 'btn-form')}
                    onClick={() => {
                      dispatch(Actions.changeStep('previous'))
                      scrollTo('#stepper')
                    }}
                  >
                    Voltar
                  </button>
                </Col> */}
                <Col>
                  <button
                    id="form-submit"
                    aria-label="Finalizar Pedido"
                    type="submit"
                    className={styles.finishOrderButton}
                    disabled={disableButton}
                  >
                    Finalizar Pedido
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </>
      ) : (
        <>
          <Row className="mt-3">
            <Col xs={12}>
              <p className={styles.binMessage}>{invalidBinMessage}</p>
            </Col>
            <Col xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <button
                type="button"
                aria-label="voltar"
                className={classNames(
                  styles.backButton,
                  styles.backButtonMsg,
                  'btn-form',
                )}
                onClick={() => {
                  setInvalidBinMessage(null)
                  // scrollTo('#stepper')
                }}
              >
                Voltar
              </button>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}
