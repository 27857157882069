import * as Actions from "../actions";

const initialState = {
  cart: [],
  cards: [],
  currentPaymentTab: false,
  paymentInfo: {},
  addressInfo: {},
  billingAddress: {},
  orderSubtotal: null,
  orderFinalValue: null,
  giftVouchers: null,
  useGiftVouchers: true,
  usePix: false,
  usePicPay: false,
  useAme: false,
  sendingRequest: false,
  processResult: null,
  steps: [
    { complete: false, description: "Endereço", key: "address" },
    { complete: false, description: "Pagamento", key: "payment" },
    { complete: false, description: "Confirmar dados", key: "confirmation" },
    { complete: false, description: "Finalizado", key: "finish" },
  ],
  currentStep: { key: "address", index: 0 },
  loading: false,
};

const orderCheckoutReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CARDS: {
      return {
        ...state,
        cards: action.payload,
      };
    }
    case Actions.GET_GIFT_VOUCHERS: {
      return {
        ...state,
        giftVouchers: action.payload,
      };
    }
    case Actions.SET_ORDER_SUBTOTAL: {
      return {
        ...state,
        orderSubtotal: action.payload,
      };
    }
    case Actions.SET_ORDER_FINAL_VALUE: {
      return {
        ...state,
        orderFinalValue: action.payload,
      };
    }
    case Actions.SET_CART: {
      return {
        ...state,
        cart: action.payload,
      };
    }
    case Actions.SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.payload,
      };
    }
    case Actions.SET_PAYMENT_INFO: {
      return {
        ...state,
        paymentInfo: action.payload,
      };
    }
    case Actions.SET_CURRENT_PAYMENT_TAB: {
      return {
        ...state,
        currentPaymentTab: action.payload,
      };
    }
    case Actions.SET_ADDRESS_INFO: {
      return {
        ...state,
        addressInfo: action.payload,
      };
    }
    case Actions.SET_BILLING_ADDRESS: {
      return {
        ...state,
        billingAddress: action.payload,
      };
    }
    case Actions.SET_USE_GIFT_VOUCHERS: {
      return {
        ...state,
        useGiftVouchers: action.payload,
      };
    }
    case Actions.SET_USE_PIX: {
      return {
        ...state,
        usePix: action.payload,
        useAme: false,
        usePicPay: false,
      };
    }
    case Actions.SET_USE_AME: {
      return {
        ...state,
        useAme: action.payload,
        usePix: false,
        usePicPay: false,
      };
    }
    case Actions.SET_USE_PIC_PAY: {
      return {
        ...state,
        usePicPay: action.payload,
        useAme: false,
        usePix: false,
      };
    }
    case Actions.SET_PROCESS_RESULT: {
      return {
        ...state,
        processResult: action.payload,
      };
    }
    case Actions.SET_STEPS: {
      return {
        ...state,
        steps: action.payload,
      };
    }
    case Actions.SET_COUPON: {
      return {
        ...state,
        coupon: action.payload,
      };
    }
    case Actions.SET_SENDING_REQUEST: {
      return {
        ...state,
        sendingRequest: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default orderCheckoutReducer;
