import { SvgIcon } from "@mui/material";
import React from "react";

export const ChevronDown = (props) => (
  <SvgIcon
    {...props}
    style={{
      transition: "transform 0.5s ease-in-out",
      transform: props.animate ? "rotate(180deg)" : "none",
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M4.52014 7.5332L10.2694 13.2825L16.0186 7.5332"
        stroke={props.color}
        strokeWidth="1.37982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
