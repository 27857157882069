import * as Actions from "store/actions/pre_partners";

const LAST_STEP = 7;

const saveLocalStorage = (data) => {
  localStorage.setItem("pre_partners", JSON.stringify(data));
}
const storage = JSON.parse(localStorage.getItem("pre_partners"));

const INITIAL_STATE = storage ? {...storage }  : {
  indexStep: 0,
  formsData: {},
  lastStep: LAST_STEP,
  showAddress: false,
  hasConfirmData: false,
  id: "",
  formsPayment: {},
}

export default (state = INITIAL_STATE, action ) => {
  switch (action.type) {
    case Actions.SET_NEXT_STEP:
      saveLocalStorage({ ...state, indexStep: action.payload });
      return { ...state, indexStep: action.payload };

    case Actions.SET_PREV_STEP:
      saveLocalStorage({ ...state, indexStep: action.payload });
      return { ...state, indexStep: action.payload };

    case Actions.INFO_FORMS:
      saveLocalStorage({ ...state, formsData: {...state.formsData, ...action.payload }});
      return { ...state, formsData: {...state.formsData, ...action.payload }};

    case Actions.SHOW_ADDRESS_FORMS:
      return { ...state, showAddress: action.payload };

    case Actions.PAYMENT_METHODS:
      saveLocalStorage({ ...state, formsPayment: action.payload });
      return { ...state, formsPayment: action.payload };

    case Actions.CLEAR_USER_INFO:
      saveLocalStorage({ ...state, formsData: {} });
      return {...state, formsData: {}};

    case Actions.CLEAR_PAYMENT:
      saveLocalStorage({ ...state, formsPayment: {} });
      return {...state, formsPayment: {}};

    case Actions.HAS_CONFIRM_DATAS:
      saveLocalStorage({ ...state, hasConfirmData: action.payload });
      return { ...state, hasConfirmData: action.payload };

    case Actions.SET_ID:
      saveLocalStorage({ ...state, id: action.payload });
      return { ...state, id: action.payload };

    default:
      return state;
  }
}
