import { SvgIcon } from "@mui/material";
import React from "react";

export const ArrowRight = ({color="white", ...props}) => (
  <SvgIcon
    {...props}
    style={{
      transition: "transform 0.5s ease-in-out",
      transform: props.animate ? "rotate(180deg)" : "none",
      width: props.width ? props.width : "14px",
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill={color}
    >
      <path
        d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75V5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.696699C9.46447 0.403806 8.98959 0.403806 8.6967 0.696699C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75H14V5.25H1V6.75Z"
        fill={color}
      />
    </svg>
  </SvgIcon>
);
