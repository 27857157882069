import React from "react";
import clsx from "clsx";
import css from "../../index.module.scss";
import useIsMobile from "utils/useIsMobile";

export default function ({ offer }) {
  const hasDescriptionPTags = /<p>.*?<\/p>/i.test(offer.description);
  const hasRulesPTags = /<p>.*?<\/p>/i.test(offer?.rules);
  const { isMobile } = useIsMobile();

  return (
    <div
      style={{
        maxWidth: 700,
        marginLeft: !isMobile ? 0 : "auto",
        marginRight: !isMobile ? 0 : "auto",
        paddingLeft: !isMobile ? 0 : 100,
      }}
    >
      <>
        {hasDescriptionPTags ? (
          <div
            dangerouslySetInnerHTML={{ __html: offer.description }}
            className={clsx(
              "font-weight-bold",
              css["color-darkgray"],
              css[`${isMobile ? "font-size-md" : "font-size-lg"}`]
            )}
            style={{
              fontWeight: 900,
              fontSize: 22,
              marginTop: !isMobile ? 20 : 0,
            }}
          />
        ) : (
          <p
            className={clsx(
              "font-weight-bold",
              css["color-darkgray"],
              css[`${isMobile ? "font-size-md" : "font-size-lg"}`]
            )}
            style={{
              fontWeight: 900,
              fontSize: 22,
              marginTop: !isMobile ? 20 : 0,
            }}
          >
            {offer.description}
          </p>
        )}
      </>

      <p>{offer?.type === "voucher" && `Utilizar Cupom: ${offer?.voucher}`}</p>
      <>
        {offer?.rules !== "." ? (
          offer?.rules !== "-" ? (
            <>
              {hasRulesPTags ? (
                <div dangerouslySetInnerHTML={{ __html: offer.rules }} />
              ) : (
                <p>{offer?.rules}</p>
              )}
            </>
          ) : null
        ) : null}
      </>
      {/* <p>{offer?.type === "voucher" && `Utilizar Cupom: ${offer?.voucher}`}</p> */}

      <button
        aria-label=" ACESSE AQUI"
        onClick={() => window.open(offer.offer_url, "_blank")}
        className={clsx(css["btn-produto"], css["btn-laranja"])}
      >
        ACESSE AQUI
      </button>
    </div>
  );
}
