import React from 'react'
import OrderCard from '../OrderCard'
import { Divider } from '@mui/material'
import PaymentMethod from '../PaymentMethod'
import ProductList from './ProductList'
import Total from './Total'

const ReviewProducts = () => {
  return (
    <OrderCard>
      <div style={{ width: '100%' }}>
        <ProductList />
        <Divider />
        <Total />
        <PaymentMethod />
      </div>
    </OrderCard>
  )
}

export default ReviewProducts
