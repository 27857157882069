import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    background: "#Fff",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 auto",
    paddingBottom: "30px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "20px",
    },
  },
  cardContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 956,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      padding: "0 16px",
    },
  },
  title: {
    marginTop: "10px",
    color: "#ED9915",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "24px",
    fontStyle: "normal",
    fontFamily: "NeuePlak-Black",
    fontWeight: 400,
    lineHeight: "46px",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      lineHeight: "30px",
      marginTop: "24px",
    },
  },
  headline: {
    color: "#2F2F2F",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "46px",
    fontFamily: "NeuePlak-Black",
    [theme.breakpoints.down("md")]: {
      fontSize: "23px",
      lineHeight: "30px",
      width: "79%",
      marginTop: "8px",
    },
  },
  subheadline: {
    width: "535px",
    height: "85px",
    marginTop: "20px",
    color: "#888",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
    [theme.breakpoints.down("md")]: {
      width: "92%",
      marginBottom: "0px",
      marginTop: "8px",
      fontSize: "14px",
      lineHeight: "19px",
    },
  },
  card: {
    padding: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "288px",
    height: "382px",
    borderRadius: "11.67px",
    background: "#FCF0DC",
    boxShadow: "0px 3.112px 7.78px 0px #FAD392",
    [theme.breakpoints.down("md")]: {
      width: "288px",
      height: "309px",
      justifyContent: "center",
      marginBottom: "32px",
    },
  },
  cardTitle: {
    marginTop: "28px",
    color: "#4F4F4F",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "28.007px",
    fontStyle: "normal",
    fontFamily: "NeuePlak-Bold",
    fontWeight: 400,
    lineHeight: "36.231px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: "23px",
      lineHeight: "30px",
      width: "90%",
      marginTop: "20px",
    },
  },
  cardSubtitle: {
    marginTop: "8px",
    color: "#888",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26.839px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: "14px",
      lineHeight: "20px",
      width: "90%",
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "78.531px",
    height: "78.531px",
    borderRadius: "50%",
    flexShrink: 0,
    background: "#ED9915",
    boxShadow: "-2.334px 4.668px 7.002px rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.down("md")]: {},
  },

  image: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {},
  },
}));
