import React from "react";

import { classNames } from "utils";
import css from "../../index.module.scss";

export default function ({ value = 0 }) {
  if (!value || parseInt(value) <= 0) return null;

  return (
    <div
      className={classNames(
        "d-flex align-self-start position-absolute mt-1 mb-4",
        css["discount-flag-position"]
      )}
    >
      <div className={css["img-desconto-produto"]} style={{ transform: "rotate(180deg)" }}>
        <span className={classNames("text-light pr-3 pl-1", css["textRotate"])}>
          {parseInt(value)}% <span className="text-light">OFF </span>
        </span>
      </div>
    </div>
  );
}
