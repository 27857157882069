import React from 'react'
import { useSelector } from 'react-redux'
import { formatMonetaryValue } from 'utils'
import styles from './index.module.scss'

const Total = () => {
  const paymentInfo = useSelector(
    ({ orderCheckout }) => orderCheckout.paymentInfo,
  )
  const orderSubtotal = useSelector(
    ({ orderCheckout }) => orderCheckout.orderSubtotal,
  )
  const orderFinalValue = useSelector(
    ({ orderCheckout }) => orderCheckout.orderFinalValue,
  )

  return (
    <div className={styles.container}>
      <div className={styles.checkoutContainer}>
        <div className={styles.value}>Subtotal:</div>
        <div className={styles.value}>{formatMonetaryValue(orderSubtotal)}</div>
      </div>
      <div className={styles.checkoutContainer}>
        <div className={styles.value}>Vale-presente:</div>
        <div className={styles.value}>
          {orderSubtotal - orderFinalValue > 0
            ? formatMonetaryValue(orderSubtotal - orderFinalValue)
            : '--'}
        </div>
      </div>
      <br />
      <div className={styles.checkoutContainer}>
        <h2 className={styles.total}>Total</h2>
        {paymentInfo.installments > 1 && (
          <small
            style={{
              textAlign: 'right',
              marginTop: '-0.5em',
              marginBottom: '0.5em',
            }}
          >
            (com juros)
          </small>
        )}
        <p className={styles.checkoutTotal}>
          {paymentInfo.installments > 1
            ? formatMonetaryValue(
                paymentInfo.installments_value * paymentInfo.installments,
              )
            : formatMonetaryValue(orderFinalValue)}
        </p>
      </div>
    </div>
  )
}

export default Total
