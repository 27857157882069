import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form, FormGroup } from 'react-bootstrap'

import AddIcon from '@material-ui/icons/Add'
import AddressCard from 'components/address/AddressCard'

import * as Actions from '../../store/actions'

import { getAddresses } from 'services/api'
import { scrollTo } from 'utils'
import styles from './index.module.scss'
import { CircularProgress, Radio } from '@material-ui/core'
import AddressDialog from 'components/address/AddressDialog'
import OrderCard from '../OrderCard'
import OrderCardHeader from '../OrderCard/OrderCardHeader'
import OrderCardStep from '../OrderCard/OrderCardStep'
import OrderCardTitle from '../OrderCard/OrderCardTitle'
import OrderCardContent from '../OrderCard/OrderCardContent'
import { Box, Dialog, DialogTitle } from '@mui/material'

export default function () {
  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' })

  const dispatch = useDispatch()
  const [initialLoading, setInitialLoading] = React.useState(false)
  const [showDialog, setShowDialog] = React.useState(false)
  const [addresses, setAddresses] = React.useState(null)
  const [selectedAddress, setSelectedAddress] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const selAddress = addresses?.find((a) => a.id === selectedAddress?.id)

  const addressInfo = useSelector(
    ({ orderCheckout }) => orderCheckout.addressInfo,
  )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
    // setSelectedValue(value)
  }

  const validateAddress = (address) => {
    address.city = address.city.replace(/\s\s+/g, ' ').trim()
    address.neighborhood = address.neighborhood.replace(/\s\s+/g, ' ').trim()
    address.number = address.number.replace(/\s\s+/g, ' ').trim()
    address.state = address.state.replace(/\s\s+/g, ' ').trim()
    address.street = address.street.replace(/\s\s+/g, ' ').trim()
    address.zip_code = address.zip_code.replace(/\s\s+/g, ' ').trim()

    if (
      address.city === '' ||
      address.neighborhood === '' ||
      address.number === '' ||
      address.state === '' ||
      address.street === '' ||
      address.zip_code === ''
    ) {
      return false
    } else {
      return true
    }
  }

  const onSubmit = async (data) => {
    if (validateAddress(selectedAddress)) {
      dispatch(Actions.setAddressInfo({ ...data, ...selectedAddress }))
      dispatch(Actions.setBillingAddress({ ...data, ...selectedAddress }))
      dispatch(Actions.changeStep('next'))
      scrollTo('#stepper')
    } else {
      alert('Endereço inválido!')
    }
  }

  const fetchAddresses = useCallback(() => {
    setInitialLoading(true)
    getAddresses()
      .then((response) => {
        setAddresses(response)
        if (Array.isArray(response) && response.length > 0) {
          if (addressInfo && Object.keys(addressInfo).length > 0) {
            setSelectedAddress(addressInfo)
          } else {
            let defaultAddress = response.find((add) => add.default)
            setSelectedAddress(defaultAddress || response[0])
          }
        }
      })
      .finally(() => setInitialLoading(false))
  }, [addressInfo])

  React.useEffect(() => {
    fetchAddresses()
  }, [fetchAddresses])

  return (
    <OrderCard>
      <OrderCardHeader>
        <OrderCardStep>2</OrderCardStep>
        <OrderCardTitle>Endereços</OrderCardTitle>
      </OrderCardHeader>
      <OrderCardContent>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          height={'100%'}
        >
          <AddressDialog
            show={showDialog}
            onClose={() => {
              setShowDialog(false)
              fetchAddresses()
            }}
          />

          <SimpleDialog
            selectedAddress={selectedAddress}
            setSelectedAddress={(address) => setSelectedAddress(address)}
            addresses={addresses}
            open={open}
            onClose={handleClose}
          />

          {initialLoading ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  // sx={{
                  //   '@media (max-width: 768px)': {
                  //     flexDirection: 'column',
                  //   },
                  // }}
                >
                  {selectedAddress?.id && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          backgroundColor: '#fff',
                          width: '269px',
                          borderRadius: '5px',
                          padding: '16px 16px 16px 0px',

                          '@media (max-width: 768px)': {
                            padding: '8px 8px 8px 0px',
                            width: '176px',
                          },
                        }}
                      >
                        <Radio
                          checked={true}
                          value={selectedAddress.id}
                          color="secondary"
                          name="address"
                          size="small"
                        />

                        <AddressCard
                          address={selAddress}
                          className={'flex-grow-1'}
                          style={{ cursor: 'pointer' }}
                        />
                      </Box>
                    </Box>
                  )}
                  <div
                    className={styles.addButton}
                    onClick={() => setShowDialog(true)}
                  >
                    <AddIcon />
                    <Box className={styles.add}>Adicionar</Box>
                  </div>
                </Box>
              </FormGroup>
            </Form>
          )}
        </Box>
        <Box
          variant="text"
          onClick={handleClickOpen}
          className={styles.changeAddress}
        >
          Alterar endereço
        </Box>
      </OrderCardContent>
    </OrderCard>
  )
}

function SimpleDialog(props) {
  const {
    onClose,
    selectedValue,
    open,
    addresses,
    setSelectedAddress,
    selectedAddress,
  } = props

  const handleClose = () => {
    onClose(selectedValue)
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      <Box sx={{ background: '#ECECEE', padding: '29px 42px' }}>
        <DialogTitle className={styles.dialogTitle}>
          Alterar endereço
        </DialogTitle>
        <Box
          sx={{
            pt: 0,
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: 4,

            '@media (max-width: 768px)': {
              flexDirection: 'column',
            },
          }}
        >
          {addresses?.map((address) => (
            <Box
              onClick={() => setSelectedAddress(address)}
              key={address.id}
              sx={{
                display: 'flex',
                backgroundColor: '#fff',
                marginBottom: 4,
                width: '269px',
                borderRadius: '5px',
                padding: '16px 16px 16px 0px',

                '@media (max-width: 768px)': {
                  padding: '8px 8px 8px 0px',
                  width: '176px',
                  marginBottom: 2,
                },
              }}
            >
              <Radio
                checked={selectedAddress?.id === address.id}
                onChange={() => setSelectedAddress(address)}
                value={address.id}
                color="secondary"
                name="address"
              />

              <AddressCard
                address={address}
                className={'flex-grow-1'}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          ))}
        </Box>
        <Box onClick={handleClose} className={styles.goBackButton}>
          Voltar
        </Box>
      </Box>
    </Dialog>
  )
}
