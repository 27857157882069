import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoggedIn } from "store/selectors";
import { classNames } from "utils";
import useIsMobile from "utils/useIsMobile";
import ClientMenu from "components/clientMenu/index";
import { MenuIcon, SearchIcon, LoginIcon, CartIcon } from "assets/icons";
import { useSideWindowAction } from "context/SideWindow-context"
import styles from "./index.module.scss";

const NewLayoutHeader = () => {
  const history = useHistory();
  const partnerSettings = useSelector((state) => state.global.partner);
  const userInfos = useSelector((state) => state.user.info);
  const isMobile = useIsMobile();
  const logged = useSelector(isLoggedIn);
  const { openSideWindow } = useSideWindowAction()

  const [searchValue, setSearchValue] = useState("");
  const [clientMenuModal, setClientMenuModal] = useState(false);

  const [totalItemCart, setTotalItemCart] = useState(0);

  const checkoutInfo = useSelector(({ product }) => product?.checkout);

  const order = JSON.parse(localStorage.getItem("AU_order"));

  useEffect(() => {
    if(order) {
      setTotalItemCart(order.order.length);
    }
  }, [checkoutInfo, order]);

  const getCompanyLogo = () => {
    return partnerSettings?.logo || "./logo.png";
  };

  const handleSearch = (e) => {
    if(searchValue && e.key === "Enter") {
      history.push(`/busca/${searchValue}`);
    }
  }

  // const handleUser = () => {
  //   if (logged) {
  //     history.push("/usuario")
  //   }

  //   if(!logged) {
  //     history.push("/partners")
  //   }
  // }

  return (
    <div className={classNames("header", styles.container)}>
      <div className={styles.menu} onClick={() => openSideWindow('MOBILE_MENU')}>
        <MenuIcon color={partnerSettings?.main_colors?.primary_color} />
      </div>
      <div
        className={styles.logoContainer}
        onClick={() => history.push("/ofertas")}
      >
        <img
          src={getCompanyLogo()}
          alt="logo"
          className={classNames("main-logo", styles.mainLogo)}
        />
      </div>
      <div className={styles.searchBar}>
        <SearchIcon />
        <input
          type="text"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          onKeyDown={handleSearch}
          className={styles.input}
          placeholder={ isMobile
            ? "O que você está procurando?"
            : "O que você está procurando? Produtos, serviços e mais!"
          }
        />
      </div>
      <div className={styles.user}>
        <div
          className={styles.iconLogin}
          onClick={() => setClientMenuModal(!clientMenuModal)}
        >
          <LoginIcon color={partnerSettings?.main_colors?.primary_color} />
          <ClientMenu
            onHeader={true}
            open={clientMenuModal}
            onHide={() => setClientMenuModal(false)}
          />
        </div>
        <div className={styles.name}>
          {logged
            ? <p>Olá, <span>{userInfos?.name?.split(" ")[0]}</span></p>
            : <p>Login</p>
          }
        </div>
        <div
          className={styles.iconCart}
          onClick={() => openSideWindow('CART_WINDOW')}
        >
          {totalItemCart > 0 && (<span className={styles.itemCart}>{totalItemCart}</span>)}
          <CartIcon color={partnerSettings?.main_colors?.primary_color}/>
        </div>
      </div>
    </div>
  )
}

export default NewLayoutHeader;
