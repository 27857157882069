import React from 'react';
import { useStyles } from './useStyle';

const QuizResult = (props) => {
  const classes = useStyles();

  const {
    info,
    gift_voucher,
  } = props.result;

  const finishQuiz = () => {
    props.btnFinish();
    localStorage.removeItem('hasQuiz');
  }

  return (
    <div className={classes.container}>
       <h2 className={classes.title}>
          {info?.result}
        </h2>
        <p className={classes.description}>
          {info?.description}
        </p>
        {info?.image && (
          <img src={info.image} alt='quiz' className={classes.image} />
        )}
        {gift_voucher?.token && (
          <p className={classes.gift}>
            Vale presente <span>{gift_voucher?.token}</span>
          </p>
        )}
        {info?.finish_button && (
          <button onClick={finishQuiz} className={classes.btn}>
            {info.finish_button}
          </button>
        )}
    </div>
  )
}

export default QuizResult;
