import React, { useState } from 'react'
import { useSideWindowAction } from 'context/SideWindow-context'
import EmptyCart from '../emptyCart'
import ItemsCart, { formmattedPrice } from '../itemsCart'

import styles from './styles.module.scss'

const SideWindowCart = () => {
  const { closeSideWindow } = useSideWindowAction()
  const order = JSON.parse(localStorage.getItem('AU_order'))
  
  const [total, setTotal] = useState(0)

  const calcTotal = (items) => {
    const total = items?.reduce((acc, item) => {
      acc += item.quantity * item.value;
      return acc
    }, 0)

    setTotal(total)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>Carrinho</p>
        <button onClick={() => closeSideWindow()}>X</button>
      </div>
      <div className={styles.body}>
        {order?.order?.length >= 1
          ? <ItemsCart order={order} calcTotal={calcTotal}/>
          : <EmptyCart />
        }
      </div>
      <div className={styles.footer}>
        {total > 0 && (
          <button onClick={() => window.open('/checkout', '_self')}>
            <p>Finalizar compra</p>
            <span>R${formmattedPrice(total)}</span>
          </button>
        )}
      </div>
    </div>
  )
}

export default SideWindowCart
