import React from "react";

import { useDispatch, useSelector } from "react-redux";

import styles from "./index.module.scss";
import { classNames } from "utils";
import { useSubscripitionGiftVoucher } from "services/api";
import { TextFieldController } from "components/forms";
import { useForm } from "react-hook-form";
import { Form, Row } from "react-bootstrap";
import * as Actions from "./store/actions";

const situationMessages = {
  invalid: "Esse código não é válido! Por favor verifique o código digitado.",
  already_taken: "Esse código já está sendo utilizado.",
  unavailable: "Esse código não está mais disponível.",
};

export default function () {
  const dispatch = useDispatch();
  const { handleSubmit, control, errors } = useForm();
  const subscriptionPackageId = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.plan.id
  );
  const availableAmount = useSelector(
    ({ subscriptionCheckout }) =>
      subscriptionCheckout?.giftVouchers?.available_amount || 0
  );

  const plan = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.plan
  );

  const [couponSituation, setCouponSituation] = React.useState();

  const onSubmit = React.useCallback(
    async (data) => {
      let params = {
        token: data.code,
        subscription_package_id: subscriptionPackageId,
      };

      useSubscripitionGiftVoucher(params).then((data) => {
        if (data.success) {
          dispatch(Actions.getSubscriptionGiftVouchers());
          setCouponSituation();

          if (parseFloat(plan.price_in_money - availableAmount) <= 0) {
            dispatch(Actions.changeStep("next"));
          }
        } else {
          setCouponSituation(data.data.reason);
        }
      });
    },
    [availableAmount, dispatch, plan.price_in_money, subscriptionPackageId]
  );

  React.useEffect(() => {
    dispatch(Actions.getSubscriptionGiftVouchers());
  }, [dispatch]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.couponContainer}>
      <TextFieldController
        label="Código de desconto"
        name="code"
        errors={errors}
        control={control}
      />
      {couponSituation && (
        <span className={styles.couponSituationMessage}>
          {situationMessages[couponSituation]}
        </span>
      )}
      <button
        aria-label="aplicar desconto"
        type="submit"
        className={classNames("custom-btn-laranja btn-form mt-2")}
      >
        Aplicar desconto
      </button>

      {availableAmount > 0 && (
        <Row className={styles.giftCardCheckedRow}>
          <span className="font-weight-bold" style={{ fontSize: "0.85em" }}>
            Utilizando saldo de R${" "}
            {availableAmount.toFixed(2).toString().replace(".", ",")}
          </span>
        </Row>
      )}
    </Form>
  );
}
