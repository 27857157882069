import React from 'react'
import { Controller } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiSelect-root': {
      backgroundColor: 'white',
    },
    width: '100%',
  },
  root: {
    '& .MuiInputBase-input': {
      backgroundColor: 'white',
      borderRadius: 4,
      paddingTop: '13px',
      paddingBottom: '13px',
    },
    '& fieldset': {
      borderColor: '#ECECEE',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
}))

const SelectFieldController = ({
  name,
  label,
  errors,
  defaultValue,
  handleChange,
  control,
  options,
  required = true,
}) => {
  const classes = useStyles()
  let error = Boolean(errors && errors[name])

  const BaseComponent = (props) => {
    let customOnChange = (event) => {
      if (typeof props.onChange === 'function') {
        props.onChange(event)
      }
      if (typeof handleChange === 'function') {
        handleChange(event)
      }
    }

    return (
      <>
        <label htmlFor="">{label}</label>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          error={error}
          InputLabelProps={{
            shrink: false,
            style: {
              position: 'relative',
              top: '-20px',
              left: '-13px',
            },
          }}
        >
          <Select
            className={classes.root}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            {...props}
            labelId={`select-${name}`}
            id={name}
            name={name}
            onChange={customOnChange}
          >
            {options &&
              options.map((option, index) => (
                <MenuItem value={option.value} key={index}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>

          {error && <FormHelperText>Campo obrigatório</FormHelperText>}
        </FormControl>
      </>
    )
  }

  return control ? (
    <Controller
      as={(props) => <BaseComponent {...props} />}
      defaultValue={defaultValue || ''}
      name={name}
      rules={{ required: required }}
      control={control}
    />
  ) : (
    <BaseComponent
      required={required}
      defaultValue={defaultValue || ''}
      name={name}
    />
  )
}

export default SelectFieldController
