import React from "react";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import friends from "./images/friends.png";
import { useStyles } from "./useStyle";
import CtaButton from "components/ctaButton";
import FallbackImage from "components/fallbackImage";
import { useHistory } from "react-router-dom";
const Headline = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={6} className={classes.headlineContainer}>
        <Typography className={classes.headline}>
          Mais entretenimento,
          <br /> mais diversão,{" "}
          <span className={classes.maisAlegria}>+alegria!</span>
        </Typography>
        <Typography className={classes.subheadline}>
          Saiba tudo sobre o único clube de vantagens focado <br />
          <strong>em entretenimento, com diferenciais exclusivos.</strong>
        </Typography>
        <CtaButton onClick={() => history.push("/planos")} />
      </Grid>
      <FallbackImage width={isMobile ? 272 : 488} height={isMobile ? 272 : 482}>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <Box className={classes.imageBackground}>
            <img src={friends} alt="headline" className={classes.image} />
          </Box>
        </Grid>
      </FallbackImage>
    </Grid>
  );
};

export default Headline;
