import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import LazyLoad from "react-lazyload";

import { classNames } from "utils";
import styles from "./index.module.scss";
import * as Actions from "../../store/actions";
import CreditCard from "./CreditCard";
import Box from "@material-ui/core/Box";
import Pix from "./Pix";
// import Ame from "./AmeDigital";

import PixIcon from "./imgs/pix1.png";
import CreditCardIcon from "./imgs/card1.png";
// import AmeDigital from "./imgs/ame1.png";
// import PicPayImage from "./imgs/pic1.png";

import { altImage, srcImg } from "utils/helper";
// import PicPay from "./PicPay";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box className="pt-3">{children}</Box>}
    </div>
  );
}

function TabOption(props) {
  const { text, icon, index, ...other } = props;

  return (
    <Tab
      {...other}
      id={`tab-${index}`}
      aria-controls={`tabpanel-${index}`}
      label={
        <div className="d-flex flex-column justify-content-center w-100">
          <span>{icon}</span>
          {/* <span>{text}</span> */}
        </div>
      }
    />
  );
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function () {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = React.useState(false);
  const partnerSettings = useSelector((state) => state.global.partner);
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  const orderFinalValue = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.orderFinalValue
  );
  const useGiftVoucher = useSelector(
    ({ subscriptionCheckout }) => subscriptionCheckout.useGiftVouchers
  );

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (useGiftVoucher && orderFinalValue === 0) {
      dispatch(Actions.changeStep("next"));
    }
  }, [useGiftVoucher, orderFinalValue, dispatch]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const hasCreditCardPaymentMethod =
    partnerSettings.payment_info.payment_options.includes("credit_card") &&
    partnerSettings.payment_info.cc_subscription_payment_gateway;

  const hasPixPaymentMethod =
    partnerSettings.payment_info.payment_options.includes("pix") &&
    partnerSettings.payment_info.pix_subscription_payment_gateway;

  return (
    <div className={styles.rootContainer}>
      <Row className="d-flex">
        <Col className={classNames(styles.infosWrapper)}>
          <h1 className={styles.sectionTitle}>Pagamento via</h1>
          <Paper square>
            {partnerSettings.payment_info.payment_options.length === 0 ? (
              <p className={styles.noPayment}>
                Não há nenhuma forma de pagamento disponível.
              </p>
            ) : (
              <Tabs
                value={currentTab}
                onChange={handleChange}
                indicatorColor="secondary"
                scrollable={windowDimensions.width < 700 ? "false" : "true"}
                variant={
                  windowDimensions.width < 700 ? "standard" : "scrollable"
                }
                orientation={
                  windowDimensions.width < 700 ? "vertical" : "horizontal"
                }
                scrollButtons="on"
                className={styles.tabs}
              >
                {partnerSettings.payment_info.payment_options
                  .filter((item) => item !== "ame" && item !== "picpay")
                  .map((method, i) => {
                    if (
                      method === "credit_card" &&
                      !hasCreditCardPaymentMethod
                    ) {
                      return null;
                    } else if (method === "pix" && !hasPixPaymentMethod) {
                      return null;
                    } else {
                      return (
                        <TabOption
                          index={i}
                          key={i}
                          // text={tabOptionText(method)}
                          icon={
                            <LazyLoad once>
                              <img
                                src={srcImg(
                                  method,
                                  CreditCardIcon,
                                  PixIcon
                                  // AmeDigital,
                                  // PicPayImage
                                )}
                                alt={altImage(method)}
                                className={styles.paymentMethodIcon}
                                // style={{
                                //   height: i === 3 ? 80 : 25,
                                //   width: i === 3 ? 80 : 25,
                                // }}
                              />
                            </LazyLoad>
                          }
                        />
                      );
                    }
                  })}
              </Tabs>
            )}
          </Paper>
          {partnerSettings.payment_info.payment_options.map((method, i) => (
            <TabPanel value={currentTab} key={i} index={i}>
              {method === "credit_card" && hasCreditCardPaymentMethod && (
                <CreditCard />
              )}
              {method === "pix" && hasPixPaymentMethod && <Pix />}
              {/* {method === "ame" && <Ame />}
              {method === "picpay" && <PicPay />} */}
            </TabPanel>
          ))}
        </Col>
      </Row>
    </div>
  );
}
